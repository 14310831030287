import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./SearchField.module.scss";
import { useEffect, useRef, useState } from "react";
import { addToHistory, selectSearchQuery, setSearchQuery, } from "@src/entities/search-popup";
import { useCreateSearchQueryMutation } from "@src/shared/api";
import { useAppDispatch, useAppSelector } from "@src/shared/hooks";
import { SearchIcon, CloseIcon } from "@src/shared/ui";
export var SearchField = function (_a) {
    var onChangeText = _a.onChangeText, onSubmit = _a.onSubmit;
    var dispatch = useAppDispatch();
    var createQuery = useCreateSearchQueryMutation()[0];
    var inputRef = useRef(null);
    var timerId = useRef(null);
    var searchValue = useAppSelector(selectSearchQuery);
    var _b = useState(""), currentValue = _b[0], setCurrentValue = _b[1];
    useEffect(function () {
        setCurrentValue(searchValue);
        if (inputRef.current && searchValue) {
            inputRef.current.focus();
        }
    }, [searchValue]);
    useEffect(function () {
        timerId.current = setTimeout(function () {
            dispatch(setSearchQuery(currentValue));
        }, 400);
        return function () {
            if (timerId.current) {
                clearTimeout(timerId.current);
            }
        };
    }, [currentValue, timerId]);
    var changeTextHandler = function (val) {
        if (onChangeText)
            onChangeText(val);
        setCurrentValue(val);
    };
    var clearFieldHandler = function () {
        if (onChangeText)
            onChangeText("");
        setCurrentValue("");
    };
    var submitHandler = function () {
        if (!currentValue)
            return;
        createQuery(currentValue);
        addToHistory({ text: currentValue, catalog: null }, "query");
        window.location.href = "/search/?search=".concat(currentValue);
        if (onSubmit)
            onSubmit(currentValue);
    };
    var enterKeyDownHandler = function (event) {
        // Проверка кода нажатой клавиши
        if (event.key === "Enter") {
            submitHandler();
        }
    };
    return (_jsxs("div", { className: styles.root, children: [_jsx("button", { className: styles.icon, type: "button", onClick: submitHandler, children: _jsx(SearchIcon, {}) }), _jsx("input", { ref: inputRef, className: styles.field, type: "text", placeholder: "\u041F\u043E\u0438\u0441\u043A \u0432 \u043A\u0430\u0442\u0430\u043B\u043E\u0433\u0435", value: currentValue, onChange: function (e) { return changeTextHandler(e.target.value); }, onKeyDown: enterKeyDownHandler }), !!currentValue && (_jsx("button", { className: styles.closeButton, type: "button", onClick: clearFieldHandler, children: _jsx(CloseIcon, {}) }))] }));
};
