import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styles from "./Desktop.module.scss";
import { useState } from "react";
import cn from "classnames";
import { Bonus } from "../../Bonus";
import { ProductImages } from "../../CardImages";
import { CardSpecification } from "../../CardSpecification";
import { CardTags } from "../../CardTags";
import { OldPrice } from "../../OldPrice";
import { Rating } from "../../Rating";
import { numberWithSpaces } from "@src/shared/lib";
import { EmptyBoxIcon, SmallButton, Tag, Text } from "@src/shared/ui";
export var Desktop = function (_a) {
    var _b, _c, _d;
    var sku = _a.sku, name = _a.name, url = _a.url, price = _a.price, discountedPrice = _a.discountedPrice, status = _a.status, images = _a.images, rating = _a.rating, reviewsCount = _a.reviewsCount, bonusesAmount = _a.bonusesAmount, tags = _a.tags, specifications = _a.specifications, slots = _a.slots, _e = _a.withImagesPagination, withImagesPagination = _e === void 0 ? true : _e, _f = _a.isHoverable, isHoverable = _f === void 0 ? true : _f, onClickLink = _a.onClickLink;
    var isAvailable = status.code === "IN_STOCK" || status.code === "LIMITED";
    var currentButton = null;
    if (slots === null || slots === void 0 ? void 0 : slots.inCartButton) {
        currentButton = isAvailable ? (slots === null || slots === void 0 ? void 0 : slots.inCartButton) : (_jsx(SmallButton, { icon: _jsx(EmptyBoxIcon, {}), isDisabled: true }));
    }
    var _g = useState(false), isVisibleTags = _g[0], setIsVisibleTags = _g[1];
    var _h = useState(false), isHovered = _h[0], setIsHovered = _h[1];
    var _j = useState(false), isShowSpecs = _j[0], setIsShowSpecs = _j[1];
    var handleToggleVisibility = function (value) {
        setIsVisibleTags(value);
    };
    var handleHover = function () {
        setIsHovered(true);
    };
    var handleLeaveHover = function () {
        setIsHovered(false);
        setIsVisibleTags(false);
        setIsShowSpecs(false);
    };
    var handleShowSpecs = function (value) {
        setIsShowSpecs(value);
    };
    var clickProductLinkHandler = function (event) {
        event.preventDefault();
        if (onClickLink)
            onClickLink();
        window.location.href = url;
    };
    var cardContent = (_jsxs(_Fragment, { children: [(tags === null || tags === void 0 ? void 0 : tags.length) > 0 && (_jsx("div", { className: styles.cardTagsWrapper, children: _jsx(CardTags, { tags: tags, isExpanded: isVisibleTags, onShow: handleToggleVisibility, isDesktop: true }) })), _jsxs("a", { href: url, className: styles.main, onMouseEnter: handleHover, onClick: clickProductLinkHandler, children: [(slots === null || slots === void 0 ? void 0 : slots.favouriteBtn) && (_jsx("div", { className: styles.favourite, children: slots === null || slots === void 0 ? void 0 : slots.favouriteBtn })), (slots === null || slots === void 0 ? void 0 : slots.compareBtn) && (_jsx("div", { className: styles.compare, children: slots === null || slots === void 0 ? void 0 : slots.compareBtn })), _jsx("div", { className: styles.imagesWrapper, children: _jsx("div", { className: styles.images, children: _jsx(ProductImages, { images: images.slice(0, 7), isNotAvailable: !isAvailable, withPagination: withImagesPagination, isDesktop: true }) }) }), _jsxs("div", { className: styles.rating, children: [reviewsCount > 0 && (_jsx(Rating, { isDesktop: true, rating: rating, reviewsCount: reviewsCount })), _jsxs(Text, { color: "#C0C0C0", variant: "body2", isDesktop: true, children: ["\u041A\u043E\u0434: ", sku] })] }), _jsx("div", { className: styles.nameWrapper, children: _jsx(Text, { variant: "body1", isDesktop: true, className: styles.name, children: name }) }), bonusesAmount !== undefined && (_jsx("div", { className: cn(styles.bonus, (_b = {},
                            _b[styles.hide] = isShowSpecs && bonusesAmount <= 0,
                            _b)), children: bonusesAmount > 0 && (_jsx(Bonus, { isDesktop: true, bonusesAmount: bonusesAmount })) })), _jsxs("div", { className: cn(styles.discountedPrice, (_c = {},
                            _c[styles.hide] = isShowSpecs && price === discountedPrice && isAvailable,
                            _c)), children: [price !== discountedPrice && isAvailable && (_jsxs(_Fragment, { children: [_jsx(OldPrice, { isDesktop: true, price: price, discountedPrice: discountedPrice }), _jsxs(Tag, { isDesktop: true, backgroundColor: "#8976DE", children: ["-", numberWithSpaces(price - discountedPrice), " \u20BD"] })] })), !isAvailable && (_jsx(Text, { isDesktop: true, variant: "body1", children: "\u041D\u0435\u0442 \u0432 \u043D\u0430\u043B\u0438\u0447\u0438\u0438" }))] }), _jsxs("div", { className: styles.purchase, children: [_jsxs(Text, { variant: "bodyPrice", className: styles.price, isDesktop: true, children: [numberWithSpaces(discountedPrice), " \u20BD"] }), !!currentButton && (_jsx("div", { className: styles.button, children: currentButton }))] })] })] }));
    var bonusesBlockHeight = isShowSpecs && bonusesAmount <= 0 ? 28 : 0;
    var discountedPriceHeight = isShowSpecs && price === discountedPrice && isAvailable ? 30 : 0;
    var cardSpecification = (_jsx(CardSpecification, { onClickMoreDetail: handleShowSpecs, data: specifications, isExpanded: isShowSpecs, addedHeight: bonusesBlockHeight + discountedPriceHeight }));
    return isHoverable && isHovered ? (_jsx("div", { className: styles.mainWrapper, children: _jsx("div", { className: styles.wrapper, onMouseLeave: handleLeaveHover, children: _jsxs("div", { className: styles.overflow, children: [_jsx("div", { className: cn(styles.cardLayer, (_d = {}, _d[styles.moveUp] = isShowSpecs, _d)), children: cardContent }), _jsx("div", { className: styles.specsLayer, children: cardSpecification })] }) }) })) : (_jsx("div", { className: styles.mainWrapper, onMouseEnter: handleHover, children: cardContent }));
};
