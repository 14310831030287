import purchase_interactive from '../lib/purchase-interactive-new';

const promotion = document.querySelector('.promotion');

if (promotion) {
  const promotion_layer_all = promotion.querySelectorAll('.promotion-layer');

  promotion_layer_all.forEach(promotion_layer_each);

  const layer_special = promotion.querySelector('.layer-special');
  const layer_product = promotion.querySelector('.layer-product');

  if (layer_product) {
    const list_unit_all = layer_product.querySelectorAll('.list-unit');

    if (list_unit_all.length) {
      list_unit_all.forEach(list_unit_each);
    }

    function list_unit_each(list_unit) {
      // Идентификатор товара
      const data_id = list_unit.getAttribute('data-id');

      const unit_content = list_unit.querySelector('.unit-content');

      const content_indicate = unit_content.querySelector('.content-indicate');

      content_indicate.addEventListener('click', content_indicate_click);

      async function content_indicate_click(event) {
        event.preventDefault();

        if (!content_indicate.classList.contains('indicate_selected')) {
          const response = await purchase_interactive('add', data_id);

          if (response) {
            content_indicate.classList.add('indicate_selected');
          }
        } else {
          const response = await purchase_interactive('delete', data_id);

          if (response) {
            content_indicate.classList.remove('indicate_selected');
          }
        }
      }
    }
  }
}

function promotion_layer_each(promotion_layer) {
  const indicate_previous = promotion_layer.querySelector('.indicate_previous');
  const indicate_next = promotion_layer.querySelector('.indicate_next');

  const layer_count = promotion_layer.querySelector('.layer-count');

  const layer_list = promotion_layer.querySelector('.layer-list');

  // Получаем каждый слайд
  const list_unit_all = layer_list.querySelectorAll('.list-unit');

  // Получаем каждый индикатор
  const count_indicate_all = layer_count.querySelectorAll('.count-indicate');

  // Добавляем события на каждый слайд
  list_unit_all.forEach(list_unit_each);

  if (list_unit_all.length > 1) {
    indicate_previous.style.display = 'block';
    indicate_next.style.display = 'block';

    layer_count.style.display = 'flex';

    // Помечаем первый индикатор как активный
    count_indicate_all[0].classList.add('indicate_selected');

    setTimeout(initial_timeout, 20);

    function initial_timeout() {
      indicate_previous.style.opacity = '1';
      indicate_next.style.opacity = '1';

      layer_count.style.opacity = '1';
    }
  }

  const data_interval = promotion_layer.getAttribute('data-interval');

  let timeout_identifier = setTimeout(indicate_next_click, data_interval);

  let transition = false;

  indicate_previous.addEventListener('click', indicate_previous_click);
  indicate_next.addEventListener('click', indicate_next_click);

  function list_unit_each(list_unit) {
    let countdown_end = list_unit.getAttribute('data-countdown');

    if (promotion_layer.classList.contains('layer-special')) {
      const unit_info = list_unit.querySelector('.unit-info');

      if (countdown_end < 864000) {
        unit_info.classList.remove('info_hidden');
      }

      const info_text = unit_info.querySelector('.info-text');
      const info_countdown = unit_info.querySelector('.info-countdown');

      if (countdown_end > 86400) {
        info_countdown.textContent =  countdown_date(Math.ceil(countdown_end / 86400));
      } else {
        info_text.textContent = 'Закончится';
        info_countdown.textContent = 'Сегодня';
      }

      function countdown_date(countdown_end) {
        if (countdown_end % 100 > 10 && countdown_end % 100 < 20) {
          return `${ countdown_end } дней`;
        } else if (countdown_end % 10 > 1 && countdown_end % 10 < 5) {
          return `${ countdown_end } дня`;
        } else if (countdown_end % 10 > 0 && countdown_end % 10 < 2) {
          return `${ countdown_end } день`;
        } else {
          return `${ countdown_end } дней`;
        }
      }
    }

    if (promotion_layer.classList.contains('layer-product')) {
      const info_button = list_unit.querySelector('.unit-notify > .notify-info > .info-button');

      info_button.classList.remove('button_hidden');

      const button_countdown = info_button.querySelector('.button-countdown');

      button_countdown.textContent = countdown_time(countdown_end--);

      let interval_identifier = setInterval(button_countdown_interval, 1000);

      function button_countdown_interval() {
        button_countdown.textContent = countdown_time(countdown_end--);

        if (countdown_end < 0) clearInterval(interval_identifier);
      }

      function countdown_time(countdown_end) {
        let hs = Math.floor(countdown_end / 3600 % 24);
        let ms = Math.floor(countdown_end / 60 % 60);
        let ss = Math.floor(countdown_end % 60);

        // Возвращаем время в формате '00:00:00'
        return `${ hs < 10 ? '0' + hs : hs }:${ ms < 10 ? '0' + ms : ms }:${ ss < 10 ? '0' + ss : ss }`;
      }
    }
  }

  // Событие по клику назад
  function indicate_previous_click() {
    const list_unit_all = layer_list.querySelectorAll('.list-unit');

    if (!transition) {
      clearTimeout(timeout_identifier);

      transition = true;

      if (list_unit_all.length > 1) {
        const last_unit = list_unit_all[list_unit_all.length - 1];
        const data_unit = last_unit.getAttribute('data-unit');

        layer_list.prepend(last_unit);

        layer_list.style.transform = 'translateX(-100%)';

        count_indicate_all.forEach((count_indicate) => count_indicate_each(count_indicate, data_unit));

        setTimeout(() => {
          layer_list.classList.add('list_transition');
          layer_list.style.transform = null;

          layer_list.addEventListener('transitionend', layer_list_transitionend, { once: true });
        }, 20);

        function layer_list_transitionend() {
          layer_list.classList.remove('list_transition');

          timeout_identifier = setTimeout(indicate_next_click, data_interval);

          transition = false;
        }
      }
    }
  }

  // Событие по клику вперед
  function indicate_next_click() {
    const list_unit_all = layer_list.querySelectorAll('.list-unit');

    if (!transition) {
      clearTimeout(timeout_identifier);

      transition = true;

      if (list_unit_all.length > 1) {
        const current_unit = list_unit_all[0];

        const next_unit = list_unit_all[1];
        const data_unit = next_unit.getAttribute('data-unit');

        count_indicate_all.forEach((count_indicate) => count_indicate_each(count_indicate, data_unit));

        layer_list.classList.add('list_transition');
        layer_list.style.transform = 'translateX(-100%)';

        layer_list.addEventListener('transitionend', layer_list_transitionend, { once: true });

        function layer_list_transitionend() {
          layer_list.append(current_unit);

          layer_list.classList.remove('list_transition');
          layer_list.style.transform = null;

          timeout_identifier = setTimeout(indicate_next_click, data_interval);

          transition = false;
        }
      }
    }
  }

  // Управление индикатором текущего слайда
  function count_indicate_each(count_indicate, data_unit) {
    const indicate_unit = count_indicate.getAttribute('data-unit');

    if (indicate_unit == data_unit) {
      count_indicate.classList.add('indicate_selected');
    } else {
      count_indicate.classList.remove('indicate_selected');
    }
  }
}
