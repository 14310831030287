import { jsx as _jsx } from "react/jsx-runtime";
import { QueriesSection, QueryItem, selectSearchQuery, setSearchQuery, WordItem, } from "@src/entities/search-popup";
import { useGetHintsQuery } from "@src/shared/api";
import { useAppDispatch, useAppSelector } from "@src/shared/hooks";
import { ArrowSearchIcon, SearchIcon } from "@src/shared/ui";
export var HintsSection = function (_a) {
    var _b, _c;
    var _d = _a.isDesktop, isDesktop = _d === void 0 ? false : _d, onSelectSearchQuery = _a.onSelectSearchQuery;
    var dispatch = useAppDispatch();
    var searchQuery = useAppSelector(selectSearchQuery);
    var data = useGetHintsQuery(searchQuery).data;
    var updateSearchQueryHandler = function (word) {
        var newQuery = "";
        if (searchQuery[-1] === " ") {
            newQuery = "".concat(searchQuery, " ").concat(word);
        }
        else {
            var queryWordsArr = searchQuery.split(" ");
            newQuery = "".concat(queryWordsArr.slice(0, -1).join(" "));
            if (newQuery) {
                newQuery += " ".concat(word, " ");
            }
            else {
                newQuery += "".concat(word, " ");
            }
        }
        dispatch(setSearchQuery(newQuery));
    };
    var queriesList = [{ text: searchQuery, catalog: null }];
    var wordsList = [];
    if (!!((_b = data === null || data === void 0 ? void 0 : data.queries) === null || _b === void 0 ? void 0 : _b.length) || !!((_c = data === null || data === void 0 ? void 0 : data.words) === null || _c === void 0 ? void 0 : _c.length)) {
        if ((data === null || data === void 0 ? void 0 : data.queries.length) > 0) {
            queriesList = data === null || data === void 0 ? void 0 : data.queries;
        }
        wordsList = (data === null || data === void 0 ? void 0 : data.words.slice(0, 3)) || [];
    }
    var queriesItems = queriesList.map(function (item) { return (_jsx(QueryItem, { text: item.text, catalog: item.catalog, slots: { startIcon: _jsx(SearchIcon, {}), controlBtnIcon: _jsx(ArrowSearchIcon, {}) }, isDesktop: isDesktop, onClickControl: function () { return onSelectSearchQuery(item); }, onClickQuery: function () { return onSelectSearchQuery(item); } }, item.text)); });
    var wordsItems = wordsList.map(function (item) { return (_jsx(WordItem, { text: item, isDesktop: isDesktop, onClick: function () { return updateSearchQueryHandler(item); } }, item)); });
    return (_jsx(QueriesSection, { slots: { queriesItems: queriesItems, wordsItems: wordsItems }, isDesktop: isDesktop }));
};
