import favorite_interactive from '../../lib/favorite-interactive';
import compare_interactive from '../../lib/compare-interactive';
import {store} from "@src/app/store";
import {favoritesApiSlice} from "../../../../common/js/redux/slices/favoritesApiSlice";
import {compareApiSlice} from "@src/shared/api";
import {correct_width} from "../../lib/correct-width";

const product_control = document.querySelector('.product-control');

if (product_control) {
  const data_id = product_control.getAttribute('data-id');

  const button_favorite = product_control.querySelector('.control-button[data-button="favorite"]');
  const button_compare = product_control.querySelector('.control-button[data-button="compare"]');

  button_favorite.addEventListener('click', button_favorite_click);
  button_compare.addEventListener('click', button_compare_click);

  function button_favorite_click() {
    if (!button_favorite.classList.contains('button_selected')) {
      store.dispatch(favoritesApiSlice.endpoints.addFavoriteProduct.initiate({productId: data_id})).then(res => {
        button_favorite.classList.add('button_selected');
      })
    } else {
      store.dispatch(favoritesApiSlice.endpoints.removeFavoriteProduct.initiate({productId: data_id})).then(res => {
        button_favorite.classList.remove('button_selected');
      })
    }
  }

  function button_compare_click() {
    if (!button_compare.classList.contains('button_selected')) {
      store.dispatch(compareApiSlice.endpoints.addCompareProduct.initiate({productId: data_id})).then(res => {
        if ("error" in res) {
          const result = res.error.data
          if (result.error == 'categoryError') {
            const overlay_error = document.querySelector('.overlay[data-overlay="error"]');

            if (overlay_error) {
              correct_width(true);

              overlay_error.setAttribute('data-id', data_id);

              if (overlay_error.style.display != 'flex') {
                overlay_error.style.display = 'flex';
              }
            }

            return;
          }

          if (result.error == 'exceededLimitError') {
            const overlay_limit = document.querySelector('.overlay[data-overlay="error-limit"]');

            if (overlay_limit) {
              correct_width(true);

              if (overlay_limit.style.display != 'flex') {
                overlay_limit.style.display = 'flex';
              }
            }

            return;
          }
        }

        button_compare.classList.add('button_selected');
      })
    } else {
      store.dispatch(compareApiSlice.endpoints.removeCompareProduct.initiate({productId: data_id})).then(res => {
        button_compare.classList.remove('button_selected');
      })
    }
  }
}
