import { createSlice } from "@reduxjs/toolkit"

import {customerAPI} from "../../api/customerApi"

const initialState = {
  data: null,
  foksClubCard: null,
  status: "idle",
  errorMsg: null
}

export const getMeCustomer = () => {
  return (dispatch) => {
    dispatch(setStatusCustomer('loading'))
    customerAPI.getMe().then(response => {
      if (response.status === 200 && response.data.success) {
        const responseData = response.data.data
        const customerData = {
          firstName: responseData.first_name,
          lastName: responseData.last_name,
          phone: responseData.phone,
          email: responseData.email,
          city: responseData.city,
          street: responseData.street,
          house: responseData.house,
          apartment: responseData.apartment
        }
        dispatch(setCustomerData(customerData))
        if (responseData.foks_club_card) {
          dispatch(setFoksClubCard(responseData.foks_club_card))
        }
        dispatch(setStatusCustomer('success'))
      } else {
        dispatch(setStatusCustomer('error'))
      }
    })
  }
}

export const getFoksClubCard = (number) => {
  return (dispatch) => {
    customerAPI.getFoksClubCard({number}).then(response => {
      if (response.status === 200) {
        if (response.data.success) {
          dispatch(setFoksClubCard(response.data.data))
        } else {
          dispatch(setErrorMessage(response.data.msg))
        }
      }
    })
  }
}

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setErrorMessage: (state, action) => {
      state.errorMsg = action.payload
    },
    setStatusCustomer: (state, action) => {
      state.status = action.payload
    },
    setCustomerData: (state, action) => {
      state.data = action.payload
    },
    setFoksClubCard: (state, action) => {
      state.foksClubCard = action.payload
    }
  }
})

export const selectStatusCustomer = (state) => {
  return state.customer.status
}

export const selectCustomerData = (state) => {
  return state.customer.data
}

export const selectFoksClubCard = (state) => {
  return state.customer.foksClubCard
}

export const selectErrorMessage = (state) => {
  return state.customer.errorMsg
}

export const {
  setErrorMessage,
  setStatusCustomer,
  setCustomerData,
  setFoksClubCard
} = customerSlice.actions

export default customerSlice.reducer
