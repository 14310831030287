import styles from "./styles.modules.scss"

import React from "react"

export const FilledField = ({title, value}) => (
  <div className={styles.root}>
    <span className={styles.title}>{title}</span>
    <span className={styles.value}>{value}</span>
  </div>
)
