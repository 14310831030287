import {correct_width} from '../../lib/correct-width';

const overlay_limit = document.querySelector('.overlay[data-overlay="error-limit"]');

if (overlay_limit) {
  const button_cancel = overlay_limit.querySelector('.button_cancel');

  button_cancel.addEventListener('click', button_cancel_click);

  function button_cancel_click() {
    if (overlay_limit.style.display != 'none') {
      correct_width(false);

      overlay_limit.style.display = 'none';
    }
  }
}
