import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./Desktop.module.scss";
import cn from "classnames";
import { SignInForm, SendCodeForm, SendPhoneForm } from "../form";
import { ResetPasswordForm } from "../form/ResetPasswordForm";
import { AdvantagesList } from "@entities/advantage";
import { useAppDispatch } from "@shared/hooks";
import { CloseIcon, Text } from "@shared/ui";
import { setActivePopup } from "@entities/popup";
import { useAppSelector } from "@src/shared/hooks";
import { resetState, selectRestoreData, selectStage } from "../../model";
import { ArrowRightIcon } from "@src/shared/ui";
export var Desktop = function (_a) {
    var _b;
    var isOpen = _a.isOpen;
    var dispatch = useAppDispatch();
    var stage = useAppSelector(selectStage);
    var _c = useAppSelector(selectRestoreData), phone = _c.phone, sessionKey = _c.sessionKey;
    var handleCloseModal = function () {
        dispatch(setActivePopup(""));
        dispatch(resetState());
    };
    var handleBackClick = function () {
        dispatch(resetState());
    };
    var currentTitle = stage === "START" ? "Вход в аккаунт" : "Забыли пароль?";
    return (_jsxs("div", { className: cn(styles.main, (_b = {}, _b[styles.open] = isOpen, _b)), children: [_jsx("div", { className: styles.overlay, onClick: handleCloseModal }), _jsxs("div", { className: styles.modal, children: [_jsx(AdvantagesList, { isDesktop: true }), _jsxs("div", { className: styles.content, children: [_jsxs("div", { className: styles.header, children: [stage !== "START" && (_jsx("div", { className: styles.backBtn, onClick: handleBackClick, children: _jsx(ArrowRightIcon, {}) })), _jsx(Text, { variant: "h3", isDesktop: true, children: currentTitle }), _jsx("div", { className: styles.closeBtn, onClick: handleCloseModal, children: _jsx(CloseIcon, {}) })] }), stage === "START" && (_jsx(SignInForm, { isDesktop: true })), (stage === "INPUT_PHONE" || stage === "INPUT_CODE") && (_jsx(SendPhoneForm, { isDesktop: true })), stage === "INPUT_CODE" && phone && sessionKey && (_jsx(SendCodeForm, { phone: phone, sessionKey: sessionKey, isDesktop: true })), stage === "INPUT_PASSWORD" && sessionKey && (_jsx(ResetPasswordForm, { sessionKey: sessionKey, isDesktop: true }))] })] })] }));
};
