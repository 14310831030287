import styles from "./styles.modules.scss"

import React, {useEffect, useState} from "react"
import {Provider, useDispatch, useSelector} from "react-redux"
import {Element, scroller} from "react-scroll"
import cn from "classnames"

import {store} from "@src/app/store"
import {
  selectCurrentStep,
  selectOrderingData
} from "../../../../js/redux/slices/orderingSlice"
import {selectStatusCustomer} from "../../../../js/redux/slices/customerSlice"
import {selectStatusShops} from "../../../../js/redux/slices/shopsSlice"
import {orderingApi} from "../../../../js/api/orderingApi"
import {StepProducts} from "../StepProducts"
import {StepContactsInfo} from "../StepContactsInfo"
import {StepReceivingMethod} from "../StepReceivingMethod"
import {StepComment} from "../StepComment"
import {StepPaymentMethod} from "../StepPaymentMethod"
import {OrderDetail} from "../OrderDetail"
import {useGetCartDetailQuery} from "@src/shared/api"
import {
  geWarningData,
  initialOrderingData,
  orderDataMapper,
  showWarning
} from "./model"
import {sendOrderToYM} from "../../../../js/lib/yandex"
import {OnlinePaymentInfo} from "../OnlinePaymentInfo"
 import {SuccessPopup} from "../../../../src-new/widgets/Ordering/ui/SuccessPopup";
import {setIsShowSuccessPopup} from "../../../../src-new/widgets/Ordering/model";

const OrderingScreen = ({isMobile, withOnlinePayment = false}) => {
  const dispatch = useDispatch()

  const [firstRender, setFirstRender] = useState(true)

  const currentStep = useSelector(selectCurrentStep)

  const {data: cartData, isSuccess} = useGetCartDetailQuery()

  const customerStatus = useSelector(selectStatusCustomer)
  const shopsStatus = useSelector(selectStatusShops)
  const orderData = useSelector(selectOrderingData)

  useEffect(() => {
    dispatch(initialOrderingData())
  }, [])

  useEffect(() => {
    async function createOrder() {
      let redirect_url = "/"
      await orderingApi.createOrder(orderDataMapper(orderData)).then(response => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            dispatch(setIsShowSuccessPopup(true))
            sendOrderToYM(response.data.data.order)
            return
          }
          if (response.data.status === "success_payment") {
            dispatch(setIsShowSuccessPopup(true))
            sendOrderToYM(response.data.data.order)
            setTimeout(() => {
              window.location = response.data.data.redirect_url
            }, 3000)
            return;
          }
          const warningData = geWarningData(response.data.status)
          showWarning(warningData)
        }
        setTimeout(() => {
          window.location = redirect_url
        }, 3000)
      })
    }

    if (currentStep === "CREATING") {
      createOrder()
    }

    if (!firstRender) {
      setTimeout(() => {
        scroller.scrollTo(currentStep, {
          smooth: true,
          isDynamic: true,
          duration: 500,
          offset: isMobile ? -104 : -167
        })
      }, 350)
    }
  }, [currentStep])

  useEffect(() => {
    setFirstRender(false)
  }, [])

  useEffect(() => {
    if (isSuccess && !Object.keys(cartData).length) {
      window.location = "/cart/"
    }
  }, [cartData, isSuccess]);

  if (!isSuccess && !cartData ||
    ["idle", "loading"].includes(customerStatus) ||
    ["idle", "loading"].includes(shopsStatus)) {
    return null
  }

  return (
      <>
        <SuccessPopup isDesktop={!isMobile}/>
        <div className={cn(styles.root, isMobile && styles.mobile)}>
          <div className={styles.steps}>
            <Element name={"CART"}>
              <StepProducts
                  isCurrent={currentStep === "CART"}
                  isCreating={currentStep === "CREATING"}
                  isMobile={isMobile}
              />
            </Element>
            <Element name={"CONTACTS"}>
              <StepContactsInfo
                  isCurrent={currentStep === "CONTACTS"}
                  isCreating={currentStep === "CREATING"}
                  isMobile={isMobile}
              />
            </Element>
            <Element name={"RECEIVING"}>
              <StepReceivingMethod
                  isCurrent={currentStep === "RECEIVING"}
                  isCreating={currentStep === "CREATING"}
                  isMobile={isMobile}
              />
            </Element>
            <Element name={"PAYMENT"}>
              <StepPaymentMethod
                  isCurrent={currentStep === "PAYMENT"}
                  isCreating={currentStep === "CREATING"}
                  withOnline={withOnlinePayment}
                  isMobile={isMobile}
              />
            </Element>
            <Element name={"COMMENT"}>
              <StepComment
                  isCurrent={currentStep === "COMMENT"}
                  isCreating={currentStep === "CREATING"}
                  isMobile={isMobile}
              />
            </Element>
          </div>
          <div className={styles.detail}>
            <Element name={"DETAIL"}>
              <OrderDetail
                  isCurrent={currentStep === "DETAIL"}
                  isCreating={currentStep === "CREATING"}
                  isMobile={isMobile}
              />
            </Element>
            <OnlinePaymentInfo bankAlign={"center"}/>
          </div>
        </div>
      </>
  )
}

export default (props) => (
    <Provider store={store}>
      <OrderingScreen {...props}/>
    </Provider>
)
