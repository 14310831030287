const brief_offer = document.querySelector('.brief-offer');

if (brief_offer) {
  const offer_list = brief_offer.querySelector('.offer-list');

  let list_unit_all = offer_list.querySelectorAll('.list-unit');

  if (list_unit_all.length > 1) {
    setInterval(offer_interval, 7000);
  }

  function offer_interval() {
    list_unit_all = offer_list.querySelectorAll('.list-unit');

    const list_unit = list_unit_all[0];
    const next_unit = list_unit_all[1];

    offer_list.classList.add('list_transition');
    offer_list.style.transform = 'translateY(-100%)';

    offer_list.addEventListener('transitionend', offer_list_transitionend, { once: true });

    function offer_list_transitionend() {
      offer_list.append(list_unit);

      offer_list.classList.remove('list_transition');
      offer_list.style.transform = null;
    }
  }
}

const brief_contact = document.querySelector('.brief-contact');

if (brief_contact) {
  const contact_button = brief_contact.querySelector('.contact-button');
  const contact_dropdown = brief_contact.querySelector('.contact-dropdown');

  contact_button.addEventListener('click', contact_button_click);

  function contact_button_click() {
    if (contact_dropdown.style.display != 'block') {
      contact_dropdown.style.display = 'block';
    } else {
      contact_dropdown.style.display = 'none';
    }

    document.addEventListener('click', document_click);

    function document_click({ target }) {
      if (!contact_button.contains(target)) {
        if (!contact_dropdown.contains(target)) contact_dropdown.style.display = 'none';
      }
    }
  }
}
