import React from "react";
import {createRoot} from "react-dom/client"

import {store} from "@src/app/store"
import {getProductData} from "../redux/slices/productSlice"
import {cartApi, compareApiSlice, favoritesApi} from "@src/shared/api"
import {PrivacyPolicyPopup} from "@widgets/PrivacyPolicyPopup"
import {SignInPopup} from "@widgets/SignInPopup"
import {SignUpPopup} from "@widgets/SignUpPopup"
import {LoginButton} from "@src/features/auth/LoginButton"
import {Header} from "@src/widgets/Header";
import {Catalog} from "@src/widgets/Catalog";
import {ProductAttributesTab} from "@src/widgets/ProductAttributesTab";
import {RangeFilterItemFeature} from "@src/features/RangeFilterItemFeature";
import {SearchPopup} from "@src/widgets/SearchPopup";

export const initializeApp = (isDesktop = false) => {
  store.dispatch(cartApi.endpoints.getCart.initiate())
  store.dispatch(favoritesApi.endpoints.getFavoriteProductsBrief.initiate())
  store.dispatch(compareApiSlice.endpoints.getCompareProductsBrief.initiate())

  const productPage = document.querySelector('#product')

  if (productPage) {
    const productSlug = productPage.getAttribute("data-product-slug")
    if (productSlug) {
      store.dispatch(getProductData(productSlug))
    }

    const featuresTabDiv = productPage.querySelector("#features-tab")
    const root = createRoot(featuresTabDiv);
    root.render(<ProductAttributesTab productSlug={productSlug} isDesktop={isDesktop}/>)
  }

  const privacyPopupDiv = document.getElementById('privacy-popup-layer')

  if (privacyPopupDiv) {
    const root = createRoot(privacyPopupDiv);
    root.render(<PrivacyPolicyPopup isDesktop={isDesktop}/>)
  }

  const signInPopupDiv = document.getElementById("sign-in-popup-layer")
  const signUpPopupDiv = document.getElementById("sign-up-popup-layer")
  const signUpForFavoritesPopupDiv = document.getElementById("sign-up-for-favorites-popup-layer")

  if (signInPopupDiv) {
    const root = createRoot(signInPopupDiv);
    root.render(<SignInPopup isDesktop={isDesktop}/>)
  }

  if (signUpPopupDiv) {
    const root = createRoot(signUpPopupDiv);
    root.render(<SignUpPopup popupKey={"signUp"} isDesktop={isDesktop}/>)
  }

  if (signUpForFavoritesPopupDiv) {
    const root = createRoot(signUpForFavoritesPopupDiv);
    root.render(<SignUpPopup title={"Добавлен в избранное"} popupKey={"signUpFavorites"} isDesktop={isDesktop}/>)
  }

  const loginButtonLayer = document.getElementById("login-button")

  if (loginButtonLayer) {
    const root = createRoot(loginButtonLayer);
    root.render(<LoginButton isDesktop={isDesktop}/>)
  }

  const header = document.getElementById("header")

  if (header) {
    const root = createRoot(header);
    root.render(<Header slots={{searchPopup: <SearchPopup isDesktop={isDesktop}/>}} isDesktop={isDesktop}/>)
  }

  const catalogDiv = document.getElementById('header-catalog')

  if (catalogDiv) {
    const root = createRoot(catalogDiv);
    root.render(<Catalog isDesktop={isDesktop}/>)
  }

  const filterBlock = document.getElementById("filter")

  if (filterBlock) {
    const rangeFilterEl = filterBlock.querySelectorAll(".range-filter-slider")
    if (rangeFilterEl.length) {
      rangeFilterEl.forEach(el => {
        const attributeData = JSON.parse(el.getAttribute("data-attribute"))
        const valuesString = el.getAttribute("data-values")
        let values = []
        if (valuesString) {
          values = JSON.parse(valuesString)
        }
        const root = createRoot(el);
        root.render(<RangeFilterItemFeature attributeData={attributeData} values={values} isDesktop={isDesktop}/>)
      })
    }
  }

  const developerPage = document.getElementById("developer-page")

  if (developerPage) {
    const searchPopupEl = developerPage.querySelector("#SearchPopup")
    const root = createRoot(searchPopupEl);
    root.render(<SearchPopup isDesktop={isDesktop}/>)
  }
}
