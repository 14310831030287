import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "../styles.module.scss";
import { useEffect, useState } from "react";
import { PopularQueries } from "../PopularQueries";
import { ProductsCardsSection } from "../ProductsCardsSection";
import { SearchHistorySection } from "../SearchHistorySection";
import { getFromLocalStorage } from "@src/entities/search-popup";
import { useGetPopularQueriesQuery, useGetProductsForYouQuery, useGetProductsOftenSearchQuery, } from "@src/shared/api";
export var PreviewDataSection = function (_a) {
    var _b = _a.isDesktop, isDesktop = _b === void 0 ? false : _b, onSelectSearchQuery = _a.onSelectSearchQuery, onSelectCatalog = _a.onSelectCatalog;
    var _c = useState(false), isExistsHistory = _c[0], setIsExistsHistory = _c[1];
    var popularQueries = useGetPopularQueriesQuery().data;
    var oftenSearch = useGetProductsOftenSearchQuery().data;
    var forYou = useGetProductsForYouQuery().data;
    var changeHistoryHandler = function () {
        var history = getFromLocalStorage();
        setIsExistsHistory(!!history.categories.length || !!history.queries.length);
    };
    useEffect(function () {
        changeHistoryHandler();
    }, []);
    return (_jsxs(_Fragment, { children: [isExistsHistory ? (_jsxs(_Fragment, { children: [_jsx(SearchHistorySection, { isDesktop: isDesktop, onSelectQuery: onSelectSearchQuery, onSelectCatalog: onSelectCatalog, onChanged: changeHistoryHandler }), _jsx("div", { className: styles.separator })] })) : ((popularQueries === null || popularQueries === void 0 ? void 0 : popularQueries.length) > 0 && (_jsxs(_Fragment, { children: [_jsx(PopularQueries, { isDesktop: isDesktop, onSelectQuery: onSelectSearchQuery }), _jsx("div", { className: styles.separator })] }))), (oftenSearch === null || oftenSearch === void 0 ? void 0 : oftenSearch.length) > 0 && (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.productsSection, children: _jsx(ProductsCardsSection, { title: "\u0427\u0430\u0441\u0442\u043E \u0438\u0449\u0443\u0442", products: oftenSearch, isDesktop: isDesktop }) }), _jsx("div", { className: styles.separator })] })), (forYou === null || forYou === void 0 ? void 0 : forYou.length) > 0 && (_jsx("div", { className: styles.productsSection, children: _jsx(ProductsCardsSection, { title: "\u0422\u043E\u0432\u0430\u0440\u044B \u0434\u043B\u044F \u0432\u0430\u0441", products: forYou, isDesktop: isDesktop }) }))] }));
};
