var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Card } from "@entities/product";
import { CartBtn } from "@features/CartBtn";
import { FavouriteBtn } from "@features/FavouriteBtn";
import { Provider } from "react-redux";
import { store } from "@src/app/store";
import { CompareBtn } from "@features/CompareBtn";
var ProductCard = function (_a) {
    var isDesktop = _a.isDesktop, product = __rest(_a, ["isDesktop"]);
    var cartButton = _jsx(CartBtn, { productId: product.id, isDesktop: isDesktop });
    var compareButton = _jsx(CompareBtn, { productId: product.id, isDesktop: isDesktop });
    var favouriteButton = _jsx(FavouriteBtn, { productId: product.id, isDesktop: isDesktop });
    return _jsx(Card, __assign({}, product, { isDesktop: isDesktop, slots: {
            inCartButton: cartButton,
            compareBtn: compareButton,
            favouriteBtn: favouriteButton
        } }));
};
var ProductCardContainer = function (props) { return (_jsx(Provider, { store: store, children: _jsx(ProductCard, __assign({}, props)) })); };
export { ProductCardContainer as ProductCard };
