const content_select_all = document.querySelectorAll('.content-select');

if (content_select_all.length) content_select_all.forEach(content_select_each);

function content_select_each(content_select) {
  const select_control = content_select.querySelector('.select-control');
  const select_list = content_select.querySelector('.select-list');

  const control_text = select_control.querySelector('.control-text');

  const list_unit_all = select_list.querySelectorAll('.list-unit');

  list_unit_all.forEach(list_unit_each);

  select_control.addEventListener('click', select_control_click);

  document.addEventListener('click', document_click);

  function list_unit_each(list_unit) {
    const unit_control = list_unit.querySelector('input');

    const unit_text = list_unit.querySelector('.unit-text');

    unit_control.addEventListener('input', unit_control_input);

    function unit_control_input() {
      select_list.classList.add('list_hidden');

      if (unit_control.checked) {
        // Снимаем отметки со всех элементов
        list_unit_all.forEach((list_unit) => list_unit.classList.remove('unit_selected'));

        list_unit.classList.add('unit_selected');

        // Меняем текст главного элемента на текст выбранного
        control_text.textContent = unit_text.textContent;
      }
    }
  }

  function select_control_click() {
    if (select_list.classList.contains('list_hidden')) {
      select_list.classList.remove('list_hidden');
    } else {
      select_list.classList.add('list_hidden');
    }
  }

  function document_click({ target }) {
    if (!select_list.contains(target) && !select_control.contains(target)) {
      if (!select_list.classList.contains('list_hidden')) {
        select_list.classList.add('list_hidden');
      }
    }
  }
}
