import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { FavoritesDropdown } from "./FavoritesDropdown";
import { selectIsShowFavoritesPopup, setIsShowPopup, } from "@src/entities/favorites";
import { useGetFavoriteProductsBriefQuery } from "@src/shared/api";
import { useAppDispatch, useAppSelector } from "@src/shared/hooks";
import { ButtonWithPopup, HeartIcon } from "@src/shared/ui";
export var FavoritesNavButton = function (_a) {
    var _b;
    var _c = _a.popupOffset, popupOffset = _c === void 0 ? 0 : _c;
    var dispatch = useAppDispatch();
    var favoriteProducts = useGetFavoriteProductsBriefQuery().data;
    var isShowPopup = useAppSelector(selectIsShowFavoritesPopup);
    useEffect(function () {
        dispatch(setIsShowPopup(false));
    }, [isShowPopup]);
    return (_jsx(ButtonWithPopup, { text: "\u0418\u0437\u0431\u0440\u0430\u043D\u043D\u043E\u0435", href: "/favorites/", icon: _jsx(HeartIcon, {}), count: (_b = favoriteProducts === null || favoriteProducts === void 0 ? void 0 : favoriteProducts.length) !== null && _b !== void 0 ? _b : 0, isDisable: !(favoriteProducts === null || favoriteProducts === void 0 ? void 0 : favoriteProducts.length), popup: _jsx(FavoritesDropdown, {}), popupOffset: popupOffset, isShowPopup: isShowPopup }));
};
