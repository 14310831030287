import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { Slider as BaseSlider, sliderClasses } from "@mui/base/Slider";
import { styled } from "@mui/system";
var Slider = styled(BaseSlider)(function () { return "\n  height: 6px;\n  width: 100%;\n  padding: 9px 0;\n  display: inline-flex;\n  align-items: center;\n  position: relative;\n  cursor: pointer;\n  touch-action: none;\n  -webkit-tap-highlight-color: transparent;\n\n  &.".concat(sliderClasses.disabled, " {\n    pointer-events: none;\n    cursor: default;\n    opacity: 0.4;\n  }\n\n  & .").concat(sliderClasses.rail, " {\n    display: block;\n    position: absolute;\n    width: 100%;\n    height: 3px;\n    border-radius: 2px;\n    background-color: var(--main-main, #EF4123);\n  }\n\n  & .").concat(sliderClasses.track, " {\n    display: block;\n    position: absolute;\n    height: 3px;\n    border-radius: 2px;\n    background-color: var(--main-main, #EF4123)\n  }\n\n  & .").concat(sliderClasses.thumb, " {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    position: absolute;\n    margin-left: -6px;\n    width: 18px;\n    height: 18px;\n    box-sizing: border-box;\n    border: 4px solid var(--main-main, #EF4123);\n    border-radius: 50%;\n    outline: 0;\n    background-color: var(--main-white, #FFFFFF);\n    transition-property: box-shadow, transform;\n    transition-timing-function: ease;\n    transition-duration: 120ms;\n    transform-origin: center;\n  }\n"); });
export var SliderFilterField = function (_a) {
    var min = _a.min, max = _a.max, step = _a.step, value = _a.value, onChange = _a.onChange;
    var _b = React.useState(value), currentValue = _b[0], setCurrentValue = _b[1];
    useEffect(function () {
        setCurrentValue(value || []);
    }, value);
    var handleChange = function (event, newValue, activeThumb) {
        if (!Array.isArray(newValue)) {
            return;
        }
        var values;
        if (activeThumb === 0) {
            values = [Math.min(newValue[0], currentValue[1] - step), currentValue[1]];
        }
        else {
            values = [currentValue[0], Math.max(newValue[1], currentValue[0] + step)];
        }
        setCurrentValue(values);
        if (onChange)
            onChange(values);
    };
    return (_jsx("div", { onDragStart: function (e) { return e.preventDefault(); }, children: _jsx(Slider, { min: min, max: max, step: step, value: currentValue, disableSwap: true, onChange: handleChange }) }));
};
