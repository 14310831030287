import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./CategoryItem.module.scss";
import cn from "classnames";
import { ArrowDownIcon, CatalogIcon, Text, } from "@src/shared/ui";
export var CategoryItem = function (_a) {
    var _b;
    var name = _a.name, url = _a.url, iconKey = _a.iconKey, _c = _a.withArrow, withArrow = _c === void 0 ? false : _c, _d = _a.isDesktop, isDesktop = _d === void 0 ? false : _d, _e = _a.isActive, isActive = _e === void 0 ? false : _e, onClickArrow = _a.onClickArrow, onHover = _a.onHover;
    var handleClick = function (e) {
        e.preventDefault();
        if (onClickArrow) {
            onClickArrow();
        }
    };
    var mainClasses = cn(styles.main, (_b = {},
        _b[styles.desktop] = isDesktop,
        _b[styles.active] = isActive,
        _b));
    return (_jsxs("a", { className: mainClasses, href: url, onMouseEnter: onHover, children: [_jsxs("div", { className: styles.block, children: [_jsx("div", { className: styles.icon, children: _jsx(CatalogIcon, { iconKey: iconKey }) }), _jsx(Text, { variant: "body4", isDesktop: isDesktop, className: styles.text, children: name })] }), withArrow && !isDesktop && (_jsx("div", { onClick: handleClick, className: styles.button, children: _jsx(ArrowDownIcon, {}) }))] }));
};
