import styles from "./CartPage.modules.scss"

import React from 'react'
import {Provider} from "react-redux"
import cn from "classnames"

import {CartProduct} from "../CartProduct"
import {CartSummary} from "../CartSummary"
import {store} from "@src/app/store"
import {useGetCartDetailQuery, useGetMeQuery} from "@src/shared/api"
import {FoksClubInfo} from "../FoksClubInfo"

const CartPage = ({isMobile = false}) => {
  const {data: cart, isLoading} = useGetCartDetailQuery()
  const {data: customer} = useGetMeQuery()

  const foksClubCard = customer?.data?.foks_club_card

  const products = cart?.products

  if (isLoading) return <></>

  return (
    <div className={cn(styles.root, isMobile && styles.mobile)}>
      <div className={styles.headingLayer}>
        <h1 className={styles.heading}>Моя корзина</h1>
        {!!(cart?.total_quantity && !isMobile) && (
          <span className={styles.count}>{cart?.total_quantity}</span>
        )}
      </div>
      {!!foksClubCard && (
        <FoksClubInfo
          total={foksClubCard.balance_of_bonuses}
          feature={foksClubCard.future_bonuses}
          number={foksClubCard.number}
          isMobile={isMobile}
        />
      )}
      {!!products?.length ? (
        <div className={styles.content}>
          <div className={styles.products}>
            {products.map(item => (
              <CartProduct key={item.product.id} cartProduct={item} isMobile={isMobile}/>
            ))}
          </div>
          <div className={styles.summary}>
            <CartSummary
              count={cart.total_quantity}
              currentPrice={cart.total_discount_price}
              oldPrice={cart.total_price}
              discount={cart.total_discount_amount}
            />
          </div>
        </div>
      ) : (
        <span>Ваша корзина пуста!</span>
      )}
    </div>
  )
}

const CartPageContainer = (props) => (
  <Provider store={store}>
    <CartPage {...props}/>
  </Provider>
)

export {CartPageContainer as CartPage}
