var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./CartDropdown.module.scss";
import { CartProductPrices } from "@src/entities/cart";
import { ProductCardForPopup, ProductsDropdown } from "@src/entities/product";
import { useClearCartMutation, useGetCartQuery, useRemoveCartProductMutation, } from "@src/shared/api";
import { Text } from "@src/shared/ui";
export var CartDropdown = function () {
    var _a, _b, _c, _d, _e, _f;
    var cart = useGetCartQuery(null).data;
    var cleanCart = useClearCartMutation()[0];
    var removeProduct = useRemoveCartProductMutation()[0];
    var acceptButtonHandler = function () {
        window.location.href = "/cart/";
    };
    var cartProductsArray = (_b = (_a = cart === null || cart === void 0 ? void 0 : cart.products) === null || _a === void 0 ? void 0 : _a.map(function (item) {
        var removeProductHandler = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, removeProduct({ productId: item.product.id })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        return (_jsx(ProductCardForPopup, { product: item.product, onCancel: removeProductHandler }, item.product.id));
    })) !== null && _b !== void 0 ? _b : [];
    var summary = (_jsxs("div", { className: styles.summary, children: [_jsxs("div", { className: styles.pricesLayer, children: [_jsx(Text, { variant: "body2", isDesktop: true, children: "\u0421\u0443\u043C\u043C\u0430" }), _jsx(CartProductPrices, { price: (_c = cart === null || cart === void 0 ? void 0 : cart.total_price) !== null && _c !== void 0 ? _c : 0, discountedPrice: (_d = cart === null || cart === void 0 ? void 0 : cart.total_discount_price) !== null && _d !== void 0 ? _d : 0, discountAmount: (_e = cart === null || cart === void 0 ? void 0 : cart.total_discount_amount) !== null && _e !== void 0 ? _e : 0, bonusesAmount: (_f = cart === null || cart === void 0 ? void 0 : cart.total_bonuses_amount) !== null && _f !== void 0 ? _f : 0, className: styles.prices })] }), _jsxs("div", { className: styles.quantityLayer, children: [_jsx(Text, { variant: "body2", isDesktop: true, children: "\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u0442\u043E\u0432\u0430\u0440\u043E\u0432" }), _jsx("span", { className: styles.quantity, children: cart === null || cart === void 0 ? void 0 : cart.total_quantity })] })] }));
    return (_jsx(ProductsDropdown, { slots: { cards: cartProductsArray, summary: summary }, acceptBtnText: "\u041A\u0443\u043F\u0438\u0442\u044C", onAccept: acceptButtonHandler, onClean: cleanCart }));
};
