import { createSlice } from "@reduxjs/toolkit"
import {favoritesAPI} from "../../api/favoritesApi";

import {favoritesApiSlice} from "./favoritesApiSlice"

const initialState = {
  products: [],
}

export const getFavoriteProducts = () => {
  return (dispatch) => {
    favoritesAPI.getProductsIds().then(response => {
      if (response.status === 200) {
        dispatch(setProducts(response.data))
      }
    })
  }
}

export const favoritesSlice = createSlice({
  name: "favorites",
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload
    },
    addProduct: (state, action) => {
      state.products.push(Number(action.payload))
    },
    removeProduct: (state, action) => {
      state.products.pop(Number(action.payload))
    },
    clearProducts: (state) => {
      state.products = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(favoritesApiSlice.endpoints.getFavoritesProductsIds.matchFulfilled, (state, action) => {
        state.products = action.payload
      })
  },
})

export const selectFavoritesProducts = (state) => {
  return state.favorites.products
}

export const {setProducts, addProduct, removeProduct, clearProducts} = favoritesSlice.actions

export default favoritesSlice.reducer