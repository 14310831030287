var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./Button.module.scss";
import cn from "classnames";
import { Text } from "../Text";
export var Button = function (_a) {
    var _b;
    var children = _a.children, _c = _a.Component, Component = _c === void 0 ? "button" : _c, href = _a.href, _d = _a.variant, variant = _d === void 0 ? "primary" : _d, className = _a.className, startIcon = _a.startIcon, endIcon = _a.endIcon, _e = _a.type, type = _e === void 0 ? "button" : _e, _f = _a.disabled, disabled = _f === void 0 ? false : _f, textSize = _a.textSize, _g = _a.isActive, isActive = _g === void 0 ? false : _g, _h = _a.isDisabled, isDisabled = _h === void 0 ? false : _h, rest = __rest(_a, ["children", "Component", "href", "variant", "className", "startIcon", "endIcon", "type", "disabled", "textSize", "isActive", "isDisabled"]);
    var classes = cn(styles.root, className, (_b = {},
        _b[styles.primary] = variant === "primary",
        _b[styles.secondary] = variant === "secondary",
        _b[styles.third] = variant === "third",
        _b[styles.active] = isActive,
        _b[styles.disabled] = isDisabled,
        _b[styles.link] = variant === "link",
        _b));
    var currentTextSize = cn({
        buttonLarge: textSize === "large",
        buttonNormal: textSize === "normal",
        buttonMedium: textSize === "medium" || variant !== "third",
        buttonSmall: textSize === "small" || variant === "third",
        buttonExtraSmall: textSize === "extraSmall",
        body2: textSize === "link",
    });
    return (_jsxs(Component, __assign({ className: classes }, rest, { href: href, type: type, disabled: disabled, children: [startIcon && _jsx("div", { className: styles.icon, children: startIcon }), _jsx(Text, { className: styles.title, variant: currentTextSize, children: children }), endIcon && _jsx("div", { className: styles.icon, children: endIcon })] })));
};
