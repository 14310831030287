import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {
  selectPaymentMethod,
  selectReceivingMethod,
  setCurrentStep,
  setPaymentMethod,
} from "../../../../../js/redux/slices/orderingSlice"
import {PaymentInfo} from "./PaymentInfo"
import {StepWrapper} from "../../StepWrapper"
import {FilledFieldsWrapper} from "../../FilledFieldsWrapper"
import {SelectMethod} from "../../SelectMethod"
import {Button, EditButton, FilledField} from "../../shared"
import {EmailForm} from "./EmailForm"

export const StepPaymentMethod = ({isCurrent, isCreating, withOnline, isMobile = false}) => {
  const dispatch = useDispatch()

  const method = useSelector(selectPaymentMethod)
  const receivingMethod = useSelector(selectReceivingMethod)

  let paymentMethods = [
    {title: "Наличными при получении", value: "CASH", active: true}
  ]

  if (withOnline) {
    paymentMethods = [
      {title: "Оплата картой онлайн", value: "ONLINE", active: receivingMethod === "DELIVERY"},
      ...paymentMethods
    ]
  }

  const methodData = paymentMethods.find(item => item.value === method)

  const [mode, setMode] = useState("edit")

  useEffect(() => {
    if (isCurrent && !method) {
      if (withOnline) {
        dispatch(setPaymentMethod(receivingMethod === "DELIVERY" ? "ONLINE" : "CASH"))
      }
      dispatch(setPaymentMethod("CASH"))
    }
    setMode(isCurrent ? "edit" :  method ? "filled" : "blank")
  }, [isCurrent, method, receivingMethod])

  const selectMethodHandler = (method) => {
    dispatch(setPaymentMethod(method))
  }

  const nextStepHandler = () => {
    dispatch(setCurrentStep("COMMENT"))
  }

  const backStepHandler = () => {
    dispatch(setCurrentStep("RECEIVING"))
  }

  const clickEditHandler = () => {
    dispatch(setCurrentStep("PAYMENT"))
  }

  let content = null
  switch (mode) {
    case "filled":
      content = (
        <FilledFieldsWrapper variant={isMobile ? "column" : "row"}>
          <FilledField title={"Способ"} value={methodData.title}/>
        </FilledFieldsWrapper>
      )
      break
    case "edit":
      content = (
        <>
          <SelectMethod options={paymentMethods} selected={methodData} isMobile={isMobile} onSelect={selectMethodHandler}/>
          {method === "ONLINE" && (
            <>
              <PaymentInfo isMobile={isMobile}/>
              <EmailForm formId={"emailForm"} isMobile={isMobile} onSubmit={nextStepHandler}/>
            </>
          )}
        </>
      )
      break
  }

  return (
    <StepWrapper
      title={"Способ оплаты"}
      number={"4"}
      editBtn={!isCreating && <EditButton title={"Редактировать"} onClick={clickEditHandler}/>}
      backStepBtn={<Button title={"Назад"} variant={"gray"} onClick={backStepHandler}/>}
      nextStepBtn={method === "CASH" ? (
        <Button title={"Далее"} onClick={nextStepHandler}/>
      ) : (
        <Button title={"Далее"} type={"submit"} form={"emailForm"}/>
      )}
      isMobile={isMobile}
      mode={mode}
    >
      {content}
    </StepWrapper>
  )
}
