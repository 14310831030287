import React, {useEffect, useState} from "react"
import {useDispatch} from "react-redux"

import {setCurrentStep} from "../../../../../js/redux/slices/orderingSlice"
import {StepWrapper} from "../../StepWrapper"
import {ProductItem} from "./ProductItem"
import {RemoveProductFromCartBtn} from "../../features/RemoveProductFromCartBtn"
import {Button, EditButton, Text} from "../../shared"
import {useGetCartDetailQuery} from "@src/shared/api"

export const StepProducts = ({isCurrent, isCreating, isMobile = false}) => {
  const dispatch = useDispatch()

  const {data: cart} = useGetCartDetailQuery()

  const products = cart?.products

  const [mode, setMode] = useState("edit")

  useEffect(() => {
    setMode(isCurrent ? "edit" : products ? "filled" : "blank")
  }, [isCurrent, products])

  const nextStepHandler = () => {
    dispatch(setCurrentStep("CONTACTS"))
  }

  const clickEditHandler = () => {
    dispatch(setCurrentStep("CART"))
  }

  return (
    <StepWrapper
      title={"Товары в заказе"}
      number={"1"}
      editBtn={!isCreating && <EditButton title={"Редактировать"} onClick={clickEditHandler}/>}
      backStepBtn={<Button Component={"a"} href={"/cart/"} title={"Вернуться к корзине"} variant={"gray"}/>}
      nextStepBtn={<Button title={"Далее"} onClick={nextStepHandler}/>}
      isMobile={isMobile}
      mode={mode}
    >
      {!!products?.length ? (
        <>
          {products?.map(cartProduct => (
            <ProductItem
              key={cartProduct.product.slug}
              product={cartProduct.product}
              services={cartProduct.services}
              quantity={cartProduct.quantity}
              controlBtn={<RemoveProductFromCartBtn
                productId={cartProduct.product.id}
                productSlug={cartProduct.product.slug}
              />}
              isMobile={isMobile}
              isShort={mode === "filled"}
            />
          ))}
        </>
      ) : (
        <Text>Корзина пуста</Text>
      )}

    </StepWrapper>
  )
}
