const wordByCount = (count, wordOne, wordTwo, wordThree) => {
  /**
   * Возвращает правильную форму слова для колличества чего-то
   *
   * @param {number} count Количество чего-то
   * @param {string} wordOne Первая форма слова. Например: "кот".
   * @param {string} wordTwo Вторая форма слова. Например: "кота".
   * @param {string} wordThree Третья форма слова. Например: "котов".
   * @return {string} wordOne | wordTwo | wordThree
   */
  if (count === 0) {
    return wordThree
  }
  let val = count % 100
  if (val > 10 && val < 20) {
    return wordThree
  } else {
    val = count % 10
    if (val === 1) {
      return wordOne
    } else if (val > 1 && val < 5) {
      return wordTwo
    } else {
      return wordThree
    }
  }
}

export default wordByCount