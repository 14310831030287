import React from 'react';

import compare_interactive from '../../js/lib/compare-interactive';
import favorite_interactive from '../../js/lib/favorite-interactive';
import numberWithSpaces from "../../js/utils";

export function ProductAlt({ productData, productType, onIndicateClick }) {
  function indicateClick(event) {
    event.preventDefault();

    if (onIndicateClick) onIndicateClick()

    if (productType === 'favorite') {
      // Удаляем товар из избранного
      favorite_interactive('delete', productData.id);
    }

    if (productType === 'compare') {
      // Удаляем товар из сравнения
      compare_interactive('delete', productData.id);
    }
  }

  return (
    <div className="list-unit product-alt">
      <a className="unit-layer" href={`${productData.url}`}>
        <div className="layer-picture">
          <img width="84" height="84" src={productData.main_image ? productData.main_image.desktop.preview.image : '/static/store/img/noimage.png'} alt="" />
        </div>
        <div className="layer-info">
          <span className="info-title">{productData.name}</span>
          <div className="info-price">
            <PriceUpper price={productData.price} discount={productData.discount_amount} />
            <PriceLower price={productData.discounted_price} bonuses={productData.bonuses} bonuses_amount={productData.bonuses_amount} />
          </div>
        </div>
        <div className="layer-indicate">
          <div className="indicate" onClick={indicateClick}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="transparent" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.35355 0.646447C1.15829 0.451184 0.841709 0.451184 0.646447 0.646447C0.451184 0.841709 0.451184 1.15829 0.646447 1.35355L1.35355 0.646447ZM10.6464 11.3536C10.8417 11.5488 11.1583 11.5488 11.3536 11.3536C11.5488 11.1583 11.5488 10.8417 11.3536 10.6464L10.6464 11.3536ZM11.3536 1.35355C11.5488 1.15829 11.5488 0.841709 11.3536 0.646447C11.1583 0.451184 10.8417 0.451184 10.6464 0.646447L11.3536 1.35355ZM0.646447 10.6464C0.451184 10.8417 0.451184 11.1583 0.646447 11.3536C0.841709 11.5488 1.15829 11.5488 1.35355 11.3536L0.646447 10.6464ZM0.646447 1.35355L10.6464 11.3536L11.3536 10.6464L1.35355 0.646447L0.646447 1.35355ZM10.6464 0.646447L0.646447 10.6464L1.35355 11.3536L11.3536 1.35355L10.6464 0.646447Z" fill="#000000"/>
            </svg>
          </div>
        </div>
      </a>
    </div>
  );
}

function PriceUpper({ price, discount }) {
  if (!discount) {
    return null;
  }

  const locale_price = numberWithSpaces(price);

  return (
    <div className="price-upper">
      <span className="upper-full">{`${locale_price} ₽`}</span>
    </div>
  );
}

function PriceLower({ price, bonuses, bonuses_amount }) {
  const locale_price = numberWithSpaces(price);

  if (!bonuses) {
    return (
      <div className="price-lower">
        <span className="lower-current">{`${locale_price} ₽`}</span>
      </div>
    );
  }

  const locale_bonuses_amount = numberWithSpaces(bonuses_amount);

  return (
    <div className="price-lower">
      <span className="lower-current">{`${locale_price} ₽`}</span>
      <span className="lower-bonus">{`+${locale_bonuses_amount} бонусов`}</span>
    </div>
  );
}
