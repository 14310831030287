import { createSlice } from "@reduxjs/toolkit";
var initialState = {
    activePopup: "",
};
export var popupSlice = createSlice({
    name: "popup",
    initialState: initialState,
    reducers: {
        setActivePopup: function (state, action) {
            state.activePopup = action.payload;
        },
    },
});
export var selectActivePopup = function (state) { return state.popup.activePopup; };
export var setActivePopup = popupSlice.actions.setActivePopup;
