const inset_all = document.querySelectorAll('.inset');

// Добавление оснывных событий на каждый блок
if (inset_all.length) inset_all.forEach(inset_each);

// Основные функции
function inset_each(inset) {
  const control_button_all = inset.querySelectorAll('.inset-control > .control-button[data-unit]');
  const content_tab_all = inset.querySelectorAll('.inset-content > .content-tab[data-unit]');

  // Добавляем события на кнопки вкладок
  control_button_all.forEach(control_button_each);

  function control_button_each(control_button) {
    const button_unit = control_button.getAttribute('data-unit');

    control_button.addEventListener('click', () => unit_observe(button_unit));
  }

  function unit_observe(data_unit) {
    // Отмечаем нужную кнопку
    control_button_all.forEach(control_button_each);

    // Показываем нужнную вкладку
    content_tab_all.forEach(content_tab_each);

    function control_button_each(control_button) {
      const button_unit = control_button.getAttribute('data-unit');

      if (button_unit != data_unit) {
        control_button.classList.remove('button_selected');
      } else {
        control_button.classList.add('button_selected');
      }
    }

    function content_tab_each(content_tab) {
      const tab_unit = content_tab.getAttribute('data-unit');

      if (tab_unit != data_unit) {
        content_tab.classList.add('tab_hidden');
      } else {
        content_tab.classList.remove('tab_hidden');
      }
    }
  }
}
