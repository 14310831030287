import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./AdvantagesList.module.scss";
import { useRef } from "react";
import cn from "classnames";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/pagination";
import { formatArray } from "../../lib";
import { CardIcon, PercentIcon, PersonIcon, PromoIcon, TruckIcon, WarrantyIcon } from "./icons";
import { Item } from "./Item";
import { ArrowDownIcon, Text } from "@shared/ui";
var data = [
    {
        icon: _jsx(PercentIcon, {}),
        title: "Cертифицированная продукция",
        description: "На каждый товар в сети магазинов ФОКС есть сертификат качества от производителя",
    },
    {
        icon: _jsx(PersonIcon, {}),
        title: "Сервисный центр",
        description: "Для установки, обслуживания и рем  онта техники у нас есть свой сервисный центр. В том числе он осуществляет гарантийный ремонт",
    },
    {
        icon: _jsx(CardIcon, {}),
        title: "Система лояльности",
        description: "Покупая в ФОКС, вы получаете бонусы. Используйте их для оплаты следующих покупок. Бонусную карту можно оформить при совершении любой покупки",
    },
    {
        icon: _jsx(TruckIcon, {}),
        title: "Бесплатная доставка по ДНР",
        description: "Доставка по Донецку и Макеевке бесплатно при заказе на сумму от 5000 руб. или при весе товара от 5 кг. По Горловке и Енакиево бесплатная доставка при заказе от 7500 руб",
    },
    {
        icon: _jsx(WarrantyIcon, {}),
        title: "Гарантия 1 год",
        description: "Даем гарантию на 12 месяцев (если иное не предусмотрено производителем). Также можно продлить срок гарантийного обслуживания на 12, 24 и 36 месяцев",
    },
    {
        icon: _jsx(PromoIcon, {}),
        title: "Регулярно проводим акции",
        description: "На нашем сайте вы всегда найдете информацию об актуальных акциях",
    },
];
export var AdvantagesList = function (_a) {
    var _b;
    var _c = _a.isDesktop, isDesktop = _c === void 0 ? false : _c;
    var swiperRef = useRef();
    var currentData = isDesktop ? formatArray(data, 3) : formatArray(data, 2);
    var handleClickNextButton = function () {
        var _a;
        (_a = swiperRef.current) === null || _a === void 0 ? void 0 : _a.slideNext();
    };
    var handleClickPrevButton = function () {
        var _a;
        (_a = swiperRef.current) === null || _a === void 0 ? void 0 : _a.slidePrev();
    };
    return (_jsxs("div", { className: cn(styles.main, (_b = {}, _b[styles.desktop] = isDesktop, _b)), children: [_jsx("div", { className: styles.header, children: _jsx(Text, { variant: "h3", isDesktop: isDesktop, children: "\u041F\u0440\u0435\u0438\u043C\u0443\u0449\u0435\u0441\u0442\u0432\u0430" }) }), _jsx(Swiper, { loop: true, modules: [Pagination], className: styles.swiper, onBeforeInit: function (swiper) {
                    swiperRef.current = swiper;
                }, children: currentData.map(function (currentDataItem, index) { return (_jsx(SwiperSlide, { children: _jsx("div", { className: cn(styles.block), children: currentDataItem.map(function (item, index) { return (_jsx(Item, { isDesktop: isDesktop, description: item.description, icon: item.icon, title: item.title }, index)); }) }) }, index)); }) }), _jsxs("div", { className: styles.btns, children: [_jsx("div", { className: cn(styles.prevButton, styles.button), onClick: handleClickPrevButton, children: _jsx("div", { className: styles.icon, children: _jsx(ArrowDownIcon, {}) }) }), _jsx("div", { className: cn(styles.nextButton, styles.button), onClick: handleClickNextButton, children: _jsx("div", { className: styles.icon, children: _jsx(ArrowDownIcon, {}) }) })] })] }));
};
