import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./styles.module.scss";
import cn from "classnames";
import { Text } from "@src/shared/ui";
export var ProductAttributesList = function (_a) {
    var groupedAttributes = _a.groupedAttributes, _b = _a.isDesktop, isDesktop = _b === void 0 ? false : _b;
    return (_jsx("div", { className: cn(styles.root, isDesktop && styles.desktop), children: groupedAttributes.map(function (groupItem) { return (_jsxs("div", { className: styles.group, children: [_jsx("div", { className: styles.groupHeading, children: _jsx(Text, { variant: "body5", isDesktop: isDesktop, children: groupItem.name }) }), _jsx("div", { className: styles.attributesList, children: groupItem.attributes.map(function (attributeItem, index) { return (_jsxs("div", { className: styles.attributeItem, children: [_jsx(Text, { variant: "body1", className: styles.nameText, isDesktop: isDesktop, children: attributeItem.attribute.name }), _jsxs(Text, { variant: "body5", className: styles.valuesText, isDesktop: isDesktop, children: [attributeItem.values
                                        .map(function (valueItem) { return valueItem.value; })
                                        .join(", "), " ", attributeItem.attribute.measure_unit] })] }, index)); }) })] }, groupItem.name)); }) }));
};
