import ReactDOM from "react-dom";
import React from "react";

import {BuyOneClickButton} from "../../../../common/jsx/components/BuyOneClickButton";

const oneClickButtonLayer = document.querySelector('[data-button="oneclick"]')

if (oneClickButtonLayer) {
  const productDiv = document.getElementById("product")
  const productSlug = productDiv.getAttribute("data-product-slug")
  ReactDOM.render(<BuyOneClickButton productSlug={productSlug} />, oneClickButtonLayer)
}
