const selector_all = [
  document.querySelector('.brief'),
  document.querySelector('.header-upper'),
  document.querySelector('.header-lower'),
  document.querySelector('.indent'),
  document.querySelector('.useful-rich'),
  document.querySelector('.useful-social'),
  document.querySelector('.service'),
  document.querySelector('.footer'),
];

export function correct_width(correct) {
  let correct_width = null;

  if (correct) {
    correct_width = `${ window.innerWidth - document.body.clientWidth }px`;

    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = null;
  }

  selector_all.forEach(selector_each);

  function selector_each(selector) {
    if (selector) selector.style.paddingRight = correct_width;
  }
}
