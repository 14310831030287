import {baseApiSlice} from "./api"

export const customerApiSlice = baseApiSlice.injectEndpoints({
  endpoints: build => ({
    getMe: build.query({
      query: () => ({url: "/customer/me/", method: "GET"}),
    }),
  }),
})

export const {
  useGetMeQuery,
} = customerApiSlice
