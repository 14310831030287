import styles from "./styles.modules.scss"

import React, {useEffect, useState} from "react"
import {useForm, Controller} from "react-hook-form"
import {useDispatch, useSelector} from "react-redux"
import cn from "classnames"

import {
  selectContactsData, setContactsData
} from "../../../../../../js/redux/slices/orderingSlice"
import {selectCustomerData} from "../../../../../../js/redux/slices/customerSlice"
import {FilledFieldsWrapper} from "../../../FilledFieldsWrapper"
import {
  FormWrapper, FilledField, getFullName
} from "../../../shared"
import {InputField} from "../../../../../../shared/ui"
import {PHONE_REGEX} from "../../../../../../shared/lib"

export const ContactsForm = ({formId, onSubmit, isMobile = false}) => {
  const dispatch = useDispatch()

  const customerData = useSelector(selectCustomerData)
  const contacts = useSelector(selectContactsData)

  let initialData = null
  if (customerData) {
    initialData = {
      firstName: customerData.firstName,
      lastName: customerData.lastName,
      phone: customerData.phone,
      email: customerData.email,
    }
  }

  const [isActiveInitial, setIsActiveInitial] = useState(!!initialData)

  const {
    control,
    handleSubmit,
    watch,
    clearErrors,
    formState: {errors},
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      ...contacts
    },
  })

  const onSubmitHandler = (data) => {
    let contactsData = data
    if (isActiveInitial) {
      contactsData = initialData
    }
    dispatch(setContactsData(contactsData))
    if (onSubmit) onSubmit(contactsData)
  }

  const {firstName, lastName, phone, email} = watch()

  useEffect(() => {
    if (!initialData) return

    if (!!firstName || !!lastName || !!phone || !!email) {
      setIsActiveInitial(false)
    } else {
      setIsActiveInitial(true)
    }
  }, [initialData, firstName, lastName, phone, email])

  useEffect(() => {
    if (isActiveInitial) {
      clearErrors()
    }
  }, [isActiveInitial])

  const isCanUseInitialData = !!initialData?.firstName && !!initialData?.phone && !!initialData?.email

  return (
    <div className={cn(styles.root, isMobile && styles.mobile)}>
      {isCanUseInitialData && (
        <FormWrapper title={"Используйте основную информацию"}>
          <FilledFieldsWrapper variant={isMobile ? "column" : "row"} withFrame active={isActiveInitial}>
            <FilledField title={"Имя и фамилия"} value={getFullName(initialData?.firstName, initialData?.lastName)}/>
            <FilledField title={"Номер телефона"} value={initialData?.phone}/>
            <FilledField title={"E-mail"} value={initialData?.email}/>
          </FilledFieldsWrapper>
        </FormWrapper>
      )}
      <FormWrapper title={initialData ? "Или введите новую" : "Введите ваши контактные данные"}>
        <form id={formId} onSubmit={handleSubmit(onSubmitHandler)} className={styles.form}>
          <Controller
            control={control}
            name={"firstName"}
            render={({field: {onChange, value}}) => (
              <InputField
                placeholder={"Имя"}
                value={value}
                errorText={errors?.firstName?.message?.toString()}
                onChange={value => onChange(value)}
              />
            )}
            rules={{
              required: isActiveInitial ? false : "* Введите имя"
            }}
          />
          <Controller
            control={control}
            name={"lastName"}
            render={({field: {onChange, value}}) => (
              <InputField
                placeholder={"Фамилия"}
                value={value}
                errorText={errors?.lastName?.message?.toString()}
                onChange={value => onChange(value)}
              />
            )}
          />
          <Controller
            control={control}
            name={"phone"}
            render={({field: {onChange, value}}) => (
              <InputField
                placeholder={"Номер телефона"}
                type="mask"
                mask="+7 (999) 999-99-99"
                value={value}
                errorText={errors?.phone?.message?.toString()}
                onChange={value => onChange(value)}
              />
            )}
            rules={{
              required: isActiveInitial ? false : "* Введите номер телефона",
              pattern: {
                value: PHONE_REGEX,
                message: "Введите корректный номер телефона",
              },
            }}
          />
          <Controller
            control={control}
            name={"email"}
            render={({field: {onChange, value}}) => (
              <InputField
                placeholder={"E-mail"}
                value={value}
                errorText={errors?.email?.message?.toString()}
                onChange={value => onChange(value)}
              />
            )}
          />
        </form>
      </FormWrapper>
    </div>
  )
}
