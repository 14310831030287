import styles from "./ProductsSection.modules.scss"

import React from "react"

import cn from "classnames"
import {Skeleton} from "@mui/material";

export const ProductsSectionSkeleton = ({title, isMobile = false}) => {

  const classes = cn(
    styles.root,
    {
      [styles.mobile]: isMobile
    }
  )

  return (
    <div className={classes}>
      <h2 className={styles.heading}>{title}</h2>
      <div className={styles.section}>
        <Skeleton
          variant={"rounded"}
          height={isMobile ? 30 : 42}
          animation={"wave"}
        />
        <Skeleton
          variant={"rounded"}
          height={isMobile ? 320 : 346}
          animation={"wave"}
        />
      </div>
    </div>
  )
}
