import {baseApi} from "./base"

export const productAPI = {
  getProduct(productSlug) {
    const url = `product/${productSlug}/`
    return baseApi.get(url)
  },
  getAdditionalProducts({productSlug, categorySlug = ''}) {
    const url = `product/${productSlug}/additionals/?category=${categorySlug}`
    return baseApi.get(url)
  },
  getAdditionalProductsSection(productSlug) {
    const url = `product/${productSlug}/additionals/section/`
    return baseApi.get(url)
  }
}