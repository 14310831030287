import {store} from "../../../common/js/redux/store"
import {addProduct, clearProducts, removeProduct} from "../../../common/js/redux/slices/cartSlice"
import {
  addCartProductWithYM,
  clearCartWithYM,
  removeCartProductWithYM
} from "../../../common/js/lib/cart";

async function purchase_interactive(purchase_action, product_id, product_quantity = 1, product_services = []) {
  const cartAction = purchase_action;
  const productId = Number(product_id);

  let response = null;

  // Если запрос это не очистка корзины - передаем id товара
  if (purchase_action === "add") {
    response = await addCartProductWithYM({productId: product_id})
  } else if (purchase_action === "delete") {
    response = await removeCartProductWithYM({productId: product_id})
  } else {
    response = await clearCartWithYM()
  }
  // Проверка статуса запроса
  if (response?.data?.success) {
    const cartArray = localStorage.getItem('cart-array');

    if (cartAction === 'add') {
      store.dispatch(addProduct(product_id))
    } else if (cartAction === 'delete') {
      store.dispatch(removeProduct(product_id))
    } else {
      store.dispatch(clearProducts())
    }

    if (cartAction) {
      if (cartArray) {
        // Преобразуем стоку в массив
        const parsedArray = JSON.parse(cartArray);

        if (cartAction == 'add') {
          // Добавляем товар в массив
          parsedArray.push(productId);
        }

        if (cartAction == 'delete') {
          const arrayIndex = parsedArray.indexOf(productId);

          if (arrayIndex != -1) {
            // Удаляем элемент из массива
            parsedArray.splice(arrayIndex, 1);
          }
        }

        // Записываем массив в локальное хранилище
        localStorage.setItem('cart-array', JSON.stringify(parsedArray));
      } else {
        // Записываем массив в локальное хранилище
        localStorage.setItem('cart-array', JSON.stringify([productId]));
      }
    } else {
      // Удаляем массив из локального хранилища
      localStorage.removeItem('cart-array');
    }

    const additional = document.querySelector(`[data-for="order:add"][data-id="${productId}"]`);

    if (additional) {
      if (purchase_action == 'add') {
        additional.setAttribute('data-order', 'true');
      }

      if (purchase_action == 'delete' || !purchase_action) {
        additional.removeAttribute('data-order');
      }
    }

    const product = document.querySelector('.product');

    if (product) {
      const dataId = product.getAttribute('data-id');


      const group_section = product.querySelector('.product-group > .group-section');
      const unit_service = product.querySelector('.unit-service');

      if (dataId == productId) {
        if (purchase_action == 'add') {
          if (unit_service){
            unit_service.setAttribute('data-init', 'true');
          }
          if (group_section) {
            group_section.setAttribute('data-order', 'true');
          }
        }
      }

      if (purchase_action == 'delete' || !purchase_action) {
        if (dataId == productId || !purchase_action) {
          const button_order = product.querySelector('.button_order');

          if (unit_service) {
            unit_service.removeAttribute('data-init');
          }

          if (group_section) {
            group_section.removeAttribute('data-order');
          }

          if (button_order) {
            if (button_order.classList.contains('button_outline')) {
              button_order.classList.replace('button_outline', 'button_fill');

              const layer_text = button_order.querySelector('.layer-text');

              if (layer_text) {
                layer_text.textContent = 'В корзину';
              }
            }
          }
        }
      }
    }

    // Получаем шапку сайта
    const header_upper = document.querySelector('.header-upper');

    if (header_upper) {

      if (purchase_action == 'add') {
        const uniqAll = document.querySelectorAll('[data-uniq="cart"]');

        if (uniqAll.length) {
          uniqAll.forEach((uniq) => {
            if (uniq.getAttribute('data-id') == productId) {
              uniq.classList.add('button_selected');
              uniq.querySelector('.layer-text').textContent = 'Добавлен';
            }
          });
        }

        const product_unit_all = document.querySelectorAll(`.list-unit[data-id="${product_id}"][data-type="product"]`);

        if (product_unit_all.length) {
          product_unit_all.forEach(product_unit_each);
        }

        function product_unit_each(product_unit) {
          const data_reference = product_unit.getAttribute('data-reference');

          if (data_reference == 'promotion') {
            const content_indicate = product_unit.querySelector('.content-indicate');

            if (!content_indicate.classList.contains('indicate_selected')) {
              content_indicate.classList.add('indicate_selected');
            }
          }

          if (data_reference == 'any') {
            const button_purchase = product_unit.querySelector('.button_purchase');

            if (!button_purchase.classList.contains('button_selected')) {
              button_purchase.classList.add('button_selected');
            }

            const purchase_text = button_purchase.querySelector('.layer-text');

            purchase_text.textContent = 'Добавлен';
          }
        }
      }

      if (purchase_action == 'delete') {
        const uniqAll = document.querySelectorAll('[data-uniq="cart"]');

        if (uniqAll.length) {
          uniqAll.forEach((uniq) => {
            if (uniq.getAttribute('data-id') == productId) {
              uniq.classList.remove('button_selected');
              uniq.querySelector('.layer-text').textContent = 'В корзину';
            }
          });
        }

        const product_unit_all = document.querySelectorAll(`.list-unit[data-id="${product_id}"][data-type="product"]`);

        if (product_unit_all.length) {
          product_unit_all.forEach(product_unit_each);
        }
      }

      if (!purchase_action) {
        const product_unit_all = document.querySelectorAll(`.list-unit[data-type="product"]`);

        if (product_unit_all.length) {
          product_unit_all.forEach(product_unit_each);
        }
      }

      // ReactDOM.render(<PurchaseDropdown dropdownData={result} />, purchase_overlay);

      // Если товар добавлен в карзину - возвращаем 'True'
      return true;
    }
  } else {
    console.log('Response is not okey!');

    // Если при добавлении товара в корзину произошла ошибка - возвращаем 'False'
    return false;
  }
}

function product_unit_each(product_unit) {
  const data_reference = product_unit.getAttribute('data-reference');

  if (data_reference == 'promotion') {
    const content_indicate = product_unit.querySelector('.content-indicate');

    if (content_indicate.classList.contains('indicate_selected')) {
      content_indicate.classList.remove('indicate_selected');
    }
  }

  if (data_reference == 'any') {
    const button_purchase = product_unit.querySelector('.button_purchase');

    if (button_purchase.classList.contains('button_selected')) {
      button_purchase.classList.remove('button_selected');
    }

    const purchase_text = button_purchase.querySelector('.layer-text');

    purchase_text.textContent = 'В корзину';
  }
}

export default purchase_interactive
