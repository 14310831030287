import { correct_width } from '../lib/correct-width';

const service = document.querySelector('.service');

if (service) {
  const serviceOverlay = service.querySelector('.overlay[data-overlay="service-overlay"]');

  const buttonAll = service.querySelectorAll('.service-control > .control-button');

  buttonAll.forEach((button) => {
    button.addEventListener('click', () => {
      if (serviceOverlay && serviceOverlay.style.display != 'flex') {
        serviceOverlay.style.display = 'flex';

        correct_width(true);
      }
    });
  });

  if (serviceOverlay) {
    const layerForm = serviceOverlay.querySelector('.layer-form');

    const buttonSubmit = layerForm.querySelector('button[type="submit"]');

    buttonSubmit.addEventListener('click', async (event) => {
      if (!event.defaultPrevented) {
        event.preventDefault();

        const fetchData = await fetch(
          '/servisnyj-centr/',
          {
            method: 'POST',
            body: new FormData(layerForm),
          }
        );

        if (fetchData.ok) {
          const dataResult = await fetchData.json();

          if (dataResult.status == 'success') {
            const warningOverlay = service.querySelector('.overlay[data-overlay="warning"]');

            if (serviceOverlay.style.display != 'none') {
              serviceOverlay.style.display = 'none';
            }

            if (warningOverlay) {
              warningOverlay.style.display = 'flex';
            }
          }
          setTimeout(timeout_redirect, 1000);
        }
      }
    });
  }
  function timeout_redirect() {
    // Перенаправляем на главную страницу
    location.href = '/servisnyj-centr/';
  }
}
