export var wordByCount = function (count, wordOne, wordTwo, wordThree) {
    /**
     * Возвращает правильную форму слова для колличества чего-то
     *
     * @param {number} count Количество чего-то
     * @param {string} wordOne Первая форма слова. Например: "кот".
     * @param {string} wordTwo Вторая форма слова. Например: "кота".
     * @param {string} wordThree Третья форма слова. Например: "котов".
     * @return {string} wordOne | wordTwo | wordThree
     */
    if (count === 0) {
        return wordThree;
    }
    var val = count % 100;
    if (val > 10 && val < 20) {
        return wordThree;
    }
    val = count % 10;
    if (val === 1) {
        return wordOne;
    }
    if (val > 1 && val < 5) {
        return wordTwo;
    }
    return wordThree;
};
