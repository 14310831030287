import React from "react"

import ProductCard from "../ProductCard"
import SwiperWrapper from "../SwiperWrapper"
// import {useSelector} from "react-redux"
// import {selectCompareProducts} from "../../../js/redux/slices/compareSlice"
// import {selectFavoritesProducts} from "../../../js/redux/slices/favoritesSlice"
// import {compareInteractive} from "../../../js/lib/compareInteractive"
// import {favoriteInteractive} from "../../../js/lib/favoriteInteractive"

const ProductsSwiper = ({products, addedProducts, toggleAddProduct, isMobile}) => {
  // const compareProducts = useSelector(selectCompareProducts)
  // const favoritesProducts = useSelector(selectFavoritesProducts)

  // const handlerToggleToCompare = async (productId) => {
  //   if (compareProducts.includes(productId)) {
  //     await compareInteractive('delete', productId)
  //   } else {
  //     await compareInteractive('add', productId)
  //   }
  // }
  //
  // const handlerToggleToFavorite = async (productId) => {
  //   if (favoritesProducts.includes(productId)) {
  //     await favoriteInteractive('delete', productId)
  //   } else {
  //     await favoriteInteractive('add', productId)
  //   }
  // }

  return (
    <SwiperWrapper
      slidesPerView={"auto"}
      slidesPerGroup={2}
      spaceBetween={isMobile ? 16 : 52}
      freeMode
      withNav={false}
      dynamicWidth
    >
      {products.map(product => (
        <ProductCard
          key={product.slug}
          id={product.id}
          name={product.name}
          slug={product.slug}
          image={product.images.length ? product.images[0].desktop.preview.image : ''}
          status={product.status}
          tags={product.color_tags}
          price={product.price}
          discountedPrice={product.discounted_price}
          discountAmount={product.discount_amount}
          withBonuses={product.bonuses}
          bonuses={product.bonuses_amount}
          reviewsCount={product.reviews_count}
          rating={product.rating}
          isAdd={addedProducts && addedProducts.includes(product.id)}
          device={"desktop"}
          variant={"addProduct"}
          onAddClick={toggleAddProduct}
          isMobile={isMobile}
        />
      ))}
    </SwiperWrapper>
  )
}

export default ProductsSwiper