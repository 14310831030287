import React from "react"
import Button from "../Button"
import BoxIcon from "../icons/Box"
import CartIcon from "../icons/Cart"

const CartButton = ({isAdd, disabled = false, type = "cart", size = "small", onClick}) => {
  let addButton = null
  if (type === "cart") {
    addButton = (
      !disabled ? (
        <Button icon={<CartIcon/>} active={isAdd} size={size} onClick={onClick} >{isAdd ? "Добавлен" : "В корзину"}</Button>
      ) : (
        <Button disabled size={size}>{status.name}</Button>
      )
    )

  } else if (type === 'box') {
    addButton = (
      <Button icon={<BoxIcon/>} size={size} active={isAdd} type={type} onClick={onClick} style={{padding: '4px 42px'}}/>
    )
  }

  return addButton
}

export default CartButton