import styles from "./styles.modules.scss"

import React from "react"
import cn from "classnames"

export const  FilledFieldsWrapper = ({children, withFrame, active = false, variant = "row", firstFull = false}) => {
  const classes = cn(
    styles.root,
    {
      [styles.row]: variant === "row",
      [styles.column]: variant === "column",
      [styles.firstFull]: firstFull,
      [styles.frame]: withFrame,
      [styles.active]: active
    }
  )
  return (
    <div className={classes}>
      {React.Children.map(children, child => (
        <div className={styles.item}>
          {child}
        </div>
      ))}
    </div>
  )
}
