import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"

import {productAPI} from "../../api/productApi"

const initialState = {
  status: "idle",
  productData: null,
  addProductsSection: {
    products: [],
    categories: [],
    selectedProducts: []
  },
}


export const getProductData = (productSlug) => {
  return (dispatch) => {
    productAPI.getProduct(productSlug).then(response => {
      if (response.status === 200) {
        dispatch(setProductData(response.data))
      }
    })
  }
}


export const getAdditionalProductsSection = (productSlug) => {
  return (dispatch) => {
    productAPI.getAdditionalProductsSection(productSlug).then(response => {
      if (response.status === 200) {
        dispatch(setAdditionalProductsSection({...response.data}))
      }
    })
  }
}

export const getAdditionalProducts = ({productSlug, categorySlug = ''}) => {
  return (dispatch) => {
    productAPI.getAdditionalProducts({productSlug, categorySlug}).then(response => {
      if (response.status === 200) {
        dispatch(setAdditionalProducts(response.data))
      }
    })
  }
}

export const fetchAddProducts = createAsyncThunk('product/fetchAddProducts', async ({productSlug}) => {
  const response = await productAPI.getAdditionalProductsSection(productSlug)
  return response.data
})

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProductData: (state, action) => {
      state.productData = action.payload
    },
    setAdditionalProducts: (state, action) => {
      state.addProductsSection.products = action.payload
    },
    setAdditionalProductsSection: (state, action) => {
      state.addProductsSection = {...action.payload, selectedProducts: []}
    },
    addSelectedProduct: (state, action) => {
      const isAdd = state.addProductsSection.selectedProducts.find(productId => productId === action.payload)
      if (!isAdd) {
        state.addProductsSection.selectedProducts.push(action.payload)
      }
    },
    removeSelectedProduct: (state, action) => {
      const isAdd = state.addProductsSection.selectedProducts.find(productId => productId === action.payload)
      if (isAdd) {
        state.addProductsSection.selectedProducts = state.addProductsSection.selectedProducts.filter(productId => productId !== action.payload)
      }
    },
    clearSelectedProduct: (state) => {
      state.addProductsSection.selectedProducts = []
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAddProducts.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(fetchAddProducts.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.addProductsSection = {...action.payload, selectedProducts: []}
      })
  }
})

export const selectProductData = (state) => {
  return state.product.productData
}

export const selectAdditionalProducts = (categorySlug = null) => (state) => {
  let products = state.product.addProductsSection.products
  if (categorySlug) {
    products = products.filter(product => product.category.tag === categorySlug)
  }
  return products.slice(0, 20)
}

export const selectAdditionalProductsByIds = (productIds) => (state) => {
  return state.product.addProductsSection.products.filter(product => productIds.includes(product.id))
}

export const selectAdditionalProductsCategories = (state) => {
  return state.product.addProductsSection.categories
}

export const selectSelectedProducts = (state) => {
  const selectedProductsIds = state.product.addProductsSection.selectedProducts
  return state.product.addProductsSection.products.filter(product => selectedProductsIds.includes(product.id))
}

export const selectSelectedProductsIds = (state) => {
  return state.product.addProductsSection.selectedProducts
}

export const {
  setProductData,
  setAdditionalProducts,
  setAdditionalProductsSection,
  addSelectedProduct,
  removeSelectedProduct,
  clearSelectedProduct
} = productSlice.actions

export default productSlice.reducer