var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Provider } from "react-redux";
import { store } from "@src/app/store";
import { setActivePopup } from "@src/entities/popup";
import { useAppDispatch } from "@src/shared/hooks";
import { ManIcon, NavButton } from "@src/shared/ui";
var LoginButton = function (_a) {
    var isDesktop = _a.isDesktop;
    var dispatch = useAppDispatch();
    var handleClick = function () {
        dispatch(setActivePopup("signIn"));
    };
    return (_jsx(NavButton, { title: "\u0412\u0445\u043E\u0434", icon: _jsx(ManIcon, {}), isDesktop: isDesktop, onClick: handleClick }));
};
var LoginButtonWithStore = function (props) { return (_jsx(Provider, { store: store, children: _jsx(LoginButton, __assign({}, props)) })); };
export { LoginButtonWithStore as LoginButton };
