import styles from "./CartProductsSection.modules.scss"

import React from "react";
import cn from "classnames";

import {ProductCard} from "../ProductCard";
import SwiperWrapper from "../../../SwiperWrapper";

export const CartProductsSection = ({title, products, isMobile = false}) => {
  const productsItems = products

  const ListWrapper = ({children}) => (
    isMobile ? (
      <SwiperWrapper
        slidesPerView={1}
        slidesPerGroup={1}
        spaceBetween={20}
        withNav={false}
        pagination
        breakpoints={{
          320: {
            slidesPerView: 1
          },
          468: {
            slidesPerView: 2
          },
          768: {
            slidesPerView: 3
          },
          1024: {
            slidesPerView: 4
          },
        }}
      >
        {children}
      </SwiperWrapper>
    ) : (
      <div className={styles.listDesktop}>
        {children}
      </div>
    )
  )

  return (
    <div className={cn(styles.root, isMobile && styles.mobile)}>
      <span className={styles.title}>{title}</span>
      <ListWrapper>
        {productsItems.map(product => (
          <ProductCard
            key={product.id}
            product={product}
            className={cn(styles.item, isMobile && styles.mobile)}
          />
        ))}
      </ListWrapper>
    </div>
  )
}
