var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import styles from "./styles.module.scss";
import cn from "classnames";
export var ChartFilterField = function (_a) {
    var rangesList = _a.rangesList, selectedRanges = _a.selectedRanges, activeRange = _a.activeRange, onChange = _a.onChange;
    // Находим максимальное значение quantity
    var maxQuantity = Math.max.apply(Math, rangesList.map(function (range) { return range.quantity; }));
    // Рассчитываем коэффициент
    var coefficient = 60 / maxQuantity;
    // Создаем массив chartDataList для хранения длин линий
    var chartDataList = rangesList.map(function (range) { return ({
        min: range.min,
        max: range.max,
        height: range.quantity * coefficient > 1 ? range.quantity * coefficient : 1,
    }); });
    // Добавляем дополнительные линии, чтобы получить 17
    while (chartDataList.length < 17) {
        chartDataList.push(null);
    }
    var clickLineHandler = function (value) {
        if (onChange) {
            var isRangeSelected = !!selectedRanges.find(function (item) { return item[0] === value[0] && item[1] === value[1]; });
            if (isRangeSelected) {
                onChange(selectedRanges.filter(function (item) { return item[0] !== value[0] && item[1] !== value[1]; }));
            }
            else {
                onChange(__spreadArray(__spreadArray([], selectedRanges, true), [value], false));
            }
        }
    };
    return (_jsx("div", { className: styles.root, children: chartDataList.map(function (item, index) {
            var isActive = false;
            if (item) {
                if (activeRange) {
                    isActive = activeRange[0] <= item.min && activeRange[1] >= item.max;
                }
                else if (selectedRanges === null || selectedRanges === void 0 ? void 0 : selectedRanges.length) {
                    isActive = !!selectedRanges.find(function (rangeItem) {
                        return item.min === rangeItem[0] && item.max === rangeItem[1];
                    });
                }
                else {
                    isActive = true;
                }
            }
            return (_jsx("button", { type: "button", className: styles.chartBtn, onClick: function () { return clickLineHandler([item.min, item.max]); }, disabled: !item, children: _jsx("div", { className: cn(styles.chartLine, isActive && styles.active), style: { height: "".concat(item ? item.height : 1, "px") } }) }, index));
        }) }));
};
