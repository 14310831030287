import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./Mobile.module.scss";
import { useState } from "react";
import cn from "classnames";
import SimpleBar from "simplebar-react";
import { SearchField } from "./SearchField";
import logoImg from "@src/assets/images/logo.png";
import { useToggleBodyOverflow } from "@src/shared/hooks";
import { Text, BurgerMenuIcon, SearchIcon } from "@src/shared/ui";
export var Mobile = function (_a) {
    var slots = _a.slots;
    var _b = useState(false), isActiveSearch = _b[0], setIsActiveSearch = _b[1];
    useToggleBodyOverflow(isActiveSearch);
    var showBurgerMenuHandler = function () {
        // TODO: Заменить на показ нового компонента BurgerMenu
        var overlayAlt = document.querySelector(".overlay-alt");
        if (overlayAlt) {
            var overlayLayer = overlayAlt.querySelector('.overlay-layer[data-layer="1"]');
            var overlayLayerEach = function (overlayLayer) {
                if (!(overlayLayer === null || overlayLayer === void 0 ? void 0 : overlayLayer.classList.contains("layer_hidden"))) {
                    overlayLayer === null || overlayLayer === void 0 ? void 0 : overlayLayer.classList.add("layer_hidden");
                }
            };
            var overlayLayerAll = overlayAlt.querySelectorAll(".overlay-layer");
            if (overlayAlt.style.display !== "block") {
                document.body.style.overflow = "hidden";
                overlayAlt.style.display = "block";
                if (overlayLayer === null || overlayLayer === void 0 ? void 0 : overlayLayer.classList.contains("layer_hidden")) {
                    overlayLayer.classList.remove("layer_hidden");
                }
            }
            else {
                document.body.style.overflow = null;
                overlayAlt.style.display = "none";
                overlayLayerAll.forEach(overlayLayerEach);
            }
        }
    };
    var activeSearchHandler = function () {
        setIsActiveSearch(true);
    };
    var cancelSearchHandler = function () {
        setIsActiveSearch(false);
    };
    var main = (_jsxs("div", { className: styles.main, children: [_jsx("button", { className: styles.burgerButton, type: "button", onClick: showBurgerMenuHandler, children: _jsx(BurgerMenuIcon, {}) }), _jsx("a", { className: styles.logoLink, href: "/", children: _jsx("img", { src: logoImg, alt: "", width: 112, height: 32 }) }), _jsx("button", { className: styles.searchButton, type: "button", onClick: activeSearchHandler, children: _jsx(SearchIcon, {}) })] }));
    var search = (_jsxs("div", { className: styles.search, children: [_jsx(SearchField, {}), _jsx("button", { type: "button", onClick: cancelSearchHandler, children: _jsx(Text, { variant: "buttonExtraSmall", children: "\u041E\u0442\u043C\u0435\u043D\u0430" }) })] }));
    return (_jsxs("div", { className: styles.root, children: [!!(slots === null || slots === void 0 ? void 0 : slots.searchPopup) && isActiveSearch ? search : main, !!(slots === null || slots === void 0 ? void 0 : slots.searchPopup) && (_jsx(SimpleBar, { className: cn(styles.searchPopupLayer, isActiveSearch && styles.show), scrollbarMaxSize: 260, autoHide: true, children: slots.searchPopup }))] }));
};
