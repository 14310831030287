var _a;
import { configureStore } from "@reduxjs/toolkit";
import customerReducer from "../../../js/redux/slices/customerSlice";
import orderingOldReducer from "../../../js/redux/slices/orderingSlice";
import productReducer from "../../../js/redux/slices/productSlice";
import shopsReducer from "../../../js/redux/slices/shopsSlice";
import { cartSlice } from "@src/entities/cart";
import { catalogSlice } from "@src/entities/catalog";
import { compareSlice } from "@src/entities/compare";
import { favoritesSlice } from "@src/entities/favorites";
import { popupSlice } from "@src/entities/popup";
import { searchPopupSlice } from "@src/entities/search-popup";
import { baseApi } from "@src/shared/api";
import { orderingSlice } from "@src/widgets/Ordering";
import { signInSlice } from "@widgets/SignInPopup/model";
export var store = configureStore({
    reducer: (_a = {
            orderingOld: orderingOldReducer,
            customer: customerReducer,
            shops: shopsReducer,
            product: productReducer,
            catalog: catalogSlice.reducer,
            cart: cartSlice.reducer,
            favorites: favoritesSlice.reducer,
            popup: popupSlice.reducer,
            compare: compareSlice.reducer,
            searchPopup: searchPopupSlice.reducer,
            signIn: signInSlice.reducer,
            ordering: orderingSlice.reducer
        },
        _a[baseApi.reducerPath] = baseApi.reducer,
        _a),
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware().concat(baseApi.middleware);
    },
    devTools: process.env.NODE_ENV === "development",
});
