import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./PrivacyPolicyPopup.module.scss";
import { useEffect, useState } from "react";
import cn from "classnames";
import Cookies from 'js-cookie';
import { Button, CloseIcon, LinkButton, Text } from "@shared/ui";
export var PrivacyPolicyPopup = function (_a) {
    var _b;
    var _c = _a.isDesktop, isDesktop = _c === void 0 ? false : _c;
    var _d = useState(false), isOpen = _d[0], setIsOpen = _d[1];
    useEffect(function () {
        var isShowPrivacy = Boolean(Cookies.get("show-privacy"));
        if (!isShowPrivacy) {
            setIsOpen(true);
        }
    }, []);
    var mainClasses = cn(styles.main, (_b = {},
        _b[styles.open] = isOpen,
        _b[styles.desktop] = isDesktop,
        _b));
    var handleClose = function () {
        setIsOpen(false);
        Cookies.set("show-privacy", "true", { expires: 365 });
    };
    return (_jsxs("div", { className: mainClasses, children: [_jsxs("div", { className: styles.header, children: [_jsx(Text, { variant: "h5", isDesktop: isDesktop, children: "\u0421\u043E\u0433\u043B\u0430\u0448\u0435\u043D\u0438\u0435 \u0441 \u043F\u043E\u043B\u0438\u0442\u0438\u043A\u043E\u0439 \u043A\u043E\u043D\u0444\u0438\u0434\u0435\u043D\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0441\u0442\u0438" }), _jsx("div", { className: styles.closeBtn, onClick: handleClose, children: _jsx(CloseIcon, {}) })] }), _jsx(Text, { variant: "body1", isDesktop: isDesktop, children: "\u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0430\u044F \u0438\u0441\u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u044C \u044D\u0442\u043E\u0442 \u0441\u0430\u0439\u0442, \u0432\u044B \u0430\u0432\u0442\u043E\u043C\u0430\u0442\u0438\u0447\u0435\u0441\u043A\u0438 \u0441\u043E\u0433\u043B\u0430\u0448\u0430\u0435\u0442\u0435\u0441\u044C \u0441 \u043D\u0430\u0448\u0435\u0439 \u043F\u043E\u043B\u0438\u0442\u0438\u043A\u043E\u0439 \u043A\u043E\u043D\u0444\u0438\u0434\u0435\u043D\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0441\u0442\u0438. \u041C\u044B \u0443\u0432\u0430\u0436\u0430\u0435\u043C \u0432\u0430\u0448\u0443 \u043A\u043E\u043D\u0444\u0438\u0434\u0435\u043D\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0441\u0442\u044C \u0438 \u043E\u0431\u044F\u0437\u0443\u0435\u043C\u0441\u044F \u0437\u0430\u0449\u0438\u0449\u0430\u0442\u044C \u0432\u0430\u0448\u0438 \u043B\u0438\u0447\u043D\u044B\u0435 \u0434\u0430\u043D\u043D\u044B\u0435." }), _jsxs("div", { className: styles.control, children: [_jsx(Button, { className: styles.acceptButton, onClick: handleClose, children: "\u041F\u043E\u043D\u044F\u0442\u043D\u043E" }), _jsx(LinkButton, { href: "/politika_konfidenczialnosti.pdf", className: styles.link, children: _jsx(Text, { variant: "buttonMedium", isDesktop: isDesktop, children: "\u0423\u0437\u043D\u0430\u0442\u044C \u043F\u043E\u0434\u0440\u043E\u0431\u043D\u0435\u0435" }) })] })] }));
};
