import styles from "./ProductCard.modules.scss"

import React from "react"
import cn from "classnames"

import ProductTags from "../ProductTags"
import RatingStars from "../RatingStars"
import Image from "../Image"
import CommentIcon from "../icons/Comment"
import ProductPrices from "../ProductPrices"
// import Button from "../Button"
// import CartIcon from "../icons/Cart"
// import HeartIcon from "../icons/Heart"
// import CompareIcon from "../icons/Compare"
// import BoxIcon from "../icons/Box"
import CartButton from "../CartButton";
import {FavouriteBtn} from "@features/FavouriteBtn";

const ProductCard = (
  {
    id,
    name,
    slug,
    image = null,
    status,
    tags,
    price,
    discountedPrice,
    discountAmount,
    withBonuses,
    bonuses,
    reviewsCount,
    rating,
    isAdd,
    inFavorites,
    inCompare,
    onAddClick,
    onFavoriteClick,
    onCompareClick,
    isMobile = false,
    variant = "default"
  }
) => {
  const classes = cn(
    styles.root,
    {
      [styles.mobile]: isMobile
    }
  )

  const onClickAddButtonHandler = () => {
    onAddClick(id)
  }

  const favouriteButton = <FavouriteBtn productId={id} isDesktop={!isMobile} />

  return (
    <div className={classes}>
      <a href={`/product/${slug}`} className={styles.main}>
        <div className={styles.imageLayer}>
          <div className={styles.tagsLayer}>
            <ProductTags tags={tags}/>
          </div>
          <Image src={image} alt={name} className={styles.image}/>
        </div>
        <div className={styles.reviewsLayer}>
          <RatingStars rating={rating}/>
          {reviewsCount > 0 && (
            <span className={styles.reviewsCount}>
            <CommentIcon/>
              <span className={styles.count}>{reviewsCount}</span>
          </span>
          )}
        </div>
        <div className={styles.title}>{name}</div>
        <div className={styles.pricesLayer}>
          <ProductPrices
            price={price}
            discountedPrice={discountedPrice}
            discountAmount={discountAmount}
            withBonuses={withBonuses}
            bonusesAmount={bonuses}
            isMobile={isMobile}
          />
        </div>
      </a>
      <div className={styles.control}>
        <CartButton isAdd={isAdd} type={"box"} onClick={onClickAddButtonHandler}/>
        {!isMobile && (
          <>
            <div className={styles.favoriteBtn}>
              {favouriteButton}
            </div>
            {/*<Button*/}
            {/*  icon={<CompareIcon/>}*/}
            {/*  variant={"secondary"}*/}
            {/*  active={inCompare}*/}
            {/*  onClick={onCompareClick}*/}
            {/*/>*/}
          </>
        )}
      </div>
    </div>
  )
}

export default ProductCard