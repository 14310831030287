import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./Desktop.module.scss";
import cn from "classnames";
import { LoginLink } from "../LoginLink";
import { SignUpForm } from "./form";
import { AdvantagesList } from "@entities/advantage";
import { setActivePopup } from "@entities/popup";
import { GoogleAuthBtn, YandexAuthBtn } from "@features/auth";
import { useAppDispatch } from "@shared/hooks";
import { CloseIcon, OrSeparator, Text } from "@shared/ui";
export var Desktop = function (_a) {
    var _b;
    var isOpen = _a.isOpen, title = _a.title;
    var dispatch = useAppDispatch();
    var handleCloseModal = function () { return dispatch(setActivePopup("")); };
    var currentTitle = title ? (_jsx(Text, { variant: "h3", className: styles.title, isDesktop: true, children: title })) : (_jsx(Text, { variant: "h3", isDesktop: true, children: "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0430\u0446\u0438\u044F" }));
    return (_jsxs("div", { className: cn(styles.main, (_b = {}, _b[styles.open] = isOpen, _b)), children: [_jsx("div", { className: styles.overlay, onClick: handleCloseModal }), _jsxs("div", { className: styles.modal, children: [_jsx(AdvantagesList, { isDesktop: true }), _jsxs("div", { className: styles.content, children: [_jsxs("div", { className: styles.header, children: [currentTitle, _jsx("div", { className: styles.closeBtn, onClick: handleCloseModal, children: _jsx(CloseIcon, {}) })] }), _jsxs("div", { className: styles.authBtns, children: [_jsx(GoogleAuthBtn, { isDesktop: true }), _jsx(YandexAuthBtn, { isDesktop: true })] }), _jsx(OrSeparator, { isDesktop: true }), _jsx(SignUpForm, { isDesktop: true }), _jsx(LoginLink, { isDesktop: true }), _jsx("div", {})] })] })] }));
};
