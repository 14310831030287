import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./styles.module.scss";
import cn from "classnames";
import placeholderImg from "@src/assets/images/placeholder.png";
import { CloseIcon, Text } from "@src/shared/ui";
export var QueryCategoryItem = function (_a) {
    var name = _a.name, imageSrc = _a.imageSrc, _b = _a.isDesktop, isDesktop = _b === void 0 ? false : _b, onClick = _a.onClick, onClickClose = _a.onClickClose;
    var closeHandler = function (event) {
        event.stopPropagation();
        onClickClose === null || onClickClose === void 0 ? void 0 : onClickClose();
    };
    return (_jsxs("div", { className: cn(styles.root, isDesktop && styles.desktop), onClick: onClick, children: [_jsx("div", { className: styles.imageLayer, children: _jsx("img", { className: styles.image, src: imageSrc || placeholderImg, alt: "" }) }), _jsx("div", { className: styles.textLayer, children: _jsx(Text, { className: styles.text, variant: "body4", isDesktop: isDesktop, children: name }) }), _jsx("button", { className: styles.closeBtn, type: "button", onClick: closeHandler, children: _jsx(CloseIcon, {}) })] }));
};
