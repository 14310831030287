import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {
  selectDeliveryAddressData,
  selectPaymentMethod,
  selectPickupAddressData,
  selectReceivingMethod,
  setCurrentStep,
  setPaymentMethod,
  setReceivingMethod
} from "../../../../../js/redux/slices/orderingSlice"
import {DeliveryForm} from "./DeliveryForm"
import {PickUpForm} from "./PickUpForm"
import {StepWrapper} from "../../StepWrapper"
import {FilledFieldsWrapper} from "../../FilledFieldsWrapper"
import {SelectMethod} from "../../SelectMethod"
import {Button, EditButton, Text, FilledField} from "../../shared"

export const StepReceivingMethod = ({isCurrent, isCreating, isMobile = false}) => {
  const dispatch = useDispatch()

  const method = useSelector(selectReceivingMethod)
  const paymentMethod = useSelector(selectPaymentMethod)

  const deliveryAddress = useSelector(selectDeliveryAddressData)
  const pickupAddress = useSelector(selectPickupAddressData)

  const deliveryMethods = [
    {title: "Доставка", value: "DELIVERY", active: true},
    {title: "Самовывоз", value: "PICKUP", active: true}
  ]

  const methodData = deliveryMethods.find(item => item.value === method)

  const [mode, setMode] = useState("edit")

  useEffect(() => {
    if (method === "DELIVERY") {
      setMode(isCurrent ? "edit" : deliveryAddress ? "filled" : "blank")
    } else {
      setMode(isCurrent ? "edit" : pickupAddress ? "filled" : "blank")
    }
  }, [isCurrent])

  const setMethodHandler = (method) => {
    dispatch(setReceivingMethod(method))
  }

  const nextStepHandler = () => {
    dispatch(setCurrentStep("PAYMENT"))
    if (paymentMethod && method === "PICKUP") {
      dispatch(setPaymentMethod("CASH"))
    }
  }

  const backStepHandler = () => {
    dispatch(setCurrentStep("CONTACTS"))
  }

  const clickEditHandler = () => {
    dispatch(setCurrentStep("RECEIVING"))
  }

  let content = null
  switch (mode) {
    case "filled":
      const addressData = method === "DELIVERY" ? deliveryAddress : pickupAddress
      content = (
        <FilledFieldsWrapper variant={isMobile ? "column" : "row"} firstFull>
          <FilledField title={"Способ"} value={methodData.title}/>
          <FilledField title={"Город"} value={addressData?.city}/>
          <FilledField title={"Улица"} value={addressData?.street}/>
          <FilledField title={"Дом"} value={addressData?.house}/>
          {!!addressData?.apartment && <FilledField title={"Квартира"} value={addressData.apartment}/>}
        </FilledFieldsWrapper>
      )
      break
    case "edit":
      content = (
        <>
          <SelectMethod
            options={deliveryMethods}
            selected={methodData}
            onSelect={setMethodHandler}
            isMobile={isMobile}
          />
          {method === "DELIVERY" && (
            <>
              <DeliveryForm formId={"deliveryForm"} onSubmit={nextStepHandler} isMobile={isMobile}/>
              <Text textAlign={"right"}>от 5000₽ доставка бесплатная</Text>
            </>
          )}
          {method === "PICKUP" && (
            <PickUpForm formId={"pickupForm"} onSubmit={nextStepHandler} isMobile={isMobile}/>
          )}
        </>
      )
      break
  }

  return (
    <StepWrapper
      title={"Способ получения"}
      number={"3"}
      editBtn={!isCreating && <EditButton title={"Редактировать"} onClick={clickEditHandler}/>}
      backStepBtn={<Button title={"Назад"} variant={"gray"} onClick={backStepHandler}/>}
      nextStepBtn={<Button
        title={"Далее"}
        type={"submit"}
        form={method === "DELIVERY" ? "deliveryForm" : "pickupForm"}
      />}
      isMobile={isMobile}
      mode={mode}
    >
      {content}
    </StepWrapper>
  )
}
