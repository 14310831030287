import React from "react"

const Plus = ({width = "100%", height = "100%", color = "#EF4123"}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
    <path d="M12 20V4M4 12L20 12" stroke={color} strokeWidth="2" strokeLinecap="round"/>
  </svg>

)

export default Plus
