import { jsx as _jsx } from "react/jsx-runtime";
import { YandexIcon } from "./icons";
import { Button } from "@shared/ui";
export var YandexAuthBtn = function (_a) {
    var _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.isDesktop, isDesktop = _c === void 0 ? false : _c;
    var handleAuth = function () {
        if (!disabled) {
            // Auth Logic
        }
    };
    return (_jsx(Button, { Component: "a", href: "/accounts/yandex/login/?method=js_sdk", variant: "secondary", startIcon: _jsx(YandexIcon, {}), children: isDesktop && "Войти через Яндекс" }));
};
