import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {
  selectContactsData,
  setCurrentStep
} from "../../../../../js/redux/slices/orderingSlice"
import {ContactsForm} from "./ContactsForm"
import {StepWrapper} from "../../StepWrapper"
import {FilledFieldsWrapper} from "../../FilledFieldsWrapper"
import {Button, EditButton, getFullName, FilledField} from "../../shared"

export const StepContactsInfo = ({isCurrent, isCreating, isMobile = false}) => {
  const dispatch = useDispatch()

  const contacts = useSelector(selectContactsData)

  const [mode, setMode] = useState("edit")

  useEffect(() => {
    setMode(isCurrent ? "edit" : contacts ? "filled" : "blank")
  }, [isCurrent])

  const nextStepHandler = () => {
    dispatch(setCurrentStep("RECEIVING"))
  }

  const backStepHandler = () => {
    dispatch(setCurrentStep("CART"))
  }

  const clickEditHandler = () => {
    dispatch(setCurrentStep("CONTACTS"))
  }

  let content = null
  switch (mode) {
    case "filled":
      content = (
        <FilledFieldsWrapper variant={isMobile ? "column" : "row"}>
          <FilledField title={"Имя и фамилия"} value={getFullName(contacts?.firstName, contacts?.lastName)}/>
          <FilledField title={"Номер телефона"} value={contacts?.phone}/>
          {!!contacts?.email && <FilledField title={"E-mail"} value={contacts.email}/>}
        </FilledFieldsWrapper>
      )
      break
    case "edit":
      content = <ContactsForm formId={"contactForm"} isMobile={isMobile} onSubmit={nextStepHandler}/>
      break
  }

  return (
    <StepWrapper
      title={"Контактная информация"}
      number={"2"}
      editBtn={!isCreating && <EditButton title={"Редактировать"} onClick={clickEditHandler}/>}
      backStepBtn={<Button title={"Назад"} variant={"gray"} onClick={backStepHandler}/>}
      nextStepBtn={<Button title={"Далее"} type={"submit"} form={"contactForm"}/>}
      isMobile={isMobile}
      mode={mode}
    >
      {content}
    </StepWrapper>
  )
}
