import {useEffect} from "react";

export const useMultiRefsOutsideClicker = (refs, callback, args) => {
  useEffect(() => {
    function handleClickOutside(event) {
      for (const ref of refs) {
        if (ref.current && ref.current.contains(event.target)) {
          return;
        }
      }

      callback();
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [callback, refs, args]);
}
