import styles from "./styles.modules.scss"

import React from "react"
import {useDispatch, useSelector} from "react-redux"

import {
  selectSpendBonuses,
  setCurrentStep,
  setSpendBonuses
} from "../../../../../js/redux/slices/orderingSlice"
import {selectFoksClubCard} from "../../../../../js/redux/slices/customerSlice"
import {Price} from "./Price"
import {BonusesBadge} from "./BonusesBadge"
import {FoksClubInfo} from "./FoksClubInfo"
import {FoksClubCardForm} from "./FoksClubCardForm"
import {splitCardNumberBySpaces} from "../model"
import {
  Separator, Title, Row,
  InfoFieldWrapper,
  Button, Checkbox
} from "../../shared"

import {useGetCartDetailQuery} from "@src/shared/api"

export const OrderDetail = ({isCurrent, isCreating, isMobile = false}) => {
  const dispatch = useDispatch()

  const {data: cartData} = useGetCartDetailQuery()

  const spendBonuses = useSelector(selectSpendBonuses)
  const foksClubCard = useSelector(selectFoksClubCard)

  const backStepHandler = () => {
    dispatch(setCurrentStep("COMMENT"))
  }

  const orderCreatingHandler = () => {
    dispatch(setCurrentStep("CREATING"))
  }

  const spendBonusesChangeHandler = (value) => {
    dispatch(setSpendBonuses(value))
  }

  return (
    <div className={styles.root}>
      <Title isMobile>Детали заказа</Title>
      <Separator/>
      <InfoFieldWrapper title={"Бонусная программа"} gap={16}>
        {foksClubCard ? (
          <>
            <FoksClubInfo
              bonusesCount={foksClubCard.balance_of_bonuses}
              cardNumber={splitCardNumberBySpaces(foksClubCard.number)}
              isMobile={isMobile}
            />
            {!!foksClubCard.balance_of_bonuses && (
              <Checkbox label={"Потратить бонусы"} onChange={spendBonusesChangeHandler}/>
            )}
          </>
        ) : (
          <>
            <FoksClubCardForm/>
            <a href="/foks-club/" className={styles.howConnect}>Как подключить?</a>
          </>
        )}
      </InfoFieldWrapper>
      <Separator/>
      <InfoFieldWrapper title={"Стоимость без скидок"}>
        <Price value={cartData.total_price} size={"m"} isMobile={isMobile}/>
      </InfoFieldWrapper>
      {(!!cartData.total_discount_amount || !!(spendBonuses && !!foksClubCard?.balance_of_bonuses)) && (
        <Row isWrap>
          {!!cartData.total_discount_amount && (
            <InfoFieldWrapper title={"Размер скидки"}>
              <Price value={cartData.total_discount_amount} isMobile={isMobile}/>
            </InfoFieldWrapper>
          )}
          {!!(spendBonuses && !!foksClubCard?.balance_of_bonuses) && (
            <InfoFieldWrapper title={"Оплачено бонусами"}>
              <Price value={foksClubCard.balance_of_bonuses} isMobile={isMobile}/>
            </InfoFieldWrapper>
          )}
        </Row>
      )}
      <Separator/>
      {!!cartData.total_bonuses_amount && (
        <InfoFieldWrapper title={"Бонусы на следующую покупку"}>
          <BonusesBadge bonusesCount={cartData.total_bonuses_amount} size={"l"}/>
        </InfoFieldWrapper>
      )}
      <InfoFieldWrapper title={"Итоговая стоимость"}>
        <Price
          value={cartData.total_discount_price - (spendBonuses && foksClubCard?.balance_of_bonuses || 0)}
          size={"l"} color={"#EF4123"} isMobile={isMobile}/>
      </InfoFieldWrapper>
      {isMobile && (isCurrent || isCreating) && (
        <>
          {!isCreating && (
            <Button title={"Назад"} variant={"gray"} onClick={backStepHandler}/>
          )}
          <Button title={"Оформить заказ"} type={"submit"} onClick={orderCreatingHandler} disabled={isCreating}/>
        </>
      )}
    </div>
  )
}
