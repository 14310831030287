import { baseApi } from "./base";
export var searchApiSlice = baseApi.injectEndpoints({
    endpoints: function (build) { return ({
        searchByQuery: build.query({
            query: function (query) { return ({ url: "/search/", method: "GET", params: { query: query } }); },
        }),
        getHints: build.query({
            query: function (query) { return ({ url: "/search/hints/", method: "GET", params: { query: query } }); },
        }),
        getProductsForYou: build.query({
            query: function () { return ({ url: "/search/for-you/", method: "GET" }); },
        }),
        getProductsOftenSearch: build.query({
            query: function () { return ({ url: "/search/often-search/", method: "GET" }); },
        }),
        updateOftenProductIndex: build.mutation({
            query: function (productId) { return ({
                url: "/search/often-search/",
                method: "POST",
                body: { id: productId },
            }); },
        }),
        getPopularQueries: build.query({
            query: function () { return ({ url: "/search/query/", method: "GET" }); },
        }),
        createSearchQuery: build.mutation({
            query: function (text) { return ({
                url: "/search/query/",
                method: "POST",
                body: {
                    text: text,
                },
            }); },
        }),
    }); },
});
export var useSearchByQueryQuery = searchApiSlice.useSearchByQueryQuery, useGetHintsQuery = searchApiSlice.useGetHintsQuery, useGetProductsForYouQuery = searchApiSlice.useGetProductsForYouQuery, useGetProductsOftenSearchQuery = searchApiSlice.useGetProductsOftenSearchQuery, useUpdateOftenProductIndexMutation = searchApiSlice.useUpdateOftenProductIndexMutation, useGetPopularQueriesQuery = searchApiSlice.useGetPopularQueriesQuery, useCreateSearchQueryMutation = searchApiSlice.useCreateSearchQueryMutation;
