const sale_filter = document.querySelector('.sale > .sale-content .filter');

if (sale_filter) {
  const filter_group_all = sale_filter.querySelectorAll('.filter-group');

  filter_group_all.forEach(filter_group_each);

  function filter_group_each(filter_group) {
    const group_info = filter_group.querySelector('.group-info');
    const group_content = filter_group.querySelector('.group-content');

    group_info.addEventListener('click', group_info_click);
    // group_info.addEventListener('transitionend', group_info_transitionend);

    function group_info_click() {
      group_info.classList.toggle('info_close');
      group_content.classList.toggle('hide')
    }

    // function group_info_transitionend() {
    //   if (group_content.style.height != '0px') group_content.style.height = 'auto';
    // }
  }
}
