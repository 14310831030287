import styles from "./styles.modules.scss"

import React from "react"
import cn from "classnames"

import {numberWithSpaces, wordByCount} from "../../../shared"

export const FoksClubInfo = ({bonusesCount, cardNumber, className, isMobile = false}) => (
  <div className={cn(styles.root, isMobile && styles.mobile, className)}>
    <div className={styles.bonuses}>
      <span className={styles.title}>У вас есть</span>
      <span className={styles.count}>
        {numberWithSpaces(bonusesCount)} {wordByCount(bonusesCount, "бонус", "бонуса", "бонусов")}
      </span>
    </div>
    <div className={styles.card}>
      <span className={styles.title}>Номер карты</span>
      <span className={styles.number}>{cardNumber}</span>
    </div>
  </div>
)