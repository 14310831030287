import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styles from "./Desktop.module.scss";
import { useState } from "react";
import cn from "classnames";
import { Bonus } from "../../Bonus";
import { ProductImages } from "../../CardImages";
import { CardTags } from "../../CardTags";
import { OldPrice } from "../../OldPrice";
import { Rating } from "../../Rating";
import { CardSpecification } from "../../CardSpecification";
import { formattedPrice } from "@shared/lib";
import { EmptyBoxIcon, SmallButton, Tag, Text } from "@shared/ui";
export var Desktop = function (_a) {
    var _b, _c, _d;
    var images = _a.images, rating = _a.rating, reviewsCount = _a.reviewsCount, url = _a.url, sku = _a.sku, name = _a.name, bonusesAmount = _a.bonusesAmount, price = _a.price, discountedPrice = _a.discountedPrice, slots = _a.slots, status = _a.status, tags = _a.tags, specifications = _a.specifications;
    var isAvailable = status.code === "IN_STOCK" || status.code === "LIMITED";
    var currentButton = isAvailable ? (slots === null || slots === void 0 ? void 0 : slots.inCartButton) : (_jsx(SmallButton, { icon: _jsx(EmptyBoxIcon, {}), isDisabled: true }));
    var _e = useState(false), isVisibleTags = _e[0], setIsVisibleTags = _e[1];
    var _f = useState(false), isHovered = _f[0], setIsHovered = _f[1];
    var _g = useState(false), isShowSpecs = _g[0], setIsShowSpecs = _g[1];
    var handleToggleVisibility = function (value) {
        setIsVisibleTags(value);
    };
    var handleHover = function () {
        setIsHovered(true);
    };
    var handleLeaveHover = function () {
        setIsHovered(false);
        setIsVisibleTags(false);
        setIsShowSpecs(false);
    };
    var handleShowSpecs = function (value) {
        setIsShowSpecs(value);
    };
    var cardContent = (_jsxs(_Fragment, { children: [tags.length > 0 && (_jsx("div", { className: styles.cardTagsWrapper, children: _jsx(CardTags, { tags: tags, isExpanded: isVisibleTags, onShow: handleToggleVisibility, isDesktop: true }) })), _jsxs("a", { href: url, className: styles.main, onMouseEnter: handleHover, children: [_jsx("div", { className: styles.favourite, children: slots === null || slots === void 0 ? void 0 : slots.favouriteBtn }), _jsx("div", { className: styles.compare, children: slots === null || slots === void 0 ? void 0 : slots.compareBtn }), _jsx("div", { className: styles.imagesWrapper, children: _jsx("div", { className: styles.images, children: _jsx(ProductImages, { isDesktop: true, images: images.slice(0, 7), isNotAvailable: !isAvailable }) }) }), _jsxs("div", { className: styles.rating, children: [reviewsCount > 0 && (_jsx(Rating, { isDesktop: true, rating: rating, reviewsCount: reviewsCount })), _jsxs(Text, { color: "#C0C0C0", variant: "body2", isDesktop: true, children: ["\u041A\u043E\u0434: ", sku] })] }), _jsx("div", { className: styles.nameWrapper, children: _jsx(Text, { variant: "body1", isDesktop: true, className: styles.name, children: name }) }), _jsx("div", { className: cn(styles.bonus, (_b = {},
                            _b[styles.hide] = isShowSpecs && bonusesAmount <= 0,
                            _b)), children: bonusesAmount > 0 && (_jsx(Bonus, { isDesktop: true, bonusesAmount: bonusesAmount })) }), _jsxs("div", { className: cn(styles.discountedPrice, (_c = {},
                            _c[styles.hide] = isShowSpecs && price === discountedPrice && isAvailable,
                            _c)), children: [price !== discountedPrice && isAvailable && (_jsxs(_Fragment, { children: [_jsx(OldPrice, { isDesktop: true, price: price, discountedPrice: discountedPrice }), _jsxs(Tag, { isDesktop: true, backgroundColor: "#8976DE", children: ["-", formattedPrice(price - discountedPrice), " \u20BD"] })] })), !isAvailable && (_jsx(Text, { isDesktop: true, variant: "body1", children: "\u041D\u0435\u0442 \u0432 \u043D\u0430\u043B\u0438\u0447\u0438\u0438" }))] }), _jsxs("div", { className: styles.purchase, children: [_jsxs(Text, { variant: "bodyPrice", className: styles.price, isDesktop: true, children: [formattedPrice(discountedPrice), " \u20BD"] }), _jsx("div", { className: styles.button, children: currentButton })] })] })] }));
    var bonusesBlockHeight = isShowSpecs && bonusesAmount <= 0 ? 28 : 0;
    var discountedPriceHeight = isShowSpecs && price === discountedPrice && isAvailable ? 30 : 0;
    var cardSpecification = (_jsx(CardSpecification, { onClickMoreDetail: handleShowSpecs, data: specifications, isExpanded: isShowSpecs, addedHeight: bonusesBlockHeight + discountedPriceHeight }));
    return isHovered ? (_jsx("div", { className: styles.mainWrapper, children: _jsx("div", { className: styles.wrapper, onMouseLeave: handleLeaveHover, children: _jsxs("div", { className: styles.overflow, children: [_jsx("div", { className: cn(styles.cardLayer, (_d = {}, _d[styles.moveUp] = isShowSpecs, _d)), children: cardContent }), _jsx("div", { className: styles.specsLayer, children: cardSpecification })] }) }) })) : (_jsx("div", { className: styles.mainWrapper, onMouseEnter: handleHover, children: cardContent }));
};
