import { baseApi } from "./base";
var apiWithTag = baseApi.enhanceEndpoints({
    addTagTypes: ["Cart", "CartDetail"],
});
export var cartApi = apiWithTag.injectEndpoints({
    endpoints: function (build) { return ({
        getCart: build.query({
            query: function () { return ({ url: "/cart/", method: "GET" }); },
            providesTags: ["Cart"],
        }),
        getCartDetail: build.query({
            query: function () { return ({ url: "/cart/detail/", method: "GET" }); },
            providesTags: ["CartDetail"],
        }),
        addCartProduct: build.mutation({
            query: function (_a) {
                var productId = _a.productId, _b = _a.quantity, quantity = _b === void 0 ? 1 : _b, _c = _a.services, services = _c === void 0 ? undefined : _c;
                return ({
                    url: "/cart/add/",
                    method: "POST",
                    body: {
                        product_id: productId,
                        quantity: quantity,
                        services: services,
                    },
                });
            },
            invalidatesTags: ["Cart", "CartDetail"],
        }),
        setCartProductQuantity: build.mutation({
            query: function (_a) {
                var productId = _a.productId, _b = _a.quantity, quantity = _b === void 0 ? 1 : _b;
                return ({
                    url: "/cart/quantity/",
                    method: "POST",
                    body: {
                        product_id: productId,
                        quantity: quantity,
                    },
                });
            },
            invalidatesTags: ["Cart", "CartDetail"],
        }),
        removeCartProduct: build.mutation({
            query: function (_a) {
                var productId = _a.productId;
                return ({
                    url: "/cart/remove/",
                    method: "POST",
                    body: {
                        product_id: productId,
                    },
                });
            },
            invalidatesTags: ["Cart", "CartDetail"],
        }),
        addCartProductService: build.mutation({
            query: function (_a) {
                var productId = _a.productId, serviceId = _a.serviceId;
                return ({
                    url: "/cart/service/add/",
                    method: "POST",
                    body: {
                        product_id: productId,
                        service_id: serviceId,
                    },
                });
            },
            invalidatesTags: ["Cart", "CartDetail"],
        }),
        removeCartProductService: build.mutation({
            query: function (_a) {
                var productId = _a.productId, serviceId = _a.serviceId;
                return ({
                    url: "/cart/service/remove/",
                    method: "POST",
                    body: {
                        product_id: productId,
                        service_id: serviceId,
                    },
                });
            },
            invalidatesTags: ["Cart", "CartDetail"],
        }),
        clearCart: build.mutation({
            query: function () { return ({
                url: "/cart/clear/",
                method: "POST",
            }); },
            invalidatesTags: ["Cart", "CartDetail"],
        }),
    }); },
});
export var useGetCartQuery = cartApi.useGetCartQuery, useGetCartDetailQuery = cartApi.useGetCartDetailQuery, useAddCartProductMutation = cartApi.useAddCartProductMutation, useSetCartProductQuantityMutation = cartApi.useSetCartProductQuantityMutation, useRemoveCartProductMutation = cartApi.useRemoveCartProductMutation, useAddCartProductServiceMutation = cartApi.useAddCartProductServiceMutation, useRemoveCartProductServiceMutation = cartApi.useRemoveCartProductServiceMutation, useClearCartMutation = cartApi.useClearCartMutation;
