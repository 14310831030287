import styles from "./ProductsSection.modules.scss"

import React from "react"

import CategoriesSwiper from "../CategoriesSwiper/CategoriesSwiper"
import ProductsSwiper from "../ProductsSwiper"
import cn from "classnames";

const ProductsSection = ({title, products, categories, addedProducts, onChangeCategory, toggleAddProduct, isMobile = false}) => {

  const classes = cn(
    styles.root,
    {
      [styles.mobile]: isMobile
    }
  )

  return (
    <div className={classes}>
      <h2 className={styles.heading}>{title}</h2>
      <div className={styles.section}>
        <CategoriesSwiper categories={categories} withAll onChange={onChangeCategory} isMobile={isMobile}/>
        <ProductsSwiper products={products} addedProducts={addedProducts} toggleAddProduct={toggleAddProduct} isMobile={isMobile}/>
      </div>
    </div>
  )
}

export default ProductsSection