import {baseApi} from "./base"

export const customerAPI = {
  getMe() {
    const url = "customer/me/"
    return baseApi.get(url)
  },
  getFoksClubCard({number}) {
    const url = "foks_club/card/"
    return baseApi.get(url, {params: {number}})
  }
}
