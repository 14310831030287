import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./Mobile.module.scss";
import cn from "classnames";
import { SendCodeForm, SendPhoneForm, SignInForm } from "../form";
import { ResetPasswordForm } from "../form/ResetPasswordForm";
import { useToggleBodyOverflow, useAppDispatch } from "@shared/hooks";
import { CloseIcon, Text } from "@shared/ui";
import { setActivePopup } from "@entities/popup";
import { resetRestoreData, resetState, selectRestoreData, selectStage, setStage } from "../../model";
import { useAppSelector } from "@src/shared/hooks";
import { ArrowRightIcon } from "@src/shared/ui";
export var Mobile = function (_a) {
    var _b;
    var isOpen = _a.isOpen;
    var dispatch = useAppDispatch();
    var stage = useAppSelector(selectStage);
    var _c = useAppSelector(selectRestoreData), phone = _c.phone, sessionKey = _c.sessionKey;
    useToggleBodyOverflow(isOpen);
    var handleCloseModal = function () {
        dispatch(setActivePopup(""));
        dispatch(resetState());
    };
    var handleBackClick = function () {
        if (stage === "INPUT_CODE") {
            dispatch(resetRestoreData());
            dispatch(setStage("INPUT_PHONE"));
        }
        else {
            dispatch(resetState());
        }
    };
    var currentTitle = stage === "START" ? "Вход в аккаунт" : "Забыли пароль?";
    return (_jsxs("div", { className: cn(styles.main, (_b = {}, _b[styles.open] = isOpen, _b)), children: [_jsx("div", { className: styles.overlay, onClick: handleCloseModal }), _jsx("div", { className: styles.modal, children: _jsxs("div", { className: styles.content, children: [_jsxs("div", { className: styles.header, children: [stage !== "START" && (_jsx("div", { className: styles.backBtn, onClick: handleBackClick, children: _jsx(ArrowRightIcon, {}) })), _jsx(Text, { variant: "h3", children: currentTitle }), _jsx("div", { className: styles.closeBtn, onClick: handleCloseModal, children: _jsx(CloseIcon, {}) })] }), stage === "START" && (_jsx(SignInForm, {})), stage === "INPUT_PHONE" && (_jsx(SendPhoneForm, { isDesktop: true })), stage === "INPUT_CODE" && phone && sessionKey && (_jsx(SendCodeForm, { phone: phone, sessionKey: sessionKey, isDesktop: true })), stage === "INPUT_PASSWORD" && sessionKey && (_jsx(ResetPasswordForm, { sessionKey: sessionKey, isDesktop: true }))] }) })] }));
};
