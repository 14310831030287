import styles from "./styles.modules.scss"

import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Controller, useForm} from "react-hook-form"

import {
  getFoksClubCard, selectErrorMessage, setErrorMessage
} from "../../../../../../js/redux/slices/customerSlice"
import {Button, InputField} from "../../../shared"

export const FoksClubCardForm = () => {
  const dispatch = useDispatch()

  const errorMessage = useSelector(selectErrorMessage)

  const {
    control,
    handleSubmit,
    watch,
    formState: {errors},
  } = useForm({
    mode: 'onChange' || 'onTouched',
    defaultValues: {
      number: ""
    },
  })

  const onSubmitHandler = async (data) => {
    dispatch(getFoksClubCard(data.number))
  }

  const {number: currentNumber} = watch()

  useEffect(() => {
    if (!!!currentNumber) {
      dispatch(setErrorMessage(null))
    }
  }, [currentNumber])

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} className={styles.root}>
      <Controller
        control={control}
        name={"number"}
        render={({field: {onChange, value}}) => (
          <InputField
            placeholder={"8 000 000 00 00"}
            value={value}
            errorText={errors?.number?.message?.toString() || errorMessage || null}
            withErrorText={!!errorMessage}
            onChange={value => onChange(value)}
          />
        )}
        rules={{
          required: "* Введите номер карты",
        }}
      />
      <Button
        title={"Добавить"}
        variant={"secondary"}
        type={"submit"}
      />
    </form>
  )
}
