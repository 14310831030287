import React from 'react';
import ReactDOM from 'react-dom';

// Компонент выпадающего списка
import { FavoriteDropdown } from '../../jsx/components/dropdown';
import {store} from "../../../common/js/redux/store";
import {addProduct, clearProducts, removeProduct} from "../../../common/js/redux/slices/favoritesSlice";

async function favorite_interactive(favorite_action, product_id) {
  const favoriteAction = favorite_action;
  const productId = Number(product_id);

  let response = null;

  if (favorite_action) {
    response = await fetch(
      `/api/favorites/${favorite_action}/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': document.querySelector(`input[name='csrfmiddlewaretoken']`).value
        },
        body: JSON.stringify(
          {
            'product_id': product_id
          }
        )
      }
    );
  } else {
    response = await fetch(
      '/api/favorites/clear/',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': document.querySelector(`input[name='csrfmiddlewaretoken']`).value
        }
      }
    );
  }
  if (response.ok) {
    const favoriteArray = localStorage.getItem('favorite-array');

    if (favoriteAction === 'add') {
      store.dispatch(addProduct(product_id))
    } else if (favoriteAction === 'delete') {
      store.dispatch(removeProduct(product_id))
    } else {
      store.dispatch(clearProducts())
    }

    if (favoriteAction) {
      if (favoriteArray) {
        // Преобразуем стоку в массив
        const parsedArray = JSON.parse(favoriteArray);

        if (favorite_action == 'add') {
          // Добавляем товар в массив избранного
          parsedArray.push(productId);
        }

        if (favorite_action == 'delete') {
          const arrayIndex = parsedArray.indexOf(productId);

          if (arrayIndex != -1) {
            // Удаляем элемент из массива
            parsedArray.splice(arrayIndex, 1);
          }
        }

        // Записываем массив в локальное хранилище
        localStorage.setItem('favorite-array', JSON.stringify(parsedArray));
      } else {
        // Записываем массив в локальное хранилище
        localStorage.setItem('favorite-array', JSON.stringify([productId]));
      }
    } else {
      // Удаляем массив из локального хранилища
      localStorage.removeItem('favorite-array');
    }


    const result = await response.json();

    const product = document.querySelector('.product');

    if (product) {
      const dataId = product.getAttribute('data-id');

      const product_control = product.querySelector('.product-control');

      if (product_control) {
        const button_favorite = product_control.querySelector('.control-button[data-button="favorite"]');

        if (dataId == productId && favorite_action == 'add') {
          button_favorite.classList.add('button_selected');
        }

        if (dataId == productId || !favorite_action) {
          if (favorite_action == 'delete' || !favorite_action) {
            button_favorite.classList.remove('button_selected');
          }
        }
      }
    }

    if (favoriteAction == 'delete') {
      const product_unit_all = document.querySelectorAll(`.list-unit[data-id="${product_id}"][data-type="product"]`);

      if (product_unit_all.length) {
        product_unit_all.forEach(product_unit_each);
      }
    }

    if (!favoriteAction) {
      const product_unit_all = document.querySelectorAll(`.list-unit[data-type="product"]`);

      if (product_unit_all.length) {
        product_unit_all.forEach(product_unit_each);
      }
    }

    const header_upper = document.querySelector('.header-upper');

    if (header_upper) {
      const button_favorite = header_upper.querySelector('.upper-control > .control-button[data-button="favorite"]');

      if (button_favorite) {
        const favorite_layer = button_favorite.querySelector('.button-layer');
        const favorite_overlay = button_favorite.querySelector('.button-overlay');

        const indicate_counter = favorite_layer.querySelector('.indicate-counter');

        if (result.length) {
          if (favorite_layer.classList.contains('layer_disabled')) {
            favorite_layer.classList.remove('layer_disabled');
          }

          if (indicate_counter.classList.contains('counter_hidden')) {
            indicate_counter.classList.remove('counter_hidden');
          }

          indicate_counter.textContent = result.length;
        } else {
          if (!favorite_layer.classList.contains('layer_disabled')) {
            favorite_layer.classList.add('layer_disabled');
          }

          if (!indicate_counter.classList.contains('counter_hidden')) {
            indicate_counter.classList.add('counter_hidden');
          }
        }

        ReactDOM.render(<FavoriteDropdown dropdownData={result} />, favorite_overlay);
      }

      // Если есть ответ с сервера
      return true;
    } else {
      // Елси ответа нет
      return false;
    }
  }
}

function product_unit_each(product_unit) {
  const data_reference = product_unit.getAttribute('data-reference');

  if (data_reference == 'any') {
    const button_favorite = product_unit.querySelector('.button_favorite');

    if (button_favorite.classList.contains('button_selected')) {
      button_favorite.classList.remove('button_selected');
    }
  }
}

export default favorite_interactive