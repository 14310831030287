import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import purchase_interactive from '../../js/lib/purchase-interactive-new';
import compare_interactive from '../../js/lib/compare-interactive';
import numberWithSpaces from "../../js/utils";

const compare = document.querySelector('.compare');

export function Compare({ compareData }) {
  const productAll = compareData.products;

  // Если товаров в сравнении нет - возвращаем сообщение
  if (!Object.keys(compareData).length) {
    return 'Товаров в сравнении нет!';
  }

  const [diff, setDiff] = useState(true);

  // Контейнер секций характеристик
  const listSectionReference = useRef(null);

  useEffect(initEffect, [compareData]);
  useEffect(diffEffect, [compareData, diff]);

  function initEffect() {
    const listSection = listSectionReference.current;

    const itemAll = listSection.querySelectorAll('.list-item');

    // Добавляем события на каждый элемент
    itemAll.forEach(itemEventEach);

    function itemEventEach(item) {
      const itemInfo = item.querySelector('.item-info');
      const itemContent = item.querySelector('.item-content');

      // Добавляем анимацию скрытия и демонстрации списка
      itemInfo.addEventListener('click', itemInfoClick);

      function itemInfoClick() {
        if (item.classList.contains('item_expanded')) {
          item.classList.remove('item_expanded');
        }  else {
          item.classList.add('item_expanded');
        }

        if (itemContent.style.height != '0px') {
          itemContent.style.height = `${itemContent.scrollHeight}px`;

          setTimeout(() => itemContent.style.height = '0px', 20);
        } else {
          itemContent.style.height = `${itemContent.scrollHeight}px`;

          itemContent.addEventListener('transitionend', itemContentTransitionend, { once: true });
        }

        function itemContentTransitionend() {
          itemContent.style.height = 'auto';
        }
      }
    }
  }

  function diffEffect() {
    const listSection = listSectionReference.current;

    if (diff) {
      const itemAll = listSection.querySelectorAll('.list-item');

      // Показываем различия характеристик только есть товаров в сравнении больше одного
      if (productAll.length > 1) itemAll.forEach(itemReduceEach);

      function itemReduceEach(item) {
        const groupAll = item.querySelectorAll('.content-group');

        // Преобразуем список элементов в массив
        const groupArray = Array.from(groupAll);

        let reduceResult = groupArray.reduce(groupReduce, null);

        if (reduceResult) {
          // Если ни одна из групп не имеет различий - ставим модификатор
          if (!item.hasAttribute('data-diff')) {
            item.setAttribute('data-diff', 'true');
          }
        }

        function groupReduce(groupDiff, group, groupIndex) {
          const specifyAll = group.querySelectorAll('.list-unit');



          // Преобразуем список элементов в массив
          const specifyArray = Array.from(specifyAll);

          let reduceResult = specifyArray.reduce(specifyReduce, null);

          // Преобразуем результат в булев тип
          reduceResult = Boolean(reduceResult);

          if (reduceResult) {
            // Если все характеристики совпадают и нет модификатора - ставим его
            if (!group.hasAttribute('data-diff')) {
              group.setAttribute('data-diff', 'true');
            }

            // На первой итерации возвращаем результат сравнения характеристик
            if (!groupIndex) {
              return reduceResult;
            }

            // Если хотя бы одна группа имеет различия - возвращаем False
            if (!groupDiff) {
              return false;
            }

            // Иначе возвращаем преобразованный результат
            return reduceResult;
          }

          return reduceResult;

          function specifyReduce(specifyDiff, specify, specifyIndex) {
            const dataSpecify = specify.getAttribute('data-specify');

            // На первой итерации возвращаем характеристику
            if (!specifyIndex) {
              return dataSpecify;
            }

            // Если различия в характеристиках есть - возвращаем False
            if (dataSpecify != specifyDiff) {
              return false;
            }

            // Если есть различия в характеристиках то возвращаем False
            if (!specifyDiff) {
              return false;
            }

            // Иначе вовращаем значение характеристики
            return dataSpecify;
          }
        }
      }
    } else {
      const itemDiffAll = listSection.querySelectorAll('[data-diff="true"]');

      itemDiffAll.forEach(itemDiffEach);

      function itemDiffEach(diff) {
        // Если у элемента есть модификатор - удаляем его
        if (diff.hasAttribute('data-diff')) {
          diff.removeAttribute('data-diff');
        }
      }
    }
  }

  // Функция для каждого товара в сравнении
  function productMap(productData, productIndex) {
    return (
      <ProductItem key={productIndex} productData={productData} />
    );
  }

  // Функция для каждой секции характеристик
  function sectionMap(sectionData, sectionIndex) {
    return (
      <SectionItem key={sectionIndex} sectionData={sectionData} productAll={compareData.products} isOpen={sectionIndex < 2} />
    );
  }

  function detailMap(productData, productIndex) {
    return (
      <DetailItem key={productIndex} productData={productData} />
    );
  }

  function DetailControl() {
    function compareChange() {
      // Выставляем значение обратное текущему
      setDiff(!diff);
    }

    return (
      <label className="detail-control">
        <input className="control-checkbox" type="checkbox" name="compare" onChange={compareChange} checked={diff} />
        <div className="control-indicate"></div>
        <span className="control-text">Показывать только различия</span>
      </label>
    );
  }

  return (
    <React.Fragment>
      <div className="compare-info">
        <h1 className="info-title">Сравнение товаров</h1>
      </div>
      <div className="compare-detail">
        <DetailControl />
      </div>
      <div className="compare-content">
        <div className="content-list" data-list="product">
          {compareData.products.map(productMap)}
        </div>
        <div ref={listSectionReference} className="content-list" data-list="section">
          {compareData.category_attributes.map(sectionMap)}
        </div>
        <div className="content-list" data-list="detail">
          {compareData.products.map(detailMap)}
        </div>
      </div>
      <div className="compare-control">
        <button className="control-button" onClick={() => compare_interactive()}>
          <span className="button-text">Очистить</span>
        </button>
      </div>
    </React.Fragment>
  );
}

// Основные компоненты
function ProductItem({ productData }) {

  function LayerInfo({ infoData }) {
    if (!infoData.length) {
      return null;
    }

    return (
      <div className="layer-info">
        {infoData.map((unitData, unitIndex) => <span key={unitIndex} className="info-unit" style={{ backgroundColor: unitData.color }}>{unitData.name}</span>)}
      </div>
    );
  }

  function LayerDetail({ dataRating, dataReview }) {
    function DetailReview({ dataReview }) {
      if (!dataReview) {
        return null;
      }

      return (
        <div className="detail-review">
          <div className="review-indicate">
            <svg width="16" height="14" viewBox="0 0 16 14" fill="transparent" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M8 12C12.4183 12 16 9.31371 16 6C16 2.68629 12.4183 0 8 0C3.58172 0 0 2.68629 0 6C0 7.55088 0.784547 8.96433 2.07222 10.0293L2 10L0 14L6.54425 11.9009C7.0163 11.966 7.50285 12 8 12Z" fill="#C4C4C4"/>
            </svg>
          </div>
          <span className="review-text">{dataReview}</span>
        </div>
      );
    }

    function DetailRating({ dataRating }) {
      function RatingIndicate({ dataIndicate }) {
        if (!dataIndicate) {
          return (
            <div className="rating-indicate">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="transparent" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 0.809018L7.10932 4.22315L7.16545 4.3959H7.34708H10.9369L8.03268 6.50595L7.88573 6.61271L7.94186 6.78546L9.05118 10.1996L6.14695 8.08954L6 7.98278L5.85305 8.08954L2.94882 10.1996L4.05814 6.78546L4.11426 6.61271L3.96732 6.50595L1.06308 4.3959H4.65292H4.83455L4.89068 4.22315L6 0.809018Z" stroke="currentColor" strokeWidth="0.5"/>
              </svg>
            </div>
          );
        }

        return (
          <div className="rating-indicate">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="transparent" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="currentColor"/>
            </svg>
          </div>
        );
      }

      return (
        <div className="detail-rating">
          {dataRating.map((dataIndicate, inidcateIndex) => <RatingIndicate dataIndicate={dataIndicate} key={inidcateIndex} />)}
        </div>
      );
    }

    return (
      <div className="layer-detail">
        <DetailRating dataRating={dataRating} />
        <DetailReview dataReview={dataReview} />
      </div>
    );
  }

  function PurchaseButton() {
    const cartArray = localStorage.getItem('cart-array');

    function PurchaseIndicate() {
      return (
        <React.Fragment>
          <div className="layer-indicate" data-indicate="before">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="transparent" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M1.84788 1.5H0V0H2.4C2.72839 0 3.01859 0.213635 3.11614 0.5272L3.92604 3.13043H15C15.2288 3.13043 15.445 3.23483 15.5873 3.41395C15.7296 3.59308 15.7823 3.82734 15.7305 4.05016L14.7305 8.3545C14.6626 8.6469 14.4269 8.87063 14.1313 8.92319L5.33134 10.4884C4.95929 10.5546 4.59612 10.3336 4.48386 9.9728L2.65777 4.10323L1.84788 1.5ZM4.3927 4.63043L5.7197 8.89579L13.3814 7.53303L14.0558 4.63043H4.3927ZM7 13.5C7 14.6046 6.10457 15.5 5 15.5C3.89543 15.5 3 14.6046 3 13.5C3 12.3954 3.89543 11.5 5 11.5C6.10457 11.5 7 12.3954 7 13.5ZM14 15.5C15.1046 15.5 16 14.6046 16 13.5C16 12.3954 15.1046 11.5 14 11.5C12.8954 11.5 12 12.3954 12 13.5C12 14.6046 12.8954 15.5 14 15.5Z" fill="currentColor"/>
            </svg>
          </div>
          <div className="layer-indicate" data-indicate="after">
            <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 6L6 10L14 2" stroke="currentColor" strokeWidth="2"/>
            </svg>
          </div>
        </React.Fragment>
      );
    }

    if (productData.status.id != 1 && productData.status.id != 2) {
      return (
        <div className="control-button button_disabled" data-button="purchase">
          <div className="button-layer">
            <span className="layer-text">Нет в наличии</span>
          </div>
          <div className="button-overlay" style={{ top: '0', left: '0' }}></div>
        </div>
      );
    }

    const purchaseReference = useRef(null);

    if (cartArray) {
      const parsedArray = JSON.parse(cartArray);

      if (parsedArray.includes(productData.id)) {
        return (
          <div ref={purchaseReference} className="control-button button_selected" data-button="purchase" onClick={purchaseClick} data-uniq="cart" data-id={productData.id}>
            <div className="button-layer">
              <PurchaseIndicate />
              <span className="layer-text">Добавлен</span>
            </div>
            <div className="button-overlay" style={{ top: '0', left: '0' }}></div>
          </div>
        );
      }
    }

    async function purchaseClick(event) {
      event.preventDefault();

      // Получаем кнопку добавления в корзину
      const purchaseButton = purchaseReference.current;

      if (!purchaseButton.classList.contains('button_selected')) {
        await purchase_interactive('add', productData.id);
      } else {
        await purchase_interactive('delete', productData.id);
      }
    }

    return (
      <div ref={purchaseReference} className="control-button" data-button="purchase" onClick={purchaseClick} data-uniq="cart" data-id={productData.id}>
        <div className="button-layer">
          <PurchaseIndicate />
          <span className="layer-text">В корзину</span>
        </div>
        <div className="button-overlay" style={{ top: '0', left: '0' }}></div>
      </div>
    );
  }

  return (
    <div className="list-item" data-compare-product={productData.id}>
      <a className="item-layer" href={`${productData.url}`}>
        <LayerInfo infoData={productData.color_tags} />
        <div className="layer-picture">
          <img width="188" height="148" src={productData.main_image ? productData.main_image.desktop.preview.image : '/static/store/img/noimage.png'} alt="" />
        </div>
        <LayerDetail dataRating={productData.stars_list} dataReview={productData.reviews_count} />
        <div className="layer-content">
          <span className="content-title">{productData.name}</span>
          <ContentPrice
            price={productData.price}
            discountPrice={productData.discounted_price}
            discountAmount={productData.discount_amount}
            bonuses={productData.bonuses}
            bonusesAmount={productData.bonuses_amount}
          />
        </div>
        <div className="layer-control">
          <PurchaseButton />
        </div>
      </a>
      <div className="item-detail">
        <button className="detail-button" onClick={() => compare_interactive('delete', productData.id)}>
          <span className="button-text">Удалить</span>
        </button>
      </div>
    </div>
  );
}

function SectionItem({ sectionData, productAll, isOpen }) {
  function GroupItem({ groupData, productAll }) {
    function SpecifyItem({ specifyData }) {
      return (
        <div className="list-unit" data-specify={specifyData.value}>
          <span className="unit-text">{ specifyData.value }</span>
        </div>
      );
    }

    let specifyAll = null;


    if (groupData.type == 'LIST') {
      specifyAll = productAll.map(productMap);

      function productMap(productData, productIndex) {
        let searchProductAttribute = productData.attributes.find(product_attribute => product_attribute.attribute.name === groupData.groupName)
        if (!searchProductAttribute) {
          return (
            <SpecifyItem key={productIndex} specifyData={{ value: '-' }} />
          );
        }

        if (searchProductAttribute.values.find(value => value.value === groupData.name)) {
          return (
            <SpecifyItem key={productIndex} specifyData={{ value: 'Есть' }} />
          );
        } else {
          return (
            <SpecifyItem key={productIndex} specifyData={{ value: '-' }} />
          );
        }
      }
    } else {
      specifyAll = productAll.map(productMap);

      function productMap(productData, productIndex) {
        let searchProductAttribute = productData.attributes.find(product_attribute => product_attribute.attribute.name === groupData.name)
        if (!searchProductAttribute) {
          return (
            <SpecifyItem key={productIndex} specifyData={{ value: '-' }} />
          );
        }

        return (
          <SpecifyItem key={productIndex} specifyData={{ value: searchProductAttribute.value.value }} />
        );
      }
    }

    return (
      <div className="content-group">
        <div className="group-info">
          <span className="info-title">{groupData.name}</span>
        </div>
        <div className="group-list">
          { specifyAll }
        </div>
      </div>
    );
  }

  function groupMap(groupData, groupIndex) {
    if (groupData.type == 'LIST') {
      function valueMap(valueData, valueIndex) {
        for (let productData of productAll) {
          return (
            <GroupItem key={valueIndex} groupData={{ groupName: groupData.name, name: valueData.value, type: 'LIST' }} productAll={productAll} />
          );
        }
      }

      return (
        groupData.possible_values.map(valueMap)
      );
    }

    return (
      <GroupItem key={groupIndex} groupData={groupData} productAll={productAll} />
    );
  }

  const itemContentRef = useRef(null);

  return (
    <div className={`list-item ${ isOpen ? 'item_expanded' : '' }`}>
      <div className="item-info">
        <span className="info-title">{sectionData.group}</span>
        <div className="info-indicate">
          <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1.5L9 9.5L17 1.5" stroke="#C0C0C0" strokeWidth="2"/>
          </svg>
        </div>
      </div>
      <div ref={itemContentRef} className="item-content" style={{ height: isOpen ? `auto` : '0px' }}>
        {sectionData.group_attributes.map(groupMap)}
      </div>
    </div>
  );
}

function DetailItem({ productData }) {
  function PurchaseButton() {
    const cartArray = localStorage.getItem('cart-array');

    function PurchaseIndicate() {
      return (
        <React.Fragment>
          <div className="layer-indicate" data-indicate="before">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="transparent" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M1.84788 1.5H0V0H2.4C2.72839 0 3.01859 0.213635 3.11614 0.5272L3.92604 3.13043H15C15.2288 3.13043 15.445 3.23483 15.5873 3.41395C15.7296 3.59308 15.7823 3.82734 15.7305 4.05016L14.7305 8.3545C14.6626 8.6469 14.4269 8.87063 14.1313 8.92319L5.33134 10.4884C4.95929 10.5546 4.59612 10.3336 4.48386 9.9728L2.65777 4.10323L1.84788 1.5ZM4.3927 4.63043L5.7197 8.89579L13.3814 7.53303L14.0558 4.63043H4.3927ZM7 13.5C7 14.6046 6.10457 15.5 5 15.5C3.89543 15.5 3 14.6046 3 13.5C3 12.3954 3.89543 11.5 5 11.5C6.10457 11.5 7 12.3954 7 13.5ZM14 15.5C15.1046 15.5 16 14.6046 16 13.5C16 12.3954 15.1046 11.5 14 11.5C12.8954 11.5 12 12.3954 12 13.5C12 14.6046 12.8954 15.5 14 15.5Z" fill="currentColor"/>
            </svg>
          </div>
          <div className="layer-indicate" data-indicate="after">
            <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 6L6 10L14 2" stroke="currentColor" strokeWidth="2"/>
            </svg>
          </div>
        </React.Fragment>
      );
    }

    if (productData.status == 'NOT_IN_STOCK') {
      return (
        <div className="control-button button_disabled" data-button="purchase">
          <div className="button-layer">
            <span className="layer-text">Нет в наличии</span>
          </div>
          <div className="button-overlay" style={{ top: '0', left: '0' }}></div>
        </div>
      );
    }

    const purchaseReference = useRef(null);

    if (cartArray) {
      const parsedArray = JSON.parse(cartArray);

      if (parsedArray.includes(productData.id)) {
        return (
          <div ref={purchaseReference} className="control-button button_selected" data-button="purchase" onClick={purchaseClick} data-uniq="cart" data-id={productData.id}>
            <div className="button-layer">
              <PurchaseIndicate />
              <span className="layer-text">Добавлен</span>
            </div>
            <div className="button-overlay" style={{ top: '0', left: '0' }}></div>
          </div>
        );
      }
    }

    async function purchaseClick(event) {
      event.preventDefault();

      // Получаем кнопку добавления в корзину
      const purchaseButton = purchaseReference.current;

      if (!purchaseButton.classList.contains('button_selected')) {
        await purchase_interactive('add', productData.id);
      } else {
        await purchase_interactive('delete', productData.id);
      }
    }

    return (
      <div ref={purchaseReference} className="control-button" data-button="purchase" onClick={purchaseClick} data-uniq="cart" data-id={productData.id}>
        <div className="button-layer">
          <PurchaseIndicate />
          <span className="layer-text">В корзину</span>
        </div>
        <div className="button-overlay" style={{ top: '0', left: '0' }}></div>
      </div>
    );
  }

  return (
    <div className="list-item" data-compare-detail={productData.id}>
      <div className="item-content">
        <span className="content-title">{productData.name}</span>
        <ContentPrice
          price={productData.price}
          discountPrice={productData.discounted_price}
          discountAmount={productData.discount_amount}
          bonuses={productData.bonuses}
          bonusesAmount={productData.bonuses_amount}
        />
      </div>
      <div className="item-control">
        <PurchaseButton />
      </div>
    </div>
  );
}

function ContentPrice({ price, discountPrice, discountAmount, bonuses, bonusesAmount }) {
  function PriceUpper({ price, discountAmount }) {
    if (!discountAmount) {
      return null;
    }

    const localePrice = numberWithSpaces(price)
    const localeDiscountAmount = numberWithSpaces(discountAmount);

    return (
      <div className="price-upper">
        <span className="upper-full">{`${localePrice} ₽`}</span>
        <span className="upper-discount">-{`${localeDiscountAmount} ₽`}</span>
      </div>
    );
  }

  function PriceLower({ discountPrice, bonuses, bonusesAmount }) {
    const localeDiscountPrice = numberWithSpaces(discountPrice);

    if (!bonuses) {
      return (
        <div className="price-lower">
          <span className="lower-current">{`${localeDiscountPrice} ₽`}</span>
        </div>
      );
    }

    const localeBonusesAmount = numberWithSpaces(bonusesAmount);

    return (
      <div className="price-lower">
        <span className="lower-current">{`${localeDiscountPrice} ₽`}</span>
        <span className="lower-bonus">{`+${localeBonusesAmount} бонусов`}</span>
      </div>
    );
  }

  return (
    <div className="content-price">
      <PriceUpper price={price} discountAmount={discountAmount} />
      <PriceLower discountPrice={discountPrice} bonuses={bonuses} bonusesAmount={bonusesAmount} />
    </div>
  );
}


if (compare) {
  RenderCompare();
}

async function RenderCompare() {
  const response = await fetch('/api/compare/');

  if (response.ok) {
    const compareData = await response.json();

    ReactDOM.render(<Compare compareData={compareData} />, compare);
  }
}
