import {correct_width} from '../../lib/correct-width';
import {store} from "@src/app/store";
import {compareApiSlice} from "@src/shared/api";

const overlay_error = document.querySelector('.overlay[data-overlay="error"]');

if (overlay_error) {
  const button_confirm = overlay_error.querySelector('.button_confirm');
  const button_cancel = overlay_error.querySelector('.button_cancel');

  button_confirm.addEventListener('click', button_confirm_click);
  button_cancel.addEventListener('click', button_cancel_click);

  async function button_confirm_click() {
    const unit_id = overlay_error.getAttribute('data-id');

    if (unit_id) {
      await store.dispatch(compareApiSlice.endpoints.clearCompare.initiate())
      await store.dispatch(compareApiSlice.endpoints.addCompareProduct.initiate({productId: unit_id}))

      correct_width(false);

      const button_compare = document.querySelector('.control-button[data-button="compare"]');

      if (button_compare) {
        button_compare.classList.add('button_selected');
      }

      overlay_error.style.display = 'none';

      if (overlay_error.hasAttribute('data-id')) {
        overlay_error.removeAttribute('data-id');
      }
    }
  }

  function button_cancel_click() {
    correct_width(false);

    overlay_error.style.display = 'none';

    if (overlay_error.hasAttribute('data-id')) {
      overlay_error.removeAttribute('data-id');
    }
  }
}
