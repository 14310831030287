import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./styles.module.scss";
import cn from "classnames";
import SimpleBar from "simplebar-react";
import { Text } from "@src/shared/ui";
export var ProductsSection = function (_a) {
    var title = _a.title, cards = _a.cards, _b = _a.isDesktop, isDesktop = _b === void 0 ? false : _b;
    var contentEl = (_jsx(SimpleBar, { classNames: {
            wrapper: "simplebar-wrapper ".concat(styles.wrapper),
            contentEl: "simplebar-content ".concat(styles.content),
            track: "simplebar-track ".concat(styles.track),
        }, children: cards.map(function (el, index) { return (_jsx("div", { className: styles.cardItem, children: el }, index)); }) }));
    if (isDesktop) {
        contentEl = (_jsx("div", { className: styles.cardsList, children: cards.map(function (el, index) { return (_jsx("div", { className: styles.cardItem, children: el }, index)); }) }));
    }
    return (_jsxs("div", { className: cn(styles.root, isDesktop && styles.desktop), children: [!!title && (_jsx(Text, { variant: "h5", isDesktop: isDesktop, children: title })), contentEl] }));
};
