var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./Desktop.module.scss";
import { useEffect, useRef, useState } from "react";
import cn from "classnames";
import placeholder from "@assets/images/placeholder.png";
import { removeNumberDuplicates } from "@src/shared/lib";
export var Desktop = function (_a) {
    var _b;
    var images = _a.images, isNotAvailable = _a.isNotAvailable, _c = _a.withPagination, withPagination = _c === void 0 ? true : _c;
    var _d = useState(0), selectedItemIndex = _d[0], setSelectedItemIndex = _d[1];
    var _e = useState([0]), loadedImagesIndexes = _e[0], setLoadedImagesIndexes = _e[1];
    var _f = useState(0), mainDivWidth = _f[0], setMainDivWidth = _f[1];
    var mainDivRef = useRef(null);
    useEffect(function () {
        var _a;
        if (mainDivRef === null || mainDivRef === void 0 ? void 0 : mainDivRef.current) {
            setMainDivWidth((_a = mainDivRef === null || mainDivRef === void 0 ? void 0 : mainDivRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth);
        }
    }, []);
    var handleLoadImages = function (index) {
        var isLoadedImg = loadedImagesIndexes.find(function (el) { return el === index; });
        if (!isLoadedImg && loadedImagesIndexes.length < images.length) {
            setLoadedImagesIndexes(function (prevState) {
                return removeNumberDuplicates(__spreadArray(__spreadArray([], prevState, true), [index], false));
            });
        }
        setSelectedItemIndex(index);
    };
    var placeholderImg = (_jsx("img", { src: placeholder, alt: "", className: cn(styles.image, styles.placeholder, styles.active), width: 70, height: 70 }, "placeholderImg"));
    var mappedSlides = images.length > 0
        ? images.map(function (imageItem, index) {
            var _a;
            var currentSrc = loadedImagesIndexes.find(function (el) { return el === index; }) !== undefined
                ? imageItem.src
                : placeholder;
            return (_jsx("img", { src: currentSrc, alt: "", className: cn(styles.image, (_a = {},
                    _a[styles.active] = index === selectedItemIndex,
                    _a)) }, imageItem.id));
        })
        : placeholderImg;
    var imageOverlayWidth = mainDivWidth / images.length;
    return (_jsxs("div", { className: cn(styles.main, (_b = {}, _b[styles.notAvailable] = isNotAvailable, _b)), ref: mainDivRef, children: [_jsx("div", { className: styles.overlay, children: images.map(function (el, index) { return (_jsx("div", { className: styles.item, style: {
                        width: imageOverlayWidth,
                    }, onMouseMove: function () {
                        handleLoadImages(index);
                    } }, el.id)); }) }), _jsx("div", { className: cn(styles.imageWrapper, withPagination && styles.marginBottom8), children: mappedSlides }), withPagination && (_jsx("div", { className: styles.pagination, children: images.length > 1 &&
                    images.map(function (imageItem, index) {
                        var _a;
                        return (_jsx("div", { className: cn(styles.item, (_a = {},
                                _a[styles.active] = index === selectedItemIndex,
                                _a)) }, imageItem.id));
                    }) }))] }));
};
