import styles from "./ProductCard.modules.scss"

import React from "react"
import cn from "classnames"

import Image from "../../../Image"
import CheckIcon from "../../../icons/Check"
import CartIcon from "../../../icons/Cart"
import {numberWithSpaces} from "../../../ordering/shared"
import {useGetCartQuery} from "@src/shared/api"
import {addCartProductWithYM, removeCartProductWithYM} from "../../../../../js/lib/cart";

export const ProductCard = ({product, className}) => {
  const {data: cart} = useGetCartQuery()
  const cartProductsIds = cart?.products.map(item => item.product.id)
  const isInCart = cartProductsIds?.includes(product.id)

  const toggleProductHandler = async () => {
    if (isInCart) {
      try {
        await removeCartProductWithYM({productId: product.id})
      } catch (error) {
        console.log(error)
      }
    } else {
      try {
        await addCartProductWithYM({productId: product.id})
      } catch (error) {
        console.log(error)
      }
    }
  }

  const CartButton = () => {
    return (
      <button className={cn(styles.cartButton, isInCart && styles.active)} onClick={toggleProductHandler}>
        {isInCart ? <CheckIcon width={24} height={24} color={"#EF4123"}/> :
          <CartIcon width={24} height={24} color={"#FFFFFF"}/>}
      </button>
    )
  }

  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.image}>
        <Image src={product.main_image?.mobile.preview.image} alt={""}/>
      </div>
      <span className={styles.name}>{product.name}</span>
      <div className={styles.bottomLayer}>
        <div className={styles.pricesLayer}>
          {product.price !== product.discounted_price && (
            <span className={styles.oldPrice}>{numberWithSpaces(product.price)} ₽</span>
          )}
          <span className={styles.price}>{numberWithSpaces(product.discounted_price)} ₽</span>
        </div>
        <CartButton/>
      </div>
    </div>
  )
}
