var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Desktop } from "./Desktop";
import { Mobile } from "./Mobile";
import { selectActivePopup } from "@src/entities/popup";
import { useAppSelector } from "@src/shared/hooks";
import { Provider } from "react-redux";
import { store } from "@src/app/store";
var SignUpPopup = function (_a) {
    var _b = _a.isDesktop, isDesktop = _b === void 0 ? false : _b, title = _a.title, _c = _a.popupKey, popupKey = _c === void 0 ? "signUp" : _c;
    var isOpen = useAppSelector(selectActivePopup) === popupKey;
    return (_jsxs(_Fragment, { children: [isDesktop && _jsx(Desktop, { title: title, isOpen: isOpen }), !isDesktop && _jsx(Mobile, { title: title, isOpen: isOpen })] }));
};
var SignUpPopupWithStore = function (props) { return (_jsx(Provider, { store: store, children: _jsx(SignUpPopup, __assign({}, props)) })); };
export { SignUpPopupWithStore as SignUpPopup };
