import styles from "./SwiperWrapper.modules.scss"

import React, {useState} from "react"
import {Swiper, SwiperSlide, useSwiper} from "swiper/react"
import {FreeMode, Navigation, Pagination, Thumbs} from "swiper/modules"
import cn from "classnames"

import NextButtonIcon from "../icons/NextButtonIcon"
import PrevButtonIcon from "../icons/PrevButtonIcon"
import {range} from "../../../js/lib/utils"


const SwiperWrapper = (
  {
    slidesPerView = "auto",
    slidesPerGroup = undefined,
    spaceBetween = 12,
    thumbsSwiper = null,
    setActiveSlide = undefined,
    freeMode = false,
    withNav = true,
    pagination = false,
    dynamicWidth = false,
    breakpoints,
    className,
    children
  }) => {
  const [isBeginning, setIsBeginning] = useState(true)
  const [isEnd, setIsEnd] = useState(false)
  const [currentNumber, setCurrentNumber] = useState(0)
  const slidesCount = React.Children.count(children)
  const [paginationItemsCount, setPaginationItemsCount] = useState(slidesCount)

  const NextButton = () => {
    const swiper = useSwiper()
    const classes = cn(styles.navigation, styles.nextButton, {[styles.disabled]: isEnd})

    return (
      <button className={classes} onClick={() => swiper.slideNext()}>
        <NextButtonIcon/>
      </button>
    )
  }

  const PrevButton = () => {
    const swiper = useSwiper()
    const classes = cn(styles.navigation, styles.prevButton, {[styles.disabled]: isBeginning})

    return (
      <button onClick={() => swiper.slidePrev()} className={classes}>
        <PrevButtonIcon/>
      </button>
    )
  }

  const SwiperPagination = () => {
    return (
      <div className={styles.pagination}>
        {range(0, paginationItemsCount - 1).map((_, index) => (
          <div key={index} className={cn(styles.bullet, index === currentNumber && styles.activeBullet)}/>
        ))}
      </div>
    )
  }

  return (
    <div className={cn(styles.root, className)}>
      <Swiper
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        slidesPerGroup={slidesPerGroup}
        navigation={{enabled: withNav, disabledClass: styles.disabled}}
        freeMode={freeMode}
        thumbs={{swiper: thumbsSwiper}}
        modules={[FreeMode, Navigation, Thumbs, Pagination]}
        breakpoints={breakpoints}
        className={styles.swiper}
        onBreakpoint={(swiper) => {
          if (breakpoints && pagination) {
            const slidesPerView = swiper.params.breakpoints[swiper.currentBreakpoint]?.slidesPerView
            const totalPages = Math.max(Math.ceil((slidesCount - slidesPerView + 1)), 1);
            if (!!slidesPerView) setPaginationItemsCount(totalPages)
          }
        }}
        onSlideChange={(swiper) => {
          if (withNav) {
            if (setActiveSlide) {
              setActiveSlide(swiper.activeIndex)
            }

            setIsBeginning(swiper.isBeginning)
            setIsEnd(swiper.isEnd)
          }
        }}
        onSlideChangeTransitionStart={(swiper) => {
          setCurrentNumber(swiper.activeIndex)
        }}
        onSwiper={(swiper) => {
          swiper.activeIndex = currentNumber
        }}
      >
        {withNav && (
          <>
            <NextButton/>
            <PrevButton/>
          </>
        )}
        {React.Children.map(children, (child) => (
          <SwiperSlide key={child.key} className={cn(styles.slide, dynamicWidth && styles.widthMaxContent)}>
            {child}
          </SwiperSlide>
        ))}
      </Swiper>
      {pagination && paginationItemsCount > 1 && (
        <SwiperPagination/>
      )}
    </div>
  )
}

export default SwiperWrapper