import { baseApi } from "./base";
var apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: ["Catalog"] });
export var catalogApiSlice = apiWithTag.injectEndpoints({
    endpoints: function (build) { return ({
        getCatalog: build.query({
            query: function () { return ({ url: "/catalog/", method: "GET" }); },
            providesTags: ["Catalog"],
        }),
    }); },
});
export var useGetCatalogQuery = catalogApiSlice.useGetCatalogQuery;
