const category_detail = document.querySelector('.category > .category-detail');

if (category_detail) {
  const detail_list = category_detail.querySelector('.detail-list');
  const detail_button = category_detail.querySelector('.detail-button');

  const client_height = detail_list.clientHeight;
  const scroll_height = detail_list.scrollHeight;

  if (client_height < scroll_height) {
    detail_button.classList.remove('button_hidden');

    detail_button.addEventListener('click', detail_button_click);
  }

  function detail_button_click() {
    if (!detail_list.classList.contains('list_expanded')) {
      detail_list.classList.add('list_expanded');

      detail_button.textContent = 'Скрыть';
    } else {
      detail_list.classList.remove('list_expanded');

      detail_button.textContent = 'Показать';
    }
  }
}
