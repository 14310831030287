import styles from "./ProductTags.modules.scss"

import React from "react"

const ProductTags = ({tags}) => {

  return (
    <div className={styles.root}>
      {tags.map((tag, index) => (
        <div key={index} className={styles.tag} style={{backgroundColor: tag.color}}>
          <span className={styles.title}>{tag.name}</span>
        </div>
      ))}
    </div>
  )
}

export default ProductTags