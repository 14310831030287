import styles from "./CartSummary.modules.scss"

import React from "react";

import {useGetCartQuery} from "@src/shared/api"
import {CartProductPrices} from "../CartProductPrices";
import {Button, Separator} from "../../../ordering/shared";
import wordByCount from "../../../../../js/lib/wordByCount";

export const CartSummary = ({count, currentPrice, oldPrice, discount}) => {
  const {data: cart} = useGetCartQuery()

  if (!cart) return <></>

  return (
    <div className={styles.root}>
      <span className={styles.title}>Итог</span>
      <Separator/>
      <span className={styles.countStr}>В корзине {count} {wordByCount(count, "товар", "товара", "товаров")}</span>
      <CartProductPrices
        price={oldPrice}
        discountedPrice={currentPrice}
        discountAmount={discount}
      />
      <Button
        title={"Оформить заказ"}
        Component={"a"}
        href={"/ordering"}
      />
    </div>
  )
}
