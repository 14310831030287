import styles from "./styles.modules.scss"

import React, {forwardRef, useState, useRef, useImperativeHandle, useEffect} from "react"
import cn from "classnames"

export const InputField = forwardRef((props, ref) => {

  const {
    value,
    type = "text",
    errorText,
    placeholder,
    withErrorText = false,
    className,
    onChange,
    ...rest
  } = props

  const [currentValue, setCurrentValue] = useState(value || "")

  const textInputRef = useRef(null)

  useImperativeHandle(ref, () => ({
    getValue: () => currentValue,
    onFocus: () => textInputRef?.current?.focus(),
    onBlur: () => textInputRef?.current?.blur()
  }))

  useEffect(() => {
    setCurrentValue(value || "")
  }, [value])

  return (
    <div className={styles.root}>
      {type === "text" && (
        <input
          ref={textInputRef}
          value={currentValue}
          placeholder={!!errorText ? errorText : placeholder}
          onChange={onChange}
          className={cn(styles.field, !!errorText && styles.error, className)}
          {...rest}
        />

      )}
      {type === "textarea" && (
        <textarea
          ref={textInputRef}
          value={currentValue}
          placeholder={!!errorText ? errorText : placeholder}
          onChange={onChange}
          className={cn(styles.field, !!errorText && styles.error, className)}
          style={{borderRadius: 16}}
          {...rest}
        />
      )}
      {withErrorText && (
        <span className={styles.error}>{errorText}</span>
      )}
    </div>
  )
})
