import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  products: [],
  cart: null,
  status: "idle",
  isShowPopup: false
}

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setStatusCart: (state, action) => {
      state.status = action.payload
    },
    setCart: (state, action) => {
      state.cart = action.payload
    },
    setProducts: (state, action) => {
      state.products = action.payload
    },
    addProduct: (state, action) => {
      state.products.push(Number(action.payload))
    },
    removeProduct: (state, action) => {
      state.products.pop(Number(action.payload))
    },
    clearProducts: (state) => {
      state.products = []
    },
    setIsShowCartPopup: (state, action) => {
      state.isShowPopup = action.payload
    }
  },
})

export const selectCartProducts = (state) => {
  return state.cart.products
}

export const selectCart = (state) => {
  return state.cart.cart
}

export const selectStatusCart = (state) => {
  return state.cart.status
}

export const selectIsShowCartPopup = (state) => {
  return state.cart.isShowPopup
}

export const {
  setStatusCart,
  setCart,
  setProducts,
  addProduct,
  removeProduct,
  clearProducts,
  setIsShowCartPopup
} = cartSlice.actions

export default cartSlice.reducer
