import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./styles.module.scss";
import cn from "classnames";
import { selectIsShowSuccessPopup, setIsShowSuccessPopup } from "../../model";
import { AcceptIcon, VkLogoIcon, TgLogoIcon } from "./icons";
import { useAppDispatch, useAppSelector, useToggleBodyOverflow, } from "@src/shared/hooks";
import { CloseIcon, Text } from "@src/shared/ui";
export var SuccessPopup = function (_a) {
    var _b = _a.isDesktop, isDesktop = _b === void 0 ? false : _b;
    var dispatch = useAppDispatch();
    var isShowSuccessPopup = useAppSelector(selectIsShowSuccessPopup);
    useToggleBodyOverflow(isShowSuccessPopup);
    var handlePopupClose = function () {
        dispatch(setIsShowSuccessPopup(false));
        window.location.href = "/";
    };
    var handleBackgroundClick = function (event) {
        if (event.target === event.currentTarget) {
            handlePopupClose();
        }
    };
    if (!isShowSuccessPopup)
        return null;
    return (_jsx("div", { className: cn(styles.root, isDesktop && styles.desktop), onClick: handleBackgroundClick, children: _jsxs("div", { className: styles.popup, children: [_jsx("div", { className: styles.closeBtn, onClick: handlePopupClose, children: _jsx(CloseIcon, {}) }), _jsxs("div", { className: styles.header, children: [_jsx("div", { className: styles.acceptIcon, children: _jsx(AcceptIcon, {}) }), _jsxs(Text, { className: styles.heading, variant: "h3", isDesktop: isDesktop, children: ["\u0421\u043F\u0430\u0441\u0438\u0431\u043E!", _jsx("br", {}), " \u0412\u0430\u0448 \u0437\u0430\u043A\u0430\u0437 \u0443\u0441\u043F\u0435\u0448\u043D\u043E \u043E\u0444\u043E\u0440\u043C\u043B\u0435\u043D."] })] }), _jsx(Text, { className: styles.description, variant: "body1", isDesktop: isDesktop, children: "\u0421\u043A\u043E\u0440\u043E \u043C\u0435\u043D\u0435\u0434\u0436\u0435\u0440\u044B \u043D\u0430\u0447\u043D\u0443\u0442 \u043E\u0431\u0440\u0430\u0431\u043E\u0442\u043A\u0443 \u0437\u0430\u043A\u0430\u0437\u0430. \u0412 \u0441\u043B\u0443\u0447\u0430\u0435 \u043D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u043E\u0441\u0442\u0438, \u043C\u044B \u0441\u0432\u044F\u0436\u0435\u043C\u0441\u044F \u0441 \u0412\u0430\u043C\u0438 \u043F\u043E \u0443\u043A\u0430\u0437\u0430\u043D\u043D\u043E\u043C\u0443 \u0412\u0430\u043C\u0438 \u043D\u043E\u043C\u0435\u0440\u0443." }), _jsxs("div", { className: styles.socials, children: [_jsx(Text, { className: styles.title, variant: "h5", isDesktop: isDesktop, children: "\u041D\u0430\u0448\u0438 \u0441\u043E\u0446\u0441\u0435\u0442\u0438:" }), _jsxs("div", { className: styles.links, children: [_jsx("a", { href: "https://vk.com/fox_dnr", className: styles.socialLink, children: _jsx("div", { className: styles.icon, children: _jsx(VkLogoIcon, {}) }) }), _jsx("a", { href: "https://t.me/foks_donetsk", className: styles.socialLink, children: _jsx("div", { className: styles.icon, children: _jsx(TgLogoIcon, {}) }) })] })] })] }) }));
};
