const signup = document.querySelector('.signup');

if (signup) {
  const content_control_all = signup.querySelectorAll('.content-control[data-validate]');
  content_control_all.forEach((content_control) => content_control.addEventListener('blur', control_button_verify));

  const control_unit = signup.querySelector('.control-unit > input');
  control_unit.addEventListener('input', control_button_verify);

  const control_button = signup.querySelector('.control-button[type="submit"]');
  control_button.addEventListener('click', control_button_click);

  function control_button_click(event) {
    if (!control_unit.checked) {
      event.preventDefault();
    }
  }

  function control_button_verify() {
    const control_validate_all = signup.querySelectorAll('.content-control[data-validate="true"]');

    if (control_validate_all.length == content_control_all.length) {
      if (control_unit.checked) {
        control_button.classList.remove('button_disabled');
      } else {
        control_button.classList.add('button_disabled');
      }
    }
  }
}
