export const smoothScrollToElement = (container, element, isHorizontal = false, duration = 300) => {
  const startTime = performance.now();
  const containerRect = container.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();

  let startScrollPosition;
  let targetScrollPosition;

  if (isHorizontal) {
    startScrollPosition = container.scrollLeft;
    targetScrollPosition = elementRect.left - containerRect.left + startScrollPosition;
  } else {
    startScrollPosition = container.scrollTop;
    targetScrollPosition = elementRect.top - containerRect.top + startScrollPosition;
  }

  function animate(time) {
    const elapsedTime = time - startTime;
    const progress = Math.min(elapsedTime / duration, 1);

    const currentPosition = startScrollPosition + (targetScrollPosition - startScrollPosition) * progress;

    if (isHorizontal) {
      container.scrollLeft = currentPosition;
    } else {
      container.scrollTop = currentPosition;
    }

    if (progress < 1) {
      requestAnimationFrame(animate);
    }
  }

  requestAnimationFrame(animate);
}