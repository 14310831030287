const useful_rich = document.querySelector('.useful-rich');

if (useful_rich) {
  const rich_section = useful_rich.querySelector('.rich-section');

  if (rich_section) {
    const section_content = useful_rich.querySelector('.section-content');
    const section_button = useful_rich.querySelector('.section-button');

    const content_hidden = section_content.querySelector('.content-hidden');

    const button_text = section_button.querySelector('.button-text');

    const initial_client_height = section_content.clientHeight;
    const initial_scroll_height = section_content.scrollHeight;

    if (initial_scroll_height > initial_client_height) {
      section_content.style.height = `${ initial_client_height }px`;

      section_button.style.display = 'inline-block';
    }

    section_button.addEventListener('click', section_button_click);

    function section_button_click() {
      const current_client_height = section_content.clientHeight;
      const current_scroll_height = section_content.scrollHeight;

      if (current_client_height < current_scroll_height) {
        content_hidden_visibility('hidden');

        section_content.style.height = `${ current_scroll_height }px`;

        button_text.textContent = 'Скрыть';
      } else {
        content_hidden_visibility('visible');

        section_content.style.height = `${ initial_client_height }px`;

        button_text.textContent = 'Показать';
      }
    }

    function content_hidden_visibility(visible) {
      if (visible == 'visible') {
        content_hidden.style.display = 'block';

        setTimeout(content_hidden_timeout, 20);
      }

      if (visible == 'hidden') {
        content_hidden.style.opacity = '0';

        content_hidden.addEventListener('transitionend', content_hidden_transitionend, { once: true });
      }

      function content_hidden_transitionend() {
        if (content_hidden.style.display != 'none') {
          content_hidden.style.display = 'none';
        }
      }

      function content_hidden_timeout() {
        if (content_hidden.style.opacity != '1') {
          content_hidden.style.opacity = '1';
        }
      }
    }
  }
}
