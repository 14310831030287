

export const arraySum = (array) => {
  let sum = 0
  array.forEach(item => {
    sum += item
  })
  return sum
}

export default function numberWithSpaces(x) {
  if (!x) return ""
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export const mobileDetector = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i
    .test(navigator.userAgent);
}

export const range = (start, stop, step = 1) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)

export function specialRound(num) {
  if (num < 10) return 1
  const power = Math.pow(10, Math.floor(Math.log10(num))); // степень десяти
  return Math.floor(num / power) * power; // округление
}
