import { jsx as _jsx } from "react/jsx-runtime";
import { ValuesExpandedList } from "../../ValuesExpandedList";
export var ValuesRangesList = function (_a) {
    var rangesList = _a.rangesList, measureUnit = _a.measureUnit, values = _a.values, _b = _a.isDesktop, isDesktop = _b === void 0 ? false : _b, onChange = _a.onChange;
    var activeIndexes = values.map(function (value) {
        return rangesList.findIndex(function (item) { return item.min === value[0] && item.max === value[1]; });
    });
    var valuesList = rangesList.map(function (item) { return ({
        value: "".concat(item.min, "-").concat(item.max),
        text: "".concat(item.min, "-").concat(item.max, " ").concat(measureUnit),
        quantity: item.quantity,
    }); });
    var changeSelectedRanges = function (values) {
        if (onChange)
            onChange(values.map(function (index) { return [rangesList[index].min, rangesList[index].max]; }));
    };
    return (_jsx(ValuesExpandedList, { itemsList: valuesList, activeIndexes: activeIndexes, isDesktop: isDesktop, onChange: changeSelectedRanges }));
};
