import { correct_width } from '../../lib/correct-width';

const button_review = document.querySelector('button[data-overlay="review"]');

if (button_review) button_review.addEventListener('click', button_review_click);

const overlay_review = document.querySelector('.overlay[data-overlay="review"]');

if (overlay_review) {
  // Добавляем валидацию для выбора рейтинга продукта
  const rating_control = overlay_review.querySelector('.rating-control');

  const control_radio_all = rating_control.querySelectorAll('input[type="radio"]');

  control_radio_all.forEach(control_radio_each);

  // Добавляем дополнительную валидацию на поля отзыва
  const content_control_all = overlay_review.querySelectorAll('.content-control[data-validate]');

  content_control_all.forEach(content_control_each);

  // Добавляем дополнительную валидацию на кнопку формы
  const control_button = overlay_review.querySelector('.control-button[type="submit"]');

  control_button.addEventListener('click', control_button_click);

  function control_radio_each(control_radio) {
    control_radio.addEventListener('change', control_radio_change);

    function control_radio_change() {
      if (rating_control.getAttribute('data-validate') != 'true') {
        rating_control.setAttribute('data-validate', 'true');
      }

      const control_validate_all = overlay_review.querySelectorAll('.content-control[data-validate="true"]');

      if (control_validate_all.length == content_control_all.length) {
        control_button.classList.remove('button_disabled');
      }
    }
  }

  function content_control_each(content_control) {
    content_control.addEventListener('blur', control_button_verify);
  }

  function control_button_click(event) {
    if (rating_control.getAttribute('data-validate') != 'true') {
      event.preventDefault();
    }
  }

  function control_button_verify() {
    const control_validate_all = overlay_review.querySelectorAll('.content-control[data-validate="true"]');

    if (control_validate_all.length == content_control_all.length) {
      if (rating_control.getAttribute('data-validate') == 'true') {
        control_button.classList.remove('button_disabled');
      } else {
        control_button.classList.add('button_disabled');
      }
    }
  }
}

function button_review_click() {
  if (overlay_review && overlay_review.style.display != 'flex') {
    overlay_review.style.display = 'flex';

    correct_width(true);
  }
}
