import styles from "./styles.modules.scss"

import React from "react"
import cn from "classnames"

export const InfoFieldWrapper = ({title, children, gap = 8, className}) => (
  <div className={cn(styles.root, className)} style={{gap}}>
    <span className={styles.title}>{title}</span>
    {children}
  </div>
)
