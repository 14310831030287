import styles from "./styles.modules.scss"

import React from "react"
import cn from "classnames"

export const Button = ({title, Component = "button", variant = "primary", className, onClick, ...rest}) => {
  const classes = cn(
    styles.root,
    {
      [styles.primary]: variant === "primary",
      [styles.secondary]: variant === "secondary",
    },
    className
  )
  return (
    <Component className={classes} onClick={onClick} {...rest}>
      <span className={styles.title}>{title}</span>
    </Component>
  )
}
