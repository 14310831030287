import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./Bonus.module.scss";
import cn from "classnames";
import { Text } from "@shared/ui";
import { DiamondIcon } from "../../../../shared/ui/icons";
export var Bonus = function (_a) {
    var _b;
    var _c = _a.isDesktop, isDesktop = _c === void 0 ? false : _c, bonusesAmount = _a.bonusesAmount;
    return (_jsxs("div", { className: cn(styles.main, (_b = {}, _b[styles.desktop] = isDesktop, _b)), children: [_jsx("div", { className: styles.icon, children: _jsx(DiamondIcon, {}) }), _jsxs(Text, { variant: "body1", children: ["+ ", bonusesAmount] })] }));
};
