import styles from "./styles.modules.scss"

import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useForm, Controller} from "react-hook-form"
import cn from "classnames"

import {
  selectDeliveryAddressData, setDeliveryAddressData
} from "../../../../../../js/redux/slices/orderingSlice"
import {selectCustomerData} from "../../../../../../js/redux/slices/customerSlice"
import {FilledFieldsWrapper} from "../../../FilledFieldsWrapper"
import {FormWrapper, InputField, FilledField} from "../../../shared"

export const DeliveryForm = ({formId, onSubmit, isMobile = false}) => {
  const dispatch = useDispatch()

  const customerData = useSelector(selectCustomerData)
  const deliveryAddress = useSelector(selectDeliveryAddressData)

  let initialData = null
  if (customerData?.city && customerData?.street && customerData?.house) {
    initialData = {
      city: customerData.city,
      street: customerData.street,
      house: customerData.house,
      apartment: customerData.apartment,
    }
  }

  const [isActiveInitial, setIsActiveInitial] = useState(!!initialData)
  const {
    control,
    handleSubmit,
    watch,
    clearErrors,
    formState: {errors},
  } = useForm({
    mode: "onChange" || "onTouched",
    defaultValues: {
      city: "",
      street: "",
      house: "",
      apartment: "",
      ...deliveryAddress
    },
  })

  const onSubmitHandler = (data) => {
    let deliveryAddressData = data
    if (isActiveInitial) {
      deliveryAddressData = initialData
    }
    dispatch(setDeliveryAddressData(deliveryAddressData))
    if (onSubmit) onSubmit(deliveryAddressData)
  }

  const {city, street, house, apartment} = watch()

  useEffect(() => {
    if (!!!initialData) return

    if (!!city || !!street || !!house || !!apartment) {
      setIsActiveInitial(false)
    } else {
      setIsActiveInitial(true)
    }
  }, [initialData, city, street, house, apartment])

  useEffect(() => {
    if (isActiveInitial) {
      clearErrors()
    }
  }, [isActiveInitial])

  return (
    <div className={cn(styles.root, isMobile && styles.mobile)}>
      {initialData && (
        <FormWrapper title={"Используйте основную информацию"}>
          <FilledFieldsWrapper variant={isMobile ? "column" : "row"} withFrame active={isActiveInitial}>
            <FilledField title={"Город"} value={initialData.city}/>
            <FilledField title={"Улица"} value={initialData.street}/>
            <FilledField title={"Дом"} value={initialData.house}/>
            {initialData?.apartment && (
              <FilledField title={"Квартира"} value={initialData.apartment}/>
            )}
          </FilledFieldsWrapper>
        </FormWrapper>
      )}
      <FormWrapper title={initialData ? "Или введите новую" : "Введите ваши контактные данные"}>
        <form id={formId} onSubmit={handleSubmit(onSubmitHandler)} className={styles.form}>
          <Controller
            control={control}
            name={"city"}
            render={({field: {onChange, value}}) => (
              <InputField
                placeholder={"Город"}
                value={value}
                errorText={errors?.city?.message?.toString()}
                onChange={value => onChange(value)}
              />
            )}
            rules={{
              required: isActiveInitial ? false : "* Введите город"
            }}
          />
          <Controller
            control={control}
            name={"street"}
            render={({field: {onChange, value}}) => (
              <InputField
                placeholder={"Улица"}
                value={value}
                errorText={errors?.street?.message?.toString()}
                onChange={value => onChange(value)}
              />
            )}
            rules={{
              required: isActiveInitial ? false : "* Введите улицу"
            }}
          />
          <Controller
            control={control}
            name={"house"}
            render={({field: {onChange, value}}) => (
              <InputField
                placeholder={"Дом"}
                value={value}
                errorText={errors?.house?.message?.toString()}
                onChange={value => onChange(value)}
              />
            )}
            rules={{
              required: isActiveInitial ? false : "* Введите дом"
            }}
          />
          <Controller
            control={control}
            name={"apartment"}
            render={({field: {onChange, value}}) => (
              <InputField
                placeholder={"Квартира"}
                value={value}
                errorText={errors?.apartment?.message?.toString()}
                onChange={value => onChange(value)}
              />
            )}
          />
        </form>
      </FormWrapper>
    </div>
  )
}
