import styles from "./styles.modules.scss"

import React, {useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Controller, useForm} from "react-hook-form"
import {YMaps, Map, Placemark} from "@pbe/react-yandex-maps"

import {
  selectPickupAddressData, setPickupAddressData
} from "../../../../../../js/redux/slices/orderingSlice"
import {SelectShop} from "../SelectShop"
import {FormWrapper} from "../../../shared"
import {selectShopsList} from "../../../../../../js/redux/slices/shopsSlice"

export const PickUpForm = ({formId, onSubmit, isMobile = false}) => {
  const dispatch = useDispatch()

  const pickupAddress = useSelector(selectPickupAddressData)
  const pickupShops = useSelector(selectShopsList)

  const [selectedShop, setSelectedShop] = useState(pickupAddress || pickupShops[0])

  const {
    control,
    handleSubmit,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      shop: pickupAddress?.id || pickupShops[0]?.id || "",
    },
  })

  const onSubmitHandler = (data) => {
    const selectedShop = pickupShops?.find(item => item.id === data.shop)
    dispatch(setPickupAddressData(selectedShop))
    if (onSubmit) onSubmit(selectedShop)
  }

  return (
    <FormWrapper title={"Выберите магазин Фокс"}>
      <form id={formId} onSubmit={handleSubmit(onSubmitHandler)} className={styles.root}>
        <Controller
          control={control}
          name={"shop"}
          render={({field: {onChange, value}}) => {
            const onChangeHandler = (value) => {
              onChange(value.id)
              setSelectedShop(value)
            }
            return <SelectShop
              shops={pickupShops}
              selected={selectedShop}
              isMobile={isMobile}
              onSelect={onChangeHandler}
            />
          }}
        />
        <div className={styles.map}>
          <YMaps>
            <Map
              state={{center: [selectedShop.lat, selectedShop.long], zoom: 18}}
              width={"100%"}
              height={isMobile ? 252 : 480}
            >
              {pickupShops.map(item => (
                <Placemark key={item.id} defaultGeometry={[item.lat, item.long]}/>
              ))}
            </Map>
          </YMaps>
        </div>
      </form>
    </FormWrapper>
  )
}
