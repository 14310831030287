import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./styles.module.scss";
import cn from "classnames";
import placeholderImg from "@src/assets/images/placeholder.png";
import { Text } from "@src/shared/ui";
export var CategoryItem = function (_a) {
    var name = _a.name, url = _a.url, imageSrc = _a.imageSrc, breadcrumbs = _a.breadcrumbs, isDesktop = _a.isDesktop, onClick = _a.onClick;
    var breadcrumbsStringsArr = (breadcrumbs === null || breadcrumbs === void 0 ? void 0 : breadcrumbs.chain)
        ? breadcrumbs.chain.map(function (item) { return item.name; })
        : [];
    var breadcrumbsString = breadcrumbsStringsArr.join(" / ");
    if (breadcrumbsString) {
        breadcrumbsString += " / ".concat(breadcrumbs.current.name);
    }
    var clickLinkHandler = function (event) {
        event.preventDefault();
        if (onClick)
            onClick();
        window.location.href = url;
    };
    return (_jsxs("a", { href: url, className: cn(styles.root, isDesktop && styles.desktop), onClick: clickLinkHandler, children: [_jsx("div", { className: styles.imageLayer, children: _jsx("img", { className: styles.image, src: imageSrc || placeholderImg, alt: "" }) }), _jsxs("div", { className: styles.content, children: [_jsx(Text, { className: styles.nameText, variant: "body1", isDesktop: isDesktop, children: name }), !!breadcrumbsString && (_jsx(Text, { className: styles.breadcrumbsText, isDesktop: isDesktop, children: breadcrumbsString }))] })] }));
};
