var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./SignInForm.module.scss";
import cn from "classnames";
import { Controller, useForm } from "react-hook-form";
import { GoogleAuthBtn, YandexAuthBtn } from "@features/auth";
import { EMAIL_REGEX } from "@shared/lib";
import { Button, InputField, OrSeparator, Text } from "@shared/ui";
import { SignUpLink } from "../../SignUpLink";
import { useLoginMutation } from "@src/shared/api";
import { LinkButton } from "@src/shared/ui";
import { useAppDispatch } from "@src/shared/hooks";
import { setStage } from "../../../model";
export var SignInForm = function (_a) {
    var _b;
    var _c = _a.isDesktop, isDesktop = _c === void 0 ? false : _c;
    var dispatch = useAppDispatch();
    var login = useLoginMutation()[0];
    var _d = useForm({
        mode: "onSubmit",
        defaultValues: {
            email: "",
            password: "",
        },
    }), control = _d.control, setError = _d.setError, handleSubmit = _d.handleSubmit, _e = _d.formState, errors = _e.errors, isValid = _e.isValid;
    var submitHandler = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var res, errorData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, login({ username: data.email, password: data.password })];
                case 1:
                    res = _a.sent();
                    if ('error' in res) {
                        if ('data' in res.error) {
                            errorData = res.error.data;
                            console.error("Ошибка при входе:", errorData);
                            setError("email", { message: errorData.error });
                            setError("password", { message: errorData.error });
                        }
                    }
                    else {
                        window.location.reload();
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var showError = function () {
        var _a;
        var errorName = Object.keys(errors)[0];
        if (Object.keys(errors).length > 0)
            return (_a = errors[errorName]) === null || _a === void 0 ? void 0 : _a.message;
        return false;
    };
    var showRestoreForm = function () {
        dispatch(setStage("INPUT_PHONE"));
    };
    return (_jsxs("form", { className: cn(styles.main, (_b = {}, _b[styles.desktop] = isDesktop, _b)), onSubmit: handleSubmit(submitHandler), children: [_jsxs("div", { className: styles.authBtns, children: [_jsx(GoogleAuthBtn, { isDesktop: isDesktop }), _jsx(YandexAuthBtn, { isDesktop: isDesktop })] }), _jsx(OrSeparator, { isDesktop: isDesktop }), Object.keys(errors).length > 0 && (_jsx(Text, { variant: "body1", className: styles.error, isDesktop: isDesktop, children: showError() })), _jsx(Controller, { control: control, name: "email", render: function (_a) {
                    var _b;
                    var _c = _a.field, onChange = _c.onChange, value = _c.value;
                    return (_jsx(InputField, { variant: "secondary", placeholder: "\u0412\u0430\u0448 E-mail", errorText: (_b = errors.email) === null || _b === void 0 ? void 0 : _b.message, value: value, onChange: onChange }));
                }, rules: {
                    required: {
                        value: true,
                        message: 'Поле "E-mail" является обязательным',
                    },
                    pattern: {
                        value: EMAIL_REGEX,
                        message: "Введите корректный адрес электронной почты",
                    },
                } }), _jsx(Controller, { control: control, name: "password", render: function (_a) {
                    var _b;
                    var _c = _a.field, onChange = _c.onChange, value = _c.value;
                    return (_jsx(InputField, { variant: "secondary", placeholder: "\u041F\u0430\u0440\u043E\u043B\u044C", type: "password", value: value, onChange: onChange, errorText: (_b = errors.password) === null || _b === void 0 ? void 0 : _b.message }));
                }, rules: {
                    required: {
                        value: true,
                        message: 'Поле "Пароль" является обязательным',
                    },
                } }), _jsx(LinkButton, { type: "button", Component: "button", onClick: showRestoreForm, className: styles.link, children: _jsx(Text, { variant: "buttonSmall", isDesktop: isDesktop, children: "\u0417\u0430\u0431\u044B\u043B\u0438 \u043F\u0430\u0440\u043E\u043B\u044C?" }) }), _jsx(Button, { type: "submit", variant: "primary", isDisabled: !isValid, children: "\u0412\u043E\u0439\u0442\u0438" }), _jsx(SignUpLink, { isDesktop: isDesktop })] }));
};
