import styles from "./CategoriesSwiper.modules.scss"

import React, {useState} from "react"
import cn from "classnames"

import SwiperWrapper from "../SwiperWrapper"

const CategoryButton = ({name, onClick, active = false, isMobile = false}) => {

  const classes = cn(
    styles.itemButton,
    {
      [styles.mobile]: isMobile
    },
    {
      [styles.active]: active
    }
  )

  return (
    <button className={classes} onClick={onClick} disabled={active}>
      <span className={styles.title}>{name}</span>
    </button>
  )
}


const CategoriesSwiper = ({categories, withAll = false, onChange, isMobile = false}) => {
  const [selected, setSelected] = useState(withAll ? "" : categories[0].tag)

  const clickItemHandler = (value) => {
    onChange(value)
    setSelected(value)
  }

  return (
    <SwiperWrapper
      slidesPerView={"auto"}
      slidesPerGroup={2}
      spaceBetween={4}
      freeMode
      withNav={false}
      dynamicWidth
    >
      {withAll && (
        <CategoryButton
          key={"all"}
          name={"Все"}
          onClick={() => clickItemHandler("")}
          active={selected === ""}
          isMobile={isMobile}
        />
      )}
      {categories.map(category => (
        <CategoryButton
          key={category.tag}
          name={category.name}
          onClick={() => clickItemHandler(category.tag)}
          active={selected === category.tag}
          isMobile={isMobile}
        />
      ))}
    </SwiperWrapper>
  )
}

export default CategoriesSwiper