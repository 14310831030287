import styles from "./FoksClubInfo.modules.scss"

import React from "react";
import cn from "classnames";

import {splitCardNumberBySpaces} from "../../../ordering/OrderDetail/model";
import wordByCount from "../../../../../js/lib/wordByCount";

export const FoksClubInfo = ({total, feature, number, isMobile}) => {
  return (
    <div className={cn(styles.root, isMobile && styles.mobile)}>
      <div className={styles.left}>
        <span className={styles.totalCount}>{total} {wordByCount(total, "бонус", "бонуса", "бонусов")}</span>
        {!!(!isMobile && feature) && (
          <div className={styles.feature}>
            <span className={styles.title}>Скоро будет зачисленно</span>
            <span className={styles.featureCount}>{feature} бонусов</span>
          </div>
        )}
      </div>
      <div className={styles.right}>
        <div className={styles.number}>
          <span className={styles.title}>Номер карты</span>
          <span className={styles.cardNumber}>{splitCardNumberBySpaces(number)}</span>
        </div>
      </div>
    </div>
  )
}
