import {configureStore} from "@reduxjs/toolkit"

import cartReducer from "./slices/cartSlice"
import favoritesReducer from "./slices/favoritesSlice"
import compareReducer from "./slices/compareSlice"
import productReducer from "./slices/productSlice"
import orderingReducer from "./slices/orderingSlice"
import customerReducer from "./slices/customerSlice"
import shopsReducer from "./slices/shopsSlice"
import {baseApiSlice} from "./slices/api"
import {popupSlice} from "@entities/popup"

export const store = configureStore({
  reducer: {
    product: productReducer,
    cart: cartReducer,
    favorites: favoritesReducer,
    compare: compareReducer,
    ordering: orderingReducer,
    customer: customerReducer,
    shops: shopsReducer,
    popup: popupSlice.reducer,
    [baseApiSlice.reducerPath]: baseApiSlice.reducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(baseApiSlice.middleware),
})
