import { baseApi } from "./base";
var apiWithTag = baseApi.enhanceEndpoints({
    addTagTypes: ["FavoritesBrief", "FavoritesDetail"],
});
export var favoritesApi = apiWithTag.injectEndpoints({
    endpoints: function (build) { return ({
        getFavoriteProductsBrief: build.query({
            query: function () { return ({ url: "/favorites/brief/", method: "GET" }); },
            providesTags: ["FavoritesBrief"],
        }),
        addFavoriteProduct: build.mutation({
            query: function (_a) {
                var productId = _a.productId;
                return ({
                    url: "/favorites/add/",
                    method: "POST",
                    body: {
                        product_id: productId,
                    },
                });
            },
            invalidatesTags: ["FavoritesBrief", "FavoritesDetail"],
        }),
        removeFavoriteProduct: build.mutation({
            query: function (_a) {
                var productId = _a.productId;
                return ({
                    url: "/favorites/delete/",
                    method: "POST",
                    body: {
                        product_id: productId,
                    },
                });
            },
            invalidatesTags: ["FavoritesBrief", "FavoritesDetail"],
        }),
        clearFavorites: build.mutation({
            query: function () { return ({
                url: "/favorites/clear/",
                method: "POST",
            }); },
            invalidatesTags: ["FavoritesBrief", "FavoritesDetail"],
        }),
    }); },
});
export var useGetFavoriteProductsBriefQuery = favoritesApi.useGetFavoriteProductsBriefQuery, useAddFavoriteProductMutation = favoritesApi.useAddFavoriteProductMutation, useRemoveFavoriteProductMutation = favoritesApi.useRemoveFavoriteProductMutation, useClearFavoritesMutation = favoritesApi.useClearFavoritesMutation;
