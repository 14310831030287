

export const sendOrderToYM = (orderData) => {
  ym(48552614, 'reachGoal', 'uspeshnaya_otpravka_zakaza');
  const purchaseArray = orderData.content.products.map(item => ({
    'id': item.product.sku,
    'name': item.product.name,
    'price': item.product.discounted_price,
    'brand': item.product.brand.name,
    'category': item.product.category.name,
    'quantity': item.quantity
  }))
  if (purchaseArray) {
    let purchaseId = orderData.id;

    if (typeof purchaseId == 'number') {
      purchaseId = purchaseId.toString();
    }

    window.dataLayer.push({ecommerce: null});
    window.dataLayer.push({
      'ecommerce': {
        'purchase': {
          'actionField': {
            'id': purchaseId,
          },
          'products': purchaseArray,
        }
      }
    });
  }
}

export const sendViewProductDataToYM = (productData) => {
  window.dataLayer.push({ecommerce: null});
  window.dataLayer.push({
    "ecommerce": {
      "currencyCode": "RUB",
      "detail": {
        "products": [
          {
            "id": productData.sku,
            "name" : productData.name,
            "price": productData.discounted_price,
            "brand": productData.brand,
            "category": productData.category,
          }
        ]
      }
    }
  })
}

export const sendAddProductToCartYM = (productsData) => {
  window.dataLayer.push({ecommerce: null});
  window.dataLayer.push({
    'event': 'addToCart',
    'ecommerce': {
      'currencyCode': 'RUB',
      'add': {
        'products': productsData.map(product => ({
          "id": product.sku,
          "name" : product.name,
          "price": product.discounted_price,
          "brand": product.brand.name,
          "category": product.category.name,
          'quantity': 1
        })),
      },
    },
  })
}

export const sendRemoveProductFromCartYM = (productsData) => {
  window.dataLayer.push({ecommerce: null});
  window.dataLayer.push({
    'event': 'removeFromCart',
    'ecommerce': {
      'remove': {
        'products': productsData.map(product => ({
          "id": product.sku,
          "name" : product.name,
          "price": product.discounted_price,
          "brand": product.brand.name,
          "category": product.category.name,
          'quantity': 1
        })),
      }
    }
  })
}
