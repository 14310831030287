import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { CompareDropdown } from "./CompareDropdown";
import { selectIsShowComparePopup, setIsShowPopup } from "@src/entities/compare";
import { useGetCompareProductsBriefQuery } from "@src/shared/api";
import { useAppDispatch, useAppSelector } from "@src/shared/hooks";
import { ButtonWithPopup, CompareIcon } from "@src/shared/ui";
export var CompareNavButton = function (_a) {
    var _b;
    var _c = _a.popupOffset, popupOffset = _c === void 0 ? 0 : _c;
    var dispatch = useAppDispatch();
    var products = useGetCompareProductsBriefQuery().data;
    var isShowPopup = useAppSelector(selectIsShowComparePopup);
    useEffect(function () {
        dispatch(setIsShowPopup(false));
    }, [isShowPopup]);
    return (_jsx(ButtonWithPopup, { text: "\u0421\u0440\u0430\u0432\u043D\u0435\u043D\u0438\u0435", href: "/compare/", icon: _jsx(CompareIcon, {}), count: (_b = products === null || products === void 0 ? void 0 : products.length) !== null && _b !== void 0 ? _b : 0, isDisable: !(products === null || products === void 0 ? void 0 : products.length), popup: _jsx(CompareDropdown, {}), popupOffset: popupOffset, isShowPopup: isShowPopup }));
};
