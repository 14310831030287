import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Desktop } from "./Desktop";
import { Mobile } from "./Mobile";
import { selectCatalogShow } from "@src/entities/catalog";
import { useGetCatalogQuery } from "@src/shared/api";
import { withStore } from "@src/shared/hoc";
import { useAppSelector } from "@src/shared/hooks";
var Catalog = function (_a) {
    var _b = _a.isDesktop, isDesktop = _b === void 0 ? false : _b;
    var data = useGetCatalogQuery(null).data;
    var isCatalogShow = useAppSelector(selectCatalogShow);
    if (!data) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [isDesktop && _jsx(Desktop, { isCatalogShow: isCatalogShow, data: data }), !isDesktop && _jsx(Mobile, { isCatalogShow: isCatalogShow, data: data })] }));
};
// Обертка Catalog компонента с использованием HOC
var CatalogWithStore = withStore(Catalog);
export { CatalogWithStore as Catalog };
