import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./Desktop.module.scss";
import { useEffect, useState } from "react";
import cn from "classnames";
import { CategoryPlaceholder } from "./CategoryPlaceholder";
import { CategoriesList, SubcategoriesList } from "@src/entities/catalog";
export var Desktop = function (_a) {
    var _b;
    var _c;
    var isCatalogShow = _a.isCatalogShow, data = _a.data;
    var _d = useState(null), selectedCategoryId = _d[0], setSelectedCategoryId = _d[1];
    useEffect(function () {
        var timeout;
        if (!isCatalogShow) {
            timeout = setTimeout(function () {
                setSelectedCategoryId(null);
            }, 200);
        }
        return function () { return clearTimeout(timeout); };
    }, [isCatalogShow]);
    var handleHoverCategoryItem = function (id) { return setSelectedCategoryId(id); };
    var currentCategoryObj = data.find(function (el) { return el.id === selectedCategoryId; });
    return (_jsxs("div", { className: cn(styles.main, (_b = {}, _b[styles.show] = isCatalogShow, _b)), children: [_jsx("div", { className: styles.categories, children: _jsx(CategoriesList, { activeId: selectedCategoryId, categories: data, isDesktop: true, onHoverItem: handleHoverCategoryItem }) }), _jsx("div", { className: styles.separator }), _jsxs("div", { className: styles.content, children: [(currentCategoryObj === null || currentCategoryObj === void 0 ? void 0 : currentCategoryObj.background) && (_jsx("img", { src: currentCategoryObj === null || currentCategoryObj === void 0 ? void 0 : currentCategoryObj.background, alt: "", className: styles.bgImage })), _jsx("div", { className: styles.wrapper, children: selectedCategoryId ? (_jsx(SubcategoriesList, { categories: (_c = currentCategoryObj === null || currentCategoryObj === void 0 ? void 0 : currentCategoryObj.childs) !== null && _c !== void 0 ? _c : [], isDesktop: true })) : (_jsx(CategoryPlaceholder, {})) })] })] }));
};
