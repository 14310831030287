import { jsx as _jsx } from "react/jsx-runtime";
import styles from "./CategoriesList.module.scss";
import { CategoryItem } from "../CategoryItem";
export var CategoriesList = function (_a) {
    var categories = _a.categories, _b = _a.isDesktop, isDesktop = _b === void 0 ? false : _b, onClickArrow = _a.onClickArrow, activeId = _a.activeId, onHoverItem = _a.onHoverItem;
    return (_jsx("div", { className: styles.main, children: categories.map(function (el) {
            var handleHover = function () {
                if (onHoverItem) {
                    onHoverItem(el.id);
                }
            };
            var handleClick = function () {
                if (onClickArrow) {
                    onClickArrow(el.id);
                }
            };
            return (_jsx(CategoryItem, { isActive: el.id === activeId, name: el.name, url: el.url, withArrow: el.childs.length > 0, iconKey: el.icon, onClickArrow: handleClick, onHover: handleHover, isDesktop: isDesktop }, el.id));
        }) }));
};
