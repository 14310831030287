import styles from "./Button.modules.scss"

import React, {forwardRef} from "react"
import cn from "classnames"

const Button = forwardRef((props, buttonRef) => {
  const {
    icon,
    children,
    variant = "main",
    size = "small",
    type = "default",
    style = {},
    Component = "button",
    disabled = false,
    active = false,
    iconSize = 16,
    className,
    ...rest
  } = props

  const classes = cn(
    styles.root,
    disabled && styles.disabled,
    active && styles.active,
    {
      [styles.main]: variant === "main",
      [styles.secondary]: variant === "secondary",
    },
    {
      [styles.large]: size === "large",
    },
    {
      [styles.box]: type === "box"
    },
    className,
  )

  const iconClasses = cn(
    styles.icon,
    {
      [styles.size_16]: iconSize === 16,
      [styles.size_24]: iconSize === 24,
    }
  )

  return (
    <Component
      ref={buttonRef}
      className={classes}
      disabled={disabled}
      style={{
        ...style,
      }}
      {...rest}
    >
      {icon && <div className={iconClasses}>{icon}</div>}
      {children}
    </Component>
  )
})

export default Button