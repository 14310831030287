import {getMeCustomer} from "../../../../js/redux/slices/customerSlice"
import {getShops} from "../../../../js/redux/slices/shopsSlice"

export const initialOrderingData = () => {
  return (dispatch) => {
    dispatch(getMeCustomer())
    dispatch(getShops({pickup: true}))
  }
}


export const orderDataMapper = (orderData) => {
  const receivingMethod = orderData.receiving.method
  const addressData = receivingMethod === "DELIVERY" ? orderData.receiving.deliveryAddress : orderData.receiving.pickupAddress
  return {
    "contacts": orderData.contacts,
    "receiving": {
      "method": receivingMethod,
      ...addressData
    },
    "paymentMethod": orderData.paymentMethod,
    "comment": orderData.comment,
    "spendBonuses": orderData.spendBonuses,
    "foksClubCard": orderData.foksClubCard
  }
}

export const geWarningData = (status) => {
  let resultData = null
  switch (status) {
    case "success":
      resultData = {
        warningTitle: "Спасибо!\nВаш заказ успешно оформлен.",
        warningText: "Вы будете перенаправленны на главную страницу",
        warningModifierClass: "_success",
      }
      break
    case "invalid_data" || "empty_cart":
      resultData = {
        warningTitle: "При оформлении заказа произошла ошибка!",
        warningText: "Пожалуйста убедитесь, что товар добавлен в корзину.",
        warningModifierClass: "_error",
      }
      break
    case "success_payment":
      resultData = {
        warningTitle: "Спасибо!\nВаш заказ успешно оформлен.",
        warningText: "Вы будете перенаправленны на страницу оплаты",
        warningModifierClass: "_success",
      }
      break
    case "error_payment":
      resultData = {
        warningTitle: "При создании платежа произошла ошибка!",
        warningText: "",
        warningModifierClass: "_error",
      }
      break
    default:
      resultData = {
        warningTitle: "При оформлении заказа возникла неизвестная ошибка!",
        warningText: "",
        warningModifierClass: "_error",
      }
      break
  }
  return resultData
}

export const showWarning = (data) => {
  const warning = document.querySelector('.overlay-warning[data-overlay="warning"]');
  if (warning) {
    const warningTitleSpan = warning.querySelector('.content-title')
    const warningTextSpan = warning.querySelector('.content-text')
    warning.classList.add(data.warningModifierClass)
    warningTitleSpan.innerHTML = data.warningTitle
    warningTextSpan.innerHTML = data.warningText

    if (warning.style.display != 'flex') {
      warning.style.display = 'flex';
    }
  }
}
