import React from "react";

import {CartProductsSection} from "../CartProductsSection";

export const AdditionalProductsSection = ({products, isMobile = false}) => {
  const productsItems = products.slice(0, 4)

  return (
    <CartProductsSection
      title={"Рекомендованые аксессуары"}
      products={productsItems}
      isMobile={isMobile}
    />
  )
}
