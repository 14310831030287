var STORAGE_KEY = "searchHistory";
var saveToLocalStorage = function (data) {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
};
export var getFromLocalStorage = function () {
    var data = localStorage.getItem(STORAGE_KEY);
    return data ? JSON.parse(data) : { queries: [], categories: [] };
};
export var addToHistory = function (item, type) {
    var history = getFromLocalStorage();
    if (type === "query") {
        var queryItem_1 = item;
        var existingIndex = history.queries.findIndex(function (q) { return q.text === queryItem_1.text; });
        if (existingIndex > -1) {
            history.queries.splice(existingIndex, 1);
        }
        history.queries.unshift(queryItem_1);
        history.queries = history.queries.slice(0, 10);
    }
    else if (type === "category") {
        var categoryItem_1 = item;
        var existingIndex = history.categories.findIndex(function (c) { return c.url === categoryItem_1.url; });
        if (existingIndex > -1) {
            history.categories.splice(existingIndex, 1);
        }
        history.categories.unshift(categoryItem_1);
        history.categories = history.categories.slice(0, 10);
    }
    saveToLocalStorage(history);
};
export var removeFromHistory = function (item, type) {
    var history = getFromLocalStorage();
    if (type === "query") {
        var queryItem_2 = item;
        history.queries = history.queries.filter(function (q) { return q.text !== queryItem_2.text; });
    }
    else if (type === "category") {
        var categoryItem_2 = item;
        history.categories = history.categories.filter(function (c) { return c.url !== categoryItem_2.url; });
    }
    saveToLocalStorage(history);
};
export var clearHistory = function () {
    saveToLocalStorage({ queries: [], categories: [] });
};
