var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./FirstStage.module.scss";
import { Controller, useForm } from "react-hook-form";
import { LoginLink } from "../../../LoginLink";
import { GoogleAuthBtn, YandexAuthBtn } from "@features/auth";
import { Button, InputField, OrSeparator, Text } from "@shared/ui";
export var FirstStageForm = function (_a) {
    var initialData = _a.initialData, onSubmit = _a.onSubmit;
    var _b = useForm({
        mode: "onSubmit",
        defaultValues: __assign({ name: "", surname: "" }, initialData),
    }), control = _b.control, handleSubmit = _b.handleSubmit, _c = _b.formState, errors = _c.errors, isValid = _c.isValid;
    var submitHandler = function (data) {
        if (onSubmit) {
            onSubmit(data);
        }
    };
    var showError = function () {
        var _a;
        var errorName = Object.keys(errors)[0];
        if (Object.keys(errors).length > 1)
            return "Заполните правильно все отмеченные поля";
        if (Object.keys(errors).length === 1)
            return (_a = errors[errorName]) === null || _a === void 0 ? void 0 : _a.message;
        return false;
    };
    return (_jsxs("form", { className: styles.main, onSubmit: handleSubmit(submitHandler), children: [_jsxs("div", { className: styles.authBtns, children: [_jsx(GoogleAuthBtn, {}), _jsx(YandexAuthBtn, {})] }), _jsx(OrSeparator, {}), Object.keys(errors).length > 0 && (_jsx(Text, { variant: "body1", className: styles.error, children: showError() })), _jsx(Controller, { control: control, name: "name", render: function (_a) {
                    var _b;
                    var _c = _a.field, onChange = _c.onChange, value = _c.value;
                    return (_jsx(InputField, { variant: "secondary", placeholder: "\u0418\u043C\u044F *", value: value, onChange: onChange, errorText: (_b = errors.name) === null || _b === void 0 ? void 0 : _b.message }));
                }, rules: {
                    required: {
                        value: true,
                        message: 'Поле "Имя" является обязательным',
                    },
                } }), _jsx(Controller, { control: control, name: "surname", render: function (_a) {
                    var _b;
                    var _c = _a.field, onChange = _c.onChange, value = _c.value;
                    return (_jsx(InputField, { variant: "secondary", placeholder: "\u0424\u0430\u043C\u0438\u043B\u0438\u044F *", value: value, onChange: onChange, errorText: (_b = errors.surname) === null || _b === void 0 ? void 0 : _b.message }));
                }, rules: {
                    required: {
                        value: true,
                        message: 'Поле "Фамилия" является обязательным',
                    },
                } }), _jsx(Button, { type: "submit", variant: "primary", isDisabled: !isValid, children: "\u0414\u0430\u043B\u0435\u0435" }), _jsx(LoginLink, {})] }));
};
