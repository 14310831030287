import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./SignUpLink.module.scss";
import { LinkButton, Text } from "@shared/ui";
import { useAppDispatch } from "@shared/hooks";
import { setActivePopup } from "@entities/popup";
export var SignUpLink = function (_a) {
    var _b = _a.isDesktop, isDesktop = _b === void 0 ? false : _b;
    var dispatch = useAppDispatch();
    var handleClickLinkBtn = function () {
        dispatch(setActivePopup("signUp"));
    };
    return (_jsxs("div", { className: styles.main, children: [_jsx(Text, { variant: "body1", isDesktop: isDesktop, children: "\u041D\u0435\u0442 \u0430\u043A\u043A\u0430\u0443\u043D\u0442\u0430?" }), _jsx(LinkButton, { className: styles.link, onClick: handleClickLinkBtn, children: _jsx(Text, { variant: "buttonMedium", isDesktop: isDesktop, children: "\u0417\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043E\u0432\u0430\u0442\u044C\u0441\u044F" }) })] }));
};
