import styles from "./ServicesSection.modules.scss"

import React from "react";
import cn from "classnames";
import CheckIcon from "../../../icons/Check";
import {numberWithSpaces} from "../../../ordering/shared";
import PlusIcon from "../../../icons/Plus";
import {useAddCartProductServiceMutation, useRemoveCartProductServiceMutation} from "@src/shared/api"

export const ServicesSection = (
  {
    cartProduct,
    warrantyServices,
    settingServices,
    installingServices,
    isMobile = false
  }) => {
  const activeServicesIds = cartProduct.services.map(item => item.id)
  const [addService] = useAddCartProductServiceMutation()
  const [removeService] = useRemoveCartProductServiceMutation()

  const ServicesBlock = ({title, services}) => (
    <div className={styles.block}>
      <span className={styles.title}>{title}</span>
      <div className={styles.list}>
        {services.map(item => (
          <ServiceItem
            key={item.id}
            id={item.id}
            name={item.name}
            price={item.price}
            isMobile={isMobile}
          />
        ))}
      </div>
    </div>
  )

  const ServiceItem = ({id, name, price, isMobile = false}) => {
    const isInCart = activeServicesIds.includes(id)

    const toggleServiceHandler = async () => {
      if (isInCart) {
        try {
          await removeService({
            productId: cartProduct.product.id,
            serviceId: id
          }).unwrap()
        } catch (error) {
          console.log(error)
        }
      } else {
        try {
          await addService({
            productId: cartProduct.product.id,
            serviceId: id
          }).unwrap()
        } catch (error) {
          console.log(error)
        }
      }
    }

    const CartButton = () => {
      return (
        <button className={cn(styles.cartButton, isInCart && styles.active)} onClick={toggleServiceHandler}>
          {isInCart ? <CheckIcon width={24} height={24} color={"#EF4123"}/> :
            <PlusIcon width={24} height={24} color={"#FFFFFF"}/>}
        </button>
      )
    }

    return (
      <div className={cn(styles.item, isMobile && styles.mobile)}>
        <span className={styles.name}>{name}</span>
        <div className={styles.control}>
          <span className={styles.price}>{numberWithSpaces(price)} ₽</span>
          <CartButton/>
        </div>
      </div>
    )
  }

  return (
    <div className={cn(styles.root, isMobile && styles.mobile)}>
      <span className={styles.title}>Дополнительные сервисы</span>
      <div className={styles.services}>
        {!!warrantyServices.length && (
          <ServicesBlock title={"Дополнительная гарантия"} services={warrantyServices}/>
        )}
        {!!settingServices.length && (
          <ServicesBlock title={"Настройка"} services={settingServices}/>
        )}
        {!!installingServices.length && (
          <ServicesBlock title={"Установка"} services={installingServices}/>
        )}
      </div>
    </div>
  )
}
