import React from 'react';

// Компонент товара
import { ProductAlt } from '../units/product-alt';

// Взаимодействие с корзиной
import compare_interactive from '../../js/lib/compare-interactive';
import favorite_interactive from '../../js/lib/favorite-interactive';
import numberWithSpaces from "../../js/utils"
import {clearCartWithYM, removeCartProductWithYM} from "../../../common/js/lib/cart";

export function PurchaseDropdown({cart}) {
  // Не показываем список если в нем нет элементов
  if (!cart?.products?.length) {
    return (
      <div className="dropdown">
        <div className="dropdown-placeholder">
          <span className="placeholder-text">Здесь ничего нет</span>
        </div>
      </div>
    );
  }

  function DropdownInfo({ total_price, total_discount, total_discount_price, total_bonuses, total_quantity }) {
    function PriceUpper({ total_price, total_discount }) {
      if (!total_discount) {
        return null;
      }

      const locale_price = numberWithSpaces(total_price);
      const locale_discount = numberWithSpaces(total_discount);

      return (
        <div className="price-upper">
          <span className="upper-full">{`${locale_price} ₽`}</span>
          <span className="upper-discount">{`-${locale_discount} ₽`}</span>
        </div>
      );
    }

    function PriceLower({ total_discount_price, total_bonuses }) {
      const locale_discount_price = numberWithSpaces(total_discount_price);

      if (!total_bonuses) {
        return (
          <div className="price-lower">
            <span className="lower-current">{`${locale_discount_price} ₽`}</span>
          </div>
        );
      }

      const locale_bonuses = numberWithSpaces(total_bonuses);

      return (
        <div className="price-lower">
          <span className="lower-current">{`${locale_discount_price} ₽`}</span>
          <span className="lower-bonus">{`+${locale_bonuses} бонусов`}</span>
        </div>
      );
    }

    return (
      <div className="dropdown-info">
        <div className="info-section">
          <span className="section-title">Сумма</span>
          <div className="section-price">
            <PriceUpper total_price={total_price} total_discount={total_discount} />
            <PriceLower total_discount_price={total_discount_price} total_bonuses={total_bonuses} />
          </div>
        </div>
        <div className="info-section">
          <span className="section-title">Количество товаров</span>
          <div className="section-quantity">
            <span className="quantity-text">{total_quantity}</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="dropdown">
      <div className="dropdown-list">
        {cart.products.map(cartProduct => (
          <ProductAlt
            key={cartProduct.product.id}
            productData={cartProduct.product}
            onIndicateClick={async () => {
              await removeCartProductWithYM({productId: cartProduct.product.id})
            }}
          />
        ))}
      </div>
      <DropdownInfo
        total_price={cart.total_price}
        total_discount={cart.total_discount_amount}
        total_discount_price={cart.total_discount_price}
        total_bonuses={cart.total_bonuses_amount}
        total_quantity={cart.total_quantity}
      />
      <div className="dropdown-control">
        <a className="control-button button_purchase" href="/ordering/?type=CART">
          <span className="button-text">Купить</span>
        </a>
        <div className="control-button button_clear" onClick={async () => {
          await clearCartWithYM()
        }}>
          <span className="button-text">Очистить</span>
        </div>
      </div>
    </div>
  );
}

export function FavoriteDropdown({ dropdownData }) {
  // Не показываем список если в нем нет элементов
  if (!Object.keys(dropdownData).length) {
    return (
      <div className="dropdown">
        <div className="dropdown-placeholder">
          <span className="placeholder-text">Здесь ничего нет</span>
        </div>
      </div>
    );
  }

  return (
    <div className="dropdown">
      <div className="dropdown-list">
        {dropdownData.map((productData, productIndex) => <ProductAlt key={productIndex} productData={productData} productType="favorite" />)}
      </div>
      <div className="dropdown-control">
        <a className="control-button button_compare" href="/favorites/">
          <span className="button-text">Смотреть</span>
        </a>
        <div className="control-button button_clear" onClick={() => favorite_interactive()}>
          <span className="button-text">Очистить</span>
        </div>
      </div>
    </div>
  );
}

export function CompareDropdown({ dropdownData }) {
  // Не показываем список если в нем нет элементов
  if (!Object.keys(dropdownData).length) {
    return (
      <div className="dropdown">
        <div className="dropdown-placeholder">
          <span className="placeholder-text">Здесь ничего нет</span>
        </div>
      </div>
    );
  }

  return (
    <div className="dropdown">
      <div className="dropdown-list">
        {dropdownData.products.map((productData, productIndex) => <ProductAlt key={productIndex} productData={productData} productType="compare" />)}
      </div>
      <div className="dropdown-control">
        <a className="control-button button_compare" href="/compare/">
          <span className="button-text">Сравнить</span>
        </a>
        <div className="control-button button_clear" onClick={() => compare_interactive()}>
          <span className="button-text">Очистить</span>
        </div>
      </div>
    </div>
  );
}
