import styles from "./styles.modules.scss"

import React from "react"
import cn from "classnames"

import {numberWithSpaces} from "../../../shared"

export const Price = ({value, size = "s", color = "#000000", children, isMobile = false}) => {
  const classes = cn(
    styles.root,
    isMobile && styles.mobile,
    {
      [styles.small]: size === "s",
      [styles.medium]: size === "m",
      [styles.large]: size === "l",
    }
  )
  return (
    <div className={classes}>
      <span className={styles.value} style={{color}}>{numberWithSpaces(value)} ₽</span>
      {children}
    </div>
  )
}