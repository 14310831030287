const json = document.querySelector('.json');

if (json) {
  const cartArray = json.getAttribute('data-cart-array');
  if (cartArray) {
    localStorage.setItem('cart-array', cartArray);
  } else {
    localStorage.removeItem('cart-array');
  }

  const compareArray = json.getAttribute('data-compare-array');
  if (compareArray) {
    localStorage.setItem('compare-array', compareArray);
  } else {
    localStorage.removeItem('compare-array');
  }

  const favoriteArray = json.getAttribute('data-favorite-array');
  if (favoriteArray) {
    localStorage.setItem('favorite-array', favoriteArray);
  } else {
    localStorage.removeItem('favorite-array');
  }
}
