var _a;
import { createSlice } from "@reduxjs/toolkit";
import { favoritesApi } from "@src/shared/api";
var initialState = {
    isShowPopup: false,
    products: [],
};
export var favoritesSlice = createSlice({
    name: "favorites",
    initialState: initialState,
    reducers: {
        setProducts: function (state, _a) {
            var payload = _a.payload;
            state.products = payload;
        },
        setIsShowPopup: function (state, _a) {
            var payload = _a.payload;
            state.isShowPopup = payload;
        },
    },
    extraReducers: function (builder) {
        builder
            .addMatcher(favoritesApi.endpoints.addFavoriteProduct.matchFulfilled, function (state) {
            state.isShowPopup = true;
        })
            .addMatcher(favoritesApi.endpoints.getFavoriteProductsBrief.matchFulfilled, function (state, action) {
            var _a, _b;
            state.products = (_b = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.map(function (item) { return item.id; })) !== null && _b !== void 0 ? _b : [];
        });
    },
});
export var selectFavoritesProducts = function (state) {
    return state.favorites.products;
};
export var selectIsShowFavoritesPopup = function (state) {
    return state.favorites.isShowPopup;
};
export var setProducts = (_a = favoritesSlice.actions, _a.setProducts), setIsShowPopup = _a.setIsShowPopup;
