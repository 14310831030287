import React from "react"
import ReactDOM from "react-dom"

import CartButtonContainer from "../../../../common/jsx/components/CartButton/CartButtonContainer"
import purchase_interactive from '../../lib/purchase-interactive'
import numberWithSpaces from "../../utils"

const header_upper = document.querySelector('.header-upper');
const product_group = document.querySelector('.product-group');
const product_content = document.querySelector('.product-content');

if (product_group) {
  // Половина отступа страницы от хлебных крошек
  const group_indent = 48 / 2;

  // Высота шапки сайта
  const header_upper_height = header_upper.clientHeight;

  // Вычисляем позиционирование для липкого элемента
  product_group.style.top = `${ header_upper_height + group_indent }px`;

  // Идентификатор товара в базе данных
  const product_identifier = product_group.getAttribute('data-identifier');

  // Добавляем события на слайдер
  const group_gallery = product_group.querySelector('.group-gallery');

  if (group_gallery) {
    const gallery_count = group_gallery.querySelector('.gallery-count');

    const indicate_prev = group_gallery.querySelector('.indicate_previous');
    const indicate_next = group_gallery.querySelector('.indicate_next');

    const count_list = group_gallery.querySelector('.count-list');
    const layer_list = group_gallery.querySelector('.layer-list');

    let current_unit = 0;

    const count_unit_all = count_list.querySelectorAll('.list-unit');

    if (count_unit_all.length > 1) {
      gallery_count.style.display = 'flex';
      indicate_next.style.display = 'block';

      setTimeout(initial_timeout, 20);

      function initial_timeout() {
        gallery_count.style.opacity = '1';
        indicate_next.style.opacity = '1';
      }
    }

    indicate_prev.addEventListener('click', indicate_prev_click);
    indicate_next.addEventListener('click', indicate_next_click);

    function indicate_prev_click() {
      if (indicate_next.style.display != 'block') {
        indicate_next.style.display = 'block';

        setTimeout(() => indicate_next.style.opacity = '1');
      }

      if (current_unit) {
        current_unit = current_unit - 1;

        const scroll_top = count_list.scrollTop;
        const offset_top = count_unit_all[current_unit].offsetTop;

        if (scroll_top > offset_top) {
          count_list.scrollTo(
            {
              top: offset_top,
              behavior: 'smooth',
            }
          );
        }
      }

      if (!current_unit) {
        indicate_prev.style.opacity = '0';

        indicate_prev.addEventListener('transitionend', () => indicate_prev.style.display = 'none', { once: true });
      }

      layer_list.style.transform = `translateX(-${ current_unit * 100 }%)`;

      count_unit_all.forEach(count_unit_each);
    }

    function indicate_next_click() {
      if (indicate_prev.style.display != 'block') {
        indicate_prev.style.display = 'block';

        setTimeout(() => indicate_prev.style.opacity = '1');
      }

      if (current_unit < count_unit_all.length - 1) {
        current_unit = current_unit + 1;

        const scroll_bottom = count_list.offsetHeight + count_list.scrollTop;
        const offset_bottom = count_unit_all[current_unit].offsetTop + count_unit_all[current_unit].offsetHeight;

        if (scroll_bottom < offset_bottom) {
          count_list.scrollTo(
            {
              top: offset_bottom - count_list.offsetHeight,
              behavior: 'smooth',
            }
          );
        }
      }

      if (current_unit == count_unit_all.length - 1) {
        indicate_next.style.opacity = '0';

        indicate_next.addEventListener('transitionend', () => indicate_next.style.display = 'none', { once: true });
      }

      layer_list.style.transform = `translateX(-${ current_unit * 100 }%)`;

      count_unit_all.forEach(count_unit_each);
    }

    function count_unit_each(count_unit) {
      const count_data_unit = count_unit.getAttribute('data-unit');

      if (count_data_unit != current_unit) {
        count_unit.classList.remove('unit_selected');
      } else {
        count_unit.classList.add('unit_selected');
      }
    }

    count_unit_all.forEach((count_unit) => count_unit.addEventListener('click', () => count_unit_click(count_unit)));

    function count_unit_click(count_unit) {
      const unit_selected = count_list.querySelector('.unit_selected');

      unit_selected.classList.remove('unit_selected');
      count_unit.classList.add('unit_selected');

      current_unit = Number(count_unit.getAttribute('data-unit'));

      layer_list.style.transform = `translateX(-${ current_unit * 100 }%)`;

      if (!current_unit) {
        if (indicate_prev.style.opacity != '0') {
          indicate_prev.style.opacity = '0';

          indicate_prev.addEventListener('transitionend', () => indicate_prev.style.display = 'none', { once: true });
        }

        if (indicate_next.style.display != 'block') {
          indicate_next.style.display = 'block';

          setTimeout(() => indicate_next.style.opacity = '1');
        }
      } else if (current_unit == count_unit_all.length - 1) {
        if (indicate_next.style.opacity != '0') {
          indicate_next.style.opacity = '0';

          indicate_next.addEventListener('transitionend', () => indicate_next.style.display = 'none', { once: true });
        }

        if (indicate_prev.style.display != 'block') {
          indicate_prev.style.display = 'block';

          setTimeout(() => indicate_prev.style.opacity = '1');
        }
      } else {
        if (indicate_prev.style.display != 'block') {
          indicate_prev.style.display = 'block';

          setTimeout(() => indicate_prev.style.opacity = '1');
        }

        if (indicate_next.style.display != 'block') {
          indicate_next.style.display = 'block';

          setTimeout(() => indicate_next.style.opacity = '1');
        }
      }
    }
  }

  const group_control = product_group.querySelector('.group-control')

  if (group_control) {
    const button_order = group_control.querySelector('.button_order')
    const isInCart = button_order.getAttribute('data-in-cart')

    ReactDOM.render(
      <CartButtonContainer
        productId={product_identifier}
        isAdd={isInCart}
        disabled={false}
        size={"large"}
      />,
      button_order
    )
    const button_purchase = group_control.querySelector('.button_purchase');


    // button_order.addEventListener('click', button_order_click);
    // button_purchase.addEventListener('click', button_purchase_click);

    async function button_order_click(event) {
      if (button_order.classList.contains('button_fill')) {
        event.preventDefault();

        const services = getServices();

        const button_text = button_order.querySelector('.layer-text');
        const button_link = button_order.querySelector('.button-link');

        button_link.href = '/cart';

        await purchase_interactive('add', product_identifier, 1, services);

        button_order.classList.remove('button_fill');
        button_order.classList.add('button_outline');

        button_text.textContent = 'Перейти в корзину';
      }
    }

    async function button_purchase_click(event) {
      event.preventDefault();

      const services = getServices();

      await purchase_interactive();
      await purchase_interactive('add', product_identifier, 1, services);

      window.location = '/ordering';
    }
  }

  const group_section = product_group.querySelector('.group-section');

  if (group_section) {
    const product_id = group_section.getAttribute('data-id');
    const product_full = group_section.getAttribute('data-full');
    const product_discount = group_section.getAttribute('data-discount');
    const product_price = group_section.getAttribute('data-price');
    const product_bonus = group_section.getAttribute('data-bonus') || 0;

    const section_special = group_section.querySelector('.section-special');
    const section_control = group_section.querySelector('.section-control');

    const section_detail = group_section.querySelector('.section-detail');

    const price_upper = section_detail.querySelector('.price-upper');
    const price_lower = section_detail.querySelector('.price-lower');

    const upper_full = section_detail.querySelector('.upper-full');
    const upper_discount = section_detail.querySelector('.upper-discount');

    const lower_price = price_lower.querySelector('.lower-current');
    const lower_bonus = price_lower.querySelector('.lower-bonus');

    if (section_special) {
      const group_layer = section_special.querySelector('.group-layer');

      const indicate_prev = group_layer.querySelector('.indicate_previous');
      const indicate_next = group_layer.querySelector('.indicate_next');

      const content_list = group_layer.querySelector('.layer-content > .content-list');

      const list_unit_all = content_list.querySelectorAll('.list-unit');

      const observer = new MutationObserver(observerCallback);

      observer.observe(group_section, { attributes: true, attributeFilter: ['data-order'] });
      observer.observe(content_list, { attributes: true, attributeFilter: ['data-order'], subtree: true });

      if (list_unit_all.length > 1) {
        indicate_prev.style.display = 'block';
        indicate_next.style.display = 'block';

        setTimeout(initial_timeout, 20);

        function initial_timeout() {
          indicate_prev.style.opacity = '1';
          indicate_next.style.opacity = '1';
        }
      }

      let transition = false;

      indicate_prev.addEventListener('click', indicate_prev_click);
      indicate_next.addEventListener('click', indicate_next_click);

      function indicate_prev_click() {
        const list_unit_all = content_list.querySelectorAll('.list-unit');

        if (!transition) {
          transition = true;

          if (list_unit_all.length > 1) {
            const last_unit = list_unit_all[list_unit_all.length - 1];

            content_list.prepend(last_unit);

            content_list.style.transform = 'translate3d(-100%, 0, 0)';

            setTimeout(() => {
              content_list.classList.add('list_transition');

              content_list.style.transform = 'translate3d(0, 0, 0)';

              content_list.addEventListener('transitionend', content_list_transitionend, { once: true });
            }, 20);

            function content_list_transitionend() {
              content_list.classList.remove('list_transition');

              transition = false;

              changeInfo(last_unit);

              const product_order = group_section.getAttribute('data-order');
              const current_order = last_unit.getAttribute('data-order');

              changeButton(product_order, current_order);
            }
          }
        }
      }

      function indicate_next_click() {
        const list_unit_all = content_list.querySelectorAll('.list-unit');

        if (!transition) {
          transition = true;

          if (list_unit_all.length > 1) {
            const this_unit = list_unit_all[0];
            const next_unit = list_unit_all[1];

            content_list.classList.add('list_transition');

            content_list.style.transform = 'translate3d(-100%, 0, 0)';

            content_list.addEventListener('transitionend', content_list_transitionend, { once: true });

            function content_list_transitionend() {
              content_list.append(this_unit);

              content_list.classList.remove('list_transition');

              content_list.style.transform = 'translate3d(0, 0, 0)';

              transition = false;

              changeInfo(next_unit);

              const product_order = group_section.getAttribute('data-order');
              const current_order = next_unit.getAttribute('data-order');

              changeButton(product_order, current_order);
            }
          }
        }
      }

      function observerCallback() {
        const this_unit = group_layer.querySelector('.list-unit');

        const product_order = group_section.getAttribute('data-order');
        const current_order = this_unit.getAttribute('data-order');

        changeButton(product_order, current_order);
      }
    }

    if (section_control) {
      const button_order = section_control.querySelector('.control-button[data-button="order"]');
      const button_purchase = section_control.querySelector('.control-button[data-button="purchase"]');

      button_order.addEventListener('click', button_order_click);
      button_purchase.addEventListener('click', button_purchase_click);

      /**
       *
       * @param {Event} event
       */
      async function button_order_click(event) {
        if (button_order.classList.contains('button_fill')) {
          event.preventDefault();

          const services = getServices();

          const product_order = group_section.getAttribute('data-order');

          const current_unit = group_section.querySelector('.group-layer .list-unit');
          const current_id = current_unit.getAttribute('data-id');
          const current_order = current_unit.getAttribute('data-order');

          if (!product_order) {
            // Добавляем основной товар в корзину
            await purchase_interactive('add', product_id, 1, services);
          }

          if (!current_order) {
            // Добавляем дополнительный товар в корзину
            await purchase_interactive('add', current_id, 1);
          }

          changeButton(true, true);
        }
      }

      async function button_purchase_click(event) {
        event.preventDefault();

        const services = getServices();

        const current_unit = group_section.querySelector('.group-layer .list-unit');
        const current_id = current_unit.getAttribute('data-id');
        const current_order = current_unit.getAttribute('data-order');

        await purchase_interactive();
        await purchase_interactive('add', product_id, 1, services);
        await purchase_interactive('add', current_id, 1);

        window.location = '/ordering';
      }
    }

    /**
     *
     * @param {Element} unit
     */
    function changeInfo(unit) {
      const unit_full = unit.getAttribute('data-full');
      const unit_discount = unit.getAttribute('data-discount');
      const unit_price = unit.getAttribute('data-price');
      const unit_bonus = unit.getAttribute('data-bonus') || 0;

      const summary_full = parseInt(product_full) + parseInt(unit_full);
      const summary_discount = parseInt(product_discount) + parseInt(unit_discount);

      if (summary_discount) {
        price_upper.style.display = 'flex';
      } else {
        price_upper.style.display = 'none';
      }
      upper_full.textContent = `${numberWithSpaces(summary_full)} ₽`;
      upper_discount.textContent = `-${numberWithSpaces(summary_discount)} ₽`;

      const summary_price = parseInt(product_price) + parseInt(unit_price);
      const summary_bonus = parseInt(product_bonus) + parseInt(unit_bonus);
      if (lower_bonus) {
        if (summary_bonus) {
          lower_bonus.style.display = 'block';
        } else {
          lower_bonus.style.display = 'none';
        }
        lower_bonus.textContent = `+${numberWithSpaces(summary_bonus)} бонусов`;
      }
      lower_price.textContent = `${numberWithSpaces(summary_price)} ₽`;
    }

    /**
     *
     * @param {boolean} product_order
     * @param {boolean} current_order
     */
    function changeButton(product_order, current_order) {
      const button_order = section_control.querySelector('[data-button="order"]');

      const button_text = button_order.querySelector('.layer-text');
      const button_link = button_order.querySelector('.button-link');

      if (product_order && current_order) {
        button_order.classList.add('button_outline');
        button_order.classList.remove('button_fill');

        button_text.textContent = 'Перейти в корзину';
        button_link.setAttribute('href', '/cart');
      } else {
        button_order.classList.add('button_fill');
        button_order.classList.remove('button_outline');

        button_text.textContent = 'В корзину';
        button_link.removeAttribute('href');
      }
    }
  }

  function getServices() {
    const unit_service = product_content.querySelector('.unit-service');

    const services = [];

    if (unit_service) {
      const checkbox_all = unit_service.querySelectorAll('input[type="checkbox"]');

      for (let checkbox of checkbox_all) {
        if (checkbox.checked) {
          services.push(checkbox.value);
        }
      }
    }

    return services;
  }
}
