import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./CatalogButton.module.scss";
import cn from "classnames";
import { useAppDispatch } from "@shared/hooks";
import { selectCatalogShow, setIsShowCatalog } from "@src/entities/catalog";
import { useAppSelector } from "@src/shared/hooks";
import { CatalogIcon, Text } from "@src/shared/ui";
export var CatalogButton = function () {
    var dispatch = useAppDispatch();
    var isShow = useAppSelector(selectCatalogShow);
    var toggleShowCatalogHandler = function () {
        dispatch(setIsShowCatalog(!isShow));
    };
    return (_jsxs("button", { className: cn(styles.root, isShow && styles.active), type: "button", onClick: toggleShowCatalogHandler, children: [_jsx("div", { className: styles.icon, children: _jsx(CatalogIcon, {}) }), _jsx(Text, { className: styles.text, variant: "buttonMedium", children: "\u041A\u0430\u0442\u0430\u043B\u043E\u0433" })] }));
};
