import { correct_width } from '../lib/correct-width';

const overlay_all = document.querySelectorAll('.overlay');

if (overlay_all.length) overlay_all.forEach(overlay_each);

function overlay_each(overlay) {
  const data_finite = overlay.getAttribute('data-finite');

  const overlay_layer = overlay.querySelector('.overlay-layer');

  if (overlay_layer && !data_finite) {
    overlay.addEventListener('click', overlay_click);
  }

  function overlay_click({ target }) {
    if (!overlay_layer.contains(target)) {
      if (overlay.style.display != 'none') overlay.style.display = 'none';

      correct_width(false);
    }
  }
}
