import React from "react"
import {Provider} from "react-redux"

import {store} from "@src/app/store"
import CartButton from "./CartButton"
import {useGetCartQuery} from "@src/shared/api"
import {addCartProductWithYM, removeCartProductWithYM} from "../../../js/lib/cart";

const CartButtonContainer = ({productId,...props}) => {
  const {data: cart} = useGetCartQuery()

  const isInCart = !!cart?.products?.find(item => item.product.id === Number(productId))

  const addProductToCartButtonHandler = async () => {
    await addCartProductWithYM({productId})
  }

  const removeProductFromCartCartButtonHandler = async () => {
    await removeCartProductWithYM({productId})
  }

  return (
    <CartButton
    {...props}
    isAdd={isInCart}
    onClick={isInCart ? removeProductFromCartCartButtonHandler : addProductToCartButtonHandler}
  />
  )
}

export default (props) => (
  <Provider store={store}>
    <CartButtonContainer {...props}/>
  </Provider>
)