import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { CartDropdown } from "./CartDropdown";
import { selectIsShowCartPopup, setIsShowCartPopup } from "@src/entities/cart";
import { useGetCartQuery } from "@src/shared/api";
import { useAppDispatch, useAppSelector } from "@src/shared/hooks";
import { ButtonWithPopup, CartIcon } from "@src/shared/ui";
export var CartNavButton = function (_a) {
    var _b = _a.popupOffset, popupOffset = _b === void 0 ? 0 : _b;
    var dispatch = useAppDispatch();
    var cart = useGetCartQuery(null).data;
    var isShowCartPopup = useAppSelector(selectIsShowCartPopup);
    useEffect(function () {
        dispatch(setIsShowCartPopup(false));
    }, [isShowCartPopup]);
    return (_jsx(ButtonWithPopup, { text: "\u041A\u043E\u0440\u0437\u0438\u043D\u0430", href: "/cart/", icon: _jsx(CartIcon, {}), count: (cart === null || cart === void 0 ? void 0 : cart.total_quantity) || 0, isDisable: !(cart === null || cart === void 0 ? void 0 : cart.total_quantity), popup: _jsx(CartDropdown, {}), popupOffset: popupOffset, isShowPopup: isShowCartPopup }));
};
