import styles from "./styles.modules.scss"

import React from "react"
import {useForm, Controller} from "react-hook-form"
import {useDispatch, useSelector} from "react-redux"

import {
  selectComment, setComment
} from "../../../../../../js/redux/slices/orderingSlice"
import {FormWrapper, InputField} from "../../../shared"

export const CommentForm = ({formId, onSubmit}) => {
  const dispatch = useDispatch()

  const comment = useSelector(selectComment)

  const {
    control,
    handleSubmit,
  } = useForm({
    mode: 'onChange' || 'onTouched',
    defaultValues: {
      comment: comment || ""
    },
  })

  const onSubmitHandler = (data) => {
    dispatch(setComment(data.comment))
    if (onSubmit)  onSubmit()
  }

  return (
    <FormWrapper title={"Комментарий к заказу"}>
      <form id={formId} onSubmit={handleSubmit(onSubmitHandler)} className={styles.form}>
        <Controller
          control={control}
          name={"comment"}
          render={({field: {onChange, value}}) => (
            <InputField
              type={"textarea"}
              placeholder={"Написать комментарий"}
              value={value}
              rows={5}
              onChange={value => onChange(value)}
            />
          )}
        />
      </form>
    </FormWrapper>
  )
}
