import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./styles.module.scss";
import { useState, useEffect } from "react";
import cn from "classnames";
import { Text } from "../Text";
var CheckIcon = function (_a) {
    var _b = _a.active, active = _b === void 0 ? false : _b;
    return (_jsx("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: active ? (_jsxs(_Fragment, { children: [_jsx("rect", { width: "16", height: "16", rx: "2", fill: "#EF4123" }), _jsx("circle", { cx: "8", cy: "8", r: "4", fill: "white" })] })) : (_jsx("rect", { x: "0.5", y: "0.5", width: "15", height: "15", rx: "1.5", stroke: "#EF4123" })) }));
};
export var Checkbox = function (_a) {
    var _b = _a.label, label = _b === void 0 ? null : _b, _c = _a.checked, checked = _c === void 0 ? false : _c, _d = _a.error, error = _d === void 0 ? false : _d, onChange = _a.onChange, className = _a.className;
    var _e = useState(checked), currentChecked = _e[0], setCurrentChecked = _e[1];
    useEffect(function () {
        setCurrentChecked(checked);
    }, [checked]);
    var toggleCheckedHandler = function () {
        setCurrentChecked(!currentChecked);
        if (onChange)
            onChange(!currentChecked);
    };
    return (_jsxs("div", { className: cn(styles.root, className), onClick: toggleCheckedHandler, children: [_jsx(CheckIcon, { active: currentChecked }), label && (_jsx(Text, { variant: "body2", color: error ? "#EF4123" : "#111", children: label }))] }));
};
