import { baseApi } from "./base";
var apiWithTag = baseApi.enhanceEndpoints({
    addTagTypes: ["CompareBrief", "CompareDetail"],
});
export var compareApiSlice = apiWithTag.injectEndpoints({
    endpoints: function (build) { return ({
        getCompareProductsBrief: build.query({
            query: function () { return ({ url: "/compare/brief/", method: "GET" }); },
            providesTags: ["CompareBrief"],
        }),
        addCompareProduct: build.mutation({
            query: function (_a) {
                var productId = _a.productId;
                return ({
                    url: "/compare/add/",
                    method: "POST",
                    body: {
                        product_id: productId,
                    },
                });
            },
            invalidatesTags: ["CompareBrief", "CompareDetail"],
        }),
        removeCompareProduct: build.mutation({
            query: function (_a) {
                var productId = _a.productId;
                return ({
                    url: "/compare/delete/",
                    method: "POST",
                    body: {
                        product_id: productId,
                    },
                });
            },
            invalidatesTags: ["CompareBrief", "CompareDetail"],
        }),
        clearCompare: build.mutation({
            query: function () { return ({
                url: "/compare/clear/",
                method: "POST",
            }); },
            invalidatesTags: ["CompareBrief", "CompareDetail"],
        }),
    }); },
});
export var useGetCompareProductsBriefQuery = compareApiSlice.useGetCompareProductsBriefQuery, useAddCompareProductMutation = compareApiSlice.useAddCompareProductMutation, useRemoveCompareProductMutation = compareApiSlice.useRemoveCompareProductMutation, useClearCompareMutation = compareApiSlice.useClearCompareMutation;
