import axios from "axios"

export const baseApi = axios.create({
  baseURL: "/api/v2/",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    'X-CSRFToken': document.querySelector(`input[name='csrfmiddlewaretoken']`).value,
  }
})

export const baseApiV1 = axios.create({
  baseURL: "/api/",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    'X-CSRFToken': document.querySelector(`input[name='csrfmiddlewaretoken']`).value,
  }
})