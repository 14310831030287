var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./SendCode.module.scss";
import { useEffect, useRef, useState } from "react";
import cn from "classnames";
import AuthCode from "react-auth-code-input";
import { Controller, useForm } from "react-hook-form";
import { Button, Text } from "@shared/ui";
import { wordByCount } from "@src/shared/lib";
import { useRestoreProcessMutation } from "@src/shared/api";
import { useAppDispatch } from "@src/shared/hooks";
import { resetState, setStage } from "../../../model";
var TIMER_RELOAD_TIME = 60;
export var SendCodeForm = function (_a) {
    var _b;
    var _c = _a.isDesktop, isDesktop = _c === void 0 ? false : _c, phone = _a.phone, sessionKey = _a.sessionKey;
    var _d = useForm({
        mode: "onSubmit",
        defaultValues: {
            code: "",
        },
    }), control = _d.control, handleSubmit = _d.handleSubmit, setError = _d.setError, _e = _d.formState, errors = _e.errors, isValid = _e.isValid;
    var dispatch = useAppDispatch();
    var restoreProcess = useRestoreProcessMutation()[0];
    var _f = useState(true), isDisabledSendNewCode = _f[0], setIsDisabledSendNewCode = _f[1];
    var timerRef = useRef(TIMER_RELOAD_TIME);
    var _g = useState(TIMER_RELOAD_TIME), resendSeconds = _g[0], setResendSeconds = _g[1];
    useEffect(function () {
        var intervalId;
        if (timerRef.current > 0) {
            intervalId = setInterval(function () {
                if (timerRef.current === 0) {
                    setIsDisabledSendNewCode(false);
                    clearInterval(intervalId);
                }
                else {
                    timerRef.current -= 1;
                    setResendSeconds(function (prevState) { return prevState - 1; });
                }
            }, 1000);
        }
        return function () {
            clearInterval(intervalId);
        };
    }, []);
    var handleSendNewCode = function () { return __awaiter(void 0, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, restoreProcess({ sessionKey: sessionKey }).unwrap()];
                case 1:
                    _a.sent();
                    setIsDisabledSendNewCode(true);
                    timerRef.current = TIMER_RELOAD_TIME;
                    setResendSeconds(TIMER_RELOAD_TIME);
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    if (err_1.data.error === "SessionNotFound") {
                        dispatch(resetState());
                    }
                    else {
                        throw err_1;
                    }
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var submitHandler = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, restoreProcess({ sessionKey: sessionKey, code: data.code }).unwrap()];
                case 1:
                    _a.sent();
                    dispatch(setStage("INPUT_PASSWORD"));
                    return [3 /*break*/, 3];
                case 2:
                    err_2 = _a.sent();
                    if (err_2.data.error === "SessionNotFound") {
                        dispatch(resetState());
                    }
                    else if (err_2.data.error === "CodeWrong") {
                        setError("code", { message: "Неверный код" });
                    }
                    else {
                        throw err_2;
                    }
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var showError = function () {
        var _a;
        var errorName = Object.keys(errors)[0];
        if (Object.keys(errors).length > 1)
            return "Заполните правильно все отмеченные поля";
        if (Object.keys(errors).length === 1)
            return (_a = errors[errorName]) === null || _a === void 0 ? void 0 : _a.message;
        return false;
    };
    return (_jsxs("form", { className: cn(styles.main, (_b = {}, _b[styles.desktop] = isDesktop, _b)), onSubmit: handleSubmit(submitHandler), children: [_jsxs(Text, { variant: "body1", isDesktop: isDesktop, className: styles.title, children: ["\u041C\u044B \u043E\u0442\u043F\u0440\u0430\u0432\u0438\u043B\u0438 \u0421\u041C\u0421 \u0441 \u043A\u043E\u0434\u043E\u043C \u043D\u0430 \u043D\u043E\u043C\u0435\u0440", " ", _jsx(Text, { variant: "body1", className: styles.phone, children: phone })] }), Object.keys(errors).length > 0 && (_jsx(Text, { variant: "body1", className: styles.error, isDesktop: isDesktop, children: showError() })), _jsx(Controller, { control: control, name: "code", render: function (_a) {
                    var _b;
                    var onChange = _a.field.onChange;
                    return (_jsx(AuthCode, { allowedCharacters: "numeric", onChange: function (res) { return onChange(res); }, placeholder: "X", containerClassName: styles.inputContainer, inputClassName: cn(styles.input, (_b = {},
                            _b[styles.error] = Boolean(errors.code),
                            _b)) }));
                }, rules: {
                    required: {
                        value: true,
                        message: 'Введите код из СМС',
                    },
                    minLength: {
                        value: 6,
                        message: "Неверный код",
                    },
                } }), !!resendSeconds && (_jsxs(Text, { variant: "body1", isDesktop: isDesktop, className: styles.title, children: ["\u041E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C \u043F\u043E\u0432\u0442\u043E\u0440\u043D\u043E \u043C\u043E\u0436\u043D\u043E \u0447\u0435\u0440\u0435\u0437", " ", _jsxs(Text, { variant: "body1", className: styles.phone, children: [resendSeconds, " ", wordByCount(resendSeconds, "секунду", "секунды", "секунд")] })] })), _jsx(Button, { type: "button", variant: "secondary", onClick: handleSendNewCode, isDisabled: isDisabledSendNewCode, disabled: isDisabledSendNewCode, children: "\u041F\u043E\u0432\u0442\u043E\u0440\u043D\u043E \u043E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C" }), _jsx(Button, { type: "submit", variant: "primary", isDisabled: !isValid, children: "\u041E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C" })] }));
};
