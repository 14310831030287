import React from "react"

const Star = ({width=12, height=12, empty=false}) => (
  empty ? (
    <svg width={width} height={height} viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 0.809018L7.10932 4.22315L7.16545 4.3959H7.34708H10.9369L8.03268 6.50595L7.88573 6.61271L7.94186 6.78546L9.05118 10.1996L6.14695 8.08954L6 7.98278L5.85305 8.08954L2.94882 10.1996L4.05814 6.78546L4.11426 6.61271L3.96732 6.50595L1.06308 4.3959H4.65292H4.83455L4.89068 4.22315L6 0.809018Z" stroke="#EF4123" strokeWidth="0.5"/>
    </svg>
  ) : (
    <svg width={width} height={height} viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#EF4123"/>
    </svg>
  )
)

export default Star