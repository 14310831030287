import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./LoginLink.module.scss";
import { setActivePopup } from "@entities/popup";
import { LinkButton, Text } from "@shared/ui";
import { useAppDispatch } from "@shared/hooks";
export var LoginLink = function (_a) {
    var _b = _a.isDesktop, isDesktop = _b === void 0 ? false : _b;
    var dispatch = useAppDispatch();
    var handleClickLinkBtn = function () {
        dispatch(setActivePopup("signIn"));
    };
    return (_jsxs("div", { className: styles.main, children: [_jsx(Text, { variant: "body1", isDesktop: isDesktop, children: "\u0412\u044B \u0443\u0436\u0435 \u0437\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043E\u0432\u0430\u043D\u044B?" }), _jsx(LinkButton, { className: styles.link, onClick: handleClickLinkBtn, children: _jsx(Text, { variant: "buttonMedium", isDesktop: isDesktop, children: "\u0412\u043E\u0439\u0442\u0438" }) })] }));
};
