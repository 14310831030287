import { jsx as _jsx } from "react/jsx-runtime";
import styles from "./styles.module.scss";
import cn from "classnames";
import { FoundDataSection } from "./FoundDataSection";
import { PreviewDataSection } from "./PreviewDataSection";
import { selectSearchQuery, addToHistory } from "@src/entities/search-popup";
import { useCreateSearchQueryMutation, } from "@src/shared/api";
import { useAppSelector } from "@src/shared/hooks";
export var SearchPopup = function (_a) {
    var _b = _a.isDesktop, isDesktop = _b === void 0 ? false : _b;
    var searchQuery = useAppSelector(selectSearchQuery);
    var createQuery = useCreateSearchQueryMutation()[0];
    var searchByQueryHandler = function (query) {
        createQuery(query.text);
        addToHistory(query, "query");
        if (query.catalog) {
            window.location.href = "".concat(query.catalog.url, "?search=").concat(query.text);
        }
        else {
            window.location.href = "/search/?search=".concat(query.text);
        }
    };
    var selectCatalogByQueryHandler = function (catalog) {
        createQuery(searchQuery);
        addToHistory(catalog, "category");
        window.location.href = catalog.url;
    };
    return (_jsx("div", { className: cn(styles.root, isDesktop && styles.desktop), children: searchQuery ? (_jsx(FoundDataSection, { isDesktop: isDesktop, onSelectSearchQuery: searchByQueryHandler, onSelectCatalog: selectCatalogByQueryHandler })) : (_jsx(PreviewDataSection, { isDesktop: isDesktop, onSelectSearchQuery: searchByQueryHandler, onSelectCatalog: selectCatalogByQueryHandler })) }));
};
