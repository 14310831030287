import { createSlice } from "@reduxjs/toolkit"
import {compareAPI} from "../../api/compareApi"

const initialState = {
  products: [],
}

export const getCompareProducts = () => {
  return (dispatch) => {
    compareAPI.getProductsIds().then(response => {
      if (response.status === 200) {
        dispatch(setProducts(response.data))
      }
    })
  }
}

export const compareSlice = createSlice({
  name: "compare",
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload
    },
    addProduct: (state, action) => {
      state.products.push(Number(action.payload))
    },
    removeProduct: (state, action) => {
      state.products.pop(Number(action.payload))
    },
    clearProducts: (state) => {
      state.products = []
    },
  },
})

export const selectCompareProducts = (state) => {
  return state.compare.products
}

export const {setProducts, addProduct, removeProduct, clearProducts} = compareSlice.actions

export default compareSlice.reducer