import styles from "./styles.modules.scss"

import React from "react"
import cn from "classnames"

export const Button = ({title, Component = "button", variant = "default", onClick, ...rest}) => {
  const classes = cn(
    styles.root,
    {
      [styles.secondary]: variant === "secondary",
      [styles.gray]: variant === "gray",
      [styles.link]: variant === "link",
    }
  )
  return (
    <Component className={classes} onClick={onClick} {...rest}>
      <span className={styles.title}>{title}</span>
    </Component>
  )
}
