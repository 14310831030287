import React from 'react';
import ReactDOM from 'react-dom';

import noUiSlider from 'nouislider';
import {specialRound} from "../../../../common/js/lib/utils";

const collectionsLayer  = document.querySelector(".collections-layer")

if (collectionsLayer) {
  const collectionsList = collectionsLayer.querySelector('.collections-list')
  const collectionsWrapper = collectionsLayer.querySelector('.collections-wrapper')
  const collapseButton = collectionsLayer.querySelector(".collapse-button")
  const collectionsListHeight = collectionsList.clientHeight

  const toggleCollapseCollections = () => {
    const isOpen = collectionsLayer.classList.contains("_show")
    if (isOpen) {
      collectionsLayer.classList.remove("_show")
      collectionsWrapper.style.height = "21px"
      collapseButton.innerHTML = "Показать"
    } else {
      collectionsLayer.classList.add("_show")
      collectionsWrapper.style.height = `${collectionsListHeight}px`
      collapseButton.innerHTML = "Скрыть"
    }
  }

  if (collectionsListHeight > 21) {
    collapseButton.style.display = "block"
    collapseButton.addEventListener('click', toggleCollapseCollections)
  }
}

const category_filter = document.querySelector('.category > .category-content .filter');

if (category_filter) {
  const filter_selected = category_filter.querySelector('.filter-selected');
  const filter_control = category_filter.querySelector('.filter-control');

  const filter_sliders_all = category_filter.querySelectorAll('.filter-slider');

  filter_sliders_all.forEach(item => {
    make_filter_slider(item)
  })

  function make_filter_slider(filter_slider) {

    const data_initial = filter_slider.getAttribute('data-initial');

    const data_min = filter_slider.getAttribute('data-min');
    const data_max = filter_slider.getAttribute('data-max');

    const slider_control = filter_slider.querySelector('.slider-control');

    const control_item_all = slider_control.querySelectorAll('.control-item');

    control_item_all.forEach(control_item_each);

    const item_input_min = slider_control.querySelector('.item-input[data-input="min"]');
    const item_input_max = slider_control.querySelector('.item-input[data-input="max"]');

    const slider_identifier = filter_slider.querySelector('.slider-identifier');

    const step = specialRound((data_max - data_min) / 40)

    function customRound(number, roundTo, roundUp) {
      if (roundUp) {
        return Math.ceil(number / roundTo) * roundTo;
      } else {
        return Math.floor(number / roundTo) * roundTo;
      }
    }

    noUiSlider.create(
      slider_identifier,
      {
        start: JSON.parse(data_initial),
        step,
        connect: true,
        range: {
          'min': customRound(Number(data_min), step, false),
          'max': customRound(Number(data_max), step, true)
        }
      }
    );

    slider_identifier.noUiSlider.on('slide', slider_identifier_slide);
    slider_identifier.noUiSlider.on('slide', slider_identifier_change);

    /**
     *
     * @param {HTMLDivElement} control_item
     */
    function control_item_each(control_item) {
      control_item.addEventListener('click', control_item_click);

      const item_input = control_item.querySelector('.item-input');

      item_input.addEventListener('input', item_input_change);
      item_input.addEventListener('blur', item_input_blur);
      item_input.addEventListener('keydown', item_input_keydown);

      function control_item_click() {
        if (item_input.disabled) {
          item_input.removeAttribute('disabled');
        }

        item_input.focus();
      }

      function item_input_change() {
        // Положение инпутов на странице
        const slider_control_position = slider_control.getBoundingClientRect();

        // Положение фильтра на странице
        const category_filter_position = category_filter.getBoundingClientRect();

        if (filter_control.classList.contains('control_hidden')) {
          filter_control.classList.remove('control_hidden');
        }

        // Поправка позиции
        let correction = 10;

        // Двигаем кнопку в место взаимодействия
        filter_control.style.top = `${slider_control_position.top - category_filter_position.top + correction}px`;

        // Применяем значение на слайдере
        // slider_identifier.noUiSlider.set([Number(item_input_min.value), Number(item_input_min.value)]);
      }

      function item_input_blur() {
        if (!item_input.value) {
          item_input.setAttribute('disabled', 'true');
        }

        if (item_input_min.value || item_input_max.value) {
          slider_identifier.noUiSlider.set([
            item_input_min.value ? Number(item_input_min.value) : Number(data_min),
            item_input_max.value ? Number(item_input_max.value) : Number(data_max),
          ]);
        }
      }

      function item_input_keydown(event) {
        if (event.code == 'Enter') {
          event.preventDefault();
        }
      }
    }

    function slider_identifier_slide(values) {
      if (item_input_min.disabled) item_input_min.removeAttribute('disabled');
      if (item_input_max.disabled) item_input_max.removeAttribute('disabled');

      item_input_min.value = Math.round(values[0]);
      item_input_max.value = Math.round(values[1]);
    }

    function slider_identifier_change() {
      // Положение слайдера на странице
      const slider_identifier_position = slider_identifier.getBoundingClientRect();

      // Положение фильтра на странице
      const category_filter_position = category_filter.getBoundingClientRect();

      if (filter_control.classList.contains('control_hidden')) {
        filter_control.classList.remove('control_hidden');
      }

      // Поправка позиции
      let correction = 8;

      // Двигаем кнопку в место взаимодействия
      filter_control.style.top = `${slider_identifier_position.top - category_filter_position.top - correction}px`;
    }
  }

  const filter_group_all = category_filter.querySelectorAll('.filter-group');

  filter_group_all.forEach(filter_group_each);

  function filter_group_each(filter_group) {
    const group_info = filter_group.querySelector('.group-info');
    const group_content = filter_group.querySelector('.group-content');

    const content_list = group_content.querySelector('.content-list');

    if (content_list) {
      const list_unit_all = content_list.querySelectorAll('.list-unit');

      const collapseButtonLayer = group_content.querySelector('.collapse-button-layer')

      if (collapseButtonLayer) {
        const collapseButton = collapseButtonLayer.querySelector('.collapse-button')
        collapseButton.addEventListener('click', onCollapseButtonClick)
      }

      function onCollapseButtonClick() {
        content_list.classList.remove('_collapse')
        collapseButtonLayer.classList.add('hide')
      }

      list_unit_all.forEach(list_unit_each);

      function list_unit_each(list_unit) {
        const unit_checkbox = list_unit.querySelector('input[type="checkbox"]');

        unit_checkbox.addEventListener('change', unit_checkbox_change);

        function unit_checkbox_change() {
          // Положение элемента фильтра на странице
          const list_unit_position = list_unit.getBoundingClientRect();

          // Положение фильтра на странице
          const category_filter_position = category_filter.getBoundingClientRect();

          if (filter_control.classList.contains('control_hidden')) {
            filter_control.classList.remove('control_hidden');
          }

          // Двигаем кнопку в место взаимодействия
          filter_control.style.top = `${list_unit_position.top - category_filter_position.top}px`;
        }
      }
    }

    group_info.addEventListener('click', group_info_click);

    // Основные функции
    function group_info_click() {
      group_info.classList.toggle('info_close');
      group_content.classList.toggle('hide')
    }
  }

  if (filter_control) {
    filter_control.addEventListener('click', filter_control_click);
  }

  function filter_control_click() {
    // Получаем все выбранные элементы
    let checkedValueList = [];
    let range_values = []
    let filterLink = '';

    const allFilterInputChecked = category_filter.querySelectorAll('.filter-group > .group-content .list-unit .filter-input:checked');

    if (allFilterInputChecked.length) {
      // Добавляем все отмеченные элементы в массив
      allFilterInputChecked.forEach((inputChecked) => checkedValueList.push(
        {
          "attributeId": inputChecked.dataset.attributeId,
          "valueId": inputChecked.dataset.valueId,
          "attributeName": inputChecked.name,
          "valueName": inputChecked.value
        }));
      // Сортируем массив по значениям полей attributeId и valueId
      checkedValueList.sort((a, b) => a.attributeId > b.attributeId || a.valueId > b.valueId ? 1 : -1)
    }

    if (filter_sliders_all.length) {
      filter_sliders_all.forEach(item => {
        set_slider_values(item)
      })

      function set_slider_values(filter_slider) {
        const slider_control = filter_slider.querySelector('.slider-control');
        const sliderName = filter_slider.getAttribute("data-name")
        const minPriceInput = slider_control.querySelector('.item-input[data-input="min"]');
        const maxPriceInput = slider_control.querySelector('.item-input[data-input="max"]');
        range_values.push({
          name: sliderName,
          min: minPriceInput.value,
          max: maxPriceInput.value,
          defaultMin: filter_slider.dataset.min,
          defaultMax: filter_slider.dataset.max
        })
      }
    }

    const newRangeValues = []

    const filterNewSliders = document.querySelectorAll(".range-filter-slider")

    if (filterNewSliders.length) {
      filterNewSliders.forEach(el => {
        const sliderName = el.getAttribute("data-name")
        const values = el.getAttribute('data-values');
        if (values) {
          newRangeValues.push({
            name: sliderName,
            values: JSON.parse(values)
          })
        }
      })
    }

    if (checkedValueList.length || range_values.length || newRangeValues.length) {
      filterLink = 'f';

      range_values.forEach(item => {
        if (item.min || item.max) {
          filterLink += `/${item.name}=${item.min || item.defaultMin}-${item.max || item.defaultMax}`
        }
      })

      newRangeValues.forEach(item => {
        let rangesStrList = item.values.map(range => range.join("-"))
        filterLink += `/${item.name}=${rangesStrList.join(",")}`
      })

      if (checkedValueList.length) {
        for (const item of checkedValueList) {
          if (filterLink.split('/').slice(-1)[0].includes(`${item.attributeName}=`)) {
            filterLink += `,${item.valueName}`
          } else {
            filterLink += `/${item.attributeName}=${item.valueName}`
          }
        }
      }
      filterLink += '/';
    }

    let location = window.location.pathname.split('/f/')[0] + `${window.location.pathname.includes('/f/') ? '/' : ''}` + filterLink;

    let getParams = []

    const currentSortInput = document.querySelector('.current-sort');
    const searchQueryInput = category_filter.querySelector('.search-query');

    if (currentSortInput && currentSortInput.value) {
      getParams.push(`sort=${currentSortInput.value}`)
    }
    if (searchQueryInput && searchQueryInput.value) {
      getParams.push(`search=${searchQueryInput.value}`);
    }

    if (getParams.length > 0) {
      for (let i = 0; i < getParams.length; ++i) {
          if (!i) { location += '?' } else { location += '&' }
          location += getParams[i]
      }
    }
    window.location = location
  }
}
