import {baseApiSlice} from "./api"

const apiWithTag = baseApiSlice.enhanceEndpoints({addTagTypes: ["Favorites"]})

export const favoritesApiSlice = apiWithTag.injectEndpoints({
  endpoints: build => ({
    getFavoritesProductsIds: build.query({
      query: () => ({url: "/favorites/products/ids/", method: "GET"}),
      providesTags: ["Favorites"]
    }),
    addFavoriteProduct: build.mutation({
      query: ({productId}) => {
        return {
          url: "/favorites/add/",
          method: "POST",
          body: {
            product_id: productId,
          }
        }
      },
      invalidatesTags: ["Favorites"]
    }),
    removeFavoriteProduct: build.mutation({
      query: ({productId}) => {
        return {
          url: "/favorites/delete/",
          method: "POST",
          body: {
            product_id: productId
          }
        }
      },
      invalidatesTags: ["Favorites"]
    }),
    clearFavoritesProduct: build.mutation({
      query: () => {
        return {
          url: "/favorites/clear/",
          method: "POST",
        }
      },
      invalidatesTags: ["Favorites"]
    }),
  }),
})

export const {
  useGetFavoritesProductsIdsQuery,
  useAddFavoritesProductMutation,
  useRemoveFavoritesProductMutation,
  useClearFavoritesProductMutation,
} = favoritesApiSlice
