import React from "react"
import ReactDOM from "react-dom"

import OrderingScreen from "../../../common/jsx/components/ordering/OrderingScreen"

const orderingLayer = document.querySelector('.purchase-content')

if (orderingLayer) {
  ReactDOM.render(<OrderingScreen withOnlinePayment/>, orderingLayer)
}
