import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./Mobile.module.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import cn from "classnames";
import { CategoriesList, setIsShowCatalog, SubcategoriesList, } from "@src/entities/catalog";
import { useAppDispatch } from "@src/shared/hooks";
import { ArrowDownIcon, Text } from "@src/shared/ui";
var SITE_HEADER_HEIGHT = 64;
var NAV_BAR_HEIGHT = 48;
var FIX_SAFARI_HEIGHT = 0;
export var Mobile = function (_a) {
    var _b;
    var _c, _d;
    var isCatalogShow = _a.isCatalogShow, data = _a.data;
    var dispatch = useAppDispatch();
    var _e = useState(0), categoriesHeight = _e[0], setCategoriesHeight = _e[1];
    var headerRef = useRef(null);
    var _f = useState(null), selectedCategoryId = _f[0], setSelectedCategoryId = _f[1];
    var handleResize = useCallback(function () {
        var _a, _b;
        var headerHeight = (_b = (_a = headerRef.current) === null || _a === void 0 ? void 0 : _a.offsetHeight) !== null && _b !== void 0 ? _b : 0;
        setCategoriesHeight(window.innerHeight -
            SITE_HEADER_HEIGHT -
            headerHeight -
            NAV_BAR_HEIGHT -
            FIX_SAFARI_HEIGHT);
    }, [isCatalogShow, headerRef, selectedCategoryId]);
    useEffect(function () {
        window.addEventListener("resize", handleResize);
        handleResize();
        return function () {
            window.removeEventListener("resize", handleResize);
        };
    }, [handleResize]);
    useEffect(function () {
        var timeout;
        if (!isCatalogShow) {
            timeout = setTimeout(function () {
                setSelectedCategoryId(null);
            }, 200);
        }
        return function () { return clearTimeout(timeout); };
    }, [isCatalogShow]);
    var handleClickArrow = function (id) { return setSelectedCategoryId(id); };
    var handleClickPrevBtn = function () {
        if (selectedCategoryId) {
            setSelectedCategoryId(null);
        }
        else {
            // TODO: Временное решение. Нужно удалить при интеграции на Next
            var mainLayerEl = document.querySelector('.overlay-layer[data-layer="1"]');
            var catalogLayerEl = document.querySelector('.overlay-layer[data-layer="2"]');
            catalogLayerEl.classList.add("layer_hidden");
            mainLayerEl.classList.remove("layer_hidden");
            dispatch(setIsShowCatalog(false));
        }
    };
    var currentCategoryObj = data.find(function (el) { return el.id === selectedCategoryId; });
    var currentHeaderTitle = (_c = currentCategoryObj === null || currentCategoryObj === void 0 ? void 0 : currentCategoryObj.name) !== null && _c !== void 0 ? _c : "Каталог";
    var mainClasses = cn(styles.main, (_b = {}, _b[styles.open] = isCatalogShow, _b));
    return (_jsxs("div", { className: mainClasses, children: [_jsxs("div", { className: styles.header, ref: headerRef, children: [_jsx("div", { className: styles.prevBtn, onClick: handleClickPrevBtn, children: _jsx(ArrowDownIcon, {}) }), _jsx(Text, { variant: "h3", children: currentHeaderTitle }), _jsx("div", {})] }), _jsx("div", { className: styles.categories, style: { maxHeight: categoriesHeight }, children: selectedCategoryId ? (_jsx(SubcategoriesList, { categories: (_d = currentCategoryObj === null || currentCategoryObj === void 0 ? void 0 : currentCategoryObj.childs) !== null && _d !== void 0 ? _d : [] })) : (_jsx(CategoriesList, { categories: data, onClickArrow: handleClickArrow })) })] }));
};
