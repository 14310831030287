import { createSlice } from "@reduxjs/toolkit";
var initialState = {
    isCatalogShow: false,
};
export var catalogSlice = createSlice({
    name: "catalog",
    initialState: initialState,
    reducers: {
        setIsShowCatalog: function (state, action) {
            state.isCatalogShow = action.payload;
        },
    },
});
export var selectCatalogShow = function (state) {
    return state.catalog.isCatalogShow;
};
export var setIsShowCatalog = catalogSlice.actions.setIsShowCatalog;
