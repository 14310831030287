import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import styles from "./OldPrice.module.scss";
import cn from "classnames";
import { numberWithSpaces } from "@src/shared/lib";
import { Text } from "@src/shared/ui";
export var OldPrice = function (_a) {
    var _b;
    var _c = _a.isDesktop, isDesktop = _c === void 0 ? false : _c, discountedPrice = _a.discountedPrice, price = _a.price;
    return (_jsx("div", { className: cn(styles.main, (_b = {}, _b[styles.desktop] = isDesktop, _b)), children: discountedPrice !== price && (_jsxs(Text, { variant: "bodySale", className: styles.price, isDesktop: isDesktop, children: [numberWithSpaces(price), " \u20BD"] })) }));
};
