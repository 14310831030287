import { jsx as _jsx } from "react/jsx-runtime";
import styles from "./styles.module.scss";
import cn from "classnames";
import { CategoryItem } from "@src/entities/search-popup";
export var CategoriesSection = function (_a) {
    var categories = _a.categories, _b = _a.isDesktop, isDesktop = _b === void 0 ? false : _b, onSelectCatalog = _a.onSelectCatalog;
    var categoriesItems = categories.map(function (item, index) { return (_jsx(CategoryItem, { name: item.name, url: item.url, imageSrc: item.image, breadcrumbs: item.breadcrumb, isDesktop: isDesktop, onClick: function () {
            onSelectCatalog === null || onSelectCatalog === void 0 ? void 0 : onSelectCatalog({ name: item.name, url: item.url, image: item.image });
            window.location.href = item.url;
        } }, index)); });
    return (_jsx("div", { className: cn(styles.root, isDesktop && styles.desktop), children: categoriesItems }));
};
