import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./Mobile.module.scss";
import { useEffect, useRef, useState } from "react";
import cn from "classnames";
import placeholder from "@src/assets/images/placeholder.png";
import { ArrowDownIcon, Text } from "@src/shared/ui";
export var Mobile = function (_a) {
    var _b;
    var name = _a.name, url = _a.url, image = _a.image, categories = _a.categories;
    var _c = useState(false), isOpen = _c[0], setIsOpen = _c[1];
    var _d = useState(0), itemsHeight = _d[0], setItemsHeight = _d[1];
    var itemsRef = useRef(null);
    useEffect(function () {
        if (itemsRef.current) {
            var height = itemsRef.current.offsetHeight;
            setItemsHeight(height);
        }
    }, []);
    var handleToggleShow = function (e) {
        e.preventDefault();
        setIsOpen(function (prevState) { return !prevState; });
    };
    return (_jsxs("div", { className: cn(styles.main, (_b = {}, _b[styles.open] = isOpen, _b)), children: [_jsxs("a", { className: styles.head, href: url, children: [_jsxs("div", { className: styles.block, children: [_jsx("div", { className: styles.imgWrapper, children: _jsx("img", { src: image || placeholder, alt: "", className: styles.img }) }), _jsx(Text, { variant: "body4", isDesktop: true, className: styles.text, children: name })] }), categories.length > 0 && (_jsx("div", { onClick: handleToggleShow, className: styles.button, children: _jsx(ArrowDownIcon, {}) }))] }), categories.length > 0 && (_jsx("div", { className: styles.items, style: { maxHeight: isOpen ? "".concat(itemsHeight, "px") : "0px" }, children: _jsx("div", { ref: itemsRef, className: styles.refWrapper, children: categories.map(function (el) { return (_jsx("a", { href: el.url, className: styles.item, children: _jsx(Text, { variant: "body2", children: el.name }) }, el.id)); }) }) }))] }));
};
