import styles from "./styles.modules.scss"

import React from "react"
import {useForm, Controller} from "react-hook-form"
import {useDispatch, useSelector} from "react-redux"
import cn from "classnames"

import {
  selectContactsData, setContactsData
} from "../../../../../../js/redux/slices/orderingSlice"
import {
  InputField, Checkbox
} from "../../../shared"

export const EmailForm = ({formId, onSubmit, isMobile = false}) => {
  const dispatch = useDispatch()

  const contacts = useSelector(selectContactsData)

  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      email: contacts?.email || "",
    },
  })

  const onSubmitHandler = (data) => {
    let contactsData = {...contacts, email: data.email}
    dispatch(setContactsData(contactsData))
    if (onSubmit) onSubmit(contactsData)
  }

  return (
    <div className={cn(styles.root, isMobile && styles.mobile)}>
      <form id={formId} onSubmit={handleSubmit(onSubmitHandler)} className={styles.form}>
        <span className={styles.infoText}>E-mail является обязательным полем. На него Вы получите чек об оплате.</span>
        <Controller
          control={control}
          name={"email"}
          rules={{required: "Email "}}
          render={({field: {onChange, value}}) => (
            <InputField
              placeholder={"E-mail"}
              value={value}
              errorText={errors?.email?.message?.toString()}
              onChange={value => onChange(value)}
              className={styles.input}
            />
          )}
        />
        <Controller
          control={control}
          name={"check"}
          rules={{required: true}}
          render={({field: {onChange, value}}) => (
            <Checkbox
              label={"Да, это мой действующий e-mail"}
              checked={value}
              isError={!!errors?.check}
              onChange={value => onChange(value)}
            />
          )}
        />
      </form>
    </div>
  )
}
