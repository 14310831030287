var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./styles.module.scss";
import { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { ValueItemCheckbox } from "../ValueItemCheckbox";
import { ButtonTxt } from "@src/shared/ui";
var ITEM_HEIGHT = 19;
var GAP = 14;
export var ValuesExpandedList = function (_a) {
    var itemsList = _a.itemsList, activeIndexes = _a.activeIndexes, _b = _a.showCount, showCount = _b === void 0 ? 5 : _b, _c = _a.isExpanded, isExpanded = _c === void 0 ? false : _c, _d = _a.isDesktop, isDesktop = _d === void 0 ? false : _d, onChange = _a.onChange;
    var collapsedCount = Math.min(showCount, itemsList.length);
    var expandedCount = itemsList.length;
    var expandedHeight = expandedCount * ITEM_HEIGHT + (expandedCount - 1) * GAP;
    var collapsedHeight = collapsedCount * ITEM_HEIGHT + (collapsedCount - 1) * GAP;
    var _e = useState(isExpanded), expanded = _e[0], setExpanded = _e[1];
    var listRef = useRef(null);
    var _f = useState(isExpanded ? expandedHeight : collapsedHeight), maxHeight = _f[0], setMaxHeight = _f[1];
    useEffect(function () {
        setExpanded(isExpanded);
    }, [isExpanded]);
    useEffect(function () {
        setMaxHeight(expanded ? expandedHeight : collapsedHeight);
    }, [expanded, expandedHeight, collapsedHeight]);
    var toggleExpandedHandler = function () {
        setExpanded(function (prevState) { return !prevState; });
    };
    var changeValuesHandler = function (value) {
        var newValues;
        if (activeIndexes === null || activeIndexes === void 0 ? void 0 : activeIndexes.includes(value)) {
            newValues = activeIndexes.filter(function (item) { return item !== value; });
        }
        else {
            newValues = __spreadArray(__spreadArray([], activeIndexes, true), [value], false);
        }
        if (onChange)
            onChange(newValues);
    };
    return (_jsxs("div", { className: cn(styles.root, expanded && styles.expanded), children: [_jsx("div", { className: styles.listLayer, ref: listRef, style: { maxHeight: "".concat(maxHeight, "px") }, children: itemsList.map(function (item, index) { return (_jsx(ValueItemCheckbox, { name: item.text, count: item.quantity, checked: activeIndexes.includes(index), isDesktop: isDesktop, onChange: function () { return changeValuesHandler(index); } }, index)); }) }), itemsList.length > showCount && (_jsx(ButtonTxt, { text: expanded ? "Скрыть" : "Показать всё", onClick: toggleExpandedHandler }))] }));
};
