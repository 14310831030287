var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./styles.module.scss";
import { forwardRef, useRef, useState, useImperativeHandle, useEffect, } from "react";
import cn from "classnames";
import { CloseIcon } from "./icons";
export var InputFilterField = forwardRef(function (props, ref) {
    var _a, _b;
    var _c;
    var _d = props.isDesktop, isDesktop = _d === void 0 ? false : _d, _e = props.type, type = _e === void 0 ? "text" : _e, className = props.className, onChangeText = props.onChangeText, onBlurCustom = props.onBlurCustom, onChange = props.onChange, rest = __rest(props, ["isDesktop", "type", "className", "onChangeText", "onBlurCustom", "onChange"]);
    var _f = useState((_c = props.value) !== null && _c !== void 0 ? _c : ""), localValue = _f[0], setLocalValue = _f[1];
    var _g = useState(false), isFocused = _g[0], setIsFocused = _g[1];
    var textInputRef = useRef(null);
    useEffect(function () {
        if (props.value !== undefined) {
            setLocalValue(props.value);
        }
    }, [props.value]);
    useImperativeHandle(ref, function () { return ({
        getValue: function () { return localValue; },
        onFocus: function () { var _a; return (_a = textInputRef.current) === null || _a === void 0 ? void 0 : _a.focus(); },
        onBlur: function () { var _a; return (_a = textInputRef.current) === null || _a === void 0 ? void 0 : _a.blur(); },
    }); });
    var handleFocus = function () {
        setIsFocused(true);
    };
    var handleBlur = function () {
        setIsFocused(false);
        onBlurCustom === null || onBlurCustom === void 0 ? void 0 : onBlurCustom();
    };
    var handleChange = function (event) {
        var newValue = event.target.value;
        if (type === "number" && !/^\d*\.?\d*$/.test(newValue)) {
            return;
        }
        setLocalValue(newValue);
        onChange === null || onChange === void 0 ? void 0 : onChange(event);
        onChangeText === null || onChangeText === void 0 ? void 0 : onChangeText(newValue);
    };
    var clearHandler = function () {
        setLocalValue("");
        onChangeText === null || onChangeText === void 0 ? void 0 : onChangeText("");
    };
    var inputType = type === "number" ? "text" : type;
    var rootClasses = cn(styles.root, (_a = {},
        _a[styles.focused] = isFocused,
        _a));
    var inputClasses = cn(styles.field, className, (_b = {},
        _b[styles.desktop] = isDesktop,
        _b));
    return (_jsxs("div", { className: rootClasses, children: [_jsx("input", __assign({ ref: textInputRef, type: inputType, value: localValue, className: inputClasses, onFocus: handleFocus, onBlur: handleBlur, onChange: handleChange }, rest)), !!localValue && (_jsx("button", { type: "button", className: styles.clearBtn, onClick: clearHandler, children: _jsx(CloseIcon, {}) }))] }));
});
InputFilterField.displayName = "InputFilterField";
