var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Provider } from "react-redux";
// TODO: Импорт store нарушает принципы FSD
import { store } from "@src/app/store";
// Этот HOC принимает компонент и возвращает новый компонент
export var withStore = function (WrappedComponent) {
    // Определяем компонент, который возвращает WrappedComponent обернутый в Provider
    var WithStoreComponent = function (props) { return (_jsx(Provider, { store: store, children: _jsx(WrappedComponent, __assign({}, props)) })); };
    // Возвращаем новый компонент
    return WithStoreComponent;
};
