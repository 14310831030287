import styles from "./styles.modules.scss"

import React from "react"

import {removeCartProductWithYM} from "../../../../../js/lib/cart"

export const RemoveProductFromCartBtn = ({productId}) => {

  const onClickHandler = async () => {
    try {
      await removeCartProductWithYM({productId})
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <button
      className={styles.root}
      onClick={onClickHandler}
    >
      Удалить
    </button>
  )
}
