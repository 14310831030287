var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./SendPhoneForm.module.scss";
import { useState } from "react";
import cn from "classnames";
import { Controller, useForm } from "react-hook-form";
import { PHONE_REGEX } from "@shared/lib";
import { Button, InputField, LinkButton, Text } from "@shared/ui";
import { useRestoreStartMutation } from "@src/shared/api";
import { useAppDispatch } from "@src/shared/hooks";
import { setRestoreData, setStage, resetRestoreData } from "../../../model";
import { clearPhone } from "@src/shared/lib";
export var SendPhoneForm = function (_a) {
    var _b;
    var _c = _a.isDesktop, isDesktop = _c === void 0 ? false : _c;
    var dispatch = useAppDispatch();
    var startRestore = useRestoreStartMutation()[0];
    var _d = useForm({
        mode: "onSubmit",
        defaultValues: {
            phone: "",
        },
    }), control = _d.control, handleSubmit = _d.handleSubmit, reset = _d.reset, setError = _d.setError, _e = _d.formState, errors = _e.errors, isValid = _e.isValid;
    var _f = useState(true), editMode = _f[0], setEditMode = _f[1];
    var submitHandler = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var cleanedPhone, res, sessionKey, err_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    cleanedPhone = clearPhone(data.phone);
                    return [4 /*yield*/, startRestore({ phone: cleanedPhone }).unwrap()];
                case 1:
                    res = _c.sent();
                    sessionKey = res.data.session_key;
                    dispatch(setStage("INPUT_CODE"));
                    dispatch(setRestoreData({ phone: data.phone, sessionKey: sessionKey }));
                    setEditMode(false);
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _c.sent();
                    if (((_a = err_1.data) === null || _a === void 0 ? void 0 : _a.error) === "LimitRestoreError") {
                        setError("phone", { message: "Превышено количество попыток восстановления. Попробуйте позже" });
                    }
                    else if (((_b = err_1.data) === null || _b === void 0 ? void 0 : _b.error) === "UserNotFound") {
                        setError("phone", { message: "Профиля с таким номером телефона нет" });
                    }
                    else {
                        throw err_1;
                    }
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleOpenEditMode = function () {
        setEditMode(true);
        reset();
        dispatch(resetRestoreData());
        dispatch(setStage("INPUT_PHONE"));
    };
    var showError = function () {
        var _a;
        var errorName = Object.keys(errors)[0];
        if (Object.keys(errors).length > 1)
            return "Заполните правильно все отмеченные поля";
        if (Object.keys(errors).length === 1)
            return (_a = errors[errorName]) === null || _a === void 0 ? void 0 : _a.message;
        return false;
    };
    return (_jsxs("form", { className: cn(styles.main, (_b = {}, _b[styles.desktop] = isDesktop, _b)), onSubmit: handleSubmit(submitHandler), children: [_jsx(Text, { variant: "body1", isDesktop: isDesktop, className: styles.title, children: "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u043D\u043E\u043C\u0435\u0440 \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0430 \u043D\u0430 \u043A\u043E\u0442\u043E\u0440\u044B\u0439 \u0431\u044B\u043B \u0437\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043E\u0432\u0430\u043D \u0432\u0430\u0448 \u0430\u043A\u043A\u0430\u0443\u043D\u0442." }), Object.keys(errors).length > 0 && (_jsx(Text, { variant: "body1", className: styles.error, isDesktop: isDesktop, children: showError() })), _jsxs("div", { className: styles.box, children: [_jsx(Controller, { control: control, name: "phone", render: function (_a) {
                            var _b;
                            var _c = _a.field, onChange = _c.onChange, value = _c.value;
                            return (_jsx(InputField, { disabled: !editMode, variant: "secondary", placeholder: "\u0422\u0435\u043B\u0435\u0444\u043E\u043D *", type: "mask", mask: "+7 (999) 999-99-99", value: value, onChange: onChange, errorText: (_b = errors.phone) === null || _b === void 0 ? void 0 : _b.message, isDesktop: true }));
                        }, rules: {
                            required: {
                                value: true,
                                message: 'Введите номер телефона',
                            },
                            pattern: {
                                value: PHONE_REGEX,
                                message: "Неправильный формат номера телефона",
                            },
                        } }), !editMode && isDesktop && (_jsx(LinkButton, { onClick: handleOpenEditMode, className: styles.updatePhone, children: _jsx(Text, { variant: "buttonSmall", children: "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u043D\u043E\u043C\u0435\u0440" }) }))] }), editMode && (_jsx(Button, { type: "submit", variant: "primary", isDisabled: !isValid, children: "\u041E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C" }))] }));
};
