import React from "react"
import ReactDOM from "react-dom"

import {CartPage} from "../../../common/jsx/components/cart"

const cartPageLayer = document.querySelector('.cart')

if (cartPageLayer) {
  ReactDOM.render(<CartPage/>, cartPageLayer)
}
