import {smoothScrollToElement} from "../../../common/js/lib/scroll";

const address = document.querySelector('.address');

if (address) {
  const addressList = address.querySelector('.address-list');
  const addressContent = address.querySelector('.address-content');

  const listItemAll = addressList.querySelectorAll('.list-item');

  ymaps.ready(initialMap);

  function initialMap() {
    // Объект карты
    let yandexMap = null;

    // Метка инициализации
    let yandexPlacemarkInit = null;

    // Кластеризатор геообъектов
    let yandexCluster = new ymaps.Clusterer();

    // Уровень масштаба
    const yandexZoom = 18;

    // Создаем метки и добавляем их в кластер
    listItemAll.forEach(listItemPlacemark);

    // Получаем массив начальных координат
    const initCoordinates = yandexPlacemarkInit.geometry.getCoordinates();

    // Инициализируем карту
    yandexMap = new ymaps.Map(
      addressContent,
      {
        center: initCoordinates,
        zoom: yandexZoom
      }
    );

    // Добавляем кластер геообъектов на карту
    yandexMap.geoObjects.add(yandexCluster);

    // Добавляем события на какдый элемент списка
    listItemAll.forEach(listItemEach);

    function listItemPlacemark(listItem) {
      const itemActive = listItem.classList.contains('_active');

      // Широта и долгота
      const dataLat = listItem.getAttribute('data-lat');
      const dataLong = listItem.getAttribute('data-long');

      // Создаем метку
      const yandexPlacemark = new ymaps.Placemark([dataLat, dataLong]);

      yandexPlacemark.events.add("click", () => {
        yandexMap.setCenter([dataLat, dataLong], yandexZoom);
        deactivateAll(listItemAll)
        listItem.classList.add('_active');
        smoothScrollToElement(addressList, listItem);
      })

      if (itemActive) yandexPlacemarkInit = yandexPlacemark;

      // Добавляем метку в массив
      yandexCluster.add(yandexPlacemark);
    }

    function listItemEach(listItem) {
      // Широта и долгота
      const dataLat = listItem.getAttribute('data-lat');
      const dataLong = listItem.getAttribute('data-long');

      // Добавляем событие на элемент
      listItem.addEventListener('click', listItemClick);

      function listItemClick() {
        // Устанавливаем координаты
        yandexMap.setCenter([dataLat, dataLong], yandexZoom);

        // Деактивируем все элементы
        deactivateAll(listItemAll)

        // Активируем текущий
        listItem.classList.add('_active');
      }
    }
  }
}

function deactivateAll(items) {
  items.forEach(item => {
    if (item.classList.contains('_active')) {
      item.classList.remove('_active');
    }
  })
}
