import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {
  selectComment,
  setCurrentStep
} from "../../../../../js/redux/slices/orderingSlice"
import {CommentForm} from "./CommentForm"
import {StepWrapper} from "../../StepWrapper"
import {FilledFieldsWrapper} from "../../FilledFieldsWrapper"
import {Button, EditButton, FilledField} from "../../shared"

export const StepComment = ({isCurrent, isCreating, isMobile = false}) => {
  const dispatch = useDispatch()

  const comment = useSelector(selectComment)

  const [mode, setMode] = useState("edit")

  useEffect(() => {
    setMode(isCurrent || (isCreating && !isMobile) ? "edit" : comment ? "filled" : "blank")
  }, [isCurrent])

  const nextStepHandler = () => {
    if (isMobile) {
      dispatch(setCurrentStep("DETAIL"))
    } else {
      dispatch(setCurrentStep("CREATING"))
    }
  }

  const backStepHandler = () => {
    dispatch(setCurrentStep("PAYMENT"))
  }

  const clickEditHandler = () => {
    dispatch(setCurrentStep("COMMENT"))
  }

  let content = null
  switch (mode) {
    case "filled":
      content = (
        <FilledFieldsWrapper variant={isMobile ? "column" : "row"}>
          <FilledField title={"Комментарий к заказу"} value={comment}/>
        </FilledFieldsWrapper>
      )
      break
    case "edit":
      content = <CommentForm formId={"commentForm"} onSubmit={nextStepHandler}/>
      break
  }

  return (
    <StepWrapper
      title={"Дополнительная информация"}
      number={"5"}
      editBtn={!isCreating && <EditButton title={"Редактировать"} onClick={clickEditHandler}/>}
      backStepBtn={!isCreating && <Button title={"Назад"} variant={"gray"} onClick={backStepHandler}/>}
      nextStepBtn={isMobile ? (
        <Button title={"Далее"} type={"submit"} form={"commentForm"}/>
      ) : (
        <Button title={"Оформить заказ"} type={"submit"} form={"commentForm"} disabled={isCreating}/>
      )}
      isMobile={isMobile}
      mode={mode}
    >
      {content}
    </StepWrapper>
  )
}
