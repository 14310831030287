import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const baseApiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/v2/",
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      'X-CSRFToken': document.querySelector(`input[name='csrfmiddlewaretoken']`).value,
    }
  }),
  endpoints: () => ({})
})
