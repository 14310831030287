import styles from "./ProductPrices.modules.scss"

import React from "react"
import numberWithSpaces from "../../../js/lib/utils";
import cn from "classnames";
import wordByCount from "../../../js/lib/wordByCount";

const ProductPrices = (
  {
    price,
    discountedPrice,
    discountAmount,
    withBonuses,
    bonusesAmount,
    variant = "small",
    isMobile = false,
    className
  }) => {

  const classes = cn(
    styles.root,
    {
      [styles.mobile]: isMobile,
      [styles.large]: variant === "large",
    },
    className
  )

  return (
    <div className={classes}>
      <div className={styles.upper}>
        {price > discountedPrice && (
          <>
            <span className={styles.strike}>{numberWithSpaces(price)} ₽</span>
            <span className={styles.discount}>-{numberWithSpaces(discountAmount)} ₽</span>
          </>
        )}
      </div>
      <div className={styles.bottom}>
        <div className={cn(styles.current, styles.currentRub)}>{numberWithSpaces(discountedPrice)} ₽</div>
        {withBonuses && (
          <div className={styles.bonuses}>
            {`+${numberWithSpaces(bonusesAmount)} ${wordByCount(bonusesAmount, 'бонус', 'бонуса', 'бонусов')}`}
          </div>
        )}
      </div>
    </div>
  )
}

export default ProductPrices