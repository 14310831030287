var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import styles from "./CompareBtn.module.scss";
import cn from "classnames";
import { CompareIcon } from "@shared/ui";
import { useAppDispatch, useAppSelector } from "@src/shared/hooks";
import { useAddCompareProductMutation, useRemoveCompareProductMutation } from "@src/shared/api";
import { selectCompareProducts } from "@src/entities/compare";
import { correct_width } from "../../../../desktop/js/lib/correct-width";
var showErrorModalDeprecated = function (productId, modalType, isDesktop) {
    if (modalType === 'category') {
        if (isDesktop) {
            var overlay_error = document.querySelector('.overlay[data-overlay="error"]');
            if (overlay_error) {
                correct_width(true);
                overlay_error.setAttribute('data-id', String(productId));
                // @ts-ignore
                if (overlay_error.style.display != 'flex') {
                    // @ts-ignore
                    overlay_error.style.display = 'flex';
                }
            }
        }
        else {
            var error = document.querySelector('.error[data-error="compare"]');
            if (error) {
                document.body.style.overflow = 'hidden';
                error.setAttribute('data-id', String(productId));
                if (error.classList.contains('_hidden')) {
                    error.classList.remove('_hidden');
                }
            }
        }
    }
    else if (modalType === "limit") {
        if (isDesktop) {
            var overlay_limit = document.querySelector('.overlay[data-overlay="error-limit"]');
            if (overlay_limit) {
                correct_width(true);
                // @ts-ignore
                if (overlay_limit.style.display != 'flex') {
                    // @ts-ignore
                    overlay_limit.style.display = 'flex';
                }
            }
        }
        else {
            var error = document.querySelector('.error[data-error="compare-limit"]');
            if (error) {
                document.body.style.overflow = 'hidden';
                if (error.classList.contains('_hidden')) {
                    error.classList.remove('_hidden');
                }
            }
        }
    }
};
export var CompareBtn = function (_a) {
    var _b;
    var productId = _a.productId, isDesktop = _a.isDesktop;
    var dispatch = useAppDispatch();
    var addProduct = useAddCompareProductMutation()[0];
    var removeProduct = useRemoveCompareProductMutation()[0];
    var productsIds = useAppSelector(selectCompareProducts);
    var isActive = productsIds.includes(productId);
    var handleClick = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    event.preventDefault();
                    if (!isActive) return [3 /*break*/, 1];
                    removeProduct({ productId: productId });
                    return [3 /*break*/, 4];
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, addProduct({ productId: productId }).unwrap()];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    if (error_1.data.error == 'categoryError') {
                        showErrorModalDeprecated(productId, "category", isDesktop);
                    }
                    if (error_1.data.error == 'exceededLimitError') {
                        showErrorModalDeprecated(productId, "limit", isDesktop);
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx("div", { className: cn(styles.main, (_b = {}, _b[styles.active] = isActive, _b[styles.desktop] = isDesktop, _b)), onClick: handleClick, children: _jsx(CompareIcon, {}) }));
};
