import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { getFromLocalStorage, removeFromHistory, QueriesSection, QueryCategoryItem, QueryItem, clearHistory, } from "@src/entities/search-popup";
import { CloseIcon, HistoryIcon, LinkButton, Text } from "@src/shared/ui";
export var SearchHistorySection = function (_a) {
    var _b = _a.isDesktop, isDesktop = _b === void 0 ? false : _b, onSelectQuery = _a.onSelectQuery, onSelectCatalog = _a.onSelectCatalog, onChanged = _a.onChanged;
    var _c = useState({
        queries: [],
        categories: [],
    }), history = _c[0], setHistory = _c[1];
    var historyIsEmpty = !history.categories.length && !history.queries.length;
    useEffect(function () {
        // Загрузка истории при монтировании компонента
        setHistory(getFromLocalStorage());
        onChanged === null || onChanged === void 0 ? void 0 : onChanged();
    }, []);
    var removeQueryHandler = function (value) {
        removeFromHistory(value, "query");
        setHistory(getFromLocalStorage());
        onChanged === null || onChanged === void 0 ? void 0 : onChanged();
    };
    var removeCatalogHandler = function (value) {
        removeFromHistory(value, "category");
        setHistory(getFromLocalStorage());
        onChanged === null || onChanged === void 0 ? void 0 : onChanged();
    };
    var clearHistoryHandler = function () {
        clearHistory();
        setHistory(getFromLocalStorage());
        onChanged === null || onChanged === void 0 ? void 0 : onChanged();
    };
    var queriesItems = history.queries.slice(0, 5).map(function (item) { return (_jsx(QueryItem, { text: item.text, catalog: item.catalog, slots: {
            startIcon: _jsx(HistoryIcon, {}),
            controlBtnIcon: _jsx(CloseIcon, {}),
        }, isDesktop: isDesktop, onClickQuery: function () { return onSelectQuery(item); }, onClickControl: function () { return removeQueryHandler(item); } }, item.text)); });
    var catalogsItems = history.categories
        .slice(0, 5)
        .map(function (item, index) { return (_jsx(QueryCategoryItem, { name: item.name, imageSrc: item.image, isDesktop: isDesktop, onClick: function () { return onSelectCatalog(item); }, onClickClose: function () { return removeCatalogHandler(item); } }, index)); });
    if (historyIsEmpty)
        return null;
    return (_jsx(QueriesSection, { title: "\u0418\u0441\u0442\u043E\u0440\u0438\u044F \u0437\u0430\u043F\u0440\u043E\u0441\u043E\u0432", slots: {
            queriesItems: queriesItems,
            catalogsItems: catalogsItems,
            controlBtn: (_jsx(LinkButton, { Component: "button", onClick: clearHistoryHandler, children: _jsx(Text, { variant: isDesktop ? "buttonMedium" : "buttonSmall", color: "#0B78F8", children: "\u041E\u0447\u0438\u0441\u0442\u0438\u0442\u044C" }) })),
        }, isDesktop: isDesktop }));
};
