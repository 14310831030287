const fixed = document.querySelector('.fixed');

if (fixed) {
  const fixed_reverse = fixed.querySelector('.fixed-reverse');
  const fixed_message = fixed.querySelector('.fixed-message');

  let scroll_verify = false;

  // Добовляем события на кнопку вверх
  const reverse_button = fixed_reverse.querySelector('.reverse-button');

  reverse_button.addEventListener('click', reverse_button_click);

  // Добовляем события на кнопку мессенджера
  const message_button = fixed_message.querySelector('.message-button');

  message_button.addEventListener('click', message_button_click);

  // Добавляем события на мессенджер
  const message_overlay = fixed_message.querySelector('.message-overlay');

  const indicate_close = message_overlay.querySelector('.indicate_close');

  indicate_close.addEventListener('click', indicate_close_click);

  const overlay_control = message_overlay.querySelector('.overlay-control');

  const control_unit = overlay_control.querySelector('.control-unit');
  const control_button = overlay_control.querySelector('.control-button');

  control_unit.addEventListener('input', control_unit_input);

  // Добовляем событие по управлению состоянием кнопки вверх
  document.addEventListener('scroll', document_scroll);

  // Основные функции
  function reverse_button_click() {
    window.scrollTo(
      {
        top: 0,
        behavior: 'smooth'
      }
    );
  }

  function message_button_click() {
    message_button.style.opacity = '0';

    message_button.addEventListener('transitionend', message_button_transitionend, { once: true });
  }

  function indicate_close_click() {
    message_overlay.style.opacity = '0';

    message_overlay.addEventListener('transitionend', message_overlay_transitionend, { once: true });
  }

  function control_unit_input() {
    control_unit.style.height = 'auto';

    // Изменяем размер текстового поля в зависимости от размера текста
    control_unit.style.height = `${ control_unit.scrollHeight }px`;
  }

  function document_scroll() {
    if (window.scrollY) {
      if (reverse_button.style.display != 'flex') {
        reverse_button.style.display = 'flex';

        setTimeout(() => reverse_button.style.opacity = '1', 20);
      }
    } else {
      if (!scroll_verify) {
        if (reverse_button.style.display != 'none') {
          scroll_verify = true;

          reverse_button.style.opacity = '0';

          reverse_button.addEventListener('transitionend', reverse_button_transitionend, { once: true });
        }
      }
    }
  }

  // Вспомогательные функции
  function reverse_button_transitionend() {
    reverse_button.style.display = 'none';

    scroll_verify = false;
  }

  function message_button_transitionend() {
    message_button.style.display = 'none';

    message_overlay.style.display = 'block';

    setTimeout(() => message_overlay.style.opacity = '1', 20);
  }

  function message_overlay_transitionend() {
    message_overlay.style.display = 'none';

    message_button.style.display = 'flex';

    setTimeout(() => message_button.style.opacity = '1', 20);
  }
}
