import styles from "./ThumbsSwiperWrapper.modules.scss"

import React from "react"
import {Swiper, SwiperSlide} from "swiper/react"
import {FreeMode} from "swiper/modules"
import cn from "classnames"

const ThumbsSwiperWrapper = ({children, activeSlideIndex = 0, setThumbsSwiper, setActiveSlide}) => {

  return (
    <Swiper
      slidesPerView={1000}
      direction={"vertical"}
      onSwiper={setThumbsSwiper}
      className={styles.swiper}
      watchSlidesProgress={true}
      freeMode={true}
      onClick={(swiper, event) => {
        swiper.activeIndex = swiper.clickedIndex
        setActiveSlide(swiper.activeIndex)
      }}
      modules={[FreeMode]}
    >
      {React.Children.map(children, (child, index) => (
        <SwiperSlide key={child.key} className={cn(styles.slide, index === activeSlideIndex && styles.active)}>
          {child}
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default ThumbsSwiperWrapper