import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./Mobile.module.scss";
import { useState } from "react";
import cn from "classnames";
import { FirstStageForm, SecondStageForm } from "./form";
import { useToggleBodyOverflow, useAppDispatch } from "@shared/hooks";
import { ArrowDownIcon, CloseIcon, Text } from "@shared/ui";
import { setActivePopup } from "@entities/popup";
export var Mobile = function (_a) {
    var _b;
    var isOpen = _a.isOpen, title = _a.title;
    useToggleBodyOverflow(isOpen);
    var _c = useState("first"), stage = _c[0], setStage = _c[1];
    var _d = useState(null), firstStageData = _d[0], setFirstStageData = _d[1];
    var _e = useState(null), secondStageData = _e[0], setSecondStageData = _e[1];
    var dispatch = useAppDispatch();
    var handleCloseModal = function () { return dispatch(setActivePopup("")); };
    var handleApproveFirstStage = function (data) {
        setFirstStageData(data);
        setStage("second");
    };
    var handleChangeSecondStage = function (data) {
        setSecondStageData(data);
    };
    var handleSetFirstStage = function () { return setStage("first"); };
    var currentTitle = title ? (_jsx(Text, { variant: "h3", className: styles.title, children: title })) : (_jsx(Text, { variant: "h3", children: "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0430\u0446\u0438\u044F" }));
    var firstStageHeaderContent = stage === "first" && currentTitle;
    var secondStageHeaderContent = stage === "second" && (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.prevBtn, onClick: handleSetFirstStage, children: _jsx(ArrowDownIcon, {}) }), _jsx(Text, { variant: "h3", children: "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0430\u0446\u0438\u044F" })] }));
    return (_jsxs("div", { className: cn(styles.main, (_b = {}, _b[styles.open] = isOpen, _b)), children: [_jsx("div", { className: styles.overlay, onClick: handleCloseModal }), _jsxs("div", { className: styles.modal, children: [_jsxs("div", { className: styles.header, children: [firstStageHeaderContent, secondStageHeaderContent, _jsx("div", { className: styles.closeBtn, onClick: handleCloseModal, children: _jsx(CloseIcon, {}) })] }), stage === "first" && (_jsx(FirstStageForm, { initialData: firstStageData, onSubmit: handleApproveFirstStage })), stage === "second" && (_jsx(SecondStageForm, { firstStageData: firstStageData, initialData: secondStageData, onChange: handleChangeSecondStage }))] })] }));
};
