import styles from "./styles.modules.scss"

import React from "react"
import cn from "classnames"

export const FormWrapper = ({title, children, className}) => (
  <div className={cn(styles.root, className)}>
    <span className={styles.title}>{title}</span>
    {children}
  </div>
)
