var _a;
import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/dist/query/react";
export var baseApi = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/v2/",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": (_a = (document.querySelector("input[name='csrfmiddlewaretoken']") || null)) === null || _a === void 0 ? void 0 : _a.value,
        },
    }),
    endpoints: function () { return ({}); },
});
