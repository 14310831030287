import { correct_width } from '../../lib/correct-width';

const button_comment = document.querySelector('button[data-overlay="comment"]');

if (button_comment) button_comment.addEventListener('click', button_comment_click);

function button_comment_click() {
  const overlay_comment = document.querySelector('.overlay[data-overlay="comment"]');

  if (overlay_comment && overlay_comment.style.display != 'flex') {
    overlay_comment.style.display = 'flex';

    correct_width(true);
  }
}
