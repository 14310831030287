import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./Desktop.module.scss";
import { CatalogButton } from "./CatalogButton";
import { SearchField } from "./SearchField";
import logoImg from "@src/assets/images/logo.png";
import { AuthNavButton } from "@src/features/AuthNavButton";
import { CartNavButton } from "@src/features/CartNavButton";
import { CompareNavButton } from "@src/features/CompareNavButton";
import { FavoritesNavButton } from "@src/features/FavoritesNavButton";
export var Desktop = function (_a) {
    var slots = _a.slots;
    return (_jsxs("div", { className: styles.root, children: [_jsx("a", { className: styles.logoLink, href: "/", children: _jsx("img", { src: logoImg, alt: "", width: 150, height: 42 }) }), _jsx(CatalogButton, {}), !!(slots === null || slots === void 0 ? void 0 : slots.searchPopup) && _jsx(SearchField, { popup: slots.searchPopup }), _jsxs("div", { className: styles.userNavbar, children: [_jsx(AuthNavButton, {}), _jsx(CompareNavButton, { popupOffset: -194 }), _jsx(FavoritesNavButton, { popupOffset: -109 }), _jsx(CartNavButton, { popupOffset: -24 })] })] }));
};
