import { baseApi } from "./base";
export var authApi = baseApi.injectEndpoints({
    endpoints: function (build) { return ({
        login: build.mutation({
            query: function (_a) {
                var username = _a.username, password = _a.password;
                return ({
                    url: "/login/",
                    method: "POST",
                    body: {
                        username: username,
                        password: password,
                    },
                });
            },
            invalidatesTags: [],
        }),
        register: build.mutation({
            query: function (_a) {
                var firstName = _a.firstName, lastName = _a.lastName, email = _a.email, phone = _a.phone, password = _a.password;
                return ({
                    url: "/register/",
                    method: "POST",
                    body: {
                        first_name: firstName,
                        last_name: lastName,
                        email: email,
                        phone: phone,
                        password: password,
                    },
                });
            },
        }),
        restoreStart: build.mutation({
            query: function (_a) {
                var phone = _a.phone;
                return ({
                    url: "/restore/start/",
                    method: "POST",
                    body: {
                        phone: phone,
                    },
                });
            },
        }),
        restoreProcess: build.mutation({
            query: function (_a) {
                var sessionKey = _a.sessionKey, code = _a.code;
                return ({
                    url: "/restore/process/",
                    method: "POST",
                    body: {
                        session_key: sessionKey,
                        code: code,
                    },
                });
            },
        }),
        resetPassword: build.mutation({
            query: function (_a) {
                var sessionKey = _a.sessionKey, password = _a.password;
                return ({
                    url: "/restore/reset/",
                    method: "POST",
                    body: {
                        session_key: sessionKey,
                        password: password,
                    },
                });
            },
        }),
    }); },
});
export var useLoginMutation = authApi.useLoginMutation, useRegisterMutation = authApi.useRegisterMutation, useRestoreStartMutation = authApi.useRestoreStartMutation, useRestoreProcessMutation = authApi.useRestoreProcessMutation, useResetPasswordMutation = authApi.useResetPasswordMutation;
