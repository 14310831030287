import {store} from "@src/app/store";
import {sendAddProductToCartYM, sendRemoveProductFromCartYM} from "./yandex";
import {cartApi} from "@src/shared/api";

export const addCartProductWithYM  = async ({productId, quantity = 1, services = undefined}) => {
  const response = await store.dispatch(cartApi.endpoints.addCartProduct.initiate({productId, quantity, services}))
  sendAddProductToCartYM(response.data.result.add_products)
  return response
}

export const removeCartProductWithYM = async ({productId}) => {
  const response = await store.dispatch(cartApi.endpoints.removeCartProduct.initiate({productId}))
  sendRemoveProductFromCartYM(response.data.result.delete_products)
  return response
}

export const clearCartWithYM = async () => {
  const response = await store.dispatch(cartApi.endpoints.clearCart.initiate())
  sendRemoveProductFromCartYM(response.data.result.delete_products)
  return response
}