import { jsx as _jsx } from "react/jsx-runtime";
import { setActivePopup } from "@src/entities/popup";
import { useGetMeQuery } from "@src/shared/api";
import { useAppDispatch } from "@src/shared/hooks";
import { ButtonWithPopup, ManIcon } from "@src/shared/ui";
export var AuthNavButton = function () {
    var dispatch = useAppDispatch();
    var customer = useGetMeQuery().data;
    var openSignInPopupHandler = function () {
        dispatch(setActivePopup("signIn"));
    };
    if (!(customer === null || customer === void 0 ? void 0 : customer.data)) {
        return (_jsx(ButtonWithPopup, { text: "\u0412\u0445\u043E\u0434", icon: _jsx(ManIcon, {}), onClick: openSignInPopupHandler }));
    }
    return (_jsx(ButtonWithPopup, { text: customer.data.first_name, href: "/account/profile/", icon: _jsx(ManIcon, {}) }));
};
