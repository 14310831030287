import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import { InputFilterField } from "../../InputFilterField";
export var RangeInputFields = function (_a) {
    var min = _a.min, max = _a.max, values = _a.values, onChange = _a.onChange;
    var _b = useState((values === null || values === void 0 ? void 0 : values[0]) || null), minValue = _b[0], setMinValue = _b[1];
    var _c = useState((values === null || values === void 0 ? void 0 : values[1]) || null), maxValue = _c[0], setMaxValue = _c[1];
    useEffect(function () {
        setMinValue((values === null || values === void 0 ? void 0 : values[0]) || null);
        setMaxValue((values === null || values === void 0 ? void 0 : values[1]) || null);
    }, [values]);
    var minChangeHandler = function (value) {
        var newValue = value ? Number(value) : null;
        setMinValue(newValue);
        if (value || maxValue) {
            onChange === null || onChange === void 0 ? void 0 : onChange([newValue, maxValue || null]);
        }
        else {
            onChange === null || onChange === void 0 ? void 0 : onChange(null);
        }
    };
    var maxChangeHandler = function (value) {
        var newValue = value ? Number(value) : null;
        setMaxValue(newValue);
        if (value || minValue) {
            onChange === null || onChange === void 0 ? void 0 : onChange([minValue || null, newValue]);
        }
        else {
            onChange === null || onChange === void 0 ? void 0 : onChange(null);
        }
    };
    var minBlurHandler = function () {
        var rightValue = minValue;
        if (minValue) {
            rightValue = Math.min(Math.max(Number(minValue), min), max);
        }
        if (minValue && maxValue && minValue > maxValue) {
            rightValue = maxValue;
        }
        setMinValue(rightValue);
        if (rightValue || maxValue) {
            onChange === null || onChange === void 0 ? void 0 : onChange([rightValue || null, maxValue || null]);
        }
        else {
            onChange === null || onChange === void 0 ? void 0 : onChange(null);
        }
    };
    var maxBlurHandler = function () {
        var rightValue = maxValue;
        if (maxValue) {
            rightValue = Math.min(Math.max(Number(maxValue), min), max);
        }
        if (minValue && maxValue && maxValue < minValue) {
            rightValue = minValue;
        }
        setMaxValue(rightValue);
        if (minValue || maxValue) {
            onChange === null || onChange === void 0 ? void 0 : onChange([minValue || null, rightValue || null]);
        }
        else {
            onChange === null || onChange === void 0 ? void 0 : onChange(null);
        }
    };
    return (_jsxs("div", { className: styles.root, children: [_jsx(InputFilterField, { placeholder: String(min), type: "number", value: minValue || "", onChangeText: minChangeHandler, onBlurCustom: minBlurHandler }), _jsx(InputFilterField, { placeholder: String(max), type: "number", value: maxValue || "", onChangeText: maxChangeHandler, onBlurCustom: maxBlurHandler })] }));
};
