import styles from "./BigProductGallery.modules.scss"

import React, {useState} from "react"

import ProductTags from "../ProductTags"
import SwiperWrapper from "../SwiperWrapper"
import ThumbsSwiperWrapper from "../ThumbsSwiperWrapper"
import ProductPrices from "../ProductPrices"
import CartButtonContainer from "../CartButton/CartButtonContainer"
import Rating from "../Rating"
import Image from "../Image"
import wordByCount from "../../../js/lib/wordByCount"

const BigProductGallery = ({product}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const [activeSlide, setActiveSlide] = useState(0)

  return (
    <div className={styles.root}>
      <div className={styles.productInfo}>
        <div className={styles.topInfo}>
          <div className={styles.ratingLayer}>
            {product.rating > 0 && (
              <>
                <Rating rating={product.rating} />
                <div className={styles.reviewsCount}>{product.reviews_count} {wordByCount(product.reviews_count, "отзыв", "отзыва", "отзывов")}</div>
              </>
            )}
          </div>
          <span className={styles.sku}>Артикул: {product.sku}</span>
        </div>
        <h2 className={styles.title}>{product.name}</h2>
        {product.color_tags.length > 0 && (
          <ProductTags tags={product.color_tags}/>
        )}
      </div>
      <div></div>
      <div className={styles.gallery}>
        <SwiperWrapper
          slidesPerView={1}
          slidesPerGroup={1}
          thumbsSwiper={thumbsSwiper}
          activeSlideIndex={activeSlide}
          setActiveSlide={setActiveSlide}
          className={styles.swiper}
        >
          {product.images.map(productImage => (
            <Image key={productImage.original.image} src={productImage.original.image} alt={product.name}/>
          ))}
        </SwiperWrapper>
      </div>
      <div className={styles.previewsLayer}>
        <ThumbsSwiperWrapper
          activeSlideIndex={activeSlide}
          setActiveSlide={setActiveSlide}
          setThumbsSwiper={setThumbsSwiper}
        >
          {product.images.map(productImage => (
            <Image key={productImage.desktop.preview.image} src={productImage.desktop.preview.image} alt={product.name}/>
          ))}
        </ThumbsSwiperWrapper>
      </div>
      <div className={styles.infoControl}>
        <ProductPrices
          price={product.price}
          discountedPrice={product.discounted_price}
          discountAmount={product.discount_amount}
          withBonuses={!!product.bonuses}
          bonusesAmount={product.bonuses_amount}
          variant={"large"}
        />
        <div className={styles.status}>
          <svg width="6" height="6" viewBox="0 0 6 6" fill="transparent" xmlns="http://www.w3.org/2000/svg">
            <circle cx="3" cy="3" r="3" fill={product.status.color}></circle>
          </svg>
          <span className={styles.text}>{product.status.name}</span>
        </div>
        <div className={styles.controlButtons}>
          {/*<Button active={true} size={"large"} className={styles.oneClickBtn} onClick={oneClickButtonHandler}>Купить в 1 клик</Button>*/}
          <CartButtonContainer
            productId={product.id}
            disabled={![1, 2].includes(product.status.id)}
            size={"large"}
          />
        </div>
      </div>
    </div>
  )
}

export default BigProductGallery