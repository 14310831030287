import '../sass/app.scss';
// Вспомогательные скрипты
import './utils';
// Основные скрипты
import './app-json';
import './app-brief';
import './app-header';
// Скрипты отдельных копонентов
import './components/pattern/content-control';
import './components/pattern/content-select';
import './components/pattern/verify';
import './components/useful/rich';
import './components/useful/social';
import './components/overlay';
import './components/overlay/review';
import './components/overlay/comment';
import './components/overlay/error';
import './components/overlay/error-limit';
import './components/promotion';
import './components/section';
import './components/fixed';
import './components/inset';
import './components/list';
import './components/spoiler';
// Скрипты отдельных страниц
import './pages/category/category-detail';
import './pages/category/category-filter';
import './pages/category/category-group';
import './pages/sale/sale-filter';
import './pages/product/product-group';
import './pages/product/product-content';
import './pages/product/product-control';
import './pages/product/product-inset';
import './pages/product/add-products-render';
import './pages/product/big-gallery-overlay';
import './pages/product/buy-one-click-render';
import './pages/product/send_ym';
import '../jsx/pages/compare';
import './pages/ordering';
import './pages/cart-page';
import './pages/auth/signup';
import './pages/auth/signin';
import './pages/account/personal';
import './pages/address';
import './pages/service';
// Скрипты отдельных юнитов
import './units/product-unit';
import './units/offer-unit';
import './pages/events/tekhno';
import './pages/events/tekhno/checks';
import { initializeApp } from "../../common/js/lib/initializeApp";
initializeApp(true);
