import styles from "./styles.modules.scss"

import React, {useState, useEffect} from "react"
import cn from "classnames"

const CheckIcon = ({active = false}) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    {active ? (
      <>
        <rect width="16" height="16" rx="2" fill="#EF4123"/>
        <circle cx="8" cy="8" r="4" fill="white"/>
      </>
    ) : (
      <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#EF4123"/>
    )}
  </svg>
)

export const Checkbox = ({label = null, checked = false, isError = false, onChange, className}) => {
  const [currentChecked, setCurrentChecked] = useState(checked)

  useEffect(() => {
    setCurrentChecked(checked)
  }, [checked])

  const toggleCheckedHandler = () => {
    setCurrentChecked(!currentChecked)
    if (onChange) onChange(!currentChecked)
  }

  return (
    <div className={cn(styles.root, isError && styles.error, className)} onClick={toggleCheckedHandler}>
      <CheckIcon active={currentChecked}/>
      {label && <span className={styles.label}>{label}</span>}
    </div>
  )
}
