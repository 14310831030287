import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./ButtonWithPopup.module.scss";
import { useEffect, useState } from "react";
import cn from "classnames";
export var ButtonWithPopup = function (_a) {
    var text = _a.text, icon = _a.icon, href = _a.href, _b = _a.isShowPopup, isShowPopup = _b === void 0 ? false : _b, count = _a.count, _c = _a.isDisable, isDisable = _c === void 0 ? false : _c, popup = _a.popup, _d = _a.popupOffset, popupOffset = _d === void 0 ? 0 : _d, onClick = _a.onClick;
    var _e = useState(false), isCurrentShowPopup = _e[0], setIsCurrentShowPopup = _e[1];
    var _f = useState(null), timerId = _f[0], setTimerId = _f[1];
    useEffect(function () {
        if (isShowPopup) {
            setIsCurrentShowPopup(isShowPopup);
            if (timerId)
                clearTimeout(timerId);
            setTimerId(setTimeout(function () {
                setIsCurrentShowPopup(false);
            }, 3000));
        }
    }, [isShowPopup]);
    var onMouseOverHandler = function () {
        if (timerId)
            clearTimeout(timerId);
        setTimerId(setTimeout(function () {
            setIsCurrentShowPopup(true);
        }, 100));
    };
    var onMouseOutHandler = function () {
        setTimerId(setTimeout(function () {
            setIsCurrentShowPopup(false);
        }, 300));
    };
    var clickHandler = function () {
        if (isDisable)
            return;
        if (onClick)
            onClick();
        if (href)
            window.location.href = href;
    };
    return (_jsxs("div", { className: styles.root, onMouseEnter: onMouseOverHandler, onMouseLeave: onMouseOutHandler, children: [_jsxs("button", { type: "button", className: cn(styles.button, isDisable && styles.disabled), onClick: clickHandler, children: [_jsx("div", { className: styles.icon, children: icon }), _jsx("span", { className: styles.title, children: text }), !!count && _jsx("span", { className: styles.count, children: count })] }), popup && (_jsx("div", { className: cn(styles.popupOverlay, isCurrentShowPopup && styles.show), style: { right: popupOffset }, children: _jsx("div", { className: styles.popupWrapper, children: popup }) }))] }));
};
