var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./SmallButton.module.scss";
import cn from "classnames";
import { Text } from "../Text";
export var SmallButton = function (_a) {
    var _b;
    var _c = _a.Component, Component = _c === void 0 ? "button" : _c, text = _a.text, icon = _a.icon, href = _a.href, className = _a.className, _d = _a.isActive, isActive = _d === void 0 ? false : _d, _e = _a.isDisabled, isDisabled = _e === void 0 ? false : _e, rest = __rest(_a, ["Component", "text", "icon", "href", "className", "isActive", "isDisabled"]);
    var classes = cn(styles.root, className, (_b = {},
        _b[styles.disabled] = isDisabled,
        _b[styles.active] = isActive,
        _b));
    return (_jsxs(Component, __assign({ className: classes, href: href }, rest, { children: [!!icon && _jsx("div", { className: styles.icon, children: icon }), !!text && (_jsx(Text, { className: styles.text, variant: "buttonSmall", children: text }))] })));
};
