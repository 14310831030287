import styles from "./styles.modules.scss"

import React from "react"
import cn from "classnames"

export const SelectMethod = ({options, selected, onSelect, isMobile = false}) => {

  return (
    <div className={cn(styles.root, isMobile && styles.mobile)}>
      {options.map(option => (
        <button
          key={option.value}
          className={cn(
            styles.methodBtn,
            selected?.value === option.value && styles.active,
            !option.active && styles.disable
          )}
          onClick={() => onSelect(option.value)}
          disabled={!option.active}
        >
          {option.title}
        </button>
      ))}
    </div>
  )
}
