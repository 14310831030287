var _a;
import { createSlice } from "@reduxjs/toolkit";
var initialState = {
    isShow: false,
    query: "",
};
export var searchPopupSlice = createSlice({
    name: "searchPopup",
    initialState: initialState,
    reducers: {
        setShowSearchPopup: function (state, action) {
            state.isShow = action.payload;
        },
        setSearchQuery: function (state, action) {
            state.query = action.payload;
        },
    },
});
export var selectIsShowSearchPopup = function (state) {
    return state.searchPopup.isShow;
};
export var selectSearchQuery = function (state) { return state.searchPopup.query; };
export var setShowSearchPopup = (_a = searchPopupSlice.actions, _a.setShowSearchPopup), setSearchQuery = _a.setSearchQuery;
