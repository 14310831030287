import styles from "./AddProductsSection.modules.scss"

import React, {useEffect, useState} from "react"
import {Provider, useDispatch, useSelector} from "react-redux"

import {store} from "@src/app/store"
import {
  addSelectedProduct,
  clearSelectedProduct,
  fetchAddProducts,
  removeSelectedProduct,
  selectAdditionalProducts,
  selectAdditionalProductsCategories,
  selectProductData,
  selectSelectedProducts,
  selectSelectedProductsIds
} from "../../../js/redux/slices/productSlice"
import ProductsSection from "../ProductsSection"
import ProductPrices from "../../../../common/jsx/components/ProductPrices"
import TrashIcon from "../icons/Trash"
import numberWithSpaces from "../../../js/lib/utils"
import {arraySum} from "../../../js/lib/utils"
import CartButton from "../CartButton"
import cn from "classnames"
import {addCartProductWithYM} from "../../../js/lib/cart";
import {ProductsSectionSkeleton} from "../ProductsSection/ProductsSectionSkeleton"

const AddProductsSection = ({isMobile = false}) => {
  const dispatch = useDispatch()

  const [category, setCategory] = useState(null)

  const product = useSelector(selectProductData)
  const additionalProducts = useSelector(selectAdditionalProducts(category))
  const additionalProductsCategories = useSelector(selectAdditionalProductsCategories)
  const selectedProductsIds = useSelector(selectSelectedProductsIds)
  const selectedProducts = useSelector(selectSelectedProducts)

  const additionalProductsStatus = useSelector(state => state.product.status)

  const getTotalPrice = () => {
    let total = 0
    if (product) {
      total = product.price + arraySum(selectedProducts.map(p => p.price))
    }
    return total
  }

  const getTotalDiscountedPrice = () => {
    let total = 0
    if (product) {
      total = product.discounted_price + arraySum(selectedProducts.map(p => p.discounted_price))
    }
    return total
  }

  const getTotalBonuses = () => {
    let total = 0
    if (product) {
      if (product.bonuses) {
        total = product.bonuses_amount
      }
      total += arraySum(selectedProducts.map(p => {
        if (p.bonuses) {
          return p.bonuses_amount
        } else {
          return 0
        }
      }))
    }
    return total
  }

  const totalPrice = getTotalPrice()
  const totalDiscountedPrice = getTotalDiscountedPrice()
  const totalBonuses = getTotalBonuses()

  useEffect(() => {
    if (product && additionalProductsStatus === "idle") {
      async function fetchData() {
        dispatch(fetchAddProducts({productSlug: product.slug}))
      }
      fetchData()
    }
  }, [dispatch, product, additionalProductsStatus])

  const handlerSelectCategory = (categorySlug) => {
    setCategory(categorySlug)
  }

  const toggleAddProductHandler = (productId) => {
    if (selectedProductsIds.includes(productId)) {
      dispatch(removeSelectedProduct(productId))
    } else {
      dispatch(addSelectedProduct(productId))
    }
  }

  const handlerAddToCartClick = async () => {
    dispatch(clearSelectedProduct())
    await addCartProductWithYM({productId: product.id})
    for (let productId of selectedProductsIds) {
      setTimeout(async () => {
        await addCartProductWithYM({productId})
      }, 0)
    }
  }

  const classes = cn(
    styles.root,
    {
      [styles.mobile]: isMobile
    }
  )

  return (
    <div className={classes}>
      <div className={cn(styles.sectionLayer, 'container')}>
        <div className={styles.section}>
          {additionalProductsStatus !== "succeeded" ? (
            <ProductsSectionSkeleton title={"С этим товаром покупают"} isMobile={isMobile}/>
          ) : (
            <>
              <ProductsSection
                title={"С этим товаром покупают"}
                products={additionalProducts}
                categories={additionalProductsCategories}
                addedProducts={selectedProductsIds}
                onChangeCategory={handlerSelectCategory}
                toggleAddProduct={toggleAddProductHandler}
                isMobile={isMobile}
              />
              {(product && selectedProducts.length > 0) && (
                <div className={styles.selectedLayer}>
                  <div className={styles.content}>
                    <h2 className={styles.title}>{product.name}</h2>
                    <div className={styles.list}>
                      {selectedProducts.map(product => (
                        <div key={product.id} className={styles.item}>
                          <div className={styles.info}>
                            <span className={styles.name}>{product.name}</span>
                            <span className={styles.price}>{numberWithSpaces(product.discounted_price)} ₽</span>
                          </div>
                          <button className={styles.deleteItemBtn} onClick={() => toggleAddProductHandler(product.id)}>
                            <TrashIcon width={24} height={24}/>
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={styles.control}>
                    <ProductPrices
                      price={totalPrice}
                      discountedPrice={totalDiscountedPrice}
                      discountAmount={totalPrice - totalDiscountedPrice}
                      withBonuses={!!totalBonuses}
                      bonusesAmount={totalBonuses}
                      variant={"large"}
                      isMobile={isMobile}
                      className={styles.prices}
                    />
                    <div className={styles.buttons}>
                      <CartButton isAdd={false} onClick={handlerAddToCartClick} size={"large"}/>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default (props) => (
  <Provider store={store}>
    <AddProductsSection {...props}/>
  </Provider>
)
