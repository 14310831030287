var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./SecondStage.module.scss";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { EMAIL_REGEX, PHONE_REGEX } from "@shared/lib";
import { Button, Checkbox, InputField, Text } from "@shared/ui";
import { useRegisterMutation } from "@src/shared/api";
export var SecondStageForm = function (_a) {
    var firstStageData = _a.firstStageData, initialData = _a.initialData, onChange = _a.onChange;
    var register = useRegisterMutation()[0];
    var _b = useForm({
        mode: "onSubmit",
        defaultValues: __assign({ phone: "", email: "", password: "", agreement: false }, initialData),
    }), control = _b.control, setError = _b.setError, handleSubmit = _b.handleSubmit, watch = _b.watch, _c = _b.formState, errors = _c.errors, isValid = _c.isValid;
    useEffect(function () {
        var subscription = watch(function (value) {
            var _a = value.phone, phone = _a === void 0 ? "" : _a, _b = value.email, email = _b === void 0 ? "" : _b, _c = value.password, password = _c === void 0 ? "" : _c, _d = value.agreement, agreement = _d === void 0 ? false : _d;
            var cleanedValue = { phone: phone, email: email, password: password, agreement: agreement };
            if (onChange) {
                onChange(cleanedValue);
            }
        });
        return function () { return subscription.unsubscribe(); };
    }, [onChange, watch]);
    var submitHandler = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var regData, res, errorData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    regData = __assign(__assign({}, firstStageData), data);
                    return [4 /*yield*/, register({
                            firstName: regData.name,
                            lastName: regData.surname,
                            email: regData.email,
                            phone: regData.phone,
                            password: regData.password
                        })];
                case 1:
                    res = _a.sent();
                    if ('error' in res) {
                        if ('data' in res.error) {
                            errorData = res.error.data;
                            console.error("Ошибка при регистрации:", errorData);
                            setError("email", { message: errorData.error });
                            setError("phone", { message: errorData.error });
                        }
                    }
                    else {
                        console.log("Успешный вход:", res.data);
                        window.location.reload();
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var showError = function () {
        var _a;
        var errorName = Object.keys(errors)[0];
        if (Object.keys(errors).length > 0)
            return (_a = errors[errorName]) === null || _a === void 0 ? void 0 : _a.message;
        return false;
    };
    return (_jsxs("form", { className: styles.main, onSubmit: handleSubmit(submitHandler), children: [Object.keys(errors).length > 0 && (_jsx(Text, { variant: "body1", className: styles.error, children: showError() })), _jsx(Controller, { control: control, name: "phone", render: function (_a) {
                    var _b;
                    var _c = _a.field, onChange = _c.onChange, value = _c.value;
                    return (_jsx(InputField, { variant: "secondary", placeholder: "\u0422\u0435\u043B\u0435\u0444\u043E\u043D *", type: "mask", mask: "+7 (999) 999-99-99", value: value, onChange: onChange, errorText: (_b = errors.phone) === null || _b === void 0 ? void 0 : _b.message }));
                }, rules: {
                    required: {
                        value: true,
                        message: 'Поле "Телефон является обязательным',
                    },
                    pattern: {
                        value: PHONE_REGEX,
                        message: "Введите корректный номер телефона",
                    },
                } }), _jsx(Controller, { control: control, name: "email", render: function (_a) {
                    var _b;
                    var _c = _a.field, onChange = _c.onChange, value = _c.value;
                    return (_jsx(InputField, { variant: "secondary", placeholder: "E-mail *", errorText: (_b = errors.email) === null || _b === void 0 ? void 0 : _b.message, value: value, onChange: onChange }));
                }, rules: {
                    required: {
                        value: true,
                        message: 'Поле "E-mail" является обязательным',
                    },
                    pattern: {
                        value: EMAIL_REGEX,
                        message: "Введите корректный адрес электронной почты",
                    },
                } }), _jsx(Controller, { control: control, name: "password", render: function (_a) {
                    var _b;
                    var _c = _a.field, onChange = _c.onChange, value = _c.value;
                    return (_jsx(InputField, { variant: "secondary", placeholder: "\u041F\u0430\u0440\u043E\u043B\u044C *", type: "password", value: value, onChange: onChange, errorText: (_b = errors.password) === null || _b === void 0 ? void 0 : _b.message }));
                }, rules: {
                    required: {
                        value: true,
                        message: 'Поле "Пароль" является обязательным',
                    },
                } }), _jsx(Text, { variant: "body2", className: styles.agreementText, children: "\u0412\u044B \u0430\u0432\u0442\u043E\u043C\u0430\u0442\u0438\u0447\u0435\u0441\u043A\u0438 \u0441\u043E\u0433\u043B\u0430\u0448\u0430\u0435\u0442\u0435\u0441\u044C \u043D\u0430 \u043E\u0431\u0440\u0430\u0431\u043E\u0442\u043A\u0443 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445 \u0438 \u043F\u0440\u0438\u043D\u0438\u043C\u0430\u0435\u0442\u0435 \u0443\u0441\u043B\u043E\u0432\u0438\u044F \u041F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044C\u0441\u043A\u0438\u0445 \u0441\u043E\u0433\u043B\u0430\u0448\u0435\u043D\u0438\u0439" }), _jsx(Controller, { control: control, name: "agreement", render: function (_a) {
                    var _b;
                    var _c = _a.field, onChange = _c.onChange, value = _c.value;
                    return (_jsx(Checkbox, { checked: value, onChange: onChange, label: "\u042F \u0441\u043E\u0433\u043B\u0430\u0448\u0430\u044E\u0441\u044C \u0441 \u0443\u0441\u043B\u043E\u0432\u0438\u044F\u043C\u0438", error: Boolean((_b = errors.agreement) === null || _b === void 0 ? void 0 : _b.message) }));
                }, rules: {
                    required: {
                        value: true,
                        message: 'Поле "Я соглашаюсь с условиями" является обязательным',
                    },
                } }), _jsx(Button, { type: "submit", variant: "primary", isDisabled: !isValid, children: "\u0417\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043E\u0432\u0430\u0442\u044C\u0441\u044F" })] }));
};
