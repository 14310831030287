const verify_all = document.querySelectorAll('form[data-verify]');

if (verify_all.length) verify_all.forEach(verify_each);

function verify_each(verify) {
  // Получаем все поля ввода и добавляем на них события валидации
  const content_control_all = verify.querySelectorAll('.content-control[data-validate]');
  content_control_all.forEach((content_control) => content_control.addEventListener('blur', control_button_verify));

  // Получаем кнопку отправки формы и вешаем на нее событие валидации
  const control_button = verify.querySelector('.control-button[type="submit"]');
  control_button.addEventListener('click', control_button_click);

  control_button_verify();

  function control_button_click(event) {
    content_control_all.forEach(content_control_each);

    const control_validate_all = verify.querySelectorAll('.content-control[data-validate="true"]');

    if (control_validate_all.length != content_control_all.length) event.preventDefault();

    function content_control_each(content_control) {
      const control_name = content_control.name;
      const control_placeholder = content_control.placeholder;

      const control_validate = content_control.getAttribute('data-validate');

      if (control_name == 'advantage') control_validate == 'false' ? content_control_alert('* Введите преимущества') : null;
      if (control_name == 'disadvantage') control_validate == 'false' ? content_control_alert('* Введите недостатки') : null;
      if (control_name == 'comment') control_validate == 'false' ? content_control_alert('* Введите комментарий') : null;
      if (control_name == 'text_msg') control_validate == 'false' ? content_control_alert('* Введите сообщение') : null;

      if (control_name == 'card_number') control_validate == 'false' ? content_control_alert(`* ${ control_placeholder }`) : null;

      if (control_name == 'first_name') control_validate == 'false' ? content_control_alert('* Введите имя') : null;
      if (control_name == 'last_name') control_validate == 'false' ? content_control_alert('* Введите фамилию') : null;
      if (control_name == 'name') control_validate == 'false' ? content_control_alert('* Введите имя') : null;
      if (control_name == 'phone') control_validate == 'false' ? content_control_alert('* Введите номер') : null;
      if (control_name == 'email') control_validate == 'false' ? content_control_alert('* Введите e-mail') : null;

      if (control_name == 'city') control_validate == 'false' ? content_control_alert('* Введите город') : null;
      if (control_name == 'street') control_validate == 'false' ? content_control_alert('* Введите улицу') : null;
      if (control_name == 'house') control_validate == 'false' ? content_control_alert('* Введите дом') : null;

      if (control_name == 'apartment') control_validate == 'false' ? content_control_alert(`* ${ control_placeholder }`) : null;

      if (control_name == 'username') control_validate == 'false' ? content_control_alert('* Введите номер телефона или e-mail') : null;

      if (control_name == 'password') control_validate == 'false' ? content_control_alert('* Введите пароль') : null;
      if (control_name == 'new_password') control_validate == 'false' ? content_control_alert('* Введите пароль') : null;
      if (control_name == 'old_password') control_validate == 'false' ? content_control_alert('* Введите старый пароль') : null;
      if (control_name == 'new_password1') control_validate == 'false' ? content_control_alert('* Введите новый пароль') : null;
      if (control_name == 'new_password2') control_validate == 'false' ? content_control_alert('* Подтвердите новый пароль') : null;

      function content_control_alert(placeholder) {
        if (!content_control.classList.contains('control_alert')) {
          content_control.setAttribute('placeholder', placeholder);

          content_control.classList.add('control_alert');
        }
      }
    }
  }

  function control_button_verify() {
    const control_validate_all = verify.querySelectorAll('.content-control[data-validate="true"]');

    if (control_validate_all.length == content_control_all.length) {
      control_button.classList.remove('button_disabled');
    } else {
      control_button.classList.add('button_disabled');
    }
  }
}
