"use client";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./styles.module.scss";
import { forwardRef, useImperativeHandle, useRef, } from "react";
import cn from "classnames";
import InputMask from "react-input-mask";
export var InputField = forwardRef(function (props, ref) {
    var _a;
    var _b = props.isDesktop, isDesktop = _b === void 0 ? false : _b, value = props.value, _c = props.type, type = _c === void 0 ? "text" : _c, errorText = props.errorText, placeholder = props.placeholder, _d = props.withErrorText, withErrorText = _d === void 0 ? false : _d, className = props.className, variant = props.variant, onChange = props.onChange, mask = props.mask, rest = __rest(props, ["isDesktop", "value", "type", "errorText", "placeholder", "withErrorText", "className", "variant", "onChange", "mask"]);
    var textInputRef = useRef(null);
    useImperativeHandle(ref, function () { return ({
        getValue: function () { return value; },
        onFocus: function () { var _a; return (_a = textInputRef === null || textInputRef === void 0 ? void 0 : textInputRef.current) === null || _a === void 0 ? void 0 : _a.focus(); },
        onBlur: function () { var _a; return (_a = textInputRef === null || textInputRef === void 0 ? void 0 : textInputRef.current) === null || _a === void 0 ? void 0 : _a.blur(); },
    }); });
    var inputClasses = cn(styles.field, className, (_a = {},
        _a[styles.secondary] = variant === "secondary",
        _a[styles.desktop] = isDesktop,
        _a[styles.error] = !!errorText,
        _a));
    return (_jsxs("div", { className: styles.root, children: [(type === "text" || type === "password") && (_jsx("input", __assign({ ref: textInputRef, value: value, placeholder: placeholder, className: inputClasses, type: type }, rest, { onChange: onChange }))), type === "mask" && (_jsx(InputMask, __assign({ mask: mask || "", value: value, className: inputClasses, placeholder: placeholder }, rest, { onChange: onChange }))), type === "textarea" && (_jsx("textarea", __assign({ ref: textInputRef, value: value, placeholder: placeholder, className: inputClasses, style: { borderRadius: 16 } }, rest, { onChange: onChange }))), withErrorText && _jsx("span", { className: styles.error, children: errorText })] }));
});
InputField.displayName = "InputField";
