import React from 'react';
import ReactDOM from 'react-dom';

// Компонент выпадающего списка
import { CompareDropdown } from '../../jsx/components/dropdown';
import { Compare } from '../../jsx/pages/compare';

import { correct_width } from './correct-width';
import {store} from "../../../common/js/redux/store";
import {addProduct, clearProducts, removeProduct} from "../../../common/js/redux/slices/compareSlice";

async function compare_interactive(compare_action, product_id) {
  const compareAction = compare_action;
  const productId = Number(product_id);

  let response = null;

  if (compare_action) {
    response = await fetch(
      `/api/compare/${compare_action}/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': document.querySelector(`input[name='csrfmiddlewaretoken']`).value
        },
        body: JSON.stringify(
          {
            'product_id': product_id
          }
        )
      }
    );
  } else {
    response = await fetch(
      '/api/compare/clear/',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': document.querySelector(`input[name='csrfmiddlewaretoken']`).value
        }
      }
    );
  }

  if (response.ok) {
    const compareArray = localStorage.getItem('compare-array');

    const result = await response.json();

    if (compareAction === 'add') {
      if (result.status !== "error"){
        store.dispatch(addProduct(product_id))
      }
    } else if (compareAction === 'delete') {
      store.dispatch(removeProduct(product_id))
    } else {
      store.dispatch(clearProducts())
    }

    if (result.error == 'categoryError') {
      const overlay_error = document.querySelector('.overlay[data-overlay="error"]');

      if (overlay_error) {
        correct_width(true);

        overlay_error.setAttribute('data-id', product_id);

        if (overlay_error.style.display != 'flex') {
          overlay_error.style.display = 'flex';
        }
      }

      return false;
    }

    if (result.error == 'exceededLimitError') {
      const overlay_limit = document.querySelector('.overlay[data-overlay="error-limit"]');

      if (overlay_limit) {
        correct_width(true);

        if (overlay_limit.style.display != 'flex') {
          overlay_limit.style.display = 'flex';
        }
      }

      return false;
    }

    if (compareAction) {
      if (compareArray) {
        // Преобразуем стоку в массив
        const parsedArray = JSON.parse(compareArray);

        if (compareAction == 'add') {
          // Добавляем товар в массив
          parsedArray.push(productId);
        }

        if (compareAction == 'delete') {
          const arrayIndex = parsedArray.indexOf(productId);

          if (arrayIndex != -1) {
            // Удаляем элемент из массива
            parsedArray.splice(arrayIndex, 1);
          }
        }

        // Записываем массив в локальное хранилище
        localStorage.setItem('compare-array', JSON.stringify(parsedArray));
      } else {
        // Записываем массив в локальное хранилище
        localStorage.setItem('compare-array', JSON.stringify([productId]));
      }
    } else {
      // Удаляем массив из локального хранилища
      localStorage.removeItem('compare-array');
    }

    const product = document.querySelector('.product');

    if (product) {
      const dataId = product.getAttribute('data-id');

      const product_control = product.querySelector('.product-control');

      if (product_control) {
        const button_compare = product_control.querySelector('.control-button[data-button="compare"]');

        if (dataId == productId && compare_action == 'add') {
          button_compare.classList.add('button_selected');
        }

        if (dataId == productId || !compare_action) {
          if (compare_action == 'delete' || !compare_action) {
            button_compare.classList.remove('button_selected');
          }
        }
      }
    }

    const compare = document.querySelector('.compare');

    if (compare) {
      ReactDOM.unmountComponentAtNode(compare);
      ReactDOM.render(<Compare compareData={result} />, compare);
    }

    if (compareAction == 'delete') {
      const product_unit_all = document.querySelectorAll(`.list-unit[data-id="${product_id}"][data-type="product"]`);

      if (product_unit_all.length) {
        product_unit_all.forEach(product_unit_each);
      }
    }

    if (!compareAction) {
      const product_unit_all = document.querySelectorAll(`.list-unit[data-type="product"]`);

      if (product_unit_all.length) {
        product_unit_all.forEach(product_unit_each);
      }
    }

    const header_upper = document.querySelector('.header-upper');

    if (header_upper) {
      const button_compare = header_upper.querySelector('.upper-control > .control-button[data-button="compare"]');

      if (button_compare) {
        const compare_layer = button_compare.querySelector('.button-layer');
        const compare_overlay = button_compare.querySelector('.button-overlay');

        const indicate_counter = compare_layer.querySelector('.indicate-counter');

        if (result.products) {
          if (compare_layer.classList.contains('layer_disabled')) {
            compare_layer.classList.remove('layer_disabled');
          }

          if (indicate_counter.classList.contains('counter_hidden')) {
            indicate_counter.classList.remove('counter_hidden');
          }

          indicate_counter.textContent = result.products.length;
        } else {
          if (!compare_layer.classList.contains('layer_disabled')) {
            compare_layer.classList.add('layer_disabled');
          }

          if (!indicate_counter.classList.contains('counter_hidden')) {
            indicate_counter.classList.add('counter_hidden');
          }
        }

        ReactDOM.render(<CompareDropdown dropdownData={result} />, compare_overlay);
      }

      // Если есть ответ с сервера
      return true;
    } else {
      console.log('Response is not okey!');

      // Елси ответа нет
      return false;
    }
  }
}

function product_unit_each(product_unit) {
  const data_reference = product_unit.getAttribute('data-reference');

  if (data_reference == 'any') {
    const button_compare = product_unit.querySelector('.button_compare');

    if (button_compare.classList.contains('button_selected')) {
      button_compare.classList.remove('button_selected');
    }
  }
}

export default compare_interactive