import styles from "./styles.modules.scss"

import React, {useImperativeHandle, useRef, useState} from "react"
import SimpleBar from "simplebar-react"
import cn from "classnames"

import {useMultiRefsOutsideClicker} from "../../../shared/hooks/useMultiRefsOutsideClicker"

const Arrow = () => (
  <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 1L9 9L1 0.999999" stroke="#EF4123" strokeWidth="2" strokeLinecap="round"/>
  </svg>
)

const ShopItem = ({name, city, street, house, isMobile = false}) => (
  <div className={cn(styles.item, isMobile && styles.mobile)}>
    <span className={styles.name}>{name}</span>
    <span className={styles.address}>{city}, {street}, {house}</span>
  </div>
)

export const SelectShop = React.forwardRef((props, ref) => {
  const {
    shops,
    selected,
    onSelect,
    isMobile = false
  } = props

  const [isOpen, setIsOpen] = useState(false)
  const [selectedShop, setSelectedShop] = useState(selected || shops[0])

  const selectRef = useRef(null)

  useImperativeHandle(ref, () => ({
    getValue: () => selectedShop,
    onFocus: () => selectRef?.current?.focus(),
    onBlur: () => selectRef?.current?.blur()
  }))

  const toggleOpen = () => setIsOpen(!isOpen)

  useMultiRefsOutsideClicker([selectRef], () => {
    setIsOpen(false)
  }, setIsOpen)

  const selectShopHandler = (value) => () => {
    setSelectedShop(value)
    setIsOpen(false)
    if (onSelect) onSelect(value)
  }

  return (
    <div ref={selectRef} className={cn(styles.root, isMobile && styles.mobile, isOpen && styles.active)}>
      <div className={styles.preview} onClick={toggleOpen}>
        <ShopItem {...selectedShop} isMobile={isMobile}/>
        <div className={cn(styles.icon, isOpen && styles.rotated)}>
          <Arrow/>
        </div>
      </div>
      {isOpen && (
        <SimpleBar className={styles.simpleBarWrapper}>
          <div className={styles.options}>
            {shops.map(shop => (
              shop.id !== selectedShop.id && (
                <div key={shop.id} className={styles.shopItem} onClick={selectShopHandler(shop)}>
                  <ShopItem {...shop} isMobile={isMobile}/>
                </div>
              )
            ))}
          </div>
        </SimpleBar>
      )}
    </div>
  )
})

SelectShop.displayName = "SelectField"
