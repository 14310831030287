import styles from "./CartProductPrices.modules.scss"

import React from "react"
import cn from "classnames"

import numberWithSpaces from "../../../../../js/lib/utils"

export const CartProductPrices = (
  {
    price,
    discountedPrice,
    discountAmount,
    className
  }) => {

  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.upper}>
        {price > discountedPrice && (
          <>
            <span className={styles.strike}>{numberWithSpaces(price)} ₽</span>
            <span className={styles.discount}>-{numberWithSpaces(discountAmount)} ₽</span>
          </>
        )}
      </div>
      <div className={styles.current}>{numberWithSpaces(discountedPrice)} ₽</div>
    </div>
  )
}
