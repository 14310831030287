import React from "react"
import {Provider} from "react-redux"
import ReactDOM from "react-dom"
import axios from "axios"

import BigProductGallery from "../../../../common/jsx/components/BigProductGallery"
import {store} from "../../../../common/js/redux/store"

const galleryLayer = document.querySelector('.gallery-layer')

if (galleryLayer) {
  const galleryLayerList = galleryLayer.querySelector('.layer-list')

  const withImages = galleryLayer.getAttribute("data-with-images")

  const bigGalleryOverlay = document.querySelector('.overlay[data-overlay="big-gallery"]')
  const popupWrapper = bigGalleryOverlay.querySelector('.popup-wrapper')
  const popupWrapperContent = popupWrapper.querySelector('.content')
  const closePopupButton = popupWrapper.querySelector('.close-button')


  const showBigGalleryPopup = () => {
    bigGalleryOverlay.style.display = "block"
  }

  const hideBigGalleryPopup = () => {
     bigGalleryOverlay.style.display = "none"
  }

  if (withImages) {
    galleryLayerList.addEventListener("click", showBigGalleryPopup)
    closePopupButton.addEventListener('click', hideBigGalleryPopup)

    const productSlug = document.querySelector('.product').getAttribute('data-slug')
    axios.get(`/api/v2/product/${productSlug}/`).then(response => {
      if (response.status === 200) {
        ReactDOM.render(<Provider store={store}><BigProductGallery product={response.data}/></Provider>, popupWrapperContent)
      }
    })
  }
}