import styles from "./styles.modules.scss"

import React from "react"

import {Button} from "../shared"

export const OnlinePaymentInfo = ({bankAlign = "start"}) => {
  return (
    <div className={styles.root}>
      <span className={styles.title}>Оплачивайте покупки онлайн</span>
      <div className={styles.separator}></div>
      <img src={"/static/store/icons/logo_psb.png"} alt={""} width={150} height={105} style={{alignSelf: bankAlign}}/>
      <span className={styles.text}>
        С помощью интернет-банкинга ПСБ, Вы можете оплачивать покупки любыми картами российских банков.
      </span>
      <Button title={"Подробнее"} Component={"a"} href={"/oplata-crb/"} variant={"secondary"}/>
    </div>
  )
}
