import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { CheckboxIcon } from "../icons";
export var Checkbox = function (_a) {
    var _b = _a.checked, checked = _b === void 0 ? false : _b, className = _a.className, onChange = _a.onChange;
    var _c = useState(checked), currentChecked = _c[0], setCurrentChecked = _c[1];
    useEffect(function () {
        setCurrentChecked(checked);
    }, [checked]);
    var toggleCheckedHandler = function () {
        setCurrentChecked(!currentChecked);
        if (onChange)
            onChange(!currentChecked);
    };
    return (_jsx("div", { className: className, onClick: toggleCheckedHandler, children: _jsx(CheckboxIcon, { active: currentChecked }) }));
};
