const list = document.querySelector('.list');

if (list) {
  const control_sort = list.querySelector('.control-sort');

  if (control_sort) {
    const sort_button = control_sort.querySelector('.sort-button');
    const sort_dropdown = control_sort.querySelector('.sort-dropdown');

    sort_button.addEventListener('click', sort_button_click);

    function sort_button_click() {
      if (sort_dropdown.style.display != 'block') {
        sort_dropdown.style.display = 'block';
      } else {
        sort_dropdown.style.display = 'none';
      }
    }

    document.addEventListener('click', document_click);

    function document_click({ target }) {
      if (!sort_button.contains(target) && !sort_dropdown.contains(target)) {
        if (sort_dropdown.style.display != 'none') sort_dropdown.style.display = 'none';
      }
    }
  }
}
