import React from "react";
import { createRoot } from 'react-dom/client';

import {ProductCard} from "@widgets/ProductCard";

const product_unit_all = document.querySelectorAll('.product-unit');

const product_units_section = document.querySelectorAll('[data_section="1"]')

if (product_units_section.length) {
  product_units_section.forEach(product_unit_each)
}

const observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      product_unit_each(entry.target);
      observer.unobserve(entry.target);
    }
  });
});

if (product_unit_all.length) {
  product_unit_all.forEach(unit => {
    observer.observe(unit);
  });
}

function product_unit_each (product_unit) {
  const statusStrData = product_unit.getAttribute('data-status')
  const tagsStrData = product_unit.getAttribute('data-tags')
  const imagesStrData = product_unit.getAttribute('data-images')
  const specificationsStrData = product_unit.getAttribute('data-specifications')
  const productData = {
    id: Number(product_unit.getAttribute('data-id')),
    sku: product_unit.getAttribute('data-sku'),
    name: product_unit.getAttribute('data-name'),
    slug: product_unit.getAttribute('data-slug'),
    url: product_unit.getAttribute('data-url'),
    price: Number(product_unit.getAttribute('data-price')),
    discountedPrice: Number(product_unit.getAttribute('data-discountedPrice')),
    bonusesAmount: Number(product_unit.getAttribute('data-bonusesAmount')),
    rating: Number(product_unit.getAttribute('data-rating')),
    reviewsCount: Number(product_unit.getAttribute('data-reviewsCount')),
    status: JSON.parse(statusStrData),
    tags: JSON.parse(tagsStrData),
    images: JSON.parse(imagesStrData),
    specifications: JSON.parse(specificationsStrData),
  }

  const reactRoot = document.createElement('div');
  reactRoot.classList.add(['list-unit']);
  reactRoot.classList.add(['product-unit']);

  // Замена product_unit на новый элемент
  product_unit.parentNode.replaceChild(reactRoot, product_unit);

  const root = createRoot(reactRoot);
  root.render(<ProductCard {...productData} isDesktop />);
}
