import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./CardSpecification.module.scss";
import { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { Text, LinkButton } from "@src/shared/ui";
var MAX_HEIGHT = 52;
export var CardSpecification = function (_a) {
    var _b;
    var data = _a.data, _c = _a.addedHeight, addedHeight = _c === void 0 ? 0 : _c, _d = _a.isExpanded, isExpanded = _d === void 0 ? false : _d, onClickMoreDetail = _a.onClickMoreDetail;
    var _e = useState(0), countItems = _e[0], setCountItems = _e[1];
    var _f = useState(0), itemsHeight = _f[0], setItemsHeight = _f[1];
    var itemsRef = useRef(null);
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        if (itemsRef.current) {
            var itemsHeight_1 = ((_b = (_a = itemsRef.current) === null || _a === void 0 ? void 0 : _a.children[0]) === null || _b === void 0 ? void 0 : _b.clientHeight) || 0;
            var itemsCount = 1;
            if (((_e = (_d = (_c = itemsRef.current) === null || _c === void 0 ? void 0 : _c.children[1]) === null || _d === void 0 ? void 0 : _d.clientHeight) !== null && _e !== void 0 ? _e : 0) + itemsHeight_1 + 8 <=
                MAX_HEIGHT) {
                itemsHeight_1 += (((_g = (_f = itemsRef.current) === null || _f === void 0 ? void 0 : _f.children[1]) === null || _g === void 0 ? void 0 : _g.clientHeight) || 0) + 8;
                itemsCount += 1;
            }
            if (((_k = (_j = (_h = itemsRef.current) === null || _h === void 0 ? void 0 : _h.children[2]) === null || _j === void 0 ? void 0 : _j.clientHeight) !== null && _k !== void 0 ? _k : 0) + itemsHeight_1 + 8 <=
                MAX_HEIGHT) {
                itemsCount += 1;
            }
            setCountItems(itemsCount);
            setItemsHeight((_l = itemsRef.current) === null || _l === void 0 ? void 0 : _l.offsetHeight);
        }
    }, []);
    var handleOpen = function () {
        if (onClickMoreDetail) {
            onClickMoreDetail(true);
        }
    };
    var handleClose = function () {
        if (onClickMoreDetail) {
            onClickMoreDetail(false);
        }
    };
    var mappedItems = data.map(function (el) { return (_jsxs("div", { className: styles.item, children: [_jsx(Text, { className: styles.name, variant: "body2", children: el.attribute.name }), _jsxs(Text, { className: styles.measureUnit, variant: "body2", children: [el.values[0].value, " ", el.attribute.measure_unit] })] }, "".concat(el.attribute.slug, "_").concat(el.values[0].slug))); });
    var currentItems = itemsHeight >= MAX_HEIGHT && !isExpanded
        ? mappedItems.slice(0, countItems)
        : mappedItems;
    var mainStyles = {
        maxHeight: 287 + addedHeight,
    };
    return (_jsxs("div", { className: cn(styles.main, (_b = {}, _b[styles.active] = isExpanded, _b)), style: mainStyles, children: [_jsx("div", { className: styles.items, ref: itemsRef, children: currentItems }), itemsHeight >= MAX_HEIGHT && !isExpanded && (_jsx(LinkButton, { onClick: handleOpen, className: styles.openBtn, children: _jsx(Text, { variant: "body3", className: styles.link, children: "\u041F\u043E\u0434\u0440\u043E\u0431\u043D\u0435\u0435..." }) })), isExpanded && (_jsx(LinkButton, { onClick: handleClose, children: _jsx(Text, { variant: "body3", className: styles.link, children: "\u0421\u043A\u0440\u044B\u0442\u044C..." }) }))] }));
};
