import React from "react"

const Cart = ({width = 24, height = 24, color = "currentColor"}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.541 14.6C12.841 14.8 13.241 14.8 13.541 14.6L18.541 11.7V12C19.241 12 19.941 12.1 20.541 12.4V10.6L21.541 10C22.041 9.7 22.141 9.1 21.941 8.6L20.441 6.1C20.341 5.9 20.241 5.7 20.041 5.6L12.141 1.2C11.941 1.1 11.741 1 11.541 1C11.341 1 11.141 1.1 10.941 1.2L3.14095 5.6C2.94095 5.7 2.74095 5.8 2.64095 6L1.14095 8.6C0.840954 9.1 1.04095 9.7 1.54095 10C1.84095 10.2 2.24095 10.2 2.54095 10V15.5C2.54095 15.9 2.74095 16.2 3.04095 16.4L10.941 20.8C11.141 20.9 11.341 21 11.541 21C11.741 21 11.941 20.9 12.141 20.8L13.041 20.3C12.741 19.7 12.641 19 12.541 18.3M10.541 18.3L4.54095 14.9V8.2L10.541 11.6V18.3ZM19.641 8.7L13.341 12.3L12.741 11.3L19.041 7.7L19.641 8.7ZM11.541 9.8V3.2L17.541 6.5L11.541 9.8ZM19.541 14V17H22.541V19H19.541V22H17.541V19H14.541V17H17.541V14H19.541Z"
      fill={color}
    />
  </svg>
)

export default Cart