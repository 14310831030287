var _a;
import { createSlice } from "@reduxjs/toolkit";
var initialState = {
    stage: "START",
    restore: {
        phone: null,
        sessionKey: null,
    }
};
export var signInSlice = createSlice({
    name: "signIn",
    initialState: initialState,
    reducers: {
        setStage: function (state, _a) {
            var payload = _a.payload;
            state.stage = payload;
        },
        setRestoreData: function (state, _a) {
            var payload = _a.payload;
            state.restore = payload;
        },
        resetRestoreData: function (state) {
            state.restore = initialState.restore;
        },
        resetState: function (state) {
            state.stage = initialState.stage;
            state.restore = initialState.restore;
        }
    },
});
export var selectStage = function (state) {
    return state.signIn.stage;
};
export var selectRestoreData = function (state) {
    return state.signIn.restore;
};
export var setStage = (_a = signInSlice.actions, _a.setStage), setRestoreData = _a.setRestoreData, resetRestoreData = _a.resetRestoreData, resetState = _a.resetState;
