export var specialRound = function (num) {
    if (num < 10)
        return 1;
    var power = Math.pow(10, Math.floor(Math.log10(num))); // степень десяти
    return Math.floor(num / power) * power; // округление
};
export var customRound = function (number, roundTo, roundUp) {
    if (roundUp) {
        return Math.ceil(number / roundTo) * roundTo;
    }
    return Math.floor(number / roundTo) * roundTo;
};
export var calculateRanges = function (input) {
    // Вычисляем минимальное и максимальное значение
    var values = input.possible_values.map(function (v) { return parseInt(v.value, 10); });
    var dataMin = Math.min.apply(Math, values);
    var dataMax = Math.max.apply(Math, values);
    // Вычисляем шаг, используя логику specialRound
    var step = Math.max(specialRound((dataMax - dataMin) / 40), 2);
    // Дорабатываем логику, чтобы количество диапазонов не превышало 17
    while (Math.ceil((dataMax - dataMin) / step) > 17) {
        step += specialRound(step);
    }
    // Создаем диапазоны
    var ranges = [];
    var currentMin = Math.max(customRound(dataMin, step, false), 1);
    var createRangeFilter = function (currentMin, currentMax) { return function (val) {
        var value = parseInt(val.value, 10);
        return value >= currentMin && value <= currentMax;
    }; };
    var isFirst = true;
    while (currentMin <= dataMax) {
        var currentMax = currentMin + step; // Вычисляем максимум для текущего диапазона
        var rangeFilter = createRangeFilter(currentMin, currentMax);
        var quantity = input.possible_values
            .filter(rangeFilter)
            .reduce(function (sum, current) { return sum + current.products_count; }, 0);
        ranges.push({ min: currentMin, max: currentMax, quantity: quantity });
        currentMin = currentMax + 1; // Следующий минимум на единицу больше текущего максимума
        if (isFirst) {
            step -= 1; // Шаг для следующих диапазонов
            isFirst = false;
        }
    }
    return ranges;
};
export var rangeToValid = function (min, max, values) {
    if (!(values === null || values === void 0 ? void 0 : values.length) && (values === null || values === void 0 ? void 0 : values.length) < 2)
        return [];
    var validMin = Math.min(Math.max(Number(values[0]), min), max);
    var validMax = Math.min(Math.max(Number(values[1]), min), max);
    if (validMin > validMax)
        return [validMax, validMin];
    return [validMin, validMax];
};
export var filterValidRanges = function (ranges, values) {
    return values.filter(function (value) {
        return !!ranges.find(function (item) { return item.min === value[0] && item.max === value[1]; });
    });
};
