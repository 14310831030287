import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import { calculateRanges, filterValidRanges, rangeToValid } from "../../lib";
import { ChartFilterField } from "../ChartFilterField";
import { SliderFilterField } from "../SliderFilterField";
import { RangeInputFields } from "./RangeInputFields";
import { ValuesRangesList } from "./ValuesRangesList";
export var RangeFilter = function (_a) {
    var attributeData = _a.attributeData, values = _a.values, _b = _a.isDesktop, isDesktop = _b === void 0 ? false : _b, onChange = _a.onChange;
    var rangesList = calculateRanges(attributeData);
    // Определяем минимальное и максимальное значения из диапазонов
    var minRangeValue = rangesList[0].min;
    var maxRangeValue = rangesList[rangesList.length - 1].max;
    var _c = useState([]), currentRanges = _c[0], setCurrentRanges = _c[1];
    var _d = useState(null), fieldsRange = _d[0], setFieldsRange = _d[1];
    var _e = useState(null), sliderRange = _e[0], setSliderRange = _e[1];
    var _f = useState(null), chartRange = _f[0], setChartRange = _f[1];
    var _g = useState([]), chartRanges = _g[0], setChartRanges = _g[1];
    var _h = useState([]), checkboxRanges = _h[0], setCheckboxRanges = _h[1];
    var updateCurrentRanges = function (newRanges) {
        if (JSON.stringify(newRanges) !== JSON.stringify(currentRanges)) {
            setCurrentRanges(newRanges);
            onChange === null || onChange === void 0 ? void 0 : onChange(newRanges);
        }
    };
    useEffect(function () {
        if (values === null || values === void 0 ? void 0 : values.length) {
            if (values.length === 1) {
                var validRange = rangeToValid(minRangeValue, maxRangeValue, values[0]);
                updateCurrentRanges([validRange]);
                setFieldsRange(validRange);
                setSliderRange(validRange);
                setChartRange(validRange);
                setChartRanges([]);
                setCheckboxRanges([]);
            }
            else {
                var validRanges = filterValidRanges(rangesList, values);
                updateCurrentRanges(validRanges);
                setFieldsRange(null);
                setSliderRange(null);
                setChartRange(null);
                setChartRanges(validRanges);
                setCheckboxRanges(validRanges);
            }
        }
        else {
            updateCurrentRanges([]);
            setFieldsRange(null);
            setSliderRange(null);
            setChartRanges([]);
            setChartRange(null);
            setCheckboxRanges([]);
        }
    }, [values]);
    // Вычисляем шаг для слайдера
    var step = rangesList[0].max - rangesList[0].min;
    var changeFieldsHandler = function (value) {
        var _a, _b;
        setFieldsRange(value);
        var minValue = (_a = value === null || value === void 0 ? void 0 : value[0]) !== null && _a !== void 0 ? _a : minRangeValue;
        var maxValue = (_b = value === null || value === void 0 ? void 0 : value[1]) !== null && _b !== void 0 ? _b : maxRangeValue;
        setSliderRange(value ? [minValue, maxValue] : null);
        setChartRanges([]);
        setChartRange(value ? [minValue, maxValue] : null);
        setCheckboxRanges([]);
        updateCurrentRanges(value ? [[minValue, maxValue]] : []);
    };
    var changeCheckboxesHandler = function (values) {
        setCheckboxRanges(values);
        setChartRange(null);
        if (values.length === 1) {
            setFieldsRange(values[0]);
            setChartRanges([values[0]]);
            setSliderRange(values[0]);
        }
        else {
            setFieldsRange(null);
            setChartRanges(values);
            setSliderRange(null);
        }
        updateCurrentRanges(values);
    };
    var changeSliderHandler = function (value) {
        setSliderRange(value);
        setFieldsRange(value);
        setChartRange(value);
        setChartRanges([]);
        setCheckboxRanges([]);
        updateCurrentRanges([value]);
    };
    var changeChartHandler = function (values) {
        setChartRange(null);
        setChartRanges(values);
        if (values.length === 1) {
            setFieldsRange(values[0]);
            setSliderRange(values[0]);
        }
        else {
            setFieldsRange(null);
            setSliderRange(null);
        }
        setCheckboxRanges(values);
        updateCurrentRanges(values);
    };
    return (_jsxs("div", { className: styles.root, children: [_jsx(RangeInputFields, { min: minRangeValue, max: maxRangeValue, values: fieldsRange, onChange: changeFieldsHandler }), _jsxs("div", { className: styles.rangeLayer, children: [_jsx(ChartFilterField, { rangesList: rangesList, selectedRanges: chartRanges, activeRange: chartRange, onChange: changeChartHandler }), _jsx(SliderFilterField, { min: minRangeValue, max: maxRangeValue, step: step, value: sliderRange || [minRangeValue, maxRangeValue], onChange: changeSliderHandler })] }), _jsx("div", { className: styles.listLayer, children: _jsx(ValuesRangesList, { rangesList: rangesList, measureUnit: attributeData.attribute.measure_unit, values: checkboxRanges, isDesktop: isDesktop, onChange: changeCheckboxesHandler }) })] }));
};
