import { jsx as _jsx } from "react/jsx-runtime";
import styles from "./Text.module.scss";
import { memo } from "react";
import cn from "classnames";
export var Text = memo(function (_a) {
    var _b;
    var _c = _a.Component, Component = _c === void 0 ? "span" : _c, _d = _a.variant, variant = _d === void 0 ? "body2" : _d, color = _a.color, className = _a.className, children = _a.children, _e = _a.isDesktop, isDesktop = _e === void 0 ? false : _e;
    var rootClassName = cn((_b = {},
        _b[styles.h1] = variant === "h1",
        _b[styles.h2] = variant === "h2",
        _b[styles.h3] = variant === "h3",
        _b[styles.h4] = variant === "h4",
        _b[styles.h5] = variant === "h5",
        _b[styles.bodyPrice] = variant === "bodyPrice",
        _b[styles.body1] = variant === "body1",
        _b[styles.body2] = variant === "body2",
        _b[styles.body3] = variant === "body3",
        _b[styles.body4] = variant === "body4",
        _b[styles.body5] = variant === "body5",
        _b[styles.bodySale] = variant === "bodySale",
        _b[styles.buttonLarge] = variant === "buttonLarge",
        _b[styles.buttonNormal] = variant === "buttonNormal",
        _b[styles.buttonMedium] = variant === "buttonMedium",
        _b[styles.buttonSmall] = variant === "buttonSmall",
        _b[styles.buttonExtraSmall] = variant === "buttonExtraSmall",
        _b[styles.desktop] = isDesktop,
        _b), className);
    return (_jsx(Component, { className: rootClassName, style: { color: color }, children: children }));
});
Text.displayName = "Text";
