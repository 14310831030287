import styles from "./styles.modules.scss"

import React from "react"
import cn from "classnames"

import {wordByCount} from "../../../shared"

export const BonusesBadge = ({bonusesCount, prefix = "+", size = "s", className}) => {
  const classes = cn(
    styles.root,
    {
      [styles.small]: size === "s",
      [styles.large]: size === "l",
    },
    className
  )
  return (
    <div className={classes}>
      <span className={styles.text}>
        {prefix}{bonusesCount} {wordByCount(bonusesCount, "бонус", "бонуса", "бонусов")}
      </span>
    </div>
  )
}
