import styles from "./CartProduct.modules.scss"

import React from "react"
import cn from "classnames"

import {CartProductPrices} from "../CartProductPrices"
import {AdditionalProductsSection} from "../AdditionalProductsSection";
import Image from "../../../Image"
import {Separator} from "../../../ordering/shared"
import {useSetCartProductQuantityMutation, useAddFavoriteProductMutation} from "@src/shared/api"
import {ServicesSection} from "../ServicesSection"
import {removeCartProductWithYM} from "../../../../../js/lib/cart"

export const CartProduct = ({cartProduct, isMobile = false}) => {
  const product = cartProduct.product
  const quantity = cartProduct.quantity
  const warrantyServices = product.warranty_services
  const installingServices = product.installing_services
  const settingServices = product.setting_up_services
  const additionalProducts = product.additional_products

  const [setQuantity] = useSetCartProductQuantityMutation()
  const [addFavoriteProduct] = useAddFavoriteProductMutation()

  const removeProductHandler = async () => {
    try {
      await removeCartProductWithYM({productId: product.id})
    } catch (error) {
      console.log(error)
    }
  }

  const incrementProductHandler = async () => {
    try {
      await setQuantity({productId: product.id, quantity: quantity + 1})
    } catch (error) {
      console.log(error)
    }
  }

  const decrementProductHandler = async () => {
    if (quantity <= 1) return
    try {
      await setQuantity({productId: product.id, quantity: quantity - 1})
    } catch (error) {
      console.log(error)
    }
  }

  const saveForLaterHandler = async () => {
    try {
      await removeCartProductWithYM({productId: product.id})
      addFavoriteProduct({productId: product.id})
    } catch (error) {
      console.log(error)
    }
  }

  const Control = () => (
    <div className={cn(styles.control, isMobile && styles.mobile)}>
      <div className={styles.buttons}>
        <div className={styles.linkBtn} onClick={saveForLaterHandler}>Сохранить на потом</div>
        <div className={styles.linkBtn} onClick={removeProductHandler}>Удалить</div>
      </div>
      <div className={styles.quantityControl}>
        <div className={styles.singBtn} onClick={decrementProductHandler}>-</div>
        <div className={styles.value}>{quantity}</div>
        <div className={styles.singBtn} onClick={incrementProductHandler}>+</div>
      </div>
    </div>
  )

  return (
    <div className={cn(styles.root, isMobile && styles.mobile)}>
      <div className={styles.content}>
        <div className={styles.top}>
          <div className={styles.mainInfo}>
            <div className={styles.image}>
              <Image src={product.main_image?.[isMobile ? "mobile" : "desktop"].preview.image}/>
            </div>
            <div className={styles.nameLayer}>
              <span className={styles.sku}>SKU: {product.sku}</span>
              <span className={styles.name}>{product.name}</span>
            </div>
          </div>
          <CartProductPrices
            price={product.price}
            discountedPrice={product.discounted_price}
            discountAmount={product.discount_amount}
            className={styles.prices}
          />
        </div>
        <Control/>
      </div>
      {(!!warrantyServices?.length || !!installingServices?.length || !!settingServices?.length) && (
        <>
          <Separator/>
          <ServicesSection
            cartProduct={cartProduct}
            warrantyServices={warrantyServices}
            installingServices={installingServices}
            settingServices={settingServices}
            isMobile={isMobile}
          />
        </>
      )}
      {!!additionalProducts?.length && (
        <>
          <Separator/>
          <AdditionalProductsSection
            products={additionalProducts}
            isMobile={isMobile}
          />
        </>
      )}
    </div>
  )
}
