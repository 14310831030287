import { jsx as _jsx } from "react/jsx-runtime";
import { useRef } from "react";
import { RangeFilter } from "@src/entities/filter";
export var RangeFilterItemFeature = function (_a) {
    var attributeData = _a.attributeData, values = _a.values, _b = _a.isDesktop, isDesktop = _b === void 0 ? false : _b;
    var filterRef = useRef(null);
    var changeFilterHandler = function (values) {
        if (isDesktop && values.length) {
            var filterButton = document.getElementById("filter-control-button");
            var blockPosition = filterRef.current.offsetTop;
            if (filterButton.classList.contains("control_hidden")) {
                filterButton.classList.remove("control_hidden");
            }
            filterButton.style.top = "".concat(blockPosition - 33, "px");
        }
        var filterEl = document.getElementById("range-filter-".concat(attributeData.attribute.id));
        filterEl.dataset.values = values.length ? JSON.stringify(values) : "";
    };
    return (_jsx("div", { ref: filterRef, children: _jsx(RangeFilter, { attributeData: attributeData, values: values, isDesktop: isDesktop, onChange: changeFilterHandler }) }));
};
