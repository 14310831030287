import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./SearchField.module.scss";
import { useEffect, useRef, useState } from "react";
import cn from "classnames";
import SimpleBar from "simplebar-react";
import { addToHistory, selectSearchQuery, setSearchQuery, } from "@src/entities/search-popup";
import { useCreateSearchQueryMutation } from "@src/shared/api";
import { useAppDispatch, useAppSelector, useToggleBodyOverflow, } from "@src/shared/hooks";
import { SearchIcon, CloseIcon } from "@src/shared/ui";
export var SearchField = function (_a) {
    var popup = _a.popup, onSubmit = _a.onSubmit;
    var dispatch = useAppDispatch();
    var createQuery = useCreateSearchQueryMutation()[0];
    var inputRef = useRef(null);
    var timerId = useRef(null);
    var searchValue = useAppSelector(selectSearchQuery);
    var _b = useState(""), currentValue = _b[0], setCurrentValue = _b[1];
    var _c = useState(false), isFocused = _c[0], setIsFocused = _c[1];
    useToggleBodyOverflow(isFocused);
    useEffect(function () {
        setCurrentValue(searchValue);
        if (inputRef.current && searchValue) {
            inputRef.current.focus();
        }
    }, [searchValue]);
    useEffect(function () {
        timerId.current = setTimeout(function () {
            dispatch(setSearchQuery(currentValue));
        }, 400);
        return function () {
            if (timerId.current) {
                clearTimeout(timerId.current);
            }
        };
    }, [currentValue, timerId]);
    var changeTextHandler = function (val) {
        setCurrentValue(val);
    };
    var clearFieldHandler = function () {
        setCurrentValue("");
    };
    var submitHandler = function () {
        if (!currentValue)
            return;
        createQuery(currentValue);
        addToHistory({ text: currentValue, catalog: null }, "query");
        window.location.href = "/search/?search=".concat(currentValue);
        if (onSubmit)
            onSubmit(currentValue);
    };
    var enterKeyDownHandler = function (event) {
        // Проверка кода нажатой клавиши
        if (event.key === "Enter") {
            submitHandler();
        }
    };
    return (_jsxs("div", { className: cn(styles.root, isFocused && styles.focused), children: [_jsx("div", { className: styles.searchPopupOverlay, onClick: function () { return setIsFocused(false); } }), _jsx("div", { className: styles.popupLayer, children: _jsx(SimpleBar, { className: styles.popupWrapper, scrollbarMaxSize: 260, children: popup }) }), _jsxs("div", { className: styles.field, children: [_jsxs("div", { className: styles.fieldLayer, children: [_jsx("input", { ref: inputRef, className: styles.field, type: "text", placeholder: "\u041F\u043E\u0438\u0441\u043A \u0432 \u043A\u0430\u0442\u0430\u043B\u043E\u0433\u0435", value: currentValue, onChange: function (e) { return changeTextHandler(e.target.value); }, onFocus: function () { return setIsFocused(true); }, onKeyDown: enterKeyDownHandler }), !!currentValue && (_jsx("button", { className: styles.closeButton, type: "button", onClick: clearFieldHandler, children: _jsx(CloseIcon, {}) }))] }), _jsx("button", { className: styles.searchButton, type: "button", onClick: submitHandler, children: _jsx(SearchIcon, {}) })] })] }));
};
