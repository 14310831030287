import { jsx as _jsx } from "react/jsx-runtime";
import { Desktop as HeaderDesktop } from "./Desktop";
import { Mobile as HeaderMobile } from "./Mobile";
import { withStore } from "@src/shared/hoc";
var Header = function (_a) {
    var slots = _a.slots, _b = _a.isDesktop, isDesktop = _b === void 0 ? false : _b;
    return isDesktop ? _jsx(HeaderDesktop, { slots: slots }) : _jsx(HeaderMobile, { slots: slots });
};
var HeaderWithStore = withStore(Header);
export { HeaderWithStore as Header };
