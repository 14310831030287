import styles from "./styles.modules.scss"

import React from "react"
import cn from "classnames"

import {TitleRow} from "../TitleRow"
import {ServicesList} from "../ServicesList"
import Image from "../../../../Image"

export const ProductItem = (
  {
    product,
    services,
    quantity,
    controlBtn,
    isShort = false,
    isMobile = false
  }) => {

  if (isShort) {
    return (
      <div className={cn(styles.root, isMobile && styles.mobile)}>
        <div className={styles.content}>
          <TitleRow
            link={product.url}
            title={product.name}
            price={product.discounted_price}
            servicesCount={services.length}
            isMobile={isMobile}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={cn(styles.root, isMobile && styles.mobile)}>
      <a href={product.url}>
        <Image
        src={product.main_image ? isMobile ? product.main_image.mobile.preview.image : product.main_image.desktop.preview.image : "/static/store/img/noimage.png"}
        alt={""}
        className={styles.image}
      />
      </a>
      <div className={styles.content}>
        <TitleRow link={product.url} title={product.name} price={product.discounted_price} isMobile={isMobile}/>
        {!!services.length && (
          <ServicesList services={services} isCol={isMobile}/>
        )}
        {controlBtn && (
          <div className={styles.control}>{controlBtn}</div>
        )}
      </div>
    </div>
  )
}
