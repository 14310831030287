import React from 'react';
import ReactDOM from 'react-dom';

import { correct_width } from '../lib/correct-width';

import {ProductCard} from "@widgets/ProductCard";

const section_all = document.querySelectorAll('.section');

if (section_all.length) section_all.forEach(section_each);

function section_each(section) {
  const section_type = section.getAttribute('data-type');

  const section_layer_all = section.querySelectorAll('.section-layer');

  let data_category = null;

  let product_layer = null;

  section_layer_all.forEach(section_layer_each);

  function section_layer_each(section_layer) {
    const layer = section_layer.getAttribute('data-layer');

    const hidden_previous = section_layer.querySelector('.hidden_previous');
    const hidden_next = section_layer.querySelector('.hidden_next');

    const indicate_previous = section_layer.querySelector('.indicate_previous');
    const indicate_next = section_layer.querySelector('.indicate_next');

    const content_list = section_layer.querySelector('.layer-content > .content-list');

    let list_unit_all = section_layer.querySelectorAll('.content-list > .list-unit');

    let prev_hidden_unit_index = null;

    let next_visible_unit_index = null;
    let next_hidden_unit_index = null;

    // Добавляем события на слой категорий
    if (layer && layer.includes('category')) category_interactive();
    if (layer && layer.includes('product')) product_interactive();

    // Добавляем события на слой комментариев
    if (layer && layer.includes('comment')) reply_interactive();

    // События на слой отзывов
    if (layer && layer.includes('review-alt')) reply_interactive();

    // Сравнение ширины списка с шириной контейнера
    setTimeout(() => {
      layer_scrollable();
    }, 3000)

    let unit_indent = 0;

    if (list_unit_all > 1) {
      const first_unit_rect = list_unit_all[0].getBoundingClientRect();
      const second_unit_rect = list_unit_all[1].getBoundingClientRect();

      // Вычесляем отступ между элементами
      unit_indent = second_unit_rect.left - first_unit_rect.right;
    }

    let list_unit_length = null;

    const first_unit_index = 0;

    // Количество элементов в прокрутке
    const layer_shift_quantity = 2;

    let visible_unit = null;
    let hidden_unit = null;

    let initial_indent_horizontal = null;

    // Переменная сдвига
    let shift_horizontal = 0;

    // Если 'true', запрещаем скролл
    let transition = false;

    indicate_previous.addEventListener('click', indicate_previous_click);
    indicate_next.addEventListener('click', indicate_next_click);

    // Снимаем запрет скролла по окончанию анимации
    content_list.addEventListener('transitionend', content_list_transitionend);

    // Основные функции
    function indicate_previous_click() {
      const layer_rect = section_layer.getBoundingClientRect();

      hidden_verify(hidden_previous);

      indicate_verify(indicate_next);

      if (!transition) {
        transition = true;

        // Добавляем анимацию
        content_list.classList.add('list_transition');

        if (!prev_hidden_unit_index) {
          // Получаем индекс нужных элементов
          list_unit_all.forEach(list_unit_each);
          next_hidden_unit_index = next_hidden_unit_index - layer_shift_quantity;
        } else {
          prev_hidden_unit_index = prev_hidden_unit_index - layer_shift_quantity;
          next_hidden_unit_index = next_hidden_unit_index - layer_shift_quantity;
        }

        // Получаем нужный элемент для скролла
        if (prev_hidden_unit_index < first_unit_index) {
          hidden_unit = list_unit_all[first_unit_index];
        } else {
          hidden_unit = list_unit_all[prev_hidden_unit_index];
        }


        let indent_horizontal = 0;

        if (prev_hidden_unit_index > first_unit_index) {
          // Скрываем градиент
          hidden_verify_alt(hidden_next);

          // Если индекс нужного элемента меньше последнего то учитываем отступ
          indent_horizontal = initial_indent_horizontal;
        } else {
          // Отключаем скролл определенной стороны по окончанию ее элементов
          disable_scroll(hidden_next, hidden_previous, indicate_previous);
        }

        const hiddenLeft = hidden_unit.offsetLeft;
        const hiddenWidth = hidden_unit.offsetWidth;

        // Вычесляем значение сдвига списка
        shift_horizontal = shift_horizontal - ((shift_horizontal - hiddenLeft) + indent_horizontal);

        if (shift_horizontal < 0) {
          shift_horizontal = 0;

          prev_hidden_unit_index = null;
          next_hidden_unit_index = null;

          // Отключаем скролл определенной стороны по окончанию ее элементов
          disable_scroll(hidden_next, hidden_previous, indicate_previous);
        }

        // Двигаем список
        content_list.style.transform = `translate3d(-${ Math.round(shift_horizontal) }px, 0, 0)`;
      }

      function list_unit_each(list_unit, unit_index) {
        const unit_rect = list_unit.getBoundingClientRect();

        if (unit_rect.left < layer_rect.left) {
          const difference_index = unit_index + 1;

          prev_hidden_unit_index = difference_index - layer_shift_quantity;
        }
      }
    }

    function indicate_next_click() {
      if (!unit_indent) {
        const first_unit_rect = list_unit_all[0].getBoundingClientRect();
        const second_unit_rect = list_unit_all[1].getBoundingClientRect();

        // Вычесляем отступ между элементами
        unit_indent = second_unit_rect.left - first_unit_rect.right;
      }

      const layer_rect = section_layer.getBoundingClientRect();

      // Получаем количество элементов
      list_unit_length = list_unit_all.length;

      hidden_verify(hidden_next);

      indicate_verify(indicate_previous);

      let last_unit_index = list_unit_length - 1;

      if (!transition) {
        transition = true;

        // Добавляем анимацию
        content_list.classList.add('list_transition');

        if (!next_hidden_unit_index) {
          // Получаем индекс нужных элементов
          list_unit_all.forEach(list_unit_each);
        } else {
          next_hidden_unit_index = next_hidden_unit_index + layer_shift_quantity;
        }

        if (prev_hidden_unit_index) prev_hidden_unit_index = prev_hidden_unit_index + layer_shift_quantity;

        // Получаем первый видимый элемент
        visible_unit = list_unit_all[next_visible_unit_index];

        // Получаем нужный элемент для скролла
        if (next_hidden_unit_index > last_unit_index) {
          hidden_unit = list_unit_all[last_unit_index];
        } else {
          hidden_unit = list_unit_all[next_hidden_unit_index];
        }

        const hiddenLeft = hidden_unit.offsetLeft;
        const hiddenWidth = hidden_unit.offsetWidth;

        const layerWidth = section_layer.offsetWidth;

        if (!initial_indent_horizontal) {
          // Получаем отступ от последнего видимого элемента до края видимого слоя
          initial_indent_horizontal = layerWidth - visible_unit.offsetLeft + Math.round(unit_indent);
        }

        let indent_horizontal = 0;

        if (next_hidden_unit_index < last_unit_index) {
          // Скрываем градиент
          hidden_verify_alt(hidden_previous);

          // Если индекс нужного элемента меньше последнего то учитываем отступ
          indent_horizontal = initial_indent_horizontal;
        } else {
          // Отключаем скролл определенной стороны по окончанию ее элементов
          disable_scroll(hidden_previous, hidden_next, indicate_next);
        }

        // Вычесляем значение сдвига списка
        shift_horizontal = shift_horizontal + ((hiddenLeft + hiddenWidth - layerWidth) - shift_horizontal) + indent_horizontal;

        // Двигаем список
        content_list.style.transform = `translate3d(-${ Math.round(shift_horizontal) }px, 0, 0)`;
      }

      function list_unit_each(list_unit, unit_index) {
        const unit_rect = list_unit.getBoundingClientRect();

        if (!next_hidden_unit_index) {
          if (unit_rect.right > layer_rect.right) {
            const difference_index = unit_index - 1;

            next_visible_unit_index = unit_index;

            next_hidden_unit_index = difference_index + layer_shift_quantity;
          }
        }
      }
    }

    // Функции для отдельных слоев
    function category_interactive() {
      list_unit_all.forEach(list_unit_each);

      let product_list = null;

      // Наблюдаем за изминениями в переменных
      let product_layer_interval = setInterval(product_layer_observer, 100);
      let product_list_interval = setInterval(product_list_observer, 100);

      function list_unit_each(list_unit) {
        const unit_category = list_unit.getAttribute('data-category');

        if (list_unit.classList.contains('unit_selected')) data_category = unit_category;
      }

      function product_layer_observer() {
        if (product_layer) product_list = product_layer.querySelector('.layer-content > .content-list');
      }

      function product_list_observer() {
        if (product_list) {
          list_unit_all.forEach(category_unit_each);

          clearInterval(product_layer_interval);
          clearInterval(product_list_interval);

          function category_unit_each(category_unit) {
            const category = category_unit.getAttribute('data-category');
            const product = section.getAttribute('data-product');

            category_unit.addEventListener('click', category_unit_click);

            async function category_unit_click() {
              if (!category_unit.classList.contains('unit_selected')) {
                list_unit_all.forEach(list_unit_each);

                category_unit.classList.add('unit_selected');
                let url = `/api/products/${ section_type }/?category=${ category }`
                if (product) {
                  url += `&product=${product}`
                }
                const response = await fetch(
                  url,
                  {
                    headers: {
                      'Accept': 'application/json'
                    }
                  }
                );

                if (response.ok) {
                  const result = await response.json();

                  ReactDOM.unmountComponentAtNode(product_list);

                  ReactDOM.render(result.map(product_map), product_list);
                }
              }

              function list_unit_each(list_unit) {
                if (list_unit.classList.contains('unit_selected')) {
                  list_unit.classList.remove('unit_selected');
                }
              }

              function product_map(product_unit, unit_index) {
                return (
                  <div className={"list-unit product-unit"}>
                    <ProductCard key={ unit_index } {...product_unit} isDesktop />
                  </div>
                );
              }
            }
          }
        }
      }
    }

    async function product_interactive() {
      // Передаем текущий слой для взаимодействия с категориями
      product_layer = section_layer;

      // Наблюдаем за изменениями в списке элементов
      const content_list_observer = new MutationObserver(observer_callback);

      content_list_observer.observe(content_list, { childList: true });

      if (data_category) {
        const response = await fetch(
          `/api/products/${ section_type }?category=${ data_category }`,
          {
            headers: {
              'Accept': 'application/json'
            }
          }
        );

        if (response.ok) {
          const result = await response.json();

          ReactDOM.render(result.map(product_map), content_list);
        }
      }

      function observer_callback() {
        shift_horizontal = 0;

        content_list.style.transform = `translate3d(0, 0, 0)`;

        prev_hidden_unit_index = null;
        next_visible_unit_index = null;
        next_hidden_unit_index = null;

        layer_scrollable();

        hidden_verify(hidden_next);
        hidden_verify_alt(hidden_previous);

        indicate_verify_alt(indicate_previous);

        list_unit_all = section_layer.querySelectorAll('.content-list > .list-unit');
      }

      function product_map(product_unit, unit_index) {
        return (
          <div className={"list-unit product-unit"}>
            <ProductCard key={ unit_index } {...product_unit} isDesktop />
          </div>
        );
      }
    }

    function reply_interactive() {
      list_unit_all.forEach(reply_unit_each);

      function reply_unit_each(reply_unit) {
        const unit_list = reply_unit.querySelector('.unit-list');

        const layer_content = reply_unit.querySelector('.layer-content');

        const button_answer = reply_unit.querySelector('.button_answer');
        const button_expand = reply_unit.querySelector('.button_expand');

        button_answer.addEventListener('click', button_answer_click);
        button_expand.addEventListener('click', button_expand_click);

        document.addEventListener('click', document_click);

        function button_answer_click() {
          const answer_overlay = button_answer.getAttribute('data-overlay');

          const overlay = document.querySelector(`.overlay[data-overlay="${ answer_overlay }"]`);

          if (overlay && overlay.style.display != 'flex') {
            overlay.style.display = 'flex';

            correct_width(true);
          }
        }

        function button_expand_click() {
          if (unit_list.classList.contains('list_hidden')) {
            layer_content.classList.add('content_expanded');

            button_expand.textContent = 'Свернуть';

            unit_list.classList.remove('list_hidden');
          } else {
            layer_content.classList.remove('content_expanded');

            button_expand.textContent = 'Развернуть';

            unit_list.classList.add('list_hidden');
          }
        }

        function document_click({ target }) {
          if (!reply_unit.contains(target)) {
            if (!unit_list.classList.contains('list_hidden')) {
              layer_content.classList.remove('content_expanded');

              button_expand.textContent = 'Развернуть';

              unit_list.classList.add('list_hidden');
            }
          }
        }
      }
    }

    // Вспомогательные функции
    function layer_scrollable() {
      if (content_list.scrollWidth > section_layer.clientWidth) {
        // Показываем стрелку 'далее'
        indicate_verify(indicate_next);
      } else {
        // Скрываем градиент
        hidden_verify_alt(hidden_next);

        // Скрываем стрелку
        indicate_verify_alt(indicate_next);
      }
    }

    function disable_scroll(hidden_first, hidden_second, indicate) {
      hidden_verify(hidden_first);

      indicate_verify_alt(indicate);

      content_list.addEventListener('transitionend', () => hidden_verify_alt(hidden_second), { once: true });
    }

    function content_list_transitionend() {
      transition = false;

      // Убираем анимацию
      content_list.classList.remove('list_transition');
    }

    function hidden_verify(hidden) {
      if (!hidden.classList.contains('hidden_visible')) {
        hidden.classList.add('hidden_visible');

        setTimeout(() => hidden.style.opacity = '1', 20);
      }
    }

    function hidden_verify_alt(hidden) {
      if (hidden.classList.contains('hidden_visible')) {
        hidden.style.opacity = '0';

        hidden.addEventListener('transitionend', () => hidden_transitionend(hidden), { once: true });
      }
    }

    function indicate_verify(indicate) {
      if (indicate.classList.contains('indicate_hidden')) {
        indicate.classList.remove('indicate_hidden');

        setTimeout(() => indicate.style.opacity = '1', 20);
      }
    }

    function indicate_verify_alt(indicate) {
      if (!indicate.classList.contains('indicate_hidden')) {
        indicate.style.opacity = '0';

        indicate.addEventListener('transitionend', () => indicate_transitionend(indicate), { once: true });
      }
    }

    function hidden_transitionend(hidden) {
      hidden.classList.remove('hidden_visible');
    }

    function indicate_transitionend(indicate) {
      indicate.classList.add('indicate_hidden');
    }
  }
}
