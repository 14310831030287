import styles from './Rating.modules.scss'

import React from "react"
import cn from 'classnames'

import StarIcon from '../icons/Star'

const Rating = ({rating}) => {

  return (
    <div className={styles.root}>
      <StarIcon width={17} height={16}/>
      <div className={styles.rating}>{rating}</div>
    </div>
  )
}

export default Rating
