import styles from "./BuyOneClickButton.module.scss"

import React, {useEffect, useRef, useState} from "react"
import {Provider} from "react-redux"
import cn from "classnames"
import {useForm, Controller} from "react-hook-form"

import {Button} from "../shared/Button"
import {InputField} from "../ordering/shared"
import {useMultiRefsOutsideClicker} from "../../../js/lib/hooks"
import {customerApi} from "../../../js/redux/slices"
import {store} from "../../../js/redux/store"
import {orderingApi} from "../../../js/api/orderingApi"
import {geWarningData, showWarning} from "../ordering/OrderingScreen/model"
import LoadingDots from "../icons/LoadingDots"
import {sendOrderToYM} from "../../../js/lib/yandex"

const BuyOneClickButton = ({productSlug, isMobile}) => {
  const {data: customer} = customerApi.useGetMeQuery()

  const initialPhone = customer?.data?.phone

  const [showPopup, setShowPopup] = useState(false)
  const [timer, setTimer] = useState(null)
  const [touched, setTouched] = useState(false)
  const [loading, setLoading] = useState(false)

  const {
    control,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      phone: initialPhone || "",
    },
  })

  useEffect(() => {
    if (initialPhone) setValue("phone", initialPhone)
  }, [initialPhone])

  const ref = useRef(null)

  const onSubmitHandler = async (data) => {
    const orderData = {
      "contacts": data,
      "comment": "Покупка в 1 клик",
      "type": "ONE_CLICK",
      "productSlug": productSlug
    }
    setLoading(true)
    await orderingApi.createOrder(orderData).then(response => {
      if (response.status === 200) {
        const warningData = geWarningData(response.data.status)
        showWarning(warningData)
        if (response.data.status === "success") {
          sendOrderToYM(response.data.data)
        }
      }
      setTimeout(() => {
        setLoading(true)
        window.location = "/"
      }, 3000)
    })
  }

  const onMouseOverHandler = () => {
    if (timer) clearTimeout(timer)
    setTimer(setTimeout(() => {
      setShowPopup(true)
    }, 100))
  }

  const onMouseOutHandler = () => {
    if (!touched) {
      setTimer(setTimeout(() => {
        setShowPopup(false)
      }, 300))
    }
  }

  const onFocusField = () => {
    setTouched(true)
  }

  useMultiRefsOutsideClicker([ref], () => {
    setShowPopup(false)
    setTouched(false)
  }, [])

  return (
    <div
      ref={ref}
      className={cn(styles.root, isMobile && styles.mobile)}
      onMouseOver={onMouseOverHandler}
      onMouseOut={onMouseOutHandler}
    >
      <Button
        title={!loading ? "Купить в 1 клик" : <LoadingDots color={"#EF4123"} />}
        variant={"secondary"}
        style={{padding: "0 27px"}}
        disabled={loading}
      />
      <div className={styles.popupWrapper}>
        <div className={cn(styles.popup,showPopup && styles.show)}>
          <form onSubmit={handleSubmit(onSubmitHandler)} className={styles.form}>
            <div className={styles.fieldLayer}>
              <span className={styles.label}>Введите номер телефона по которому можно связаться с вами</span>
              <Controller
                control={control}
                name={"phone"}
                render={({field: {onChange, value}}) => (
                  <InputField
                    placeholder={"Номер телефона"}
                    value={value}
                    errorText={errors?.phone?.message?.toString()}
                    onChange={value => onChange(value)}
                    onFocus={onFocusField}
                    className={styles.field}
                  />
                )}
                rules={{
                  required: "* Введите номер телефона",
                  minLength: {
                    value: 10,
                    message: "* Не верный номер телефона"
                  },
                  maxLength: {
                    value: 13,
                    message: "* Не верный номер телефона"
                  }
                }}
              />
            </div>
            <Button
              title={!loading ? "Купить в 1 клик" : <LoadingDots/>}
              type={"submit"}
              className={styles.button}
              disabled={loading}
            />
          </form>
        </div>
      </div>
    </div>
  )
}

const BuyOneClickButtonContainer = (props) => (
  <Provider store={store}>
    <BuyOneClickButton {...props}/>
  </Provider>
)

export {BuyOneClickButtonContainer as BuyOneClickButton}
