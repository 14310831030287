const header_upper = document.querySelector('.header-upper');

const product = document.querySelector('.product');
const product_content = document.querySelector('.product-content');

if (product_content) {
  const product_id = product.getAttribute('data-id');

  // Половина отступа страницы от хлебных крошек
  const product_indent = 48 / 2;

  // Высота шапки сайта
  const header_upper_height = header_upper.clientHeight;

  // Вычисляем позиционирование для липкого элемента
  product_content.style.top = `${ header_upper_height + product_indent }px`;

  const unit_service = product_content.querySelector('.content-unit > .unit-service');

  if (unit_service) {
    // Перебираем все списки услуг
    let list_all = unit_service.querySelectorAll('.section-list');
    let item_all = unit_service.querySelectorAll('input[type="checkbox"]');

    list_all.forEach(list_each);

    function list_each(list) {
      const checkbox_all = list.querySelectorAll('input[type="checkbox"]');

      checkbox_all.forEach(checkbox_each);

      function checkbox_each(checkbox, index) {
        checkbox.addEventListener('change', checkbox_change);

        async function checkbox_change() {
          const data_init = unit_service.getAttribute('data-init');

          const is_checked = checkbox.checked;

          checkbox_all.forEach(unchecked_each);

          const services = [];

          if (is_checked) {
            services.push(checkbox.value);
          }

          if (data_init) {
            if (is_checked) {
              checkbox.checked = false;
            }

            for (let item of unit_service.querySelectorAll('input[type="checkbox"]')) {
              if (item.checked) {
                services.push(item.value);
              }
            }

            if (!is_checked) {
              checkbox.checked = true;
            }

            const response = await fetch(
              '/api/cart/add/',
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'X-CSRFToken': document.querySelector(`input[name='csrfmiddlewaretoken']`).value,
                },
                body: JSON.stringify(
                  {
                    'product_id': product_id,
                    'services': services,
                  }
                ),
              }
            );

            if (response.ok) {
              if (is_checked) {
                checkbox.checked = true;
              } else {
                checkbox.checked = false;
              }
            }
          }

          function unchecked_each(checkbox, inner_index) {
            if (inner_index != index) {
              checkbox.checked = false;
            }
          }
        }
      }
    }
  }
}

const productContent = document.querySelector('.product-content');

if (productContent) {
  const itemDelivery = productContent.querySelector('.content-item[data-item="delivery"]');

  if (itemDelivery) {
    const itemInfo = itemDelivery.querySelector('.item-info');
    const itemContent = itemDelivery.querySelector('.item-content');
    const itemControl = itemDelivery.querySelector('.item-control');

    const contentUnit = itemContent.querySelector('.content-unit[data-unit="pickup"]');

    const infoControl = itemInfo.querySelector('.info-control');

    if (infoControl) {
      const controlButton = infoControl.querySelector('.control-button');
      const controlLayer = infoControl.querySelector('.control-layer');

      controlButton.addEventListener('click', buttonClick);

      document.addEventListener('click', documentClick);

      const buttonText = controlButton.querySelector('.info-text');
      const buttonIcon = controlButton.querySelector('.info-icon');

      const itemAll = controlLayer.querySelectorAll('.list-item');

      itemAll.forEach(itemEach);

      /**
       * @param {Element} item
       */
      function itemEach(item) {
        const itemText = item.querySelector('.item-text');

        item.addEventListener('click', itemClick);

        function itemClick() {
          const isSelected = item.classList.contains('_selected');
          const isDisabled = item.classList.contains('_disabled');

          if (isSelected) {
            hideLayer(controlLayer);

            // Завершаем функцию
            return;
          }

          unitDisable(isDisabled);

          switchSelected(controlLayer.querySelector('._selected'), item);

          // Записываем новое значение
          buttonText.textContent = itemText.textContent;

          hideLayer(controlLayer);
        }
      }

      /**
       * @param {MouseEvent} event
       */
      function documentClick(event) {
        const { target } = event;

        if (!infoControl.contains(target)) {
          hideLayer(controlLayer);
        }
      }

      function buttonClick() {
        toggleLayer(controlLayer);
      }
    }

    if (itemControl) {
      const controlButton = itemControl.querySelector('.control-button');

      controlButton.addEventListener('click', buttonClick);

      function buttonClick() {
        const inset = document.querySelector('.product-inset');

        inset.scrollIntoView(
          {
            behavior: 'smooth',
          }
        );

        if (inset) {
          const insetButton = inset.querySelector('.control-button[data-hash="#shop"]');
          const insetTab = inset.querySelector('.content-tab[data-hash="#shop"]');

          // Вызываем событие на кнопке вкладки
          insetButton.click();
        }
      }
    }

    /**
     * @param {Boolean} disable
     */
    function unitDisable(disable) {
      if (disable) {
        if (!isDisabled(contentUnit)) {
          contentUnit.classList.add('_disabled');
        }
      } else {
        if (isDisabled(contentUnit)) {
          contentUnit.classList.remove('_disabled');
        }
      }
    }

    /**
     * @param {Element} prevSelected
     * @param {Element} nextSelected
     */
    function switchSelected(prevSelected, nextSelected) {
      prevSelected.classList.remove('_selected');
      nextSelected.classList.add('_selected');
    }

    function toggleLayer(layer) {
      if (!isShow(layer)) {
        showLayer(layer);
      } else {
        hideLayer(layer);
      }
    }

    /**
     * @param {Element} layer
     */
    function showLayer(layer) {
      layer.classList.add('_show');
    }

    /**
     * @param {Element} layer
     */
    function hideLayer(layer) {
      layer.classList.remove('_show');
    }

    /**
     * @param {Element} element
     */
     function isShow(element) {
      return element.classList.contains('_show');
    }

    /**
     * @param {Element} element
     */
     function isDisabled(element) {
      return element.classList.contains('_disabled');
    }
  }
}
