const personal = document.querySelector('.personal');

if (personal) {
  const personal_section_all = personal.querySelectorAll('.personal-section[data-verify]');

  personal_section_all.forEach(personal_section_each);

  function personal_section_each(personal_section) {
    const content_control_all = personal_section.querySelectorAll('.content-control[data-validate]');

    content_control_all.forEach(content_control_each);

    function content_control_each(content_control) {
      if (!content_control.value.length) {
        content_control.setAttribute('data-validate', 'false');
      } else {
        content_control.setAttribute('data-validate', 'true');
      }
    }
  }

  const personal_address = personal.querySelector('.personal-address');

  const section_view = personal_address.querySelector('.address-section[data-section="address-view"]');
  const section_add = personal_address.querySelector('.address-section[data-section="address-add"]');

  const button_change = section_view.querySelector('.button_change');

  button_change.addEventListener('click', button_change_click);

  function button_change_click() {
    section_view.classList.add('section_hidden');
    section_add.classList.remove('section_hidden');
  }
}
