import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./CardTags.module.scss";
import { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { ArrowDownIcon, Tag } from "@src/shared/ui";
export var CardTags = function (_a) {
    var _b;
    var _c = _a.isDesktop, isDesktop = _c === void 0 ? false : _c, tags = _a.tags, _d = _a.isExpanded, isExpanded = _d === void 0 ? false : _d, className = _a.className, onShow = _a.onShow;
    var _e = useState(0), tagsHeight = _e[0], setTagsHeight = _e[1];
    var tagsRef = useRef(null);
    useEffect(function () {
        if (tagsRef.current) {
            var height = tagsRef.current.offsetHeight;
            setTagsHeight(height);
        }
    }, []);
    var handleClosePopup = function (event) {
        event.preventDefault();
        onShow(false);
    };
    var handleToggleIsExpanded = function (event) {
        event.preventDefault();
        onShow(!isExpanded);
    };
    var mainClasses = cn(styles.main, className, (_b = {},
        _b[styles.desktop] = isDesktop,
        _b[styles.open] = isExpanded,
        _b));
    return (_jsxs("div", { className: mainClasses, children: [_jsx("div", { className: styles.tag, children: tags.length > 0 && (_jsx(Tag, { backgroundColor: tags[0].color, isDesktop: true, children: tags[0].name })) }), _jsxs("div", { className: styles.allTagsLayer, children: [_jsx("div", { className: styles.overlay, onClick: handleClosePopup }), _jsx("div", { className: styles.tagsWrapper, style: { maxHeight: isExpanded ? "".concat(tagsHeight, "px") : "22px" }, children: _jsx("div", { ref: tagsRef, className: styles.tags, children: tags.map(function (el, index) { return (_jsx(Tag, { backgroundColor: el.color, isDesktop: isDesktop, children: el.name }, index)); }) }) })] }), tags.length > 1 && (_jsx("div", { className: styles.arrow, onClick: handleToggleIsExpanded, children: _jsx(ArrowDownIcon, {}) }))] }));
};
