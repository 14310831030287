import { useEffect } from "react";
export var useToggleBodyOverflow = function (isOpen) {
    useEffect(function () {
        var hideOverflowTimer;
        if (isOpen) {
            hideOverflowTimer = setTimeout(function () {
                document.body.style.overflow = "hidden";
            }, 0);
        }
        else {
            document.body.style.overflow = "";
        }
        return function () {
            clearTimeout(hideOverflowTimer);
            document.body.style.overflow = "";
        };
    }, [isOpen]);
};
