import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "../styles.module.scss";
import { HintsSection } from "../HintsSection";
import { ProductsCardsSection } from "../ProductsCardsSection";
import { SearchPopupSkeleton } from "../SearchPopupSkeleton";
import { selectSearchQuery, CategoriesSection } from "@src/entities/search-popup";
import { useGetHintsQuery, useSearchByQueryQuery, useUpdateOftenProductIndexMutation, } from "@src/shared/api";
import { useAppSelector } from "@src/shared/hooks";
import { Separator } from "@src/shared/ui";
export var FoundDataSection = function (_a) {
    var _b, _c;
    var _d = _a.isDesktop, isDesktop = _d === void 0 ? false : _d, onSelectSearchQuery = _a.onSelectSearchQuery, onSelectCatalog = _a.onSelectCatalog;
    var searchQuery = useAppSelector(selectSearchQuery);
    var _e = useSearchByQueryQuery(searchQuery), results = _e.data, isFetchingResults = _e.isFetching;
    var _f = useGetHintsQuery(searchQuery), hints = _f.data, isFetchingHints = _f.isFetching;
    var updateOftenProduct = useUpdateOftenProductIndexMutation()[0];
    var isFoundProducts = !!((_b = results === null || results === void 0 ? void 0 : results.products) === null || _b === void 0 ? void 0 : _b.length);
    var isFoundCatalogs = !!((_c = results === null || results === void 0 ? void 0 : results.catalogs) === null || _c === void 0 ? void 0 : _c.length);
    if (isFetchingResults || isFetchingHints)
        return _jsx(SearchPopupSkeleton, { isDesktop: isDesktop });
    if (!isFoundProducts && !isFoundCatalogs && !hints)
        return null;
    var clickFoundProductHandler = function (productId) {
        updateOftenProduct(productId);
        onSelectSearchQuery({ text: searchQuery, catalog: null });
    };
    return (_jsxs(_Fragment, { children: [!!hints && (_jsx(HintsSection, { isDesktop: isDesktop, onSelectSearchQuery: onSelectSearchQuery })), isFoundProducts && (_jsxs(_Fragment, { children: [!!hints && _jsx(Separator, {}), _jsx("div", { className: styles.productsSection, children: _jsx(ProductsCardsSection, { products: results.products, isDesktop: isDesktop, onClickProduct: clickFoundProductHandler }) })] })), isFoundCatalogs && (_jsxs(_Fragment, { children: [isFoundProducts && _jsx(Separator, {}), _jsx(CategoriesSection, { categories: results.catalogs, isDesktop: isDesktop, onSelectCatalog: onSelectCatalog })] }))] }));
};
