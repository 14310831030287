const offer_unit_all = document.querySelectorAll('.offer-unit');

if (offer_unit_all.length) {
  offer_unit_all.forEach(offer_unit_each);
}

function offer_unit_each(offer_unit) {
  let countdown_end = offer_unit.getAttribute('data-countdown');

  const unit_notify = offer_unit.querySelector('.unit-notify');

  unit_notify.classList.remove('notify_hidden');

  const notify_countdown = unit_notify.querySelector('.notify-countdown');

  notify_countdown.textContent = countdown_time(--countdown_end);

  let interval_identifier = setInterval(button_countdown_interval, 1000);

  function button_countdown_interval() {
    notify_countdown.textContent = countdown_time(countdown_end--);

    if (countdown_end < 0) clearInterval(interval_identifier);
  }

  function countdown_time(countdown_end) {
    let hs = Math.floor(countdown_end / 3600 % 24);
    let ms = Math.floor(countdown_end / 60 % 60);
    let ss = Math.floor(countdown_end % 60);

    // Возвращаем время в формате '00:00:00'
    return `${ hs < 10 ? '0' + hs : hs }:${ ms < 10 ? '0' + ms : ms }:${ ss < 10 ? '0' + ss : ss }`;
  }
}
