import './checks.scss';

const checks = document.querySelector('.checks');

if (checks) {
  const checksControl = checks.querySelector('.checks-control');
  const checksContent = checks.querySelector('.checks-content');

  const controlAll = checksControl.querySelectorAll('.list-item');
  const contentAll = checksContent.querySelectorAll('.content-tab');

  controlAll.forEach(controlEach);

  function controlEach(control) {
    const dataIndex = Number(control.getAttribute('data-index'));

    control.addEventListener('click', controlClick);

    function controlClick() {
      const find = checksControl.querySelector('.list-item._active');

      find.classList.remove('_active');
      control.classList.add('_active');

      contentAll.forEach((content) => contentEach(content, dataIndex));
    }
  }

  function contentEach(content, index) {
    const dataIndex = Number(content.getAttribute('data-index'));

    if (dataIndex != index) content.classList.remove('_active');
    if (dataIndex == index) content.classList.add('_active');
  }
}
