import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./Mobile.module.scss";
import { useState } from "react";
import cn from "classnames";
import { Bonus } from "../../Bonus";
import { ProductImages } from "../../CardImages";
import { CardTags } from "../../CardTags";
import { OldPrice } from "../../OldPrice";
import { Rating } from "../../Rating";
import { formattedPrice } from "@shared/lib";
import { SmallButton, Text } from "@shared/ui";
export var Mobile = function (_a) {
    var _b;
    var images = _a.images, rating = _a.rating, reviewsCount = _a.reviewsCount, url = _a.url, sku = _a.sku, name = _a.name, bonusesAmount = _a.bonusesAmount, price = _a.price, discountedPrice = _a.discountedPrice, slots = _a.slots, status = _a.status, tags = _a.tags;
    var isAvailable = status.code === "IN_STOCK" || status.code === "LIMITED";
    var currentButton = isAvailable ? (slots === null || slots === void 0 ? void 0 : slots.inCartButton) : (_jsx(SmallButton, { text: "\u041D\u0435\u0442 \u0432 \u043D\u0430\u043B\u0438\u0447\u0438\u0438", isDisabled: true }));
    var _c = useState(false), isVisibleTags = _c[0], setIsVisibleTags = _c[1];
    var handleToggleVisibility = function (value) {
        setIsVisibleTags(value);
    };
    return (_jsxs("a", { href: url, className: styles.main, children: [_jsx(CardTags, { className: styles.cardTags, tags: tags, isExpanded: isVisibleTags, onShow: handleToggleVisibility }), _jsx("div", { className: styles.favourite, children: slots === null || slots === void 0 ? void 0 : slots.favouriteBtn }), _jsx("div", { className: styles.compare, children: slots === null || slots === void 0 ? void 0 : slots.compareBtn }), _jsx("div", { className: styles.images, children: _jsx(ProductImages, { images: images.slice(0, 7), isNotAvailable: !isAvailable }) }), _jsxs("div", { className: styles.ratingWrapper, children: [reviewsCount > 0 && (_jsx(Rating, { rating: rating, reviewsCount: reviewsCount })), _jsx(Text, { color: "#C0C0C0", variant: "body2", children: sku })] }), _jsx("div", { className: styles.nameWrapper, children: _jsx(Text, { variant: "body1", className: styles.name, children: name }) }), _jsxs("div", { className: styles.bonus, children: [price !== discountedPrice && (_jsx(OldPrice, { price: price, discountedPrice: discountedPrice })), bonusesAmount > 0 && _jsx(Bonus, { bonusesAmount: bonusesAmount })] }), _jsxs("div", { className: cn(styles.purchase, (_b = {}, _b[styles.notAvailable] = !isAvailable, _b)), children: [_jsxs(Text, { variant: "bodyPrice", className: styles.price, children: [formattedPrice(discountedPrice), " \u20BD"] }), currentButton] })] }));
};
