import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./styles.module.scss";
import cn from "classnames";
import { ProductAttributesList } from "./ProductAttributesList";
import { TabInfo } from "./TabInfo";
import { useGetGroupedAttributesQuery } from "@src/shared/api";
import { withStore } from "@src/shared/hoc";
var ProductAttributesTab = function (_a) {
    var productSlug = _a.productSlug, _b = _a.isDesktop, isDesktop = _b === void 0 ? false : _b;
    var data = useGetGroupedAttributesQuery({ productSlug: productSlug }).data;
    if (!data)
        return null;
    return (_jsxs("div", { className: cn(styles.root, isDesktop && styles.desktop), children: [_jsx(ProductAttributesList, { groupedAttributes: data, isDesktop: isDesktop }), _jsx(TabInfo, { isDesktop: isDesktop })] }));
};
var ProductAttributesTabWithStore = withStore(ProductAttributesTab);
export { ProductAttributesTabWithStore as ProductAttributesTab };
