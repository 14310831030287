import {createSlice} from "@reduxjs/toolkit"

const initialState = {
  status: "idle",
  currentStep: "CART",
  data: {
    contacts: null,
    receiving: {
      method: "DELIVERY",
      deliveryAddress: null,
      pickupAddress: null
    },
    paymentMethod: null,
    comment: null,
    spendBonuses: false
  },
}

export const orderingOldSlice = createSlice({
  name: "orderingOld",
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload
    },
    setContactsData: (state, action) => {
      state.data.contacts = action.payload
    },
    setReceivingMethod: (state, action) => {
      state.data.receiving.method = action.payload
    },
    setDeliveryAddressData: (state, action) => {
      state.data.receiving.deliveryAddress = action.payload
    },
    setPickupAddressData: (state, action) => {
      state.data.receiving.pickupAddress = action.payload
    },
    setPaymentMethod: (state, action) => {
      state.data.paymentMethod = action.payload
    },
    setComment: (state, action) => {
      state.data.comment = action.payload
    },
    setSpendBonuses: (state, action) => {
      state.data.spendBonuses = action.payload
    }
  }
})

export const selectOrderingStatus = (state) => state.orderingOld.status

export const selectCurrentStep = (state) => state.orderingOld.currentStep

export const selectContactsData = (state) => state.orderingOld.data.contacts

export const selectReceivingMethod = (state) => state.orderingOld.data.receiving.method

export const selectDeliveryAddressData = (state) => state.orderingOld.data.receiving.deliveryAddress

export const selectPickupAddressData = (state) => state.orderingOld.data.receiving.pickupAddress

export const selectPaymentMethod = (state) => state.orderingOld.data.paymentMethod

export const selectComment = (state) => state.orderingOld.data.comment

export const selectSpendBonuses = (state) => state.orderingOld.data.spendBonuses

export const selectOrderingData = (state) => state.orderingOld.data

export const {
  setStatus,
  setCurrentStep,
  setContactsData,
  setReceivingMethod,
  setDeliveryAddressData,
  setPickupAddressData,
  setPaymentMethod,
  setComment,
  setSpendBonuses
} = orderingOldSlice.actions

export default orderingOldSlice.reducer
