import styles from "./styles.modules.scss"

import React from "react"
import cn from "classnames"

import {numberWithSpaces} from "../../../shared"
import {wordByCount} from "../../../shared"

export const TitleRow = ({link, title, price, servicesCount, isMobile = false}) => (
  <div className={cn(styles.root, isMobile && styles.mobile)}>
    <div className={styles.content}>
      <a href={link}>
        <span className={styles.title}>{title}</span>
      </a>
      {!!(isMobile && servicesCount) && (
        <span
          className={styles.servicesCount}>+{servicesCount} {wordByCount(servicesCount, "услуга", "услуги", "услуг")}</span>
      )}
      <span className={styles.price}>{numberWithSpaces(price)} ₽</span>
    </div>
    {!!(!isMobile && servicesCount) && (
      <span
        className={styles.servicesCount}>+{servicesCount} {wordByCount(servicesCount, "услуга", "услуги", "услуг")}</span>
    )}
  </div>
)
