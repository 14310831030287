import { jsx as _jsx } from "react/jsx-runtime";
import styles from "./Mobile.module.scss";
import { useState } from "react";
import cn from "classnames";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import placeholder from "@src/assets/images/placeholder.png";
export var Mobile = function (_a) {
    var _b;
    var images = _a.images, isNotAvailable = _a.isNotAvailable, _c = _a.withPagination, withPagination = _c === void 0 ? true : _c;
    var _d = useState(false), isLoaded = _d[0], setIsLoaded = _d[1];
    var pagination = {
        clickable: false,
        renderBullet: function (index, className) {
            return "<span class=\"".concat(className, "\"></span>");
        },
        horizontalClass: styles.bullets,
        bulletClass: cn("swiper-pagination-bullet", styles.paginationBullet),
        bulletActiveClass: cn("swiper-pagination-bullet-active", styles.paginationBulletActive),
    };
    var placeholderItem = (_jsx("div", { className: styles.item, children: _jsx("img", { src: placeholder, alt: "", width: 70, height: 70 }) }));
    var mappedSlides = images.map(function (item, index) {
        var currentSrc = isLoaded || index === 0 ? item.src : placeholder;
        return (_jsx(SwiperSlide, { children: _jsx("div", { className: cn(styles.item, withPagination && styles.marginBottom30), children: _jsx("img", { className: styles.image, src: currentSrc, alt: "" }) }) }, item.id));
    });
    return (_jsx("div", { className: cn(styles.main, (_b = {}, _b[styles.notAvailable] = isNotAvailable, _b)), children: _jsx(Swiper, { loop: true, onSliderFirstMove: function () {
                setIsLoaded(true);
            }, pagination: pagination, modules: [Pagination], className: styles.swiper, children: images.length ? mappedSlides : placeholderItem }) }));
};
