const content_control_all = document.querySelectorAll('.content-control[data-validate]');

if (content_control_all.length) content_control_all.forEach(content_control_each);

function content_control_each(content_control) {
  const control_name = content_control.name;
  const control_placeholder = content_control.placeholder;

  let difference = null;

  content_control_init();

  content_control.addEventListener('input', content_control_input);
  content_control.addEventListener('focus', content_control_focus);
  content_control.addEventListener('blur', content_control_blur);

  function content_control_init() {
    if (control_name == 'advantage') content_control.value.length ? content_control.setAttribute('data-validate', 'true') : null;
    if (control_name == 'disadvantage') content_control.value.length ? content_control.setAttribute('data-validate', 'true') : null;
    if (control_name == 'comment') content_control.value.length ? content_control.setAttribute('data-validate', 'true') : null;
    if (control_name == 'text_msg') content_control.value.length ? content_control.setAttribute('data-validate', 'true') : null;

    if (control_name == 'card_number') {
      if (content_control.value.match(/^81[0-9]{9}/)) {
        content_control.setAttribute('data-validate', 'true');
      }
    }

    if (control_name == 'first_name') content_control.value.length ? content_control.setAttribute('data-validate', 'true') : null;
    if (control_name == 'last_name') content_control.value.length ? content_control.setAttribute('data-validate', 'true') : null;
    if (control_name == 'name') content_control.value.length ? content_control.setAttribute('data-validate', 'true') : null;

    if (control_name == 'phone') {
      if (content_control.value.length > 10) {
        content_control.setAttribute('data-validate', 'true');
      }
    }

    if (control_name == 'email') {
      if (content_control.value.match(/^[a-zа-я0-9_.-]+@[a-zа-я0-9]+[.][a-zа-я0-9]+/)) {
        content_control.setAttribute('data-validate', 'true');
      }
    }

    if (control_name == 'city') content_control.value.length ? content_control.setAttribute('data-validate', 'true') : null;
    if (control_name == 'street') content_control.value.length ? content_control.setAttribute('data-validate', 'true') : null;
    if (control_name == 'house') content_control.value.length ? content_control.setAttribute('data-validate', 'true') : null;

    if (control_name == 'apartment') content_control.value.length ? content_control.setAttribute('data-validate', 'true') : null;

    if (control_name == 'username') content_control.value.length ? content_control.setAttribute('data-validate', 'true') : null;

    if (control_name == 'password') content_control.value.length ? content_control.setAttribute('data-validate', 'true') : null;
    if (control_name == 'new_password') content_control.value.length ? content_control.setAttribute('data-validate', 'true') : null;
    if (control_name == 'old_password') content_control.value.length ? content_control.setAttribute('data-validate', 'true') : null;
    if (control_name == 'new_password1') content_control.value.length ? content_control.setAttribute('data-validate', 'true') : null;
    if (control_name == 'new_password2') content_control.value.length ? content_control.setAttribute('data-validate', 'true') : null;
  }

  function content_control_input() {
    if (!difference) {
      const offset_height = content_control.offsetHeight;
      const scroll_height = content_control.scrollHeight;

      // Получаем высоту рамок текстарии
      difference = offset_height - scroll_height;
    }

    if (control_name == 'card_number') {
      if (content_control.value.length > 11) {
        content_control.value = content_control.value.slice(0, 11);
      }
    }

    if (control_name == 'phone') {
      let value = content_control.value.replace(/[^0-9]+/g, '');


      // if (value.startsWith('380')) {
      //   value = value.slice(3);
      // } else if (value.startsWith('38')) {
      //   value = value.slice(2);
      // } else if (value.startsWith('3')) {
      //   value = value.slice(1);
      // }

      value = `+${value}`;

      // Если введено 13 символов - отключаем ввод новых символов
      if (value.length > 12) {
        value = value.slice(0, 13);
      }

      content_control.value = value;
    }

    if (control_name == 'bonuses') {
      content_control.value = content_control.value.replace(/[^0-9]+/, '');

      if (content_control.value.startsWith('0')) {
        content_control.value = content_control.value.slice(1);
      }

      if (!content_control.value.length) content_control.value = '0';
    }

    if (content_control.style.height) {
      content_control.style.height = 'auto';

      content_control.style.height = `${ content_control.scrollHeight + difference }px`;
    }

    if (control_name == 'username' || control_name == 'password') {
      content_control_init();
    }
  }

  function content_control_focus() {
    if (control_name == 'phone') content_control.value.length < 2 ? content_control.value = '+' : null;
  }

  function content_control_blur() {
    if (control_name == 'advantage') content_control.value.length ? content_control_validate('true', control_placeholder) : content_control_validate('false', '* Введите преимущества');
    if (control_name == 'disadvantage') content_control.value.length ? content_control_validate('true', control_placeholder) : content_control_validate('false', '* Введите недостатки');
    if (control_name == 'comment') content_control.value.length ? content_control_validate('true', control_placeholder) : content_control_validate('false', '* Введите комментарий');
    if (control_name == 'text_msg') content_control.value.length ? content_control_validate('true', control_placeholder) : content_control_validate('false', '* Введите сообщение');

    if (control_name == 'card_number') {
      if (content_control.value.match(/^81[0-9]{9}/)) {
        content_control_validate('true', control_placeholder);
      } else {
        content_control_validate('false', `* ${ control_placeholder }`);
      }
    }

    if (control_name == 'first_name') content_control.value.length ? content_control_validate('true', control_placeholder) : content_control_validate('false', '* Введите имя');
    if (control_name == 'last_name') content_control.value.length ? content_control_validate('true', control_placeholder) : content_control_validate('false', '* Введите фамилию');
    if (control_name == 'name') content_control.value.length ? content_control_validate('true', control_placeholder) : content_control_validate('false', '* Введите имя');

    if (control_name == 'phone') {
      if (content_control.value.length < 2) content_control.value = null;
      //^(\+380|\+7)\d{9,10}$
      if (content_control.value.match(/^((\+380\d{9})|(\+7\d{10}))$/)) {
        content_control_validate('true', control_placeholder);
      } else {
        content_control_validate('false', '* Введите номер');
      }
    }

    if (control_name == 'email') {
      if (content_control.value.match(/^[a-zа-я0-9_.-]+@[a-zа-я0-9]+[.][a-zа-я0-9]+/)) {
        content_control_validate('true', control_placeholder);
      } else {
        content_control_validate('false', '* Введите e-mail');
      }
    }

    if (control_name == 'city') content_control.value.length ? content_control_validate('true', control_placeholder) : content_control_validate('false', '* Введите город');
    if (control_name == 'street') content_control.value.length ? content_control_validate('true', control_placeholder) : content_control_validate('false', '* Введите улицу');
    if (control_name == 'house') content_control.value.length ? content_control_validate('true', control_placeholder) : content_control_validate('false', '* Введите дом');

    if (control_name == 'apartment') content_control.value.length ? content_control_validate('true', control_placeholder) : content_control_validate('false', `* ${ control_placeholder }`);

    if (control_name == 'username') content_control.value.length ? content_control_validate('true', control_placeholder) : content_control_validate('false', '* Введите номер телефона или e-mail');

    if (control_name == 'password') content_control.value.length ? content_control_validate('true', control_placeholder) : content_control_validate('false', '* Введите пароль');
    if (control_name == 'new_password') content_control.value.length ? content_control_validate('true', control_placeholder) : content_control_validate('false', '* Введите пароль');
    if (control_name == 'old_password') content_control.value.length ? content_control_validate('true', control_placeholder) : content_control_validate('false', '* Введите старый пароль');
    if (control_name == 'new_password1') content_control.value.length ? content_control_validate('true', control_placeholder) : content_control_validate('false', '* Введите новый пароль');
    if (control_name == 'new_password2') content_control.value.length ? content_control_validate('true', control_placeholder) : content_control_validate('false', '* Подтвердите новый пароль');
  }

  function content_control_validate(validate, placeholder) {
    // Устанавливаем атримбут валидации
    content_control.setAttribute('data-validate', validate);

    // Устанавлимаем плейсхолдер
    content_control.setAttribute('placeholder', placeholder);

    if (validate == 'true') {
      content_control.classList.remove('control_alert');
    } else {
      content_control.classList.add('control_alert');
    }
  }
}
