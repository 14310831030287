import { createSlice } from "@reduxjs/toolkit";
var initialState = {
    isShowSuccessPopup: false,
};
export var orderingSlice = createSlice({
    name: "ordering",
    initialState: initialState,
    reducers: {
        setIsShowSuccessPopup: function (state, _a) {
            var payload = _a.payload;
            state.isShowSuccessPopup = payload;
        },
    },
});
export var selectIsShowSuccessPopup = function (state) {
    return state.ordering.isShowSuccessPopup;
};
export var setIsShowSuccessPopup = orderingSlice.actions.setIsShowSuccessPopup;
