const spoilerAll = document.querySelectorAll('.spoiler');

spoilerAll.forEach(spoilerEach);

function spoilerEach(spoiler) {
  const spoilerInfo = spoiler.querySelector('.spoiler-info');
  const spoilerContent = spoiler.querySelector('.spoiler-content');

  spoilerInfo.addEventListener('click', spoilerInfoClick);

  function spoilerInfoClick() {
    if (spoilerContent.style.height != '0px') {
      spoilerInfo.classList.remove('_expanded');

      spoilerContent.style.height = '0px';
    } else {
      spoilerInfo.classList.add('_expanded');

      spoilerContent.style.height = `${spoilerContent.scrollHeight}px`;
    }
  }
}
