const product_inset = document.querySelector('.product-inset');

if (product_inset) {
  // Добавляем события на характеристики товара
  const specify_group_all = product_inset.querySelectorAll('.specify-tab > .tab-group');
  specify_group_all.forEach(specify_group_each);

  // Добавляем события на якоря
  const anchor_all = document.querySelectorAll('.anchor');
  anchor_all.forEach(anchor_each);

  function anchor_each(anchor) {
    anchor.addEventListener('click', anchor_click);

    function anchor_click(event) {
      event.preventDefault();

      // Делаем скролл до элемента вкладок
      product_inset.scrollIntoView(
        {
          behavior: 'smooth'
        }
      );

      const control_button_all = product_inset.querySelectorAll('.inset-control > .control-button');
      const content_tab_all = product_inset.querySelectorAll('.inset-content > .content-tab');

      // Отмечаем нужную кнопку как выбранную
      control_button_all.forEach(control_button_each);

      // Показываем вкладку которая соответсвует выбранной кнопке
      content_tab_all.forEach(content_tab_each);

      function control_button_each(control_button) {
        const button_hash = control_button.getAttribute('data-hash');

        if (button_hash != anchor.hash) {
          control_button.classList.remove('button_selected');
        } else {
          control_button.classList.add('button_selected');
        }
      }

      function content_tab_each(content_tab) {
        const tab_hash = content_tab.getAttribute('data-hash');

        if (tab_hash != anchor.hash) {
          content_tab.classList.add('tab_hidden');
        } else {
          content_tab.classList.remove('tab_hidden');
        }
      }
    }
  }
}

function specify_group_each(specify_group) {
  const group_info = specify_group.querySelector('.group-info');
  const group_content = specify_group.querySelector('.group-content');

  const info_indicate = group_info.querySelector('.info-indicate');

  group_info.addEventListener('click', group_info_click);

  group_content.addEventListener('transitionend', group_content_transitionend);

  function group_info_click() {
    info_indicate.classList.toggle('indicate_close');

    if (group_content.style.height != '0px') {
      group_content.style.height = `${ group_content.scrollHeight }px`;

      setTimeout(() => group_content.style.height = '0px', 20);
    } else {
      group_content.style.height = `${ group_content.scrollHeight }px`;
    }
  }

  function group_content_transitionend() {
    if (group_content.style.height != '0px') group_content.style.height = 'auto';
  }
}
