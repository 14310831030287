var _a;
import { createSlice } from "@reduxjs/toolkit";
import { cartApi } from "@src/shared/api";
var initialState = {
    isShowPopup: false,
    products: [],
};
export var cartSlice = createSlice({
    name: "cart",
    initialState: initialState,
    reducers: {
        setProducts: function (state, _a) {
            var payload = _a.payload;
            state.products = payload;
        },
        setIsShowCartPopup: function (state, _a) {
            var payload = _a.payload;
            state.isShowPopup = payload;
        },
    },
    extraReducers: function (builder) {
        builder
            .addMatcher(cartApi.endpoints.addCartProduct.matchFulfilled, function (state) {
            state.isShowPopup = true;
        })
            .addMatcher(cartApi.endpoints.getCart.matchFulfilled, function (state, action) {
            var _a, _b, _c;
            state.products =
                (_c = (_b = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.products) === null || _b === void 0 ? void 0 : _b.map(function (item) { return item.product.id; })) !== null && _c !== void 0 ? _c : [];
        });
    },
});
export var selectIsShowCartPopup = function (state) {
    return state.cart.isShowPopup;
};
export var selectCartProducts = function (state) { return state.cart.products; };
export var setIsShowCartPopup = (_a = cartSlice.actions, _a.setIsShowCartPopup), setProducts = _a.setProducts;
