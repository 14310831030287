import styles from "./styles.modules.scss"

import React from "react"
import cn from "classnames"

import {numberWithSpaces} from "../../../shared"

const ServiceItem = ({title, price, isCol}) => (
  <div className={cn(styles.serviceRow, isCol && styles.column)}>
    <span className={styles.title}>{title}</span>
    <span className={styles.price}>{numberWithSpaces(price)} ₽</span>
  </div>
)

export const ServicesList = ({services, isCol = false}) => (
  <div className={styles.root}>
    {services.map(item => (
      <ServiceItem key={item.id} title={item.name} price={item.price} isCol={isCol}/>
    ))}
  </div>
)
