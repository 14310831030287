import styles from './RatingStars.modules.scss'

import React from "react"
import cn from 'classnames'

import StarIcon from '../icons/Star'

const RatingStars = (
  {
    rating,
    maxRating = 5,
    width,
    height,
    className,
  }) => {
  let scoreRating = []
  rating = Math.round(rating)
  for (let i = 0; i < maxRating; i++) {
    scoreRating[i] = i >= rating
  }

  return (
    <div className={cn(styles.root, className)}>
      {scoreRating.map((el, index) => (
        <span className={styles.star} key={index}>
          <StarIcon width={width} height={height} empty={el}/>
        </span>
      ))}
    </div>
  )
}

export default RatingStars
