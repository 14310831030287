import styles from "./styles.modules.scss"

import React from "react"
import cn from "classnames"

export const PaymentInfo = ({isMobile = false}) => (
  <div className={cn(styles.root, isMobile && styles.mobile)}>
    <div className={styles.content}>
      <div className={styles.logos}>
        <img className={styles.psb} src={"/static/store/icons/logo_psb.png"} alt=""/>
        <img className={styles.mir} src={"/static/store/icons/logo_mir.png"} alt=""/>
      </div>

      <div className={styles.main}>
        <span className={styles.title}>Оплачивайте покупки онлайн</span>
        <span className={styles.text}>Теперь Вы можете оплачивать покупки в магазине Фокс с помощью банковской карты. Для этого Вам необходима любая карта выпущенная российским банком.</span>
      </div>
      <span className={styles.importantText}><span className={styles.highlight}>Важно!</span> После оформления заказа вы будете перенаправлены на страницу ПСБ для оплаты.</span>
      <span className={styles.importantText}><span className={styles.highlight}>Внимание!</span> Прежде чем совершить оплату заказа убедительная просьба согласовать с менеджером детали заказа и доставки товара, спасибо!</span>
    </div>
    <div className={styles.bgLayer}></div>
  </div>
)
