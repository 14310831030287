import styles from "./styles.modules.scss"

import React from "react"
import useMeasure from "react-use-measure"
import {useSpring, animated} from '@react-spring/web'
import cn from "classnames"

import {NumberIcon, Title} from "../shared"

export const StepWrapper = (
  {
    title,
    number,
    children,
    editBtn,
    backStepBtn,
    nextStepBtn,
    mode = "blank",
    isMobile = false
  }) => {
  // modes: blank, edit, filled

  const classes = cn(
    styles.root,
    isMobile && styles.mobile,
    {
      [styles.close]: mode === "blank"
    }
  )

  const isShowEditBtn = mode === "filled"
  const isShowControl = mode === "edit" && (backStepBtn || nextStepBtn)

  const [ref, {height}] = useMeasure()
  const wrapperProps = useSpring({
    height,
  })

  const contentProps = useSpring({
    delay: 100,
    opacity: mode === "blank" ? 0 : 1,
    display: mode === "blank" ? "none" : "block"
  })

  return (
    <div className={classes}>
      <animated.div style={wrapperProps}>
        <div ref={ref} className={styles.content}>
          <div className={styles.top}>
            <div className={styles.headingLayer}>
              {number && <NumberIcon number={number}/>}
              <Title isMobile={isMobile}>{title}</Title>
            </div>
            {!isMobile && isShowEditBtn && <div className={styles.editBtn}>{editBtn}</div>}
          </div>
          <animated.div style={contentProps}>
            <div className={styles.main}>
              {children}
              {isShowControl && (
                <div className={styles.control}>
                  <div className={styles.btn}>
                    {backStepBtn}
                  </div>
                  <div className={styles.btn}>
                    {nextStepBtn}
                  </div>
                </div>
              )}
              {isMobile && isShowEditBtn && <div className={styles.editBtn}>{editBtn}</div>}
            </div>
          </animated.div>
        </div>
      </animated.div>
    </div>
  )
}
