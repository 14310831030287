import { baseApi } from "./base";
export var productApi = baseApi.injectEndpoints({
    endpoints: function (build) { return ({
        getGroupedAttributes: build.query({
            query: function (_a) {
                var productSlug = _a.productSlug;
                return ({
                    url: "/product/".concat(productSlug, "/attributes/"),
                    method: "GET",
                });
            },
        }),
    }); },
});
export var useGetGroupedAttributesQuery = productApi.useGetGroupedAttributesQuery;
