var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import styles from "./FavouriteBtn.module.scss";
import Cookies from "js-cookie";
import cn from "classnames";
import { setActivePopup } from "@entities/popup";
import { HeartIcon } from "@shared/ui";
import { selectFavoritesProducts, setProducts } from "@src/entities/favorites";
import { useAppDispatch, useAppSelector } from "@src/shared/hooks";
import { useAddFavoriteProductMutation, useRemoveFavoriteProductMutation } from "@src/shared/api";
export var FavouriteBtn = function (_a) {
    var _b;
    var productId = _a.productId, _c = _a.isDesktop, isDesktop = _c === void 0 ? false : _c;
    var dispatch = useAppDispatch();
    var addProduct = useAddFavoriteProductMutation()[0];
    var removeProduct = useRemoveFavoriteProductMutation()[0];
    var productsIds = useAppSelector(selectFavoritesProducts);
    var isActive = productsIds.includes(productId);
    var handleClick = function (event) {
        event.preventDefault();
        if (isActive) {
            removeProduct({ productId: productId });
            dispatch(setProducts(productsIds.filter(function (item) { return item != productId; })));
        }
        else {
            addProduct({ productId: productId });
            dispatch(setProducts(__spreadArray(__spreadArray([], productsIds, true), [productId], false)));
            var isShowSignUp = Boolean(Cookies.get("show-sign-up"));
            if (!isShowSignUp) {
                dispatch(setActivePopup("signUpFavorites"));
                Cookies.set("show-sign-up", "true");
            }
        }
    };
    return (_jsx("div", { className: cn(styles.main, (_b = {}, _b[styles.active] = isActive, _b[styles.desktop] = isDesktop, _b)), onClick: handleClick, children: _jsx(HeartIcon, { isFilled: isActive }) }));
};
