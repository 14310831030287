import styles from "./Image.modules.scss"

import React from "react"
import cn from "classnames"

const Image = ({src, alt = "", className}) => {

  return (
    <div className={cn(styles.root, className)}>
      <img src={src || "/static/store/img/noimage.png"} alt={alt} className={cn(styles.image, className)}/>
    </div>
  )
}

export default Image