import { jsx as _jsx } from "react/jsx-runtime";
import { QueriesSection, QueryItem } from "@src/entities/search-popup";
import { useGetPopularQueriesQuery, } from "@src/shared/api/search";
import { ArrowSearchIcon, SearchIcon } from "@src/shared/ui";
export var PopularQueries = function (_a) {
    var _b = _a.isDesktop, isDesktop = _b === void 0 ? false : _b, onSelectQuery = _a.onSelectQuery;
    var queries = useGetPopularQueriesQuery().data;
    if (!(queries === null || queries === void 0 ? void 0 : queries.length))
        return null;
    var queriesItems = queries.map(function (item) { return (_jsx(QueryItem, { text: item.text, catalog: item.catalog, slots: {
            startIcon: _jsx(SearchIcon, {}),
            controlBtnIcon: _jsx(ArrowSearchIcon, {}),
        }, isDesktop: isDesktop, onClickQuery: function () { return onSelectQuery(item); }, onClickControl: function () { return onSelectQuery(item); } }, item.text)); });
    return (_jsx(QueriesSection, { title: "\u041F\u043E\u043F\u0443\u043B\u044F\u0440\u043D\u044B\u0435 \u0437\u0430\u043F\u0440\u043E\u0441\u044B", slots: { queriesItems: queriesItems }, isDesktop: isDesktop }));
};
