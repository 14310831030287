import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./Mobile.module.scss";
import { useState } from "react";
import cn from "classnames";
import { Bonus } from "../../Bonus";
import { ProductImages } from "../../CardImages";
import { CardTags } from "../../CardTags";
import { OldPrice } from "../../OldPrice";
import { Rating } from "../../Rating";
import { numberWithSpaces } from "@src/shared/lib";
import { SmallButton, Text } from "@src/shared/ui";
export var Mobile = function (_a) {
    var _b;
    var sku = _a.sku, name = _a.name, url = _a.url, price = _a.price, discountedPrice = _a.discountedPrice, status = _a.status, images = _a.images, rating = _a.rating, reviewsCount = _a.reviewsCount, bonusesAmount = _a.bonusesAmount, tags = _a.tags, slots = _a.slots, _c = _a.withImagesPagination, withImagesPagination = _c === void 0 ? true : _c, onClickLink = _a.onClickLink;
    var isAvailable = status.code === "IN_STOCK" || status.code === "LIMITED";
    var currentButton = null;
    if (slots === null || slots === void 0 ? void 0 : slots.inCartButton) {
        currentButton = isAvailable ? (slots === null || slots === void 0 ? void 0 : slots.inCartButton) : (_jsx(SmallButton, { text: "\u041D\u0435\u0442 \u0432 \u043D\u0430\u043B\u0438\u0447\u0438\u0438", isDisabled: true }));
    }
    var _d = useState(false), isVisibleTags = _d[0], setIsVisibleTags = _d[1];
    var handleToggleVisibility = function (value) {
        setIsVisibleTags(value);
    };
    var clickProductLinkHandler = function (event) {
        event.preventDefault();
        if (onClickLink)
            onClickLink();
        window.location.href = url;
    };
    return (_jsxs("a", { href: url, className: styles.main, onClick: clickProductLinkHandler, children: [(tags === null || tags === void 0 ? void 0 : tags.length) > 0 && (_jsx(CardTags, { className: styles.cardTags, tags: tags, isExpanded: isVisibleTags, onShow: handleToggleVisibility })), !!(slots === null || slots === void 0 ? void 0 : slots.favouriteBtn) && (_jsx("div", { className: styles.favourite, children: slots === null || slots === void 0 ? void 0 : slots.favouriteBtn })), !!(slots === null || slots === void 0 ? void 0 : slots.compareBtn) && (_jsx("div", { className: styles.compare, children: slots === null || slots === void 0 ? void 0 : slots.compareBtn })), _jsx("div", { className: styles.images, children: _jsx(ProductImages, { images: images.slice(0, 7), isNotAvailable: !isAvailable, withPagination: withImagesPagination }) }), _jsxs("div", { className: styles.ratingWrapper, children: [reviewsCount > 0 && (_jsx(Rating, { rating: rating, reviewsCount: reviewsCount })), _jsx(Text, { color: "#C0C0C0", variant: "body2", children: sku })] }), _jsx("div", { className: styles.nameWrapper, children: _jsx(Text, { variant: "body1", className: styles.name, children: name }) }), _jsxs("div", { className: styles.bonus, children: [price !== discountedPrice && (_jsx(OldPrice, { price: price, discountedPrice: discountedPrice })), bonusesAmount !== undefined && bonusesAmount > 0 && (_jsx(Bonus, { bonusesAmount: bonusesAmount }))] }), _jsxs("div", { className: cn(styles.purchase, (_b = {}, _b[styles.notAvailable] = !isAvailable, _b)), children: [_jsxs(Text, { variant: "bodyPrice", className: styles.price, children: [numberWithSpaces(discountedPrice), " \u20BD"] }), currentButton] })] }));
};
