import { createSlice } from "@reduxjs/toolkit"

import {shopsAPI} from "../../api/shopsApi"

const initialState = {
  items: [],
  status: "idle"
}

export const getShops = ({pickup = null}) => {
  return (dispatch) => {
    dispatch(setStatusShops("loading"))
    shopsAPI.getShops({pickup}).then(response => {
      if (response.status === 200) {
        dispatch(setShopsList(response.data))
        dispatch(setStatusShops("success"))
      } else {
        dispatch(setStatusShops("error"))
      }
    })
  }
}

export const shopsSlice = createSlice({
  name: "shops",
  initialState,
  reducers: {
    setStatusShops: (state, action) => {
      state.status = action.payload
    },
    setShopsList: (state, action) => {
      state.items = action.payload
    },
  }
})

export const selectShopsList = (state) => {
  return state.shops.items
}

export const selectStatusShops = (state) => {
  return state.shops.status
}

export const {setStatusShops, setShopsList} = shopsSlice.actions

export default shopsSlice.reducer
